<div class="lk-full-height lk-full-width lk-spaced-row">
	<div
		id="lk-auth-mfa-verify-form-layout"
		class="lk-full-height lk-double-padding">
		<form
			id="lk-auth-mfa-verify-form"
			[formGroup]="form_group"
			class="lk-shadow lk-border-radius lk-double-padding lk-spaced-row lk-wrapped-flex lk-primary-background lk-z-index">
			<div class="lk-spaced-row lk-full-width">
				<div
					class="lk-primary-dark-background"
					style="width: 20%; height: 2px"></div>
				<img
					style="width: 50%"
					[src]="logo_src" />
				<div
					class="lk-primary-dark-background"
					style="width: 20%; height: 2px"></div>
			</div>

			<p
				*ngIf="mfa_setup_mechanism == 'totp'"
				class="lk-double-margin-top">
				{{ 'AUTHENTICATION.MFA_VERIFY_TOTP_CTA' | translate }}
			</p>

			<p
				*ngIf="mfa_setup_mechanism == 'sms'"
				class="lk-double-margin-top">
				{{
					'AUTHENTICATION.MFA_VERIFY_SMS_CTA'
						| translate
							: {
									phone:
										pending_cognito_user.challengeParam
											.CODE_DELIVERY_DESTINATION
							  }
				}}
			</p>

			<mat-form-field
				appearance="fill"
				color="accent"
				style="font-size: 28px"
				class="lk-full-width lk-half-margin-bottom lk-form-field-large">
				<mat-label>
					{{ 'GENERAL.CODE_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="code"
					required />
			</mat-form-field>

			<button
				id="auth-mfa-verify-submit-button"
				mat-button
				class="lk-main-button lk-full-width lk-double-margin-top"
				(click)="onMfaVerifyClick()"
				[disabled]="is_mfa_verify_running || !isFormValid()"
				[ngClass]="getDisabledClass()">
				<h3
					class="lk-no-margin lk-primary-color"
					*ngIf="!is_mfa_verify_running">
					{{ 'GENERAL.CONFIRM_CTA' | translate }}
				</h3>
				<div
					class="lk-spinner-layout"
					*ngIf="is_mfa_verify_running">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>
		</form>
	</div>

	<div
		id="lk-auth-mfa-verify-img-layout"
		class="lk-full-height lk-double-padding lk-centered-row"
		[ngStyle]="{
			'background-image': 'url(' + background_src + ')'
		}"></div>
</div>
