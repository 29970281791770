import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Vehicle } from '../../core/vehicle.service';
import { VehicleBuilderDialogComponent } from '../../vehicle/vehicle-builder-dialog/vehicle-builder-dialog.component';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {
	SUPPORTED_LANGUAGES,
	KM_TO_MILES_COEFF,
	KM_LABEL,
	MILES_LABEL,
} from 'src/app/shared/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-vehicle-details-general',
	templateUrl: './vehicle-details-general.component.html',
	styleUrls: ['./vehicle-details-general.component.scss'],
})
export class VehicleDetailsGeneralComponent implements OnInit {
	@Input() vehicle: Vehicle;
	@Output() vehicleUpdateClick: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();

	constructor(
		private translate: TranslateService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit() {}

	onVehicleGeneralUpdateClick() {
		this.dialog_service
			.openDialog(VehicleBuilderDialogComponent, { vehicle: this.vehicle })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.vehicle) {
					this.vehicleUpdateClick.emit(dialogResult.vehicle);
				}
			});
	}

	getOnlineClass(vehicle: Vehicle) {
		if (vehicle) {
			return {
				'lk-vehicle-details-general-online-circle-enabled':
					vehicle?.stats?.online && vehicle?.stats?.online?.value,
				'lk-vehicle-details-general-online-circle-disabled':
					!vehicle?.stats?.online || !vehicle?.stats?.online?.value,
			};
		}
	}

	getOdometerValue(vehicle: Vehicle): number {
		switch (this.translate.getBrowserLang()) {
			case SUPPORTED_LANGUAGES[0]:
				return vehicle.stats.distance_covered.value / 1000;
			case SUPPORTED_LANGUAGES[1]:
				return (
					(vehicle.stats.distance_covered.value / 1000) * KM_TO_MILES_COEFF
				);
			default:
				return vehicle.stats.distance_covered.value / 1000;
		}
	}

	getOdometerMeasurementUnit() {
		switch (this.translate.getBrowserLang()) {
			case SUPPORTED_LANGUAGES[0]:
				return KM_LABEL;
			case SUPPORTED_LANGUAGES[1]:
				return MILES_LABEL;
			default:
				return KM_LABEL;
		}
	}
}
