<app-shared-app-toolbar
  [current_nav_item]="current_item"
  [refresh]="toolbar_refresh"
  (station_build_click)="onStationBuildClick(false)"
  (station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section class="lk-section-with-toolbar">
  <div class="lk-section-container-layout lk-centered-column lk-padding-md">
    <div class="lk-centered-column-wrapper">
      <div 
        style="max-width: 600px;"
        class="lk-border-radius lk-shadow lk-padding-md lk-primary-background">
        <h3 class="lk-centered-text" *ngIf="!is_invitation_loading"> 
          {{ 'STATION.PENDING_INVITATION' | translate }}  
          <span class="lk-accent-color"> {{ pending_invitation.invitation_sender_email }} </span>
          {{ 'STATION.PENDING_INVITATION_STATION_LABEL' | translate }}
          <span class="lk-accent-color"> {{ pending_invitation.station_name }}. </span>
        </h3>

        <div 
          class="lk-full-width lk-centered-row lk-wrapped-flex"
          *ngIf="!is_invitation_loading">
          <button 
            class="lk-secondary-button lk-half-margin" 
            mat-button
            [disabled]="is_reject_running" 
            [ngClass]="getRejectDisabledClass()"
            (click)="onResponseClick(false)">
            <span *ngIf="!is_reject_running"> 
              <span> {{ 'STATION.INVITATION_REFUSE_CTA' | translate }} </span>
            </span>
            <div  
              class="lk-spinner-layout" 
              *ngIf="is_reject_running">
              <mat-spinner 
                [diameter]="20"
                [color]="'accent'"></mat-spinner>
            </div>
          </button>

          <button 
            class="lk-main-button lk-half-margin" mat-button
            [disabled]="is_response_running" 
            [ngClass]="getResponseDisabledClass()"
            (click)="onResponseClick(true)">
            <span *ngIf="!is_response_running"> 
              {{ 'STATION.INVITATION_ACCEPT_CTA' | translate }} 
            </span>
            <div  
              class="lk-spinner-layout" 
              *ngIf="is_response_running">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </button>
        </div>

        <div 
          *ngIf="is_invitation_loading">
          <mat-spinner 
            [diameter]="30"
            [color]="'accent'"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</section>
