import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Station } from 'src/app/core/station.service';
import {
	STATION_NAVBAR_TEAM_ITEM_NAME,
	STATION_NAVBAR_NOTIF_ITEM_NAME,
	STATION_NAVBAR_PICS_ITEM_NAME,
	STATION_NAVBAR_UNLOCK_ITEM_NAME,
	STATION_NAVBAR_LOCK_ITEM_NAME,
	PROD_STAGE,
	STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME,
} from 'src/app/shared/constants';
import {
	STATION_NAVBAR_TEAM_LABEL,
	STATION_NAVBAR_NOTIF_LABEL,
	STATION_NAVBAR_PICS_LABEL,
	STATION_NAVBAR_UNLOCK_LABEL,
	STATION_NAVBAR_LOCK_LABEL,
	STATION_NAVBAR_DATA_SETTINGS_LABEL,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-station-navbar',
	templateUrl: './station-navbar.component.html',
	styleUrls: ['./station-navbar.component.scss'],
})
export class StationNavbarComponent implements OnInit {
	@Input() station?: Station;
	@Output() navbar_item_change: EventEmitter<string> =
		new EventEmitter<string>();
	current_item: string;
	is_production: boolean = false;
	items: string[];
	labels: string[];

	constructor() {}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;
		/*if (this.is_production) {
			this.items = [
				STATION_NAVBAR_NOTIF_ITEM_NAME,
				STATION_NAVBAR_UNLOCK_ITEM_NAME,
				STATION_NAVBAR_LOCK_ITEM_NAME,
				STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME,
			];
			this.labels = [
				STATION_NAVBAR_NOTIF_LABEL,
				STATION_NAVBAR_UNLOCK_LABEL,
				STATION_NAVBAR_LOCK_LABEL,
				STATION_NAVBAR_DATA_SETTINGS_LABEL,
			];
		} else {
			this.items = [
				STATION_NAVBAR_TEAM_ITEM_NAME,
				STATION_NAVBAR_NOTIF_ITEM_NAME,
				STATION_NAVBAR_PICS_ITEM_NAME,
				STATION_NAVBAR_UNLOCK_ITEM_NAME,
				STATION_NAVBAR_LOCK_ITEM_NAME,
				STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME,
			];
			this.labels = [
				STATION_NAVBAR_TEAM_LABEL,
				STATION_NAVBAR_NOTIF_LABEL,
				STATION_NAVBAR_PICS_LABEL,
				STATION_NAVBAR_UNLOCK_LABEL,
				STATION_NAVBAR_LOCK_LABEL,
				STATION_NAVBAR_DATA_SETTINGS_LABEL,
			];
		}*/

		if (this.station?.settings?.is_team_management_enabled) {
			this.items = [
				STATION_NAVBAR_NOTIF_ITEM_NAME,
				STATION_NAVBAR_UNLOCK_ITEM_NAME,
				STATION_NAVBAR_LOCK_ITEM_NAME,
				STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME,
				STATION_NAVBAR_TEAM_ITEM_NAME,
			];
			this.labels = [
				STATION_NAVBAR_NOTIF_LABEL,
				STATION_NAVBAR_UNLOCK_LABEL,
				STATION_NAVBAR_LOCK_LABEL,
				STATION_NAVBAR_DATA_SETTINGS_LABEL,
				STATION_NAVBAR_TEAM_LABEL,
			];
		} else {
			this.items = [
				STATION_NAVBAR_NOTIF_ITEM_NAME,
				STATION_NAVBAR_UNLOCK_ITEM_NAME,
				STATION_NAVBAR_LOCK_ITEM_NAME,
				STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME,
			];
			this.labels = [
				STATION_NAVBAR_NOTIF_LABEL,
				STATION_NAVBAR_UNLOCK_LABEL,
				STATION_NAVBAR_LOCK_LABEL,
				STATION_NAVBAR_DATA_SETTINGS_LABEL,
			];
		}

		this.current_item = this.items[0];
	}

	onItemClick(item: string): void {
		this.current_item = item;
		this.navbar_item_change.emit(item);
	}

	getItemOpacity(item: string): {} {
		return {
			opacity: this.current_item && this.current_item == item ? '1' : '.5',
		};
	}

	getItemBackgroundClass(item: string): { [key: string]: boolean } {
		return {
			'lk-station-navbar-selected-item':
				this.current_item && item == this.current_item,
			'lk-station-navbar-not-selected-item':
				!this.current_item || item != this.current_item,
		};
	}

	getItemLabel(item: string): string {
		switch (item) {
			case STATION_NAVBAR_TEAM_ITEM_NAME:
				return STATION_NAVBAR_TEAM_LABEL;
			case STATION_NAVBAR_NOTIF_ITEM_NAME:
				return STATION_NAVBAR_NOTIF_LABEL;
			case STATION_NAVBAR_PICS_ITEM_NAME:
				return STATION_NAVBAR_PICS_LABEL;
			case STATION_NAVBAR_UNLOCK_ITEM_NAME:
				return STATION_NAVBAR_UNLOCK_LABEL;
			case STATION_NAVBAR_LOCK_ITEM_NAME:
				return STATION_NAVBAR_LOCK_LABEL;
			case STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME:
				return STATION_NAVBAR_DATA_SETTINGS_LABEL;
			default:
				return STATION_NAVBAR_TEAM_LABEL;
		}
	}
}
