<app-shared-toolbar 
  [currentNavItem]="current_item" 
  [stationUpdateTrigger]="station_update_trigger"></app-shared-toolbar>

<section class="lk-section-with-toolbar lk-padding-md lk-spaced-column">
  <div class="lk-centered-column lk-full-width">
    <div 
      id="lk-station-builder-logo-form-layout" 
      class="lk-centered-column-wrapper">
      <div class="lk-centered-row lk-margin-bottom">
        <h1 class="lk-accent-color lk-no-margin lk-station-builder-step-text"> 2 </h1>
        <h1 class="lk-no-margin lk-half-margin-left"> {{ 'STATION_BUILDER.STEP_LOGO_TITLE' | translate }} </h1>
      </div>
  
      <div class="lk-shadow lk-border-radius lk-padding-md lk-primary-background">
        <div
          class="lk-centered-row lk-wrapped-flex"
          *ngIf="!is_request_running">
          <button 
            mat-button 
            id="lk-station-builder-logo-input-button" 
            (click)="fileInput.click()"
            class="lk-full-width lk-border-radius lk-centered-column" 
            *ngIf="!is_picture_loading && !image_key">
            <mat-icon class="lk-accent-color"> cloud_upload </mat-icon>
            <h3 class="lk-accent-color lk-no-margin"> upload </h3>
            <input 
              #fileInput 
              type="file" 
              accept="image/png" 
              (change)="onFileInput($event)" 
              style="display:none;">
          </button>
    
          <div 
            id="lk-station-builder-logo-img-layout" 
            class="lk-full-width lk-centered-column"
            *ngIf="!is_picture_loading && image_key">
            <img 
              id="lk-station-builder-logo-img" 
              [src]="image_key">
            <p 
              class="lk-cursor-link-hover lk-cursor-pointer lk-centered-text" 
              (click)="updateInput.click()">
              {{ 'STATION_BUILDER.UPDATE_LOGO_CTA' | translate }} 
            </p>
            <input 
              #updateInput 
              type="file" 
              accept="image/png" 
              (change)="onFileInput($event)" 
              style="display:none;">
          </div>
    
          <div 
            class="lk-full-width lk-padding lk-centered-row" 
            *ngIf="is_picture_loading">
            <mat-spinner 
              diameter="30" 
              color="accent"></mat-spinner>
          </div>
    
          <button 
            mat-button 
            class="lk-main-button lk-full-width lk-double-margin-top"
            (click)="onNextClick()" 
            *ngIf="!update_mode">
            <h3 class="lk-no-margin lk-primary-color"> {{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }} </h3>
          </button>
    
          <button 
            mat-button 
            class="lk-main-button lk-full-width lk-double-margin-top"
            (click)="onUpdateClick()" 
            *ngIf="update_mode">
            <h3 class="lk-no-margin lk-primary-color"> {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} </h3>
          </button>
        </div>

        <div 
          class="lk-centered-row"
          *ngIf="is_request_running">
          <mat-spinner 
            color="accent"
            diameter="30"></mat-spinner>
        </div>
      </div>
    </div>
  </div>

  <app-station-builder-steps [currentStep]="current_step"></app-station-builder-steps>
</section>
