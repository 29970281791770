import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingMedia, BookingMediaType } from 'src/app/core/booking-media.service';

@Component({
  selector: 'app-booking-details-gallery-dialog',
  templateUrl: './booking-details-gallery-dialog.component.html',
  styleUrls: ['./booking-details-gallery-dialog.component.scss']
})
export class BookingDetailsGalleryDialogComponent implements OnInit {
  gallery: BookingMedia[];
  current_index: number;
  type: BookingMediaType;
  pre_report_type: BookingMediaType = BookingMediaType.PRE_REPORT;
  post_report_type: BookingMediaType = BookingMediaType.POST_REPORT;

  constructor(
    private dialogRef: MatDialogRef<BookingDetailsGalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.gallery      = data.gallery;
    this.current_index = data.current;
    this.type         = data.type;
  }

  ngOnInit(): void {}

  onCloseClick() {
    this.dialogRef.close();
  }

  onNextClick() {
    this.current_index++;
  }

  onPreviousClick() {
    this.current_index--;
  }

  getCurrentBackground() {
    return {
      'background-image': 'url(' + this.gallery[this.current_index].content_url + ')'
    }
  }

  getLeftDisabledCond() {
    return this.current_index == 0;
  }

  getRightDisabledCond() {
    return this.current_index == this.gallery.length - 1;
  }

  getLeftDisabledClass() {
    return {
      'lk-booking-details-gallery-arrow-disabled':  this.getLeftDisabledCond(),
      '': !this.getLeftDisabledCond() 
    }
  }

  getRightDisabledClass() {
    return {
      'lk-booking-details-gallery-arrow-disabled':  this.getRightDisabledCond(),
      '': !this.getRightDisabledCond() 
    }
  }
}
