<div>
    <div class="lk-ended-row">
      <button 
        mat-icon-button 
        (click)="onCloseClick()" 
        class="lk-primary-medium-background">
        <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
      </button>
    </div>

    <div  
      id="lk-booking-settings-general-content-layout" 
      style="padding-top: 0"
      class="lk-padding lk-hide-scrollbar">
      <h2 class="lk-no-margin">
        {{ 'STATION_BUILDER.STEP_NOTIF_TITLE' | translate }}
      </h2>
      <p class="lk-primary-medium-color lk-no-margin lk-margin-bottom">
        {{ 'STATION_BUILDER.STEP_NOTIF_SUBTITLE' | translate }}
      </p>
      <div class="lk-column">
        <mat-slide-toggle [(ngModel)]="is_sms_checked">
          {{ 'STATION_BUILDER.SMS_NOTIF_LABEL' | translate }}
          <br> 
          <p 
            class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label"
            style="max-width: 250px">
            {{ 'STATION_BUILDER.SMS_NOTIF_DESCRIPTION' |translate }} 
          </p>
        </mat-slide-toggle>
        <mat-slide-toggle 
          [(ngModel)]="is_email_checked" 
          class="lk-margin-top">
          {{ 'STATION_BUILDER.EMAIL_NOTIF_LABEL' | translate }}
          <br> <p 
            class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label"
            style="max-width: 250px">
          {{ 'STATION_BUILDER.EMAIL_NOTIF_DESCRIPTION' |translate }} </p>
        </mat-slide-toggle>
      </div>

      <h2 class="lk-no-margin lk-double-margin-top">
        {{ 'STATION.SETTINGS_NOTIF_LANG_CTA' | translate }}
      </h2>
      <p class="lk-primary-medium-color lk-no-margin lk-margin-bottom">
        {{ 'STATION.SETTINGS_LANG_SUBTITLE' | translate }}
      </p>
      <form 
        [formGroup]="form_group" 
        class="lk-margin-bottom">
        <mat-form-field 
          appearance="fill" 
          color="accent">
          <mat-label> {{ 'STATION.SETTINGS_NOTIF_LANG_CTA' | translate }} </mat-label>
          <mat-select formControlName="lang">
            <mat-option 
              *ngFor="let lang of languages" 
              [value]="lang" 
              class="lk-options-list-item">
              <img [src]="'../../../assets/imgs/lk-flag-icons/' + lang + '.png'">
              {{ getLanguageLabel(lang) | translate }} 
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <div *ngIf="!is_production">
        <h2 class="lk-no-margin">
          {{ 'STATION.SETTINGS_PIC_POLICY_TITLE' | translate }}
        </h2>
        <p class="lk-primary-medium-color lk-no-margin lk-margin-bottom">
          {{ 'STATION.SETTINGS_PIC_POLICY_SUBTITLE' | translate }}
        </p>
        
        <div class="lk-column">
          <mat-slide-toggle 
            [(ngModel)]="is_policy_mandatory"
            (change)="onToggleChange($event, mandatory_policy)">
            {{ 'GENERAL.PICTURES_MANDATORY_POLICY_TITLE' | translate }}
            <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
            {{ 'GENERAL.PICTURES_MANDATORY_POLICY_SUBTITLE' |translate }} </p>
          </mat-slide-toggle>
      
          <mat-slide-toggle 
            class="lk-margin-top" 
            [(ngModel)]="is_policy_mandatory_pre"
            (change)="onToggleChange($event, mandatory_pre_policy)">
            {{ 'GENERAL.PICTURES_PICK_UP_POLICY_TITLE' | translate }}
            <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
            {{ 'GENERAL.PICTURES_PICK_UP_POLICY_SUBTITLE' |translate }} </p>
          </mat-slide-toggle>
      
          <mat-slide-toggle 
            class="lk-margin-top" 
            [(ngModel)]="is_policy_mandatory_post"
            (change)="onToggleChange($event, mandatory_post_policy)">
            {{ 'GENERAL.PICTURES_DROP_OFF_POLICY_TITLE' | translate }}
            <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
            {{ 'GENERAL.PICTURES_DROP_OFF_POLICY_SUBTITLE' |translate }} </p>
          </mat-slide-toggle>
      
          <mat-slide-toggle 
            class="lk-margin-top" 
            [(ngModel)]="is_policy_optional"
            (change)="onToggleChange($event, optional_policy)">
            {{ 'GENERAL.PICTURES_OPTIONAL_POLICY_TITLE' | translate }}
            <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
            {{ 'GENERAL.PICTURES_OPTIONAL_POLICY_SUBTITLE' |translate }} </p>
          </mat-slide-toggle>
      
          <mat-slide-toggle 
            class="lk-margin-top"   
            [(ngModel)]="is_policy_disabled"
            (change)="onToggleChange($event, disabled_policy)">
            {{ 'GENERAL.PICTURES_DISABLED_POLICY_TITLE' | translate }}
            <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
            {{ 'GENERAL.PICTURES_DISABLED_POLICY_SUBTITLE' |translate }} </p>
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div  class="lk-centered-row lk-full-width lk-padding">
      <button 
        id="lk-booking-settings-general-confirm-button"
        mat-button 
        class="lk-main-button" 
        (click)="onConfirmClick()">
        <span> {{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }} </span>
      </button>
    </div>
</div>
