<div>
    <div class="lk-ended-row">
      <button 
        mat-icon-button 
        (click)="onCloseClick()" 
        class="lk-primary-medium-background">
        <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
      </button>
    </div>

    <div 
      id="lk-station-builder-turo-content-layout" 
      class="lk-padding">
      <form [formGroup]="form_group">
        <mat-form-field 
          class="lk-full-width lk-margin-bottom" 
          appearance="fill" 
          color="accent">
            <mat-label> Turo email </mat-label>
            <input 
              type="email" 
              matInput 
              formControlName="email">
        </mat-form-field>

        <div class="lk-full-width lk-centered-row">
          <button 
            class="lk-main-button lk-half-margin-bottom" 
            (click)="onConfirmClick()" 
            mat-button>
              <p class="lk-no-margin lk-primary-color"> {{ 'GENERAL.CONFIRM_CTA' | translate }} </p>
          </button>
        </div>
      </form>
    </div>
  </div>
  