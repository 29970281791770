import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { NotificationType } from 'src/app/core/notification.service';
import { Station, StationService } from 'src/app/core/station.service';
import {
	LOCAL_STATION_KEY,
	SUPPORTED_LANGUAGES,
} from 'src/app/shared/constants';
import {
	GENERAL_ERROR_MESSAGE,
	SUPPORTED_LANGUAGES_LABELS,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-station-notif-settings',
	templateUrl: './station-notif-settings.component.html',
	styleUrls: ['./station-notif-settings.component.scss'],
})
export class StationNotifSettingsComponent implements OnInit {
	@Input() station: Station;
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();
	@Output() start_spinner: EventEmitter<void> = new EventEmitter<void>();
	is_sms_checked: boolean = false;
	is_email_checked: boolean = false;
	is_request_running: boolean = false;
	form_group: FormGroup;
	languages: string[] = SUPPORTED_LANGUAGES;

	constructor(
		private station_service: StationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private form_service: FormService,
	) {
		this.form_group = this.form_service.stationSettingLangFormGroup;
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.is_sms_checked =
			this.station.settings.default_notification_type ==
				NotificationType.BOTH ||
			this.station.settings.default_notification_type == NotificationType.SMS;
		this.is_email_checked =
			this.station.settings.default_notification_type ==
				NotificationType.BOTH ||
			this.station.settings.default_notification_type == NotificationType.EMAIL;
		this.form_group.get('lang').setValue(this.station.settings.default_lang);
	}

	onReset(station: Station): void {
		this.station = station;
		this.is_sms_checked =
			this.station.settings.default_notification_type ==
				NotificationType.BOTH ||
			this.station.settings.default_notification_type == NotificationType.SMS;
		this.is_email_checked =
			this.station.settings.default_notification_type ==
				NotificationType.BOTH ||
			this.station.settings.default_notification_type == NotificationType.EMAIL;
		this.form_group.get('lang').setValue(this.station.settings.default_lang);
	}

	onSmsChange(event: any): void {
		if (this.is_sms_checked && this.is_email_checked) {
			this.station.settings.default_notification_type = NotificationType.BOTH;
			this.updateStation(this.station);
		} else if (this.is_sms_checked && !this.is_email_checked) {
			this.station.settings.default_notification_type = NotificationType.SMS;
			this.updateStation(this.station);
		} else if (!this.is_sms_checked && this.is_email_checked) {
			this.station.settings.default_notification_type = NotificationType.EMAIL;
			this.updateStation(this.station);
		} else if (!this.is_sms_checked && !this.is_email_checked) {
			this.is_sms_checked = true;
			event.source.checked = true;
		}
	}

	onEmailChange(event: any): void {
		if (this.is_sms_checked && this.is_email_checked) {
			this.station.settings.default_notification_type = NotificationType.BOTH;
			this.updateStation(this.station);
		} else if (this.is_sms_checked && !this.is_email_checked) {
			this.station.settings.default_notification_type = NotificationType.SMS;
			this.updateStation(this.station);
		} else if (!this.is_sms_checked && this.is_email_checked) {
			this.station.settings.default_notification_type = NotificationType.EMAIL;
			this.updateStation(this.station);
		} else if (!this.is_sms_checked && !this.is_email_checked) {
			this.is_email_checked = true;
			event.source.checked = true;
		}
	}

	onLangSelectChange(lang: string): void {
		if (this.form_service.validateForm(this.form_group)) {
			this.station.settings.default_lang = lang;
			this.updateStation(this.station);
		}
	}

	updateStation(station: Station): void {
		this.is_request_running = true;
		this.start_spinner.emit();
		const station_logo_path = station.logo_path;
		const station_logo_url = station.logo_url;
		this.station_service.updateStation(station).subscribe({
			next: response => {
				const station: Station = response.data;
				station.logo_path = station_logo_path;
				station.logo_url = station_logo_url;
				localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(station));
				this.refresh.emit();
				this.is_request_running = false;
			},
			error: error => {
				this.refresh.emit();
				this.is_request_running = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	getLanguageLabel(lang: string): string {
		switch (lang) {
			case SUPPORTED_LANGUAGES[0]:
				return SUPPORTED_LANGUAGES_LABELS[0];
			case SUPPORTED_LANGUAGES[1]:
				return SUPPORTED_LANGUAGES_LABELS[1];
			case SUPPORTED_LANGUAGES[2]:
				return SUPPORTED_LANGUAGES_LABELS[2];
			case SUPPORTED_LANGUAGES[3]:
				return SUPPORTED_LANGUAGES_LABELS[3];
		}
	}
}
