import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { Address } from './core.module';

export const ADMINS_API_NAME: string = 'admins';
export const ADMINS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/admins/';

@Injectable({
	providedIn: 'root',
})
export class AdminService {
	listAdminsOfStation(stationId: string) {
		let apiName: string = ADMINS_API_NAME;
		let path: string = 'stations/' + stationId;
		let init = {
			response: true,
		};
		return from(API.get(apiName, path, init));
	}

	removeAdminsFromStation(stationId: string, emails: string[]) {
		let apiName: string = ADMINS_API_NAME;
		let path: string = 'stations/' + stationId + '/remove';
		let init = {
			response: true,
			body: emails,
		};
		return from(API.post(apiName, path, init));
	}

	resetMfa(email: string, phone: { prefix: string; value: string }) {
		//TODO: check super admin authentication
		let apiName: string = ADMINS_API_NAME;
		let path: string = 'reset-mfa';
		let init = {
			response: true,
			body: { email, phone },
		};
		return from(API.post(apiName, path, init));
	}

	setAsSuperAdmin(email: string) {
		let apiName: string = ADMINS_API_NAME;
		let path: string = `set-as-super-admin/${email}`;
		let init = {
			response: true,
		};
		return from(API.post(apiName, path, init));
	}

	removeAsSuperAdmin(admin_id: string) {
		let apiName: string = ADMINS_API_NAME;
		let path: string = `remove-as-super-admin/${admin_id}`;
		let init = {
			response: true,
		};
		return from(API.del(apiName, path, init));
	}

	listSuperAdmin() {
		let apiName: string = ADMINS_API_NAME;
		let path: string = `super-admin`;
		let init = {
			response: true,
		};
		return from(API.get(apiName, path, init));
	}
}

export class Admin {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	lang: string;
	business_data: BusinessData;
	is_confirmed: boolean;
	created_at: number;
	updated_at: number;
}

export class BusinessData {
	business_name: string;
	vat: string;
	sdi: string;
	address: Address;
}
