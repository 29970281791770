<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-booking-time-builder-content-layout"
		class="lk-horiz-padding lk-hide-scrollbar">
		<h3>{{ 'BOOKING.ADD_TIME_DIALOG_TITLE' | translate }}</h3>
		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'BOOKING.START_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-booking-time-builder-start-date-input"
					matInput
					type="text"
					formControlName="start_date"
					required
					[matDatepicker]="startPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-half-margin-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.START_HOUR_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-start-hour-select"
					formControlName="start_hour"
					required>
					<mat-option
						*ngFor="let hour of hours"
						[value]="hour">
						{{ hour }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-half-margin-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.START_MINUTE_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-start-minute-select"
					formControlName="start_minute"
					required>
					<mat-option
						*ngFor="let minute of minutes"
						[value]="minute">
						{{ minute }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				class="lk-half-width lk-half-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'BOOKING.END_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-booking-time-builder-end-date-input"
					type="text"
					matInput
					formControlName="end_date"
					required
					[matDatepicker]="endPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-half-margin-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.END_HOUR_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-end-hour-select"
					formControlName="end_hour"
					required>
					<mat-option
						*ngFor="let hour of hours"
						[value]="hour">
						{{ hour }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-half-margin-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.END_MINUTE_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-end-minute-select"
					formControlName="end_minute"
					required>
					<mat-option
						*ngFor="let minute of minutes"
						[value]="minute">
						{{ minute }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label> Timezone </mat-label>
				<mat-select
					id="lk-booking-time-builder-timezone-select"
					formControlName="timezone"
					required>
					<mat-option
						*ngFor="let timezone of timezones"
						[value]="timezone">
						({{ getTimezoneOffset(timezone) }})
						{{ timezone }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<div class="lk-centered-row lk-full-width lk-margin-top">
				<button
					id="lk-booking-time-builder-confirm-button"
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()">
					<span> {{ 'GENERAL.CONFIRM_CTA' | translate }} </span>
				</button>
			</div>
		</form>
	</div>
</div>
