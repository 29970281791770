import {Component} from '@angular/core';
import {Station, StationService} from '../../core/station.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {STATION_BUILDER_LOGO_STEP, TOOLBAR_STATION_NAV_ITEM_NAME} from 'src/app/shared/translations';
import {environment} from 'src/environments/environment';
import {PROD_STAGE} from 'src/app/shared/constants';
import {STATION_BUILDER_TEAM_PATH, STATION_PATH} from 'src/app/core/navigation.service';

@Component({
  selector: 'app-station-builder-logo',
  templateUrl: './station-builder-logo.component.html',
  styleUrls: ['./station-builder-logo.component.scss']
})
export class StationBuilderLogoComponent {
  station_id: string;
  station: Station;
  image_key: string;
  station_update_trigger: BehaviorSubject<boolean>  = new BehaviorSubject<boolean>(null);
  current_item: string = TOOLBAR_STATION_NAV_ITEM_NAME;
  update_mode: boolean = false;
  is_request_running: boolean = false;
  is_picture_loading: boolean = false;
  current_step: string = STATION_BUILDER_LOGO_STEP;
  is_production: boolean;
  

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private station_service: StationService
  ) {
    this.station_id = this.route.snapshot.paramMap.get('station_id');
    this.route.queryParams.subscribe(params => {
      if (params && params.update) {
        this.update_mode = params.update;
      }
      }
    );
    if (!this.station_id) {
      this.router.navigate([STATION_PATH]);
    }
  }

  ngOnInit() {
    this.is_production = environment.STAGE == PROD_STAGE;
    this.station_update_trigger.next(false);
    if (this.station_id) {
      this.readStation();
    }
  }

  onNextClick() {
    if (this.is_production) {
      this.router.navigate([STATION_PATH]);
    } else {
      this.router.navigate([STATION_PATH + '/' + this.station_id + STATION_BUILDER_TEAM_PATH]);
    }
  }

  onUpdateClick() {
    this.router.navigate([STATION_PATH]);
  }

  onFileInput(event) {
    if (this.station) {
      let file: File = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        const image : string | ArrayBuffer  = reader.result;
        this.is_picture_loading = true;
        this.station_service.setLogo(this.station_id, image.toString()).subscribe({
          next: (response) => {
            this.image_key = response.data.logo_url;
            this.is_picture_loading = false;
          },
          error: (error) => {
            this.is_picture_loading = false;
          }
        });
      }
    }
  }

  readStation() {
    this.is_request_running = true;
    this.station_service.readStation(this.station_id).subscribe({
      next: (response) => {
        this.station = response.data;
        this.image_key = this.station.logo_url;
        this.is_request_running = false;
      },
      error: (error) => {
        this.router.navigate([STATION_PATH]);
        this.is_request_running = false;
      }
    });
  }

  isStepTextVisible(step: string) {
    return step == this.current_step;
  }

  getBarClass(step: string) {
    return {
      'lk-accent-background': step == this.current_step,
      'lk-primary-medium-background': step != this.current_step
    }
  }
}
