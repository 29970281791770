<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-vehicle-builder-content-layout"
		class="lk-horiz-padding lk-hide-scrollbar">
		<h3>{{ 'VEHICLE.ADD_DIALOG_TITLE' | translate }}</h3>
		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label> {{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-vehicle-builder-plate-input"
					matInput
					type="text"
					formControlName="plate"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label>
					{{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate }}
				</mat-label>
				<mat-select
					id="lk-vehicle-builder-category-input"
					formControlName="category">
					<mat-option
						*ngFor="let category of categories"
						[value]="category">
						{{ category | titlecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label>
					{{ 'VEHICLE.ADPATER_ID_COLUMN_LABEL' | translate }}
				</mat-label>
				<input
					id="lk-vehicle-builder-adapter-input"
					matInput
					type="text"
					formControlName="adapter_id" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-vehicle-builder-brand-input"
					matInput
					type="text"
					formControlName="brand"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-vehicle-builder-model-input"
					matInput
					type="text"
					formControlName="model"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'VEHICLE.COLOR_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-vehicle-builder-color-input"
					matInput
					type="text"
					formControlName="color" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'VEHICLE.YEAR_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-vehicle-builder-year-input"
					matInput
					type="text"
					formControlName="year"
					required />
			</mat-form-field>

			<mat-form-field
				*ngIf="!is_production"
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label>
					{{ 'VEHICLE.INITIAL_STATUS_COLUMN_LABEL' | translate }}
				</mat-label>
				<mat-select
					id="lk-vehicle-builder-status-select"
					formControlName="initial_status">
					<mat-option value="{{ locked_status }}">
						{{ locked_status | titlecase }}
					</mat-option>
					<mat-option value="{{ unlocked_status }}">
						{{ unlocked_status | titlecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-expansion-panel
				class="lk-full-width"
				style="box-shadow: none">
				<mat-expansion-panel-header style="padding: 0 12px">
					<mat-panel-title class="lk-ended-row">
						<p
							id="lk-vehicle-builder-ext-panel"
							class="lk-primary-medium-color lk-small-text-size">
							{{ 'VEHICLE.TURO_VEHICLE_LINK_CTA' | translate }}
						</p>
						<img
							style="width: 50px"
							src="../../../assets/imgs/lk-turo-logo.png" />
						<p
							class="lk-small-text-size lk-no-margin lk-right-text"
							style="margin-left: -4px !important">
							?
						</p>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="lk-full-width lk-centered-row lk-margin-top">
					<form [formGroup]="form_group">
						<mat-form-field
							appearance="fill"
							color="accent"
							class="lk-full-width lk-half-margin-bottom">
							<mat-label> Ext. vehicle ID </mat-label>
							<input
								id="lk-vehicle-builder-ext-id-input"
								matInput
								type="text"
								formControlName="ext_id" />
						</mat-form-field>
					</form>
				</div>
			</mat-expansion-panel>

			<div class="lk-centered-row lk-full-width lk-margin-top">
				<button
					id="lk-vehicle-builder-confirm-button"
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()">
					<span> {{ 'GENERAL.CONFIRM_CTA' | translate }} </span>
				</button>
			</div>
		</form>
	</div>
</div>
