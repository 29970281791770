<div>
  <div class="lk-ended-row">
    <button 
      mat-icon-button 
      (click)="onCloseClick()">
      <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
    </button>
  </div>
  <div 
    id="lk-station-builder-notif-content-layout" 
    class="lk-horiz-padding lk-margin-bottom">
    <h3 class="lk-centered-text"> {{ 'STATION_BUILDER.NOTIF_DIALOG_MESSAGE' | translate }} </h3>
    <div class="lk-centered-row lk-wrapped-flex">
      <button 
        class="lk-station-builder-notif-action-layout lk-centered-column
          lk-shadow lk-border-radius lk-half-margin" (click)="onAddClick()" mat-button>
        <mat-icon class="lk-half-margin-top lk-accent-color"> add </mat-icon>
        <p class="lk-no-margin lk-half-margin-bottom lk-accent-color">
          {{ 'STATION_BUILDER.ADD_LANGUAGE_CTA' | translate }}
        </p>
      </button>

      <button 
        class="lk-station-builder-notif-action-layout lk-centered-column
          lk-shadow lk-border-radius lk-half-margin" 
        (click)="onStoreClick()" 
        mat-button>
        <mat-icon class="lk-half-margin-top lk-accent-color"> arrow_forward </mat-icon>
        <p class="lk-no-margin lk-half-margin-bottom lk-accent-color">
          {{ 'STATION_BUILDER.NOTIF_DIALOG_CONFIRM_MESSAGE' | translate }}
        </p>
      </button>
    </div>
  </div>
</div>
