<mat-expansion-panel
	style="box-shadow: none"
	[expanded]="is_expanded">
	<mat-expansion-panel-header>
		<div class="lk-spaced-row lk-full-width">
			<div class="lk-row">
				<img
					class="lk-flag-mini lk-no-margin"
					src="{{ getStationFlag() }}" />
				<p class="lk-no-margin lk-half-margin-left">
					{{ getTemplateLabel() | translate }}
				</p>
			</div>

			<div class="lk-row">
				<div
					*ngIf="is_default_selected"
					class="lk-hidden-before-md lk-row">
					<mat-icon class="lk-half-margin-right"> check_circle </mat-icon>
					<app-shared-pill [text]="'default template'"></app-shared-pill>
				</div>

				<div
					*ngIf="!is_default_selected"
					class="lk-hidden-before-md lk-row">
					<mat-icon class="lk-accent-color lk-half-margin-right">
						check_circle
					</mat-icon>

					<app-shared-pill
						[background]="'lk-accent-background lk-hidden-before-md'"
						[color]="'lk-primary-color'"
						[text]="'custom template'"></app-shared-pill>
				</div>

				<button
					mat-icon-button
					matTooltip="{{ 'STATION.TEST_NOTIF_CTA' | translate }}"
					(click)="$event.stopPropagation(); onTestClick()">
					<mat-icon [ngClass]="is_default_selected ? '' : 'lk-accent-color'">
						send
					</mat-icon>
				</button>
			</div>
		</div>
	</mat-expansion-panel-header>

	<div class="lk-full-width lk-spaced-row lk-wrapped-flex lk-bottom-margin">
		<app-stations-notif-templates-item-container
			class="lk-half-margin-top lk-half-width"
			[template_type]="template?.type || sms_type"
			[is_selected]="is_default_selected"
			[pill_text]="'default template'"
			(container_click)="onTemplateClick(true)"
			(default_click)="onDefaultClick()">
		</app-stations-notif-templates-item-container>

		<app-stations-notif-templates-item-container
			*ngIf="template?.custom"
			class="lk-half-margin-top lk-half-width"
			[template_type]="template?.type || sms_type"
			[is_default]="false"
			[is_selected]="!is_default_selected"
			[color]="'lk-accent-color'"
			[pill_background]="'lk-accent-background'"
			[pill_color]="'lk-primary-color'"
			[pill_text]="'custom template'"
			(container_click)="onTemplateClick(false)"
			(custom_update_click)="onCustomUpdateClick()"
			(custom_upload_click)="onCustomUploadClick($event)"
			(custom_delete_click)="onCustomDeleteClick()">
		</app-stations-notif-templates-item-container>

		<button
			*ngIf="!template?.custom"
			(click)="onAddTemplateClick()"
			id="lk-stations-notif-tenplates-item-add-button"
			class="lk-half-margin-top lk-half-width lk-primary-medium-button lk-border-radius"
			mat-button>
			<mat-icon
				matPrefix
				class="lk-accent-color">
				add
			</mat-icon>
			<span class="lk-accent-color">
				{{ 'STATION_BUILDER.ADD_TEMPLATE_CTA' | translate }}
			</span>
		</button>
	</div>
</mat-expansion-panel>
