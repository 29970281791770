import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';

export const UNLOCK_COMMANDS_API_NAME: string = 'unlock_commands';
export const UNLOCK_COMMANDS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/unlock-host-commands/';

@Injectable({
	providedIn: 'root',
})
export class UnlockCommandsService {
	max_read_retry: number = 10;
	current_read_index: number = 0;

	createUnlockCommand(stationId: string, vehicleId: string) {
		const apiName: string = UNLOCK_COMMANDS_API_NAME;
		const path: string = 'stations/' + stationId + '/vehicles/' + vehicleId;
		const init = { response: true };
		return from(API.post(apiName, path, init));
	}

	readUnlockCommand(commandId: string, stationId: string, vehicleId: string) {
		const apiName: string = UNLOCK_COMMANDS_API_NAME;
		const path: string =
			commandId + '/stations/' + stationId + '/vehicles/' + vehicleId;
		const init = { response: true };
		return from(API.get(apiName, path, init));
	}
}

export class UnlockCommand {
	id: string;
	source: string;
	station_id: string;
	vehicle_id: string;
	booking_id?: string;
	status: UnlockCommandStatus;
	error: {
		code: string;
		description: string;
	};
}

export enum UnlockCommandStatus {
	PENDING = 'pending',
	SUCCEEDED = 'succeeded',
	FAILED = 'failed',
}
