import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { DEFAULT_STATION_LOGO } from 'src/app/shared/constants';
import { STATION_DELETE_MESSAGE } from 'src/app/shared/translations';
import { Station, StationService } from '../../core/station.service';
import { StationDeleteDialogComponent } from '../station-delete-dialog/station-delete-dialog.component';

@Component({
	selector: 'app-station-basics',
	templateUrl: './station-basics.component.html',
	styleUrls: ['./station-basics.component.scss'],
})
export class StationBasicsComponent {
	@Input() station: Station;
	@Input() logo: string;
	@Output() station_build_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() logo_build_click: EventEmitter<Station> =
		new EventEmitter<Station>();
	@Output() station_delete_click: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private station_service: StationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	onLogoUpdateClick(event: any): void {
		const file: File = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			const image: string | ArrayBuffer = reader.result;
			this.logo = null;
			this.station_service
				.setLogo(this.station.id, image.toString())
				.subscribe({
					next: response => {
						this.station = response.data as Station;
						this.logo = this.station.logo_url;
						this.logo_build_click.emit(this.station);
					},
					error: error => {
						this.logo = DEFAULT_STATION_LOGO;
					},
				});
		};
	}

	onBasicsUpdateClick(): void {
		this.station_build_click.emit();
	}

	onDeleteClick(): void {
		this.dialog_service
			.openDialog(StationDeleteDialogComponent, {
				station: this.station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.dialog_service.openInfoDialog(STATION_DELETE_MESSAGE);
					this.station_delete_click.emit();
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}
}
