<div
	class="lk-full-width lk-half-margin-bottom lk-horiz-half-padding lk-centered-row">
	<div
		id="lk-vehicle-details-navbar-list"
		style="overflow-x: scroll"
		class="lk-row lk-hide-scrollbar lk-cursor-pointer">
		<div
			*ngFor="let item of items"
			(click)="onItemClick(item)"
			[ngClass]="getItemBackgroundClass(item)"
			class="lk-margin-right">
			<span>
				{{ getItemLabel(item) | translate | titlecase }}
			</span>
		</div>
	</div>
</div>
