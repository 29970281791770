<div
	id="lk-station-builder-dialog-root-layout"
	class="lk-hide-scrollbar">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			class="lk-background-icon"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<form
		[formGroup]="form_group"
		class="lk-full-width lk-padding">
		<div class="lk-spaced-row lk-wrapped-flex">
			<mat-form-field
				class="lk-full-width lk-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'GENERAL.STATION_NAME_LABEL' | translate }} </mat-label>
				<input
					type="text"
					matInput
					formControlName="name"
					required />
			</mat-form-field>

			<mat-form-field
				class="lk-full-width lk-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
				<input
					type="email"
					matInput
					formControlName="email"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				floatLabel="always"
				color="accent"
				class="lk-third-width lk-margin-bottom">
				<mat-label> {{ 'GENERAL.PHONE_PREFIX_LABEL' | translate }} </mat-label>
				<img
					*ngIf="current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="unknown_flag_src" />

				<input
					type="text"
					matInput
					formControlName="phone_prefix"
					required
					[matAutocomplete]="autocountry" />
				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								style="min-width: 32px; height: 24px"
								class="lk-half-margin-right"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="
										max-width: calc(100% - 6px);
										font-size: 10px;
										line-height: 18px;
									"
									class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-no-margin lk-ellipsized-text">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<!--<mat-form-field
				class="lk-third-width lk-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'GENERAL.PHONE_PREFIX_LABEL' | translate }} </mat-label>
				<mat-icon
					class="lk-primary-medium-color"
					matPrefix>
					add
				</mat-icon>
				<input
					type="text"
					matInput
					formControlName="phone_prefix"
					required />
			</mat-form-field>-->

			<mat-form-field
				class="lk-three-third-width lk-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'GENERAL.PHONE_LABEL' | translate }} </mat-label>
				<input
					id="lk-station-builder-basics-phone-input"
					type="text"
					matInput
					formControlName="phone"
					required />
			</mat-form-field>

			<mat-form-field
				class="lk-full-width"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'GENERAL.ADDRESS_LABEL' | translate }} </mat-label>
				<input
					ngx-google-places-autocomplete
					(onAddressChange)="onAddressChange($event)"
					type="text"
					matInput
					formControlName="address"
					required />
			</mat-form-field>

			<mat-expansion-panel
				class="lk-full-width lk-margin-top"
				style="box-shadow: none">
				<mat-expansion-panel-header style="padding: 0 12px">
					<mat-panel-title class="lk-ended-row">
						<span class="lk-primary-medium-color lk-small-text-size">
							{{ 'STATION_BUILDER.TURO_STATION_LINK_CTA' | translate }}
						</span>
						<img
							style="width: 50px"
							src="../../../assets/imgs/lk-turo-logo.png" />
						<p
							class="lk-small-text-size lk-no-margin lk-right-text"
							style="margin-left: -4px !important">
							?
						</p>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="lk-full-width lk-centered-row lk-margin-top">
					<form
						[formGroup]="form_group"
						class="lk-full-width">
						<mat-form-field
							class="lk-full-width lk-margin-bottom"
							appearance="fill"
							color="accent">
							<mat-label> Turo email </mat-label>
							<input
								id="lk-station-builder-basics-turo-email-input"
								type="email"
								matInput
								formControlName="ext_email" />
						</mat-form-field>

						<mat-form-field
							appearance="fill"
							color="accent"
							class="lk-full-width lk-margin-bottom">
							<mat-label> Timezone </mat-label>
							<mat-select
								id="lk-station-builder-basics-turo-timezone-select"
								formControlName="ext_timezone">
								<mat-option
									*ngFor="let timezone of timezones"
									[value]="timezone">
									({{ getTimezoneOffset(timezone) }})
									{{ timezone }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field
							class="lk-full-width lk-margin-bottom"
							appearance="fill"
							color="accent">
							<mat-label> Date format </mat-label>
							<mat-select formControlName="ext_time_format">
								<mat-option
									*ngFor="let date of date_formats"
									[value]="date.format">
									{{ date.label }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</form>
				</div>
			</mat-expansion-panel>

			<button
				mat-button
				class="lk-main-button lk-full-width lk-double-margin-top"
				(click)="onNextClick()"
				*ngIf="!update_mode"
				[disabled]="is_station_loading"
				[ngClass]="getDisabledClass()">
				<h3
					class="lk-no-margin lk-primary-color"
					*ngIf="!is_station_loading">
					{{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }}
				</h3>
				<div
					class="lk-spinner-layout"
					*ngIf="is_station_loading">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>

			<button
				mat-button
				class="lk-main-button lk-full-width lk-double-margin-top"
				(click)="onUpdateClick()"
				*ngIf="update_mode"
				[disabled]="is_station_loading"
				[ngClass]="getDisabledClass()">
				<h3
					class="lk-no-margin lk-primary-color"
					*ngIf="!is_station_loading">
					{{ 'GENERAL.SAVE_BUTTON_CTA' | translate }}
				</h3>
				<div
					class="lk-spinner-layout"
					*ngIf="is_station_loading">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>
		</div>
	</form>
</div>
