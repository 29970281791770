import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';

export const VEHICLES_SUMMARY_API_NAME: string = 'vehicles_summary';
export const VEHICLES_SUMMARY_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/vehicles-summaries/';

@Injectable({
	providedIn: 'root',
})
export class VehiclesSummaryService {
	readVehiclesSummary(stationId: string) {
		let apiName: string = VEHICLES_SUMMARY_API_NAME;
		let path: string = 'stations/' + stationId;
		let init = { response: true };
		return from(API.get(apiName, path, init));
	}
}

export class VehiclesSummary {
	total: number;
	vehicles_in_locked: number;
	vehicles_in_unlocked: number;
	vehicles_in_moving: number;
	vehicles_online: number;
	vehicles_offline: number;
}
