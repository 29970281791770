<router-outlet></router-outlet>

<button
	mat-fab
	style="
		position: fixed;
		bottom: 48px;
		right: 48px;
		background-color: #17494d;
		z-index: 1000;
	"
	(click)="onZendeskClick()">
	<mat-icon class="lk-primary-color"> chat_bubble </mat-icon>
</button>
