<div
	class="lk-spaced-row lk-wrapped-flex lk-half-margin-top lk-half-margin-bottom">
	<div
		class="lk-half-width lk-border-radius lk-primary-medium-background lk-padding lk-half-margin-bottom"
		style="min-width: fit-content">
		<p class="lk-no-margin lk-primary-medium-color lk-centered-text">
			{{ primary_title | translate }}
		</p>

		<div
			class="lk-centered-row lk-margin-top"
			style="align-items: flex-end">
			<span
				class="lk-no-margin lk-accent-color"
				style="font-size: 48px; line-height: 38px; font-weight: 800">
				{{ primary_value }}
			</span>
			<span class="lk-no-margin lk-primary-medium-color">
				{{ primary_um }}
			</span>
		</div>
	</div>

	<div
		class="lk-half-width lk-border-radius lk-primary-medium-background lk-padding lk-half-margin-bottom"
		style="min-width: fit-content">
		<p class="lk-no-margin lk-primary-medium-color lk-centered-text">
			{{ secondary_title | translate }}
		</p>

		<div
			class="lk-centered-row lk-margin-top"
			style="align-items: flex-end">
			<span
				class="lk-no-margin lk-accent-color"
				style="font-size: 48px; line-height: 38px; font-weight: 800">
				{{ secondary_value }}
			</span>
			<span class="lk-no-margin lk-primary-medium-color">
				{{ secondary_um }}
			</span>
		</div>
	</div>
</div>
