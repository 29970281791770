import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { User, UserService } from '../../core/user.service';
import { Station } from '../../core/station.service';
import {
	LOCAL_STATION_KEY,
	UNKNOWN_FLAG,
	USER_NOT_FOUND_ERROR_CODE,
	USER_STATION_CONFLICT_ERROR_CODE,
} from '../../shared/constants';
import { LocalManagerService } from '../../core/local-manager.service';
import { GENERAL_ERROR_MESSAGE } from '../../shared/translations';
import { DialogService } from '../../core/dialog.service';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';
import { Observable, map, startWith } from 'rxjs';

@Component({
	selector: 'app-booking-phone-builder-dialog',
	templateUrl: './booking-phone-builder-dialog.component.html',
	styleUrls: ['./booking-phone-builder-dialog.component.scss'],
})
export class BookingPhoneBuilderDialogComponent implements OnInit {
	form_group: FormGroup;
	is_request_loading: boolean = false;
	update_user: User;
	country_prefixes: CountryPrefix[];
	filtered_options: Observable<CountryPrefix[]>;
	current_country_prefix: CountryPrefix;
	unknown_flag_src: string = UNKNOWN_FLAG;

	constructor(
		private form_service: FormService,
		private user_service: UserService,
		private dialog_ref: MatDialogRef<BookingPhoneBuilderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private local_manager: LocalManagerService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private countries: CountriesService,
	) {
		this.form_group = this.form_service.bookingPhoneBuilderFormGroup;
		this.dialog_service.dialog = this.dialog;

		if (data && data.user) {
			this.update_user = data.user;
		}
	}

	ngOnInit() {
		this.form_group.reset();

		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);

		if (this.update_user) {
			this.form_group
				.get('phone_prefix')
				.setValue(this.update_user.phone.prefix);
			this.form_group.get('phone').setValue(this.update_user.phone.value);
		}
	}

	onCountryPrefixSelect(): void {
		const code: string = this.form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		if (this.form_service.validateForm(this.form_group)) {
			this.is_request_loading = true;
			const station: Station =
				this.local_manager.getLocalObject(LOCAL_STATION_KEY);
			let phonePrefix: string = this.form_group.get('phone_prefix').value;
			let phoneValue: string = this.form_group.get('phone').value;
			phonePrefix = phonePrefix.replace(/ /g, '');
			phonePrefix = phonePrefix.replace('+', '');
			phoneValue = phoneValue.replace(/ /g, '');
			this.user_service
				.readUserByPhone('+' + phonePrefix + phoneValue)
				.subscribe({
					next: response => {
						const user: User = response.data as User;
						this.addUserToStation(station, user);
					},
					error: error => {
						this.manageReadUserErrors(error, phoneValue, phonePrefix);
						this.is_request_loading = false;
					},
				});
		}
	}

	addUserToStation(station: Station, user: User) {
		this.is_request_loading = true;
		this.user_service.addUserToStation(station.id, user.id).subscribe({
			next: response => {
				this.dialog_ref.close({ user: user });
				this.is_request_loading = false;
			},
			error: error => {
				this.manageAddUserToStationErrors(error, user);
				this.is_request_loading = false;
			},
		});
	}

	manageReadUserErrors(error: any, phoneValue: string, phonePrefix: string) {
		if (
			error.response.data.error &&
			error.response.data.error.description &&
			error.response.data.error.code != USER_NOT_FOUND_ERROR_CODE
		) {
			this.dialog_service.openInfoDialog(error.response.data.error.description);
			this.dialog_ref.close();
		} else if (
			!error.response.data.error ||
			!error.response.data.error.description
		) {
			this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			this.dialog_ref.close();
		} else if (error.response.data.error.code == USER_NOT_FOUND_ERROR_CODE) {
			this.dialog_ref.close({ phone: phoneValue, phone_prefix: phonePrefix });
		}
	}

	manageAddUserToStationErrors(error: any, user: User) {
		if (
			error.response.data.error &&
			error.response.data.error.description &&
			error.response.data.error.code != USER_STATION_CONFLICT_ERROR_CODE
		) {
			this.dialog_service.openInfoDialog(error.response.data.error.description);
			this.dialog_ref.close();
		} else if (
			!error.response.data.error ||
			!error.response.data.error.description
		) {
			this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			this.dialog_ref.close();
		} else if (
			error.response.data.error.code == USER_STATION_CONFLICT_ERROR_CODE
		) {
			this.dialog_ref.close({ user: user });
		}
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_request_loading,
			'lk-main-button': !this.is_request_loading,
		};
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getCurrentPrefixFlag(): string {
		const phone_prefix: string = this.form_group.get('phone_prefix')?.value;
		let flag_path: string;
		if (phone_prefix) {
			const current_prefixes: CountryPrefix[] = this.filterPrefix(phone_prefix);
			if (current_prefixes && current_prefixes.length) {
				flag_path = current_prefixes[0]?.flag;
			}
		}
		return flag_path ? flag_path : UNKNOWN_FLAG;
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}
}
