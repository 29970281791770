<app-shared-app-toolbar
	[current_nav_item]="current_item"
	[refresh]="toolbar_refresh"
	(no_station_found)="onNoStationFound()"
	(station_build_click)="onStationBuildClick(false)"
	(station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section
	id="lk-booking-details-root-layout"
	class="lk-section-with-toolbar">
	<div
		id="lk-booking-details-header-layout"
		class="lk-padding-md">
		<div class="lk-spaced-row lk-horiz-half-padding">
			<div
				id="lk-booking-details-back-layout"
				class="lk-row lk-cursor-pointer"
				(click)="onBackClick()">
				<button
					class="lk-background-icon"
					mat-icon-button>
					<mat-icon class="lk-accent-color"> keyboard_arrow_left </mat-icon>
				</button>
				<p
					class="lk-accent-color"
					style="font-size: 12px; margin: 0 0 0 6px">
					{{ 'BOOKING_DETAILS.BACK_CTA' | translate }}
				</p>
			</div>

			<div class="lk-booking-details-status-layout">
				<h1
					class="lk-no-margin"
					*ngIf="booking && booking.id"
					style="margin-bottom: 6px !important">
					{{ getUserIdentifier(booking) }}
				</h1>
				<div
					class="lk-border-radius lk-primary-medium-background"
					style="padding: 2px 12px; width: fit-content"
					[ngClass]="getStatusClass(booking)">
					<span
						class="lk-ellipsized-text"
						*ngIf="booking">
						{{ getBookingStatusLabel(booking) | translate }}
					</span>
				</div>
			</div>

			<div
				style="width: 135px"
				class="lk-ended-row">
				<button
					mat-icon-button
					(click)="onRefreshClick()"
					class="lk-background-icon lk-booking-details-icon-button"
					matTooltip="{{ 'BOOKING_DETAILS.REFRESH_CTA' | translate }}">
					<mat-icon class="lk-accent-color"> refresh </mat-icon>
				</button>
				<button
					mat-icon-button
					[matMenuTriggerFor]="menu"
					class="lk-background-icon lk-booking-details-icon-button">
					<mat-icon> more_horiz </mat-icon>
				</button>

				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						(click)="onBookingDeleteClick()">
						<mat-icon style="color: red"> delete </mat-icon>
						{{ 'BOOKING.DELETE_CTA' | translate }}
					</button>
				</mat-menu>
			</div>
		</div>
	</div>

	<div
		class="lk-booking-details-navbar-layout lk-horiz-padding-md lk-full-width">
		<app-booking-details-navbar
			*ngIf="booking"
			(navbar_item_change)="
				onNavbarItemChange($event)
			"></app-booking-details-navbar>
		<div
			*ngIf="booking && current_navbar_item == navbar_items[0]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-booking-details-container-layout lk-hide-scrollbar">
			<app-booking-details-general
				id="lk-booking-details-general-layout"
				class="lk-booking-details-content-layout lk-full-height"
				[booking]="booking"
				(bookingUpdateClick)="onBookingUpdateClick($event)"
				(updateEmailClick)="onUpdateEmailClick()"
				(updatePhoneClick)="onUpdatePhoneClick()"></app-booking-details-general>

			<app-booking-details-vehicle
				id="lk-booking-details-vehicle-layout"
				class="lk-booking-details-content-layout lk-full-height"
				[booking]="booking"
				(addVehicleClick)="onAddVehicleClick($event)"
				(refresh)="onRefreshClick()"></app-booking-details-vehicle>
		</div>

		<div
			*ngIf="booking && current_navbar_item == navbar_items[1]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-booking-details-container-layout lk-hide-scrollbar">
			<app-booking-details-unlock-link
				id="lk-booking-details-unlock-link-layout"
				class="lk-booking-details-content-layout lk-full-height"
				[booking_trigger]="booking_trigger"
				[booking_update_trigger]="
					booking_update_trigger
				"></app-booking-details-unlock-link>

			<app-booking-details-lock-link
				id="lk-booking-details-lock-link-layout"
				class="lk-booking-details-content-layout lk-full-height"
				[booking_trigger]="booking_trigger"
				[booking_update_trigger]="
					booking_update_trigger
				"></app-booking-details-lock-link>
		</div>

		<div
			*ngIf="booking && current_navbar_item == navbar_items[2]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-booking-details-container-layout lk-hide-scrollbar">
			<app-booking-details-events
				id="lk-booking-details-events-layout"
				class="lk-booking-details-content-layout lk-full-height"
				[booking]="booking"></app-booking-details-events>
		</div>

		<div
			*ngIf="booking && current_navbar_item == navbar_items[4]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-booking-details-container-layout lk-hide-scrollbar">
			<app-booking-details-data
				id="lk-booking-details-data-layout"
				class="lk-booking-details-content-layout lk-full-height"
				[booking]="booking"></app-booking-details-data>
		</div>

		<div
			*ngIf="booking && current_navbar_item == navbar_items[5]"
			style="align-items: flex-start; flex-wrap: wrap; overflow-y: visible"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-booking-details-container-layout lk-hide-scrollbar">
			<app-booking-details-path
				id="lk-booking-details-path-layout"
				class="lk-full-height"
				[booking]="booking"></app-booking-details-path>
		</div>
	</div>

	<div
		*ngIf="is_booking_loading"
		class="lk-full-width lk-full-height lk-centered-row"
		style="position: absolute; top: 0; left: 0; max-height: 100vh">
		<div class="lk-overlay-spinner-layout lk-padding lk-border-radius">
			<mat-spinner
				color="accent"
				diameter="30"></mat-spinner>
		</div>
	</div>
</section>
