<div class="lk-full-height lk-full-width lk-spaced-row">
	<div
		id="lk-auth-signup-form-layout"
		class="lk-full-height lk-double-padding">
		<form
			id="lk-auth-signup-form"
			[formGroup]="form_group"
			class="lk-shadow lk-border-radius lk-double-padding lk-centered-row lk-wrapped-flex lk-primary-background lk-z-index">
			<div class="lk-spaced-row lk-full-width lk-double-margin-bottom">
				<div
					class="lk-primary-dark-background"
					style="width: 20%; height: 2px"></div>
				<img
					style="width: 50%"
					[src]="logo_src" />
				<div
					class="lk-primary-dark-background"
					style="width: 20%; height: 2px"></div>
			</div>

			<mat-form-field
				class="lk-full-width lk-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
				<input
					id="auth-signup-username-input"
					type="email"
					matInput
					formControlName="username"
					placeholder="address@email.com"
					required />
			</mat-form-field>
			<mat-form-field
				class="lk-full-width lk-margin-bottom"
				appearance="fill"
				color="accent"
				[ngClass]="
					form_group.get('password').hasError('minlength') ||
					form_group.get('password').hasError('pattern')
						? 'lk-form-field-with-error'
						: 'lk-form-field-without-error'
				">
				<mat-label> {{ 'GENERAL.PASSWORD_LABEL' | translate }} </mat-label>
				<input
					id="auth-signup-password-input"
					[type]="is_password_visible ? 'text' : 'password'"
					matInput
					[errorStateMatcher]="matcher"
					formControlName="password"
					required />
				<mat-icon
					class="lk-cursor-pointer"
					matSuffix
					(click)="is_password_visible = !is_password_visible">
					{{ is_password_visible ? 'visibility_off' : 'visibility' }}
				</mat-icon>
				<mat-error
					*ngIf="
						form_group.get('password').hasError('minlength') &&
						!form_group.get('password').hasError('required')
					">
					{{ 'AUTHENTICATION.PSS_MIN_LENGTH_ERROR' | translate }}
				</mat-error>
				<mat-error
					*ngIf="
						form_group.get('password').hasError('pattern') &&
						!form_group.get('password').hasError('minlength') &&
						!form_group.get('password').hasError('required')
					">
					{{ 'AUTHENTICATION.PSS_PATTERN_ERROR' | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field
				class="lk-full-width"
				appearance="fill"
				color="accent"
				[ngClass]="
					form_group.get('password_confirm').hasError('minlength') ||
					form_group.get('password_confirm').hasError('pattern')
						? 'lk-form-field-with-error'
						: 'lk-form-field-without-error'
				">
				<mat-label>
					{{ 'GENERAL.PASSWORD_CONFIRM_LABEL' | translate }}
				</mat-label>
				<input
					id="auth-signup-password-confirmation-input"
					[type]="is_password_visible ? 'text' : 'password'"
					matInput
					[errorStateMatcher]="matcher"
					formControlName="password_confirm"
					required />
				<mat-icon
					class="lk-cursor-pointer"
					matSuffix
					(click)="is_password_visible = !is_password_visible">
					{{ is_password_visible ? 'visibility_off' : 'visibility' }}
				</mat-icon>
				<mat-error
					*ngIf="
						form_group.get('password_confirm').hasError('minlength') &&
						!form_group.get('password_confirm').hasError('required')
					">
					{{ 'AUTHENTICATION.PSS_MIN_LENGTH_ERROR' | translate }}
				</mat-error>
				<mat-error
					*ngIf="
						form_group.get('password_confirm').hasError('pattern') &&
						!form_group.get('password_confirm').hasError('minlength') &&
						!form_group.get('password_confirm').hasError('required')
					">
					{{ 'AUTHENTICATION.PSS_PATTERN_ERROR' | translate }}
				</mat-error>
			</mat-form-field>

			<button
				id="auth-signup-submit-button"
				mat-button
				class="lk-main-button lk-full-width lk-double-margin-top"
				(click)="onSignupClick()"
				[disabled]="is_signup_running"
				[ngClass]="getDisabledClass()">
				<h3
					class="lk-no-margin lk-primary-color"
					*ngIf="!is_signup_running">
					{{ 'AUTHENTICATION.SIGNUP_BUTTON_CTA' | translate }}
				</h3>
				<div
					class="lk-spinner-layout"
					*ngIf="is_signup_running">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>

			<!--<div class="lk-ended-row lk-full-width lk-half-margin-top">
				<span
					class="lk-right-text"
					style="font-size: 14px; margin-right: 6px">
					{{ 'AUTHENTICATION.HAVE_ACCOUNT_ALREADY_CTA' | translate }}
				</span>
				<button
					id="auth-signup-signin-cta"
					(click)="onSigninClick()"
					class="lk-background-icon"
					mat-icon-button>
					<mat-icon class="lk-accent-color"> arrow_forward </mat-icon>
				</button>
			</div>-->
		</form>
	</div>

	<div
		id="lk-auth-signup-img-layout"
		class="lk-full-height lk-double-padding lk-centered-row"
		[ngStyle]="{
			'background-image': 'url(' + background_src + ')'
		}"></div>
</div>
