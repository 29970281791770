<div class="lk-dialog-large-max-width">
	<div class="lk-full-width lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-background-icon lk-z-index">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding">
		<div class="lk-row lk-margin-bottom">
			<img
				class="lk-flag-mini lk-no-margin"
				[src]="getStationFlag()" />
			<h3 class="lk-no-margin lk-half-margin-left">
				{{ getTemplateLabel() | translate }}
			</h3>
		</div>

		<form
			*ngIf="template_type == sms_type"
			class="lk-full-width lk-half-margin-top lk-row lk-wrapped-flex"
			[formGroup]="template_add_item_form_group">
			<!--<hr class="lk-stroke-dashed lk-full-width" />
			<span>
				{{ 'STATION.NOTIF_SMS_CONTENT_CTA' | translate }}
			</span>-->
			<mat-form-field
				style="width: calc(100% - 52px)"
				class="lk-full-width lk-params-list-select"
				appearance="fill"
				color="accent">
				<mat-select
					(selectionChange)="onParameterSelection($event)"
					matInput
					type="text"
					placeholder="{{ 'STATION.PARAMETERS_LIST_LABEL' | translate }}"
					formControlName="current_parameter">
					<mat-option
						[value]="param"
						*ngFor="let param of params_list">
						{{ param }}
					</mat-option>
				</mat-select>

				<button
					mat-icon-button
					matSuffix>
					<mat-icon class="lk-primary-medium-color"> add </mat-icon>
				</button>
			</mat-form-field>

			<button
				mat-icon-button
				matTooltip="{{ 'STATION.PARAMETERS_LIST_TOOLTIP' | translate }}"
				class="lk-background-icon lk-half-margin-left lk-hidden-before-lg">
				<mat-icon class="lk-accent-color"> info_outlined </mat-icon>
			</button>

			<button
				(click)="onInfoClick()"
				mat-icon-button
				class="lk-background-icon lk-half-margin-left lk-hidden-after-lg">
				<mat-icon class="lk-accent-color"> info_outlined </mat-icon>
			</button>

			<!--<hr class="lk-stroke-dashed lk-full-width" />-->

			<mat-form-field
				class="lk-full-width lk-half-margin-top lk-stations-notif-templates-item-add-dialog-field"
				appearance="fill"
				color="accent">
				<mat-label> content </mat-label>
				<textarea
					style="min-height: 150px"
					type="text"
					matInput
					formControlName="text"></textarea>
			</mat-form-field>
		</form>

		<!--<hr class="lk-stroke-dashed lk-full-width lk-double-margin-top" />
		<div
			*ngIf="template_type == sms_type"
			class="lk-spaced-row">
			<span>
				{{ 'STATION.NOTIF_SMS_FILE_UPLOAD_CTA' | translate }}
			</span>

			<button
				(click)="upload_input.click()"
				mat-icon-button>
				<mat-icon
					*ngIf="!this.template?.content"
					class="lk-accent-color">
					upload
				</mat-icon>
				<mat-icon
					*ngIf="this.template?.content"
					class="lk-accent-color">
					check_circle
				</mat-icon>
				<input
					#upload_input
					type="file"
					accept="text/*"
					(change)="onUploadClick($event)"
					style="display: none" />
			</button>
		</div>-->

		<div *ngIf="template_type == email_type">
			<hr class="lk-stroke-dashed lk-full-width" />
			<span>
				{{ 'STATION.NOTIF_EMAIL_FILE_UPLOAD_CTA' | translate }}
			</span>
			<hr class="lk-stroke-dashed lk-full-width" />
		</div>

		<button
			*ngIf="template_type == email_type"
			(click)="upload_input.click()"
			style="min-height: 100px"
			class="lk-full-width lk-button__primary-medium lk-border-radius lk-stations-notif-templates-item-add-dialog-field"
			mat-button>
			<mat-icon
				*ngIf="!this.template?.content"
				class="lk-accent-color">
				upload
			</mat-icon>
			<span
				*ngIf="this.template?.content"
				class="lk-accent-color">
				uploaded
			</span>
			<input
				#upload_input
				type="file"
				accept="text/*"
				(change)="onUploadClick($event)"
				style="display: none" />
		</button>

		<div class="lk-full-width lk-margin-top">
			<button
				(click)="onConfirmClick()"
				[disabled]="is_create_running || !isValidForm()"
				[ngClass]="getDisabledClass()"
				mat-button
				class="lk-main-button lk-margin-bottom lk-full-width">
				<span
					*ngIf="!is_create_running"
					class="lk-primary-color">
					{{ 'GENERAL.CONFIRM_CTA' | translate }}
				</span>
				<div
					class="lk-spinner-layout"
					*ngIf="is_create_running">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>
		</div>
	</div>
</div>
