<div>
	<h2 class="lk-no-margin lk-half-margin-bottom lk-accent-color">
		{{ 'PROFILE.INFO_TITLE' | translate }}
	</h2>

	<div
		class="lk-spaced-row lk-full-width"
		style="align-items: baseline">
		<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">Email</p>
		<p class="lk-no-margin lk-right-text">
			{{ email || '-' }}
		</p>
	</div>
	<hr class="lk-list-separator lk-half-margin-bottom" />

	<div
		class="lk-spaced-row lk-full-width"
		style="align-items: baseline">
		<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
			{{ 'GENERAL.PHONE_LABEL' | translate }}
		</p>
		<p class="lk-no-margin lk-right-text">
			{{ phone || '-' }}
		</p>
	</div>
	<hr class="lk-list-separator lk-margin-bottom" />
</div>
