<div
	class="lk-border-radius lk-shadow lk-padding lk-primary-background"
	style="height: calc(50% - 24px); overflow-y: scroll">
	<div class="lk-full-width lk-centered-column">
		<h2 class="lk-no-margin lk-centered-text lk-subtitle-size">
			{{ 'BOOKING_DETAILS.DOCS_SECTION_LABEL' | translate }}
		</h2>
		<p class="lk-no-margin lk-centered-text lk-primary-medium-color">
			{{ getPolicyLabel(booking.settings.pictures_policy) | translate }}
		</p>
	</div>

	<p
		*ngIf="!is_request_running && pre_reports && pre_reports.length"
		class="lk-primary-medium-color lk-no-margin lk-margin-top">
		Pre-reports
	</p>
	<div
		class="lk-full-width lk-wrapped-flex lk-spaced-row"
		*ngIf="!is_request_running && pre_reports && pre_reports.length">
		<div
			class="lk-primary-medium-background lk-booking-details-docs-layout lk-half-width lk-border-radius lk-centered-row lk-half-margin-top lk-cursor-pointer"
			(click)="onMediaClick(media)"
			*ngFor="let media of preReports">
			<img
				class="lk-booking-details-docs-img"
				[src]="media.content_url" />
		</div>
	</div>

	<p
		*ngIf="!is_request_running && post_reports && post_reports.length"
		class="lk-primary-medium-color lk-no-margin lk-margin-top">
		Post-reports
	</p>
	<div
		class="lk-full-width lk-wrapped-flex lk-spaced-row"
		*ngIf="!is_request_running && post_reports && post_reports.length">
		<div
			class="lk-primary-medium-background lk-booking-details-docs-layout lk-half-width lk-border-radius lk-centered-row lk-half-margin-top lk-cursor-pointer"
			(click)="onMediaClick(media)"
			*ngFor="let media of postReports">
			<img
				class="lk-booking-details-docs-img"
				[src]="media.content_url" />
		</div>
	</div>

	<div
		class="lk-centered-row lk-booking-details-docs-empty-layout"
		*ngIf="!is_request_running && booking_media && !booking_media.length">
		<p class="lk-centered-text">No image stored</p>
	</div>

	<div
		class="lk-full-width lk-full-height lk-centered-row"
		*ngIf="is_request_running">
		<mat-spinner
			diameter="30"
			color="accent"></mat-spinner>
	</div>
</div>
