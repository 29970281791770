export const environment = {
	production: false,
	STAGE: 'dev',
	API_BASE_PATH: 'https://back-api.dev.linkey.2hire.io',
	IDENTITY_POOL_ID: 'us-east-1:6501d013-e7cb-43b4-8445-9d68dc24e3f1',
	IDENTITY_POOL_REGION: 'us-east-1',
	USER_POOL_ID: 'us-east-1_M8BnoxYEl',
	USER_POOL_WEB_CLIENT_ID: 'h8hsf5vl30once7hucovsibgv',
	USER_POOL_REGION: 'us-east-1',
	BUCKET_NAME: 'linkey-admins-storage-bucket-gmarino',
	BUCKET_REGION: 'us-east-1',
};
