<app-shared-toolbar 
  [currentNavItem]="current_item" 
  [stationUpdateTrigger]="station_update_trigger"></app-shared-toolbar>

<section class="lk-section-with-toolbar lk-padding-md lk-spaced-column">
  <div class="lk-centered-column lk-full-width">
    <div 
      id="lk-station-builder-basics-form-layout" 
      class="lk-centered-column-wrapper">
      <div class="lk-centered-row lk-margin-bottom">
        <h1 class="lk-accent-color lk-no-margin lk-station-builder-step-text"> 1 </h1>
        <h1 class="lk-no-margin lk-half-margin-left"> {{ 'STATION_BUILDER.STEP_BASICS_TITLE' | translate }} </h1>
      </div>
  
      <form   
        [formGroup]="form_group" 
        class="lk-full-width lk-shadow lk-border-radius lk-padding-md lk-primary-background">
        <div 
          class="lk-spaced-row lk-wrapped-flex"
          *ngIf="!is_request_running">
          <mat-form-field 
            class="lk-full-width lk-margin-bottom" 
            appearance="fill" 
            color="accent">
            <mat-label> {{ 'GENERAL.STATION_NAME_LABEL' | translate }} </mat-label>
            <input 
              id="lk-station-builder-basics-name-input"
              type="text" 
              matInput 
              formControlName="name" 
              required>
          </mat-form-field>
    
          <mat-form-field 
            class="lk-full-width lk-margin-bottom" 
            appearance="fill" 
            color="accent">
            <mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
            <input 
              id="lk-station-builder-basics-email-input"
              type="email" 
              matInput 
              formControlName="email" 
              required>
          </mat-form-field>
    
          <mat-form-field 
            class="lk-third-width lk-margin-bottom" 
            appearance="fill" 
            color="accent">
            <mat-label> {{ 'GENERAL.PHONE_PREFIX_LABEL' | translate }} </mat-label>
            <mat-icon 
              class="lk-primary-medium-color" 
              matPrefix> add </mat-icon>
            <input 
              id="lk-station-builder-basics-prefix-input"
              type="text" 
              matInput 
              formControlName="phone_prefix" 
              required>
          </mat-form-field>
    
          <mat-form-field 
            class="lk-three-third-width lk-margin-bottom" 
            appearance="fill" 
            color="accent">
            <mat-label> {{ 'GENERAL.PHONE_LABEL' | translate }} </mat-label>
            <input 
              id="lk-station-builder-basics-phone-input"
              type="text" 
              matInput 
              formControlName="phone" 
              required>
          </mat-form-field>
    
          <mat-form-field 
            class="lk-full-width" 
            appearance="fill" 
            color="accent">
            <mat-label> {{ 'GENERAL.ADDRESS_LABEL' | translate }} </mat-label>
            <input 
              id="lk-station-builder-basics-address-input"
              ngx-google-places-autocomplete 
              (onAddressChange)="onAddressChange($event)"
              type="text" 
              matInput 
              formControlName="address" 
              required>
          </mat-form-field>

          <mat-expansion-panel  
            id="lk-station-builder-basics-panel-layout"
            class="lk-full-width lk-margin-top" 
            style="box-shadow: none">
            <mat-expansion-panel-header style="padding: 0 12px;">
              <mat-panel-title class="lk-ended-row">
                <span class="lk-primary-medium-color lk-small-text-size">
                  {{ 'STATION_BUILDER.TURO_STATION_LINK_CTA' | translate }} 
                </span>
                <img 
                  style="width: 50px;" 
                  src="../../../assets/imgs/lk-turo-logo.png">
                <p  
                  class="lk-small-text-size lk-no-margin lk-right-text" 
                  style="margin-left: -4px !important;"> 
                  ?
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="lk-full-width lk-centered-row lk-margin-top">
              <form 
                [formGroup]="form_group" 
                class="lk-full-width">
                <mat-form-field 
                  class="lk-full-width lk-margin-bottom" 
                  appearance="fill" 
                  color="accent">
                    <mat-label> Turo email </mat-label>
                    <input 
                      id="lk-station-builder-basics-turo-email-input"
                      type="email" 
                      matInput 
                      formControlName="ext_email">
                </mat-form-field>

                <mat-form-field 
                  appearance="fill" 
                  color="accent" 
                  class="lk-full-width lk-margin-bottom">
                  <mat-label> Timezone </mat-label>
                  <mat-select 
                    id="lk-station-builder-basics-turo-timezone-select"
                    formControlName="ext_timezone">
                    <mat-option 
                      *ngFor="let timezone of timezones" 
                      [value]="timezone">
                      ({{ getTimezoneOffset(timezone) }})
                      {{ timezone }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field 
                  class="lk-full-width lk-margin-bottom" 
                  appearance="fill" 
                  color="accent">
                    <mat-label> Date format </mat-label>
                    <mat-select 
                      id="lk-station-builder-basics-turo-date-select"
                      formControlName="ext_time_format">
                      <mat-option 
                        *ngFor="let date of date_formats" 
                        [value]="date.format">
                        {{ date.label }}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
              </form>
            </div>
          </mat-expansion-panel>
    
          <button 
            mat-button 
            id="lk-station-builder-basics-confirm-button"
            class="lk-main-button lk-full-width lk-double-margin-top"
            (click)="onNextClick()" 
            *ngIf="!update_mode" 
            [disabled]="is_station_loading" 
            [ngClass]="getDisabledClass()">
            <h3 class="lk-no-margin lk-primary-color" 
                *ngIf="!is_station_loading"> 
              {{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }}
            </h3>
            <div 
              class="lk-spinner-layout" 
              *ngIf="is_station_loading">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </button>
    
          <button 
            id="lk-station-builder-basics-update-button"
            mat-button 
            class="lk-main-button lk-full-width lk-double-margin-top"
            (click)="onUpdateClick()" 
            *ngIf="update_mode"
            [disabled]="is_station_loading" 
            [ngClass]="getDisabledClass()">
            <h3 
              class="lk-no-margin lk-primary-color" 
              *ngIf="!is_station_loading"> 
              {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} 
            </h3>
            <div 
              class="lk-spinner-layout" 
              *ngIf="is_station_loading">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </button>
        </div>

        <div 
          class="lk-centered-row"
          *ngIf="is_request_running">
          <mat-spinner
            diameter="30"
            color="accent"></mat-spinner>
        </div>
      </form>
    </div>
  </div>

  <app-station-builder-steps [currentStep]="current_step"></app-station-builder-steps>
</section>

