<div
	id="lk-booking-details-data-root-layout"
	class="lk-border-radius lk-shadow lk-padding lk-primary-background lk-full-height">
	<h3 class="lk-no-margin">
		{{ 'BOOKING_DETAILS.DATA_INITIAL_LEVELS_LABEL' | translate }}
	</h3>
	<app-booking-details-data-item
		[primary_title]="'VEHICLE.ODOMETER_COLUMN_LABEL'"
		[primary_value]="180"
		[primary_um]="getOdometerMeasurementUnit()"
		[secondary_title]="'VEHICLE.FUEL_COLUMN_LABEL'"
		[secondary_value]="75"
		[secondary_um]="'%'"></app-booking-details-data-item>

	<h3 class="lk-no-margin">
		{{ 'BOOKING_DETAILS.DATA_FINAL_LEVELS_LABEL' | translate }}
	</h3>
	<app-booking-details-data-item
		[primary_title]="'VEHICLE.ODOMETER_COLUMN_LABEL'"
		[primary_value]="720"
		[primary_um]="getOdometerMeasurementUnit()"
		[secondary_title]="'VEHICLE.FUEL_COLUMN_LABEL'"
		[secondary_value]="25"
		[secondary_um]="'%'"></app-booking-details-data-item>

	<h3 class="lk-no-margin">
		{{ 'BOOKING_DETAILS.DATA_LEVEL_DIFFERENCES_LABEL' | translate }}
	</h3>
	<app-booking-details-data-item
		[primary_title]="'VEHICLE.ODOMETER_COLUMN_LABEL'"
		[primary_value]="540"
		[primary_um]="getOdometerMeasurementUnit()"
		[secondary_title]="'VEHICLE.FUEL_COLUMN_LABEL'"
		[secondary_value]="50"
		[secondary_um]="'%'"></app-booking-details-data-item>
</div>
