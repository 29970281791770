<div
	style="padding: 20px; box-sizing: border-box"
	class="lk-full-width lk-full-height">
	<div class="lk-full-width lk-justified-row">
		<img
			style="height: 50px"
			src="../../../assets/imgs/lk-logo.svg" />
	</div>

	<!--<p
		class="lk-full-width lk-centered-text lk-double-margin-top"
		[innerHTML]="'SUBSCRIPTION.SIGNIN_SUBTITLE' | translate"></p>-->

	<div class="lk-full-width lk-justified-row">
		<div
			style="max-width: 800px; padding: 28px; box-sizing: border-box"
			class="lk-full-width lk-shadow lk-border-radius lk-primary-background lk-double-margin-top">
			<span class="lk-accent-color">
				{{ 'SUBSCRIPTION.SIGNIN_STEP_LABEL' | translate }}
			</span>
			<h2 class="lk-no-margin lk-half-margin-top">
				{{ 'SUBSCRIPTION.SIGNIN_TITLE' | translate }}
			</h2>
			<p class="lk-no-margin lk-margin-top">
				<b>{{ 'SUBSCRIPTION.SIGNIN_MESSAGE_1' | translate }}</b>
			</p>

			<p class="lk-no-margin lk-margin-top">
				{{ 'SUBSCRIPTION.SIGNIN_MESSAGE_2' | translate }}
			</p>

			<div class="lk-full-width lk-ended-row lk-double-margin-top">
				<button
					(click)="onGoToStationClick()"
					mat-button
					class="lk-main-button">
					{{ 'SUBSCRIPTION.GO_TO_STATION_CTA' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
