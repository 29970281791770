import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-auth-mfa-reset-confirm-dialog',
	templateUrl: './auth-mfa-reset-confirm-dialog.component.html',
	styleUrls: ['./auth-mfa-reset-confirm-dialog.component.scss'],
})
export class AuthMfaResetConfirmDialogComponent implements OnInit {
	phone?: string;

	constructor(
		private dialog_ref: MatDialogRef<AuthMfaResetConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.phone = data.phone;
	}

	ngOnInit(): void {}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		this.dialog_ref.close({ confirm: true });
	}
}
