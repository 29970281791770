<app-shared-app-toolbar
	[current_nav_item]="current_item"
	[refresh]="toolbar_refresh"
	(no_station_found)="onNoStationFound()"
	(station_build_click)="onStationBuildClick(false)"
	(station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section class="lk-section-with-toolbar lk-centered-row">
	<mat-spinner
		*ngIf="is_metabase_loading"
		diameter="40"
		color="accent"></mat-spinner>

	<app-metabase-loaded
		*ngIf="!is_metabase_loading && metabase_address"
		class="lk-full-width"
		style="height: 100vh"
		[metabase_address]="metabase_address"></app-metabase-loaded>
</section>
