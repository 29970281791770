<div id="lk-vehicle-summary-root-layout" class="lk-row lk-hide-scrollbar">
  <div class="lk-vehicle-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-margin-right lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color"> 
      {{ 'VEHICLE.SUMMARY_ALL_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && vehicles_summary"> 
      {{ vehicles_summary.total }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !vehicles_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>

  <div class="lk-vehicle-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-margin-right lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color"> 
      {{ 'VEHICLE.SUMMARY_LOCKED_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && vehicles_summary"> 
      {{ vehicles_summary.vehicles_in_locked }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !vehicles_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>

  <div class="lk-vehicle-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-margin-right lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color">
      {{ 'VEHICLE.SUMMARY_UNLOCKED_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && vehicles_summary"> 
      {{ vehicles_summary.vehicles_in_unlocked }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !vehicles_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>

  <div class="lk-vehicle-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-margin-right lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color">
      {{ 'VEHICLE.SUMMARY_OFFLINE_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && vehicles_summary"> 
      {{ vehicles_summary.vehicles_offline }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !vehicles_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>

  <div class="lk-vehicle-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color">
      {{ 'VEHICLE.SUMMARY_ONLINE_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && vehicles_summary"> 
      {{ vehicles_summary.vehicles_online }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !vehicles_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>
</div>
