<div class="lk-fullwidth lk-spaced-row lk-wrapped-flex">
	<div
		class="lk-centered-row lk-shared-menu-button"
		*ngFor="let item of nav_items"
		(click)="onNavItemClick(item)">
		<mat-icon [svgIcon]="item.icon"> </mat-icon>
		<p
			class="lk-no-margin lk-ellipsized-text"
			style="margin-left: 4px !important">
			{{ item.name | translate }}
		</p>
	</div>

	<div
		class="lk-full-width lk-centered-row lk-half-margin-top lk-half-margin-bottom">
		<p
			class="lk-no-margin"
			(click)="onSignoutClick()">
			<u> Logout </u>
		</p>

		<p
			class="lk-no-margin lk-margin-left"
			(click)="onProfileclick()">
			<u> {{ 'PROFILE.TITLE' | translate }} </u>
		</p>
	</div>
</div>
