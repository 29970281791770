<app-shared-toolbar 
  [currentNavItem]="current_item" 
  [stationUpdateTrigger]="station_update_trigger"></app-shared-toolbar>

<section class="lk-section-with-toolbar">
  <div class="lk-padding-md">
    <div 
      class="lk-full-width lk-spaced-row" 
      *ngIf="current_station">
      <div class="lk-row">
        <h1 class="lk-no-margin lk-ellipsized-text"> {{ current_station.name }} </h1>
        <button 
          mat-icon-button 
          [matMenuTriggerFor]="menu">
          <mat-icon> expand_more </mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item *ngFor="let station of stations"
            (click)="onStationChangeClick(station)">
            {{ station.name }}
          </button>
        </mat-menu>
      </div>
      <button 
        mat-icon-button 
        [matMenuTriggerFor]="deleteMenu" 
        class="lk-background-icon">
        <mat-icon> more_horiz </mat-icon>
      </button>

      <mat-menu #deleteMenu="matMenu">
        <button 
          mat-menu-item 
          (click)="onStationDeleteClick(current_station)">
          <mat-icon style="color: red"> delete </mat-icon>
          {{ 'STATION.DELETE_STATION_CTA' | translate }}
        </button>
      </mat-menu>
    </div>

    <div 
      class="lk-row lk-wrapped-flex" 
      *ngIf="current_station" 
      style="align-items: flex-start">
      <div 
        id="lk-station-settings-left-layout" 
        class="lk-margin-top">
        <app-station-settings-notif 
          [stationUpdateTrigger]="station_update_trigger"
          (notifUpdateClick)="onNotifUpdateClick($event)"></app-station-settings-notif>
        <app-station-settings-lang  
          [stationUpdateTrigger]="station_update_trigger"
          (langUpdateClick)="onLangUpdateClick($event)"></app-station-settings-lang>
        <app-station-settings-pictures
          *ngIf="!is_production" 
          [stationUpdateTrigger]="station_update_trigger"
          (policyUpdateClick)="onPicsPolicyUpdateClick($event)"></app-station-settings-pictures>
      </div>

      <div id="lk-station-settings-right-layout" class="lk-margin-top">
        <app-station-settings-trigger
          [stationUpdateTrigger]="station_update_trigger"
          (triggerUpdateClick)="onTriggersUpdateClick($event)"></app-station-settings-trigger>
      </div>
    </div>
  </div>

  <div 
    *ngIf="is_request_running"
    class="lk-full-width lk-full-height lk-centered-row" 
    style="position: absolute; top: 0; left: 0">
    <div class="lk-overlay-spinner-layout lk-padding lk-border-radius">
      <mat-spinner
      color="accent"
      diameter="30"></mat-spinner>
    </div>
  </div>
</section>
