<app-shared-app-toolbar
	[current_nav_item]="current_item"
	[refresh]="toolbar_refresh"
	(no_station_found)="onNoStationFound()"
	(station_build_click)="onStationBuildClick(false)"
	(station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section
	id="lk-vehicle-details-root-layout"
	class="lk-section-with-toolbar">
	<div
		class="lk-padding-md"
		style="padding-bottom: 0">
		<div class="lk-spaced-row lk-horiz-half-padding lk-wrapped-flex">
			<div
				id="lk-vehicle-details-back-layout"
				class="lk-row lk-cursor-pointer"
				(click)="onBackClick()">
				<button
					class="lk-background-icon"
					mat-icon-button>
					<mat-icon class="lk-accent-color"> keyboard_arrow_left </mat-icon>
				</button>
				<p
					class="lk-accent-color"
					style="font-size: 12px; margin: 0 0 0 6px">
					{{ 'VEHICLE_DETAILS.BACK_CTA' | translate }}
				</p>
			</div>

			<div class="lk-vehicle-details-status-layout">
				<h1
					class="lk-no-margin"
					*ngIf="vehicle && vehicle.plate"
					style="margin-bottom: 6px !important">
					{{ vehicle.plate }}
				</h1>
				<div
					class="lk-border-radius lk-primary-medium-background"
					style="padding: 2px 12px; width: fit-content"
					[ngClass]="getVehicleStatusClass(vehicle)">
					<span *ngIf="vehicle && vehicle.status">
						{{ vehicle.status | lowercase }}
					</span>
					<span *ngIf="vehicle && !vehicle.status"> unknown </span>
				</div>
			</div>

			<div
				class="lk-ended-row"
				style="width: 135px">
				<button
					mat-icon-button
					(click)="onRefreshClick()"
					class="lk-background-icon lk-vehicle-details-icon-button"
					matTooltip="{{ 'VEHICLE_DETAILS.REFRESH_CTA' | translate }}">
					<mat-icon class="lk-accent-color"> refresh </mat-icon>
				</button>
				<button
					mat-icon-button
					[matMenuTriggerFor]="menu"
					class="lk-background-icon lk-vehicle-details-icon-button">
					<mat-icon> more_horiz </mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						(click)="onVehicleDeleteClick()">
						<mat-icon style="color: red"> delete </mat-icon>
						{{ 'VEHICLE_DETAILS.DELETE_VEHICLE' | translate }}
					</button>
				</mat-menu>
			</div>
		</div>
	</div>

	<div class="lk-centered-row lk-wrapped-flex">
		<button
			mat-button
			(click)="onStartVehicleClick(vehicle)"
			class="lk-half-margin lk-main-button lk-vehicle-details-button">
			<div class="lk-centered-row">
				<mat-icon> lock_open </mat-icon>
				<p
					class="lk-primary-color lk-ellipsized-text"
					style="margin: 0 0 0 4px">
					{{ 'VEHICLE_DETAILS.UNLOCK_VEHICLE' | translate }}
				</p>
			</div>
		</button>

		<button
			mat-button
			(click)="onStopVehicleClick(vehicle)"
			class="lk-half-margin lk-main-button lk-vehicle-details-button">
			<div class="lk-centered-row">
				<mat-icon> lock </mat-icon>
				<p
					class="lk-primary-color lk-ellipsized-text"
					style="margin: 0 0 0 4px">
					{{ 'VEHICLE_DETAILS.LOCK_VEHICLE' | translate }}
				</p>
			</div>
		</button>
	</div>

	<div
		class="lk-vehicle-details-navbar-layout lk-horiz-padding-md lk-full-width lk-margin-top">
		<app-vehicle-details-navbar
			*ngIf="vehicle"
			(navbar_item_change)="
				onNavbarItemChange($event)
			"></app-vehicle-details-navbar>
		<div
			*ngIf="vehicle && current_navbar_item == navbar_items[0]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-vehicle-details-container-layout lk-hide-scrollbar">
			<app-vehicle-details-general
				id="lk-vehicle-details-general-layout"
				class="lk-vehicle-details-content-layout lk-full-height"
				[vehicle]="vehicle"
				(vehicleUpdateClick)="
					onVehicleUpdateClick($event)
				"></app-vehicle-details-general>

			<div
				id="lk-vehicle-details-container-map-layout"
				class="lk-vehicle-details-content-layout lk-full-height">
				<app-vehicle-details-map
					class="lk-full-height"
					[vehicleSubject]="vehicle_subject"></app-vehicle-details-map>
			</div>
		</div>

		<div
			*ngIf="vehicle && current_navbar_item == navbar_items[1]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-vehicle-details-container-layout lk-hide-scrollbar">
			<app-vehicle-details-commands
				id="lk-vehicle-details-commands-layout"
				class="lk-vehicle-details-content-layout lk-full-height"
				[vehicle]="vehicle"></app-vehicle-details-commands>
		</div>

		<div
			*ngIf="vehicle && current_navbar_item == navbar_items[2]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-vehicle-details-container-layout lk-hide-scrollbar">
			<app-vehicle-details-measures
				id="lk-vehicle-details-measures-layout"
				class="lk-vehicle-details-content-layout lk-full-height"
				[vehicle]="vehicle"></app-vehicle-details-measures>
		</div>

		<div
			*ngIf="vehicle && current_navbar_item == navbar_items[3]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-vehicle-details-container-layout lk-hide-scrollbar">
			<app-vehicle-details-links
				id="lk-vehicle-details-links-layout"
				class="lk-vehicle-details-content-layout lk-full-height"
				[vehicle]="vehicle"></app-vehicle-details-links>
		</div>

		<div
			*ngIf="vehicle && current_navbar_item == navbar_items[4]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-full-width lk-centered-row lk-horiz-half-padding lk-vehicle-details-container-layout lk-hide-scrollbar">
			<app-vehicle-details-gallery
				id="lk-vehicle-details-gallery-layout"
				class="lk-vehicle-details-content-layout lk-full-height"
				[vehicleSubject]="vehicle_subject"
				(vehicleUpdateClick)="
					onVehicleUpdateClick($event)
				"></app-vehicle-details-gallery>
		</div>
	</div>

	<!--<div
		class="lk-spaced-row lk-wrapped-flex lk-padding-md"
		style="padding-top: 0; height: calc(100% - 174px); flex-grow: 1">
		<div
			class="lk-vehicle-details-content-layout lk-margin-top lk-horiz-half-padding lk-full-height">
			<app-vehicle-details-general
				[vehicle]="vehicle"
				(vehicleUpdateClick)="onVehicleUpdateClick($event)"></app-vehicle-details-general>
		</div>

		<div
			class="lk-vehicle-details-content-layout lk-margin-top lk-horiz-half-padding lk-full-height">
			<app-vehicle-details-gallery
				[vehicleSubject]="vehicle_subject"
				(vehicleUpdateClick)="onVehicleUpdateClick($event)"></app-vehicle-details-gallery>
		</div>

		<div
			class="lk-vehicle-details-content-layout lk-margin-top lk-horiz-half-padding lk-full-height">
			<app-vehicle-details-map [vehicleSubject]="vehicle_subject"></app-vehicle-details-map>
		</div>
	</div>-->

	<div
		*ngIf="is_vehicle_loading"
		class="lk-full-width lk-full-height lk-centered-row"
		style="position: absolute; top: 0; left: 0; max-height: 100vh">
		<div class="lk-overlay-spinner-layout lk-padding lk-border-radius">
			<mat-spinner
				color="accent"
				diameter="30"></mat-spinner>
		</div>
	</div>
</section>
