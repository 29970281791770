import { Injectable } from '@angular/core';
import countries from '../../assets/i18n/countries.json';

@Injectable({
	providedIn: 'root',
})
export class CountriesService {
	constructor() {}

	getCountryPrefixList(): CountryPrefix[] {
		const country_prefixes: CountryPrefix[] = [];
		countries.forEach(c => {
			country_prefixes.push({
				name: c.name,
				code: c.code,
				flag: `../../assets/imgs/lk-flags/${c.code}.svg`,
				prefix: c.dial_code,
			});
		});
		return country_prefixes;
	}
}

export interface CountryPrefix {
	name: string;
	code: string;
	flag: string;
	prefix: string;
}
