<app-shared-toolbar 
  [currentNavItem]="current_item" 
  [stationUpdateTrigger]="station_update_trigger"></app-shared-toolbar>

<section class="lk-section-with-toolbar lk-padding-md lk-spaced-column">
  <div class="lk-centered-column lk-full-width">
    <div 
      id="lk-station-builder-notif-form-layout" 
      class="lk-centered-column-wrapper">
      <div class="lk-centered-row lk-margin-bottom">
        <h1 class="lk-accent-color lk-no-margin lk-station-builder-step-text"> 4 </h1>
        <h1 class="lk-no-margin lk-half-margin-left"> 
          {{ 'STATION_BUILDER.STEP_NOTIF_TITLE' | translate }} 
        </h1>
      </div>
  
      <!--<app-station-builder-notif-lang-summary 
        [station]="station" 
        [isClickable]="true"
        [notifications]="notifications"
        (langClick)="onLangClick($event)"></app-station-builder-notif-lang-summary>-->
  
      <div class="lk-shadow lk-border-radius lk-padding-md lk-primary-background">
        <div 
          class="lk-centered-row lk-wrapped-flex"
          *ngIf="!is_request_running">
          <!--
          <div class="lk-half-margin-bottom lk-spaced-row lk-full-width">
            <div  
              class="lk-accent-background lk-border-radius lk-station-builder-notif-list-lang-label" 
              style="width: fit-content;">
              <p class="lk-no-margin lk-primary-color"> {{ getLanguageLabel() | translate }} </p>
            </div>
            <mat-slide-toggle 
              class="lk-half-margin-left"
              (change)="onSetDefaultLangClick($event)"
              [checked]="default_check"> default </mat-slide-toggle>
          </div>
          -->
          
          <!--<div class="lk-full-width lk-margin-top">
            <h4 
              class="lk-no-margin lk-primary-medium-color" 
              *ngIf="isSmsEnabled()"
              style="margin-bottom: 6px"> 
              {{ 'STATION_BUILDER.SMS_NOTIF_LABEL' | translate }} 
            </h4>
          </div>-->
          <h4 
            class="lk-no-margin lk-primary-medium-color lk-margin-top" 
            *ngIf="isSmsEnabled()"
            style="margin-bottom: 6px"> 
            {{ 'STATION_BUILDER.SMS_NOTIF_LABEL' | translate }} 
          </h4>
          <div class="lk-full-width lk-centered-row lk-wrapped-flex" *ngIf="isSmsEnabled()">
            <div 
              class="lk-border-radius lk-primary-medium-background lk-half-padding
                lk-station-builder-notif-list-item-layout lk-centered-column lk-quarter-margin"
              *ngFor="let notif of sms">
              <p 
                class="lk-no-margin" 
                style="font-size: 12px" 
                [ngClass]="getNotifTextClass(notif)"> 
                {{ getNotifLabel(sms_type, notif.name) | translate }} 
              </p>
              <button 
                class="lk-station-builder-notif-list-item-icon" 
                mat-icon-button
                *ngIf="!isCurrentNotifLoading(notif) && !notif.content_url" 
                (click)="smsInput.click()">
                <mat-icon color="accent"> upload </mat-icon>
                <input 
                  #smsInput 
                  type="file" 
                  accept="text/*" 
                  (change)="onNotificationInput($event,notif)" 
                  style="display:none;">
              </button>
          
              <div class="lk-station-builder-notif-list-item-icon">
                <button 
                  mat-icon-button
                  style="margin-right: -12px"
                  *ngIf="!isCurrentNotifLoading(notif) && notif.content_url" 
                  (click)="smsUpdateInput.click()">
                  <mat-icon color="accent"> create </mat-icon>
                  <input 
                    #smsUpdateInput 
                    type="file" 
                    accept="text/*" 
                    (change)="onNotificationInput($event,notif)" 
                    style="display:none;">
                </button>

                <button 
                  mat-icon-button
                  *ngIf="!isCurrentNotifLoading(notif) && notif.content_url" 
                  (click)="onNotifDeleteClick(notif)">
                  <mat-icon color="accent"> clear </mat-icon>
                </button>
              </div>
          
              <div  
                class="lk-ended-row lk-full-width lk-station-builder-notif-list-spinner" 
                *ngIf="isCurrentNotifLoading(notif)">
                <mat-spinner 
                  diameter="20" 
                    color="accent"></mat-spinner>
              </div>
            </div>
          </div>
          
          <!--<div class="lk-full-width">
            <h4 
              class="lk-no-margin lk-primary-medium-color lk-half-margin-top" 
              *ngIf="isEmailEnabled()"
              style="margin-bottom: 6px"> 
              {{ 'STATION_BUILDER.EMAIL_NOTIF_LABEL' | translate }} 
            </h4>
          </div>-->
          <h4 
            class="lk-no-margin lk-primary-medium-color lk-half-margin-top" 
            *ngIf="isEmailEnabled()"
            style="margin-bottom: 6px"> 
            {{ 'STATION_BUILDER.EMAIL_NOTIF_LABEL' | translate }} 
          </h4>
          <div class="lk-centered-row lk-wrapped-flex lk-full-width" *ngIf="isEmailEnabled()">
            <div 
              class="lk-border-radius lk-primary-medium-background lk-half-padding
                lk-station-builder-notif-list-item-layout lk-centered-column lk-quarter-margin"
              *ngFor="let notif of emails">
              <p 
                class="lk-no-margin" 
                style="font-size: 12px" 
                [ngClass]="getNotifTextClass(notif)"> 
                {{ getNotifLabel(email_type, notif.name) | translate }} 
              </p>
              <button 
                class="lk-station-builder-notif-list-item-icon" 
                mat-icon-button
                *ngIf="!isCurrentNotifLoading(notif) && !notif.content_url" 
                (click)="emailInput.click()">
                <mat-icon color="accent"> upload </mat-icon>
                <input 
                  #emailInput 
                  type="file" 
                  (change)="onNotificationInput($event,notif)" 
                  style="display:none;">
              </button>

              <div class="lk-station-builder-notif-list-item-icon">
                <button 
                  mat-icon-button
                  style="margin-right: -12px"
                  *ngIf="!isCurrentNotifLoading(notif) && notif.content_url" 
                  (click)="emailUpdateInput.click()">
                  <mat-icon color="accent"> create </mat-icon>
                  <input 
                    #emailUpdateInput 
                    type="file" 
                    (change)="onNotificationInput($event,notif)" 
                    style="display:none;">
                </button>

                <button 
                  mat-icon-button
                  *ngIf="!isCurrentNotifLoading(notif) && notif.content_url" 
                  (click)="onNotifDeleteClick(notif)">
                  <mat-icon color="accent"> clear </mat-icon>
                </button>
              </div>
          
              <div  
                class="lk-ended-row lk-full-width lk-station-builder-notif-list-spinner" 
                *ngIf="isCurrentNotifLoading(notif)">
                <mat-spinner 
                  diameter="20" 
                  color="accent"></mat-spinner>
              </div>
            </div>
          </div>
          
          <div class="lk-full-width lk-centered-row">
            <button 
              class="lk-main-button lk-double-margin-top lk-station-builder-notif-half-width"
              mat-button 
              (click)="onNextClick()" 
              *ngIf="!update_mode">
              <h3 class="lk-no-margin lk-primary-color"> {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} </h3>
            </button>
          
            <button 
              class="lk-main-button lk-double-margin-top lk-station-builder-notif-half-width"
              mat-button 
              (click)="onUpdateClick()" 
              *ngIf="update_mode">
              <h3 class="lk-no-margin lk-primary-color"> {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} </h3>
            </button>
          </div>
        </div>

        <div 
          class="lk-centered-row"
          *ngIf="is_request_running">
          <mat-spinner
            diameter="30"
            color="accent"></mat-spinner>
        </div>
        
      </div>
    </div>
  </div>

  <app-station-builder-steps [currentStep]="current_step"></app-station-builder-steps>
</section>

