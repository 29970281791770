import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Booking } from 'src/app/core/booking.service';
import { FormService } from 'src/app/core/form.service';
import {
	MAP_DEFAULT_URL,
	MAPBOX_API_KEY,
	MAP_DEFAULT_BASE_POINT,
	MAP_DEFAULT_ZOOM,
	MAP_MAX_ZOOM,
} from 'src/app/shared/constants';

declare var L: any;

@Component({
	selector: 'app-booking-details-path',
	templateUrl: './booking-details-path.component.html',
	styleUrls: ['./booking-details-path.component.scss'],
})
export class BookingDetailsPathComponent implements OnInit {
	@Input() booking: Booking;
	path_form_group: FormGroup = this.form_service.booking_path_form_group;
	map: any;
	booking_interval = [];
	path: number[][] = [
		[41.902046, 12.498357],
		[41.906132, 12.505311],
		[41.904385, 12.506232],
		[41.903833, 12.506929],
		[41.904021, 12.509682],
	];

	constructor(private form_service: FormService) {}

	ngOnInit(): void {
		this.loadMap();
		this.drawPolyline();

		const day_in_millis: number = 1000 * 60 * 60 * 24;
		const start_date: number = this.booking.start_date;
		const end_date: number = this.booking.end_date;

		let current_date: number = start_date;
		this.booking_interval = [];
		while (current_date < end_date) {
			this.booking_interval.push(current_date);
			current_date += day_in_millis;
		}
		this.booking_interval.push(end_date);
		this.path_form_group.get('current_date').setValue(this.booking_interval[0]);
	}

	loadMap(): void {
		this.map = L.map('lk-booking-details-path-map', {
			attributionControl: false,
			zoomControl: false,
			minZoom: 3,
		});
		L.control
			.zoom({
				position: 'topright',
			})
			.addTo(this.map);

		let mapBoxUrl = MAP_DEFAULT_URL + MAPBOX_API_KEY;
		this.map.setView(MAP_DEFAULT_BASE_POINT, MAP_DEFAULT_ZOOM);
		return L.tileLayer(mapBoxUrl, {
			attribution: '',
			maxZoom: MAP_MAX_ZOOM,
			id: 'mapbox/light-v10',
			accessToken: 'your.mapbox.access.token',
		}).addTo(this.map);
	}

	drawPolyline(): void {
		let polyline = L.polyline(this.path, { color: '#0ac2bf' }).addTo(this.map);
		this.map.fitBounds(polyline.getBounds());
		const start_point = this.path[0];
		const end_point = this.path[this.path.length - 1];
		this.drwaMarker(
			start_point,
			'../../../assets/imgs/lk-start-marker-icon.png',
		);
		this.drwaMarker(end_point, '../../../assets/imgs/lk-end-marker-icon.png');
	}

	drwaMarker(point: number[], icon: string): void {
		const lkMarkerIcon = L.icon({
			iconUrl: icon,
			iconSize: [40, 42], // size of the icon
			shadowSize: [40, 42], // size of the shadow
			iconAnchor: [20, 42], // point of the icon which will correspond to marker's location
			shadowAnchor: [20, 42], // the same for the shadow
		});
		const marker = L.marker(point, { icon: lkMarkerIcon }).addTo(this.map);
	}
}
