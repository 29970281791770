<p
	class="lk-no-margin lk-half-margin-bottom"
	style="font-size: 12px">
	{{ 'AUTHENTICATION.MFA_RESET_CTA_SUBTITLE' | translate }}
</p>

<form
	[formGroup]="form_group"
	class="lk-spaced-row lk-wrapped-flex">
	<mat-radio-group
		formControlName="mfa_type"
		class="lk-full-width lk-column"
		(change)="onRadioChange($event)">
		<mat-radio-button
			[value]="sms_value"
			class="lk-half-margin-bottom"
			>sms</mat-radio-button
		>
		<mat-radio-button
			[value]="totp_value"
			class="lk-margin-bottom"
			>authenticator app</mat-radio-button
		>
	</mat-radio-group>

	<form
		[formGroup]="phone_form_group"
		class="lk-spaced-row lk-wrapped-flex">
		<mat-form-field
			*ngIf="isSmsEnabled() && previous_mfa_preference == totp_value"
			appearance="fill"
			floatLabel="always"
			color="accent"
			class="lk-third-width lk-margin-bottom">
			<mat-label>
				{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
			</mat-label>
			<img
				*ngIf="current_country_prefix"
				matPrefix
				style="min-width: 16px; height: 12px"
				class="lk-half-margin-right"
				[src]="current_country_prefix.flag" />

			<img
				*ngIf="!current_country_prefix"
				matPrefix
				style="min-width: 16px; height: 12px"
				class="lk-half-margin-right"
				[src]="unknown_flag_src" />

			<input
				id="lk-booking-phone-builder-prefix-input"
				type="text"
				matInput
				formControlName="phone_prefix"
				required
				[matAutocomplete]="autocountry" />
			<mat-autocomplete
				autoActiveFirstOption
				#autocountry="matAutocomplete"
				(optionSelected)="onCountryPrefixSelect()">
				<mat-option
					*ngFor="let country_prefix of filtered_options | async"
					[value]="country_prefix.code">
					<div class="lk-row">
						<img
							style="min-width: 32px; height: 24px"
							class="lk-half-margin-right"
							[src]="country_prefix.flag" />
						<div class="lk-column">
							<p
								style="
									max-width: calc(100% - 6px);
									font-size: 10px;
									line-height: 18px;
								"
								class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
								{{ country_prefix.name }}
							</p>
							<p
								style="line-height: 18px"
								class="lk-no-margin lk-ellipsized-text">
								{{ country_prefix.prefix }}
							</p>
						</div>
					</div>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>

		<mat-form-field
			*ngIf="isSmsEnabled() && previous_mfa_preference == totp_value"
			appearance="fill"
			color="accent"
			class="lk-three-third-width lk-margin-bottom">
			<mat-label>
				{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
			</mat-label>
			<input
				id="lk-booking-phone-builder-phone-input"
				matInput
				type="tel"
				formControlName="phone"
				required />
		</mat-form-field>
	</form>

	<div class="lk-full-width lk-centered-row lk-half-margin-bottom">
		<button
			mat-button
			class="lk-main-button lk-full-width"
			(click)="onConfirmClick()"
			[disabled]="isButtonDisabled()"
			[ngClass]="getDisabledClass()">
			<h3
				class="lk-no-margin lk-primary-color"
				*ngIf="!is_reset_running">
				{{ 'GENERAL.CONFIRM_CTA' | translate }}
			</h3>
			<div
				class="lk-spinner-layout"
				*ngIf="is_reset_running">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
		</button>
	</div>
</form>
