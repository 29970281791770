<div class="lk-full-width lk-centered-row lk-hidden-before-md">
	<div
		id="lk-station-basics-info-layout"
		class="lk-shadow lk-padding lk-border-radius lk-primary-background lk-row">
		<div
			id="lk-station-basics-logo-layout"
			class="lk-primary-medium-background lk-padding lk-centered-row">
			<img
				id="lk-station-basics-logo"
				class="lk-full-width lk-cursor-pointer"
				[src]="logo"
				(click)="fileInput.click()"
				*ngIf="logo" />

			<input
				#fileInput
				type="file"
				accept="image/png"
				(change)="onLogoUpdateClick($event)"
				style="display: none" />

			<mat-spinner
				diameter="20"
				color="accent"
				*ngIf="!logo"></mat-spinner>
		</div>

		<div
			*ngIf="station"
			id="lk-station-basics-details-layout"
			class="lk-padding">
			<h1
				class="lk-no-margin lk-station-basics-text"
				*ngIf="station.name">
				{{ station.name }}
			</h1>

			<p
				class="lk-no-margin lk-half-margin-bottom lk-station-basics-text"
				*ngIf="station.id">
				{{ station.id.substr(0, 3) + station.id.substr(station.id.length - 8, station.id.length) }}
			</p>

			<p
				class="lk-no-margin lk-primary-medium-color lk-station-basics-text"
				*ngIf="station.address && station.phone && station.email">
				{{ station.address.formatted_address }} | +{{
					station.phone.prefix + station.phone.number
				}}
				|
				{{ station.email }}
			</p>
		</div>

		<img
			id="lk-station-basics-turo-label"
			style="width: 50px"
			*ngIf="station && station.ext_email"
			src="../../../assets/imgs/lk-turo-logo.png" />
		<div
			id="lk-station-basics-update-info-button"
			*ngIf="station">
			<button
				mat-icon-button
				class="lk-background-icon"
				[matMenuTriggerFor]="menu"
				#menuTrigger="matMenuTrigger">
				<mat-icon class="lk-accent-color"> more_horiz </mat-icon>
			</button>

			<mat-menu #menu="matMenu">
				<button
					mat-menu-item
					(click)="onBasicsUpdateClick()">
					<mat-icon class="lk-accent-color"> create </mat-icon>
					<span> {{ 'STATION.UPDATE_STATION_CTA' | translate }} </span>
				</button>
				<button
					mat-menu-item
					(click)="onDeleteClick()">
					<mat-icon class="lk-warn-color"> delete </mat-icon>
					<span> {{ 'STATION.DELETE_STATION_CTA' | translate }} </span>
				</button>
			</mat-menu>
		</div>
	</div>
</div>

<div class="lk-full-width lk-centered-column lk-hidden-after-md">
	<div
		id="lk-station-basics-logo-layout"
		class="lk-primary-medium-background lk-padding lk-centered-row">
		<img
			id="lk-station-basics-logo"
			class="lk-full-width lk-cursor-pointer"
			[src]="logo"
			(click)="fileMdInput.click()"
			*ngIf="logo" />

		<input
			#fileMdInput
			type="file"
			accept="image/png"
			(change)="onLogoUpdateClick($event)"
			style="display: none" />

		<mat-spinner
			diameter="20"
			color="accent"
			*ngIf="!logo"></mat-spinner>
	</div>

	<div
		*ngIf="logo"
		style="position: relative"
		class="lk-padding lk-hidden-after-md lk-padding lk-shadow lk-primary-background lk-border-radius lk-margin-top">
		<h1
			class="lk-no-margin lk-station-basics-text"
			*ngIf="station && station.name">
			{{ station.name }}
		</h1>

		<p
			class="lk-no-margin lk-half-margin-bottom lk-station-basics-text"
			*ngIf="station && station.id">
			{{ station.id.substr(0, 3) + station.id.substr(station.id.length - 8, station.id.length) }}
		</p>

		<p
			class="lk-no-margin lk-primary-medium-color lk-station-basics-text"
			*ngIf="station && station.address && station.phone && station.email">
			{{ station.address.formatted_address }} | +{{ station.phone.prefix + station.phone.number }} |
			{{ station.email }}
		</p>

		<div
			id="lk-station-basics-update-info-button"
			*ngIf="station">
			<button
				mat-icon-button
				class="lk-background-icon"
				[matMenuTriggerFor]="menu"
				#menuTrigger="matMenuTrigger">
				<mat-icon class="lk-accent-color"> more_horiz </mat-icon>
			</button>

			<mat-menu #menu="matMenu">
				<button
					mat-menu-item
					(click)="onBasicsUpdateClick()">
					<mat-icon class="lk-accent-color"> create </mat-icon>
					<span> {{ 'STATION.UPDATE_STATION_CTA' | translate }} </span>
				</button>
				<button
					mat-menu-item
					(click)="onDeleteClick()">
					<mat-icon class="lk-warn-color"> delete </mat-icon>
					<span> {{ 'STATION.DELETE_STATION_CTA' | translate }} </span>
				</button>
			</mat-menu>
		</div>
	</div>
</div>
