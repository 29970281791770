import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-booking-empty',
  templateUrl: './booking-empty.component.html',
  styleUrls: ['./booking-empty.component.scss']
})
export class BookingEmptyComponent {
  @Output() bookingBuildClick : EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  onBookingBuildClick() {
    this.bookingBuildClick.emit();
  }
}
