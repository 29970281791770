<div class="lk-full-width lk-border-radius lk-shadow lk-padding-md lk-primary-background">
  <h2 class="lk-no-margin lk-accent-color">
    {{ 'STATION_BUILDER.STEP_NOTIF_TITLE' | translate }}
  </h2>
  <p class="lk-primary-medium-color lk-no-margin lk-margin-bottom">
    {{ 'STATION_BUILDER.STEP_NOTIF_SUBTITLE' | translate }}
  </p>

  <div class="lk-column">
    <mat-slide-toggle [(ngModel)]="is_sms_checked">
      {{ 'STATION_BUILDER.SMS_NOTIF_LABEL' | translate }}
      <br> 
      <p 
        class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label"
        style="max-width: 250px">
        {{ 'STATION_BUILDER.SMS_NOTIF_DESCRIPTION' |translate }} 
      </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      [(ngModel)]="is_email_checked" 
      class="lk-margin-top">
      {{ 'STATION_BUILDER.EMAIL_NOTIF_LABEL' | translate }}
      <br> 
      <p 
        class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label"
        style="max-width: 250px">
        {{ 'STATION_BUILDER.EMAIL_NOTIF_DESCRIPTION' |translate }} 
      </p>
    </mat-slide-toggle>
  </div>

  <div class="lk-full-width lk-ended-row lk-margin-top">
    <button 
      class="lk-main-button" 
      mat-button 
      (click)="onNotifUpdateClick()">
      <span> {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} </span>
    </button>
  </div>
</div>
