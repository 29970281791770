import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import {
	AUTH_MFA_SETUP_PATH,
	VEHICLES_PATH,
} from 'src/app/core/navigation.service';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
	CODE_MISMATCH_ERROR_CODE,
	CUSTOM_AUTH_LOGO_BACKGROUND_PATH,
	CUSTOM_AUTH_LOGO_PATH,
	DEV_STAGE,
	PROD_STAGE,
	SOFTWARE_TOKEN_MFA,
} from 'src/app/shared/constants';
import { CODE_MISMATCH_ERROR_MESSAGE } from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-auth-mfa-verify',
	templateUrl: './auth-mfa-verify.component.html',
	styleUrls: ['./auth-mfa-verify.component.scss'],
})
export class AuthMfaVerifyComponent implements OnInit {
	is_mfa_verify_running: boolean = false;
	form_group: FormGroup = this.form_service.mfa_verify_form_group;
	mfa_setup_mechanism?: 'sms' | 'totp';
	pending_cognito_user?: CognitoUser;
	is_production: boolean;

	logo_src: string = '../../../assets/imgs/lk-logo.svg';
	background_src: string =
		'../../../assets/imgs/lk-backgrounds/lk-default-auth-background.svg';

	constructor(
		private form_service: FormService,
		private auth_service: AuthenticationService,
		private router: Router,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;
		this.pending_cognito_user = this.auth_service.pending_cognito_user;
		this.mfa_setup_mechanism = this.auth_service.mfa_setup_mechanism;
		this.form_group.reset();

		const base_path = location.origin
			.replace('http://', '')
			.replace('https://', '');

		const dot_index = base_path.indexOf('.');
		const double_dot_index = base_path.indexOf(':');
		let subdomain: string;

		if (dot_index != -1) {
			subdomain = base_path.substring(0, dot_index);
		} else if (double_dot_index != -1) {
			subdomain = base_path.substring(0, double_dot_index);
		}

		console.log(subdomain);

		if (subdomain && subdomain != 'manage') {
			this.logo_src = CUSTOM_AUTH_LOGO_PATH(environment.STAGE, subdomain);
			this.background_src = CUSTOM_AUTH_LOGO_BACKGROUND_PATH(
				environment.STAGE,
				subdomain,
			);
		}
	}

	onMfaVerifyClick(): void {
		if (this.isFormValid()) {
			const otp = this.form_group.get('code')?.value;
			this.confirmSignin(otp, this.mfa_setup_mechanism == 'totp');
		}
	}

	confirmSignin(otp: string, is_totp?: boolean): void {
		this.is_mfa_verify_running = true;
		this.auth_service
			.confirmSignin(this.pending_cognito_user, otp, is_totp)
			.subscribe({
				next: () => {
					/*if (this.is_production) {
						if (this.mfa_setup_mechanism == 'sms') {
							this.setupMFAPreference();
						} else {
							this.router.navigate([VEHICLES_PATH]);
							this.is_mfa_verify_running = false;
						}
					} else {
						this.router.navigate([VEHICLES_PATH]);
						this.is_mfa_verify_running = false;
					}*/
					this.router.navigate([VEHICLES_PATH]);
					this.is_mfa_verify_running = false;
				},
				error: error => {
					if (error.code == CODE_MISMATCH_ERROR_CODE) {
						this.dialog_service.openInfoDialog(CODE_MISMATCH_ERROR_MESSAGE);
					} else {
						this.dialog_service.openInfoDialog(error.message);
					}
					this.is_mfa_verify_running = false;
				},
			});
	}

	setupMFAPreference(): void {
		this.is_mfa_verify_running = true;
		this.auth_service.setupMFAPreference(SOFTWARE_TOKEN_MFA).subscribe({
			next: () => {
				this.setupTOTP();
			},
			error: error => {
				this.dialog_service.openInfoDialog(error.message);
				this.is_mfa_verify_running = false;
			},
		});
	}

	setupTOTP(): void {
		this.is_mfa_verify_running = true;
		this.auth_service.setupTOTP().subscribe({
			next: response => {
				this.auth_service.mfa_setup_mechanism = 'totp';
				this.auth_service.totp_setup_secret = response;
				this.router.navigate([AUTH_MFA_SETUP_PATH]);
				this.is_mfa_verify_running = false;
			},
			error: error => {
				this.dialog_service.openInfoDialog(error.message);
				this.is_mfa_verify_running = false;
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_mfa_verify_running || !this.isFormValid(),
			'lk-main-button': !this.is_mfa_verify_running && this.isFormValid(),
		};
	}
}
