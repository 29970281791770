import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StationSettingsRoutingModule } from './station-settings-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { StationSettingsComponent } from './station-settings.component';
import { StationSettingsNotifComponent } from './station-settings-notif/station-settings-notif.component';
import { StationSettingsPicturesComponent } from './station-settings-pictures/station-settings-pictures.component';
import { StationSettingsLangComponent } from './station-settings-lang/station-settings-lang.component';
import { StationSettingsTriggerComponent } from './station-settings-trigger/station-settings-trigger.component';
import { StationSettingsTriggerUnlockComponent } from './station-settings-trigger-unlock/station-settings-trigger-unlock.component';
import { StationSettingsTriggerLockComponent } from './station-settings-trigger-lock/station-settings-trigger-lock.component';

@NgModule({
  declarations: [
    StationSettingsComponent,
    StationSettingsNotifComponent,
    StationSettingsPicturesComponent,
    StationSettingsLangComponent,
    StationSettingsTriggerComponent,
    StationSettingsTriggerUnlockComponent,
    StationSettingsTriggerLockComponent],
  imports: [
    CommonModule,
    StationSettingsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatExpansionModule,
    MatProgressSpinnerModule
  ],
  exports: [
    StationSettingsTriggerComponent,
    StationSettingsTriggerLockComponent,
    StationSettingsTriggerUnlockComponent,
    StationSettingsNotifComponent
  ]
})
export class StationSettingsModule { }
