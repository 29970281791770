import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from 'src/app/core/dialog.service';
import { Vehicle } from 'src/app/core/vehicle.service';
import { Booking, BookingStatus } from '../../core/booking.service';
import { BookingAddVehicleDialogComponent } from '../booking-add-vehicle-dialog/booking-add-vehicle-dialog.component';
import { zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { BookingDeleteDialogComponent } from '../booking-delete-dialog/booking-delete-dialog.component';
import {
	BOOKING_COMPLETED_LABEL,
	BOOKING_ERROR_WO_START_LABEL,
	BOOKING_ERROR_WO_STOP_LABEL,
	BOOKING_ERROR_WO_VEHICLE_LABEL,
	BOOKING_IN_PROGRESS_LABEL,
	BOOKING_PENDING_LABEL,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';
import {
	BOOKING_START_DATE_SORT_ASC_OPTION,
	BOOKING_START_DATE_SORT_DESC_OPTION,
	BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
	PROD_STAGE,
} from 'src/app/shared/constants';

@Component({
	selector: 'app-booking-list',
	templateUrl: './booking-list.component.html',
	styleUrls: ['./booking-list.component.scss'],
})
export class BookingListComponent implements OnInit {
	@Input() bookingUpdateTrigger: BehaviorSubject<Booking[]>;
	@Input() hideHeader: boolean;
	@Output() bookingDeleteClick: EventEmitter<Booking> =
		new EventEmitter<Booking>();
	@Output() bookingClick: EventEmitter<Booking> = new EventEmitter<Booking>();
	@Output() filtersClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() sortStartDateClick: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() vehicleAddClick: EventEmitter<{
		booking: Booking;
		vehicle: Vehicle;
	}> = new EventEmitter<{ booking: Booking; vehicle: Vehicle }>();
	form_group: FormGroup;
	bookings: Booking[];
	update_time: number;
	pending_label: string = BOOKING_PENDING_LABEL;
	is_production: boolean;
	desc_option: string = BOOKING_START_DATE_SORT_DESC_OPTION;
	asc_option: string = BOOKING_START_DATE_SORT_ASC_OPTION;
	current_start_date_sort_option: string;

	constructor(
		private dialogService: DialogService,
		private dialog: MatDialog,
		private formService: FormService,
	) {
		this.dialogService.dialog = this.dialog;
		this.form_group = this.formService.bookingSearchFormGroup;
		this.current_start_date_sort_option = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;
		this.bookingUpdateTrigger.subscribe(bookings => {
			if (bookings) {
				this.update_time = new Date().getTime();
				this.bookings = bookings;
			}
		});
		this.current_start_date_sort_option = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);
	}

	onAddVehicleClick(booking: Booking) {
		this.dialogService
			.openDialog(BookingAddVehicleDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.vehicle) {
					this.vehicleAddClick.emit({
						booking: booking,
						vehicle: dialogResult.vehicle,
					});
				}
			});
	}

	onSearchChange() {
		this.searchChange.emit(this.form_group.get('email').value);
	}

	onBookingClick(booking: Booking) {
		this.bookingClick.emit(booking);
	}

	onBookingDeleteClick(booking: Booking) {
		this.dialogService
			.openDialog(BookingDeleteDialogComponent, { booking: booking })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.confirm) {
					this.bookingDeleteClick.emit(booking);
				}
			});
	}

	onSortStartDateClick() {
		switch (this.current_start_date_sort_option) {
			case BOOKING_START_DATE_SORT_ASC_OPTION:
				this.current_start_date_sort_option =
					BOOKING_START_DATE_SORT_DESC_OPTION;
				break;
			case BOOKING_START_DATE_SORT_DESC_OPTION:
				this.current_start_date_sort_option = undefined;
				break;
			default:
				this.current_start_date_sort_option =
					BOOKING_START_DATE_SORT_ASC_OPTION;
				break;
		}
		if (this.current_start_date_sort_option) {
			localStorage.setItem(
				BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
				this.current_start_date_sort_option,
			);
		} else {
			localStorage.removeItem(BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY);
		}
		this.sortStartDateClick.emit(this.current_start_date_sort_option);
	}

	getBookingStatusLabel(booking: Booking) {
		if (!booking.errors || !booking.errors.length) {
			if (this.isPendingBooking(booking)) {
				return BOOKING_PENDING_LABEL;
			} else if (this.isCompletedBooking(booking)) {
				return BOOKING_COMPLETED_LABEL;
			} else {
				return BOOKING_IN_PROGRESS_LABEL;
			}
		} else {
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_VEHICLE)) {
				return BOOKING_ERROR_WO_VEHICLE_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_START)) {
				return BOOKING_ERROR_WO_START_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_STOP)) {
				return BOOKING_ERROR_WO_STOP_LABEL;
			}
		}
	}

	getStatusClass(booking: Booking) {
		let statusLabel: string;
		switch (this.getBookingStatusLabel(booking)) {
			case BOOKING_PENDING_LABEL:
				statusLabel = BookingStatus.PENDING;
				break;
			case BOOKING_IN_PROGRESS_LABEL:
				statusLabel = BookingStatus.IN_PROGRESS;
				break;
			case BOOKING_COMPLETED_LABEL:
				statusLabel = BookingStatus.COMPLETED;
				break;
			case BOOKING_ERROR_WO_VEHICLE_LABEL:
				statusLabel = BookingStatus.EXPIRED_WO_VEHICLE;
				break;
			case BOOKING_ERROR_WO_START_LABEL:
				statusLabel = BookingStatus.EXPIRED_WO_START;
				break;
			case BOOKING_ERROR_WO_STOP_LABEL:
				statusLabel = BookingStatus.EXPIRED_WO_STOP;
				break;
			default:
				statusLabel = BookingStatus.PENDING;
				break;
		}
		return {
			'lk-orange-status': statusLabel == BookingStatus.PENDING,
			'lk-green-status': statusLabel == BookingStatus.COMPLETED,
			'lk-blue-status': statusLabel == BookingStatus.IN_PROGRESS,
			'lk-red-status':
				statusLabel == BookingStatus.EXPIRED_WO_VEHICLE ||
				statusLabel == BookingStatus.EXPIRED_WO_START ||
				statusLabel == BookingStatus.EXPIRED_WO_STOP,
		};
	}

	getTimezoneOffset(booking: Booking) {
		return (
			'GMT' +
			(getTimezoneOffset(booking.timezone) / 3600000 >= 0 ? '+' : '') +
			getTimezoneOffset(booking.timezone) / 3600000
		);
	}

	getTimezoneStartTime(booking: Booking) {
		return (
			booking.start_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getTimezoneEndTime(booking: Booking) {
		return (
			booking.end_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getUserIdentifier(booking: Booking) {
		if (booking.user) {
			if (booking.user.first_name && booking.user.last_name) {
				return booking.user.first_name + ' ' + booking.user.last_name;
			} else if (
				booking.user.phone &&
				booking.user.phone.prefix &&
				booking.user.phone.value
			) {
				return '+' + booking.user.phone.prefix + booking.user.phone.value;
			} else if (booking.user.email) {
				return booking.user.email;
			}
			return (
				booking.id.substring(0, 3) +
				booking.id.substring(booking.id.length - 8, booking.id.length)
			);
		}
	}

	isPendingBooking(booking: Booking): boolean {
		const isUnlocked: boolean = booking.is_user_vehicle_unlocked;
		return !isUnlocked;
	}

	isCompletedBooking(booking: Booking): boolean {
		const lockAfterTime: number =
			booking.settings.lock_settings
				.time_to_lock_availability_after_booking_end;
		const currentTimezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone;
		const isInTime: boolean =
			new Date().getTime() >
			zonedTimeToUtc(
				booking.end_date + lockAfterTime,
				currentTimezone,
			).getTime();
		const isLocked: boolean = booking.is_user_vehicle_locked;
		return isInTime || isLocked;
	}

	isAddVehicleVisible(booking: Booking) {
		return this.getBookingStatusLabel(booking) == this.pending_label;
	}
}
