<div class="lk-full-width lk-border-radius lk-shadow lk-padding-md lk-margin-top lk-primary-background">
  <h2 class="lk-no-margin lk-accent-color">
    {{ 'STATION.SETTINGS_NOTIF_LANG_TITLE' | translate }}
  </h2>
  <p class="lk-primary-medium-color lk-no-margin lk-margin-bottom">
    {{ 'STATION.SETTINGS_LANG_SUBTITLE' | translate }}
  </p>

  <form 
    [formGroup]="form_group" 
    class="lk-margin-bottom lk-full-width lk-spaced-row lk-wrapped-flex">
    <mat-form-field 
      appearance="fill" 
      color="accent"
      class="lk-half-width lk-half-margin-top">
      <mat-label> {{ 'STATION.SETTINGS_NOTIF_LANG_CTA' | translate }} </mat-label>
      <mat-select 
        formControlName="lang" 
        required
        (selectionChange)="onLangSelectChange($event.value)">
        <mat-option 
          *ngFor="let lang of languages" 
          [value]="lang" 
          class="lk-options-list-item">
          <img [src]="'../../../assets/imgs/lk-flag-icons/' + lang + '.png'">
          {{ getLanguageLabel(lang) | translate }} 
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
