import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { Auth } from 'aws-amplify';
import { catchError, map, tap } from 'rxjs/operators';
import { LocalManagerService, LocalStationType } from './local-manager.service';
import { AUTH_SIGNIN_PATH } from './navigation.service';
import { CognitoUser } from 'amazon-cognito-identity-js';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	public loggedIn: BehaviorSubject<boolean>;

	pending_cognito_user?: CognitoUser;
	pending_cognito_email?: string;

	mfa_setup_mechanism?: 'sms' | 'totp';
	totp_setup_secret?: any;

	constructor(
		private router: Router,
		private localManager: LocalManagerService,
	) {
		this.loggedIn = new BehaviorSubject<boolean>(false);
	}

	public signUp(email: string, password: string): Observable<any> {
		return from(Auth.signUp(email, password));
	}

	public signIn(email, password): Observable<any> {
		return from(Auth.signIn(email, password)).pipe(
			tap(() => this.loggedIn.next(true)),
		);
	}

	public getAuthenticatedUser(): Observable<any> {
		return from(Auth.currentAuthenticatedUser());
	}

	public confirmSignin(
		cognito_customer: any,
		otp: string,
		is_totp?: boolean,
	): Observable<any> {
		return from(
			Auth.confirmSignIn(
				cognito_customer,
				otp,
				is_totp ? 'SOFTWARE_TOKEN_MFA' : undefined,
			),
		);
	}

	public isAuthenticated(): Observable<boolean> {
		return from(Auth.currentAuthenticatedUser()).pipe(
			map(result => {
				this.loggedIn.next(true);
				return true;
			}),
			catchError(error => {
				this.loggedIn.next(false);
				return of(false);
			}),
		);
	}

	public signOut() {
		from(Auth.signOut()).subscribe(
			result => {
				this.loggedIn.next(false);
				this.localManager.eraseLocalStation(LocalStationType.BUILDER);
				this.localManager.eraseLocalStation(LocalStationType.STATION);
				this.router.navigate([AUTH_SIGNIN_PATH]);
			},
			error => console.log(error),
		);
	}

	public forgotPassword(email: string) {
		return from(Auth.forgotPassword(email));
	}

	public forgotPasswordSubmit(
		email: string,
		code: string,
		newPassword: string,
	) {
		return from(Auth.forgotPasswordSubmit(email, code, newPassword));
	}

	public setupTOTP() {
		return from(Auth.setupTOTP(this.pending_cognito_user));
	}

	public verifyTOTP(code: string) {
		return from(Auth.verifyTotpToken(this.pending_cognito_user, code));
	}

	public setupMFAPreference(
		mfa_method: 'TOTP' | 'SMS' | 'NOMFA' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA',
	) {
		return from(Auth.setPreferredMFA(this.pending_cognito_user, mfa_method));
	}

	public getMFAPreference(user: CognitoUser) {
		return from(Auth.getPreferredMFA(user));
	}

	public getUserAttributes(user: CognitoUser) {
		return from(Auth.userAttributes(user));
	}

	public updateUserAttributes(user: CognitoUser, attributes: object) {
		return from(Auth.updateUserAttributes(user, attributes));
	}
}
