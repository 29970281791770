<div
	id="lk-station-add-notif-dialog-root-layout"
	class="lk-hide-scrollbar">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			class="lk-background-icon"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		*ngIf="!is_request_running"
		class="lk-padding">
		<hr class="lk-list-separator" />

		<mat-radio-group
			class="lk-column"
			(change)="form_group.reset()"
			[(ngModel)]="notif_type">
			<mat-radio-button
				class="lk-half-margin-bottom"
				[value]="sms_type">
				{{ sms_type }}
			</mat-radio-button>
			<mat-radio-button
				class="lk-margin-bottom"
				[value]="email_type">
				{{ email_type }}
			</mat-radio-button>
		</mat-radio-group>

		<form
			[formGroup]="form_group"
			class="lk-full-width lk-spaced-row lk-wrapped-flex">
			<div class="lk-full-width lk-spaced-row">
				<mat-form-field
					appearance="fill"
					class="lk-full-width"
					color="accent">
					<mat-label> {{ 'STATION.NOTIF_NAMES_LABEL' | translate }} </mat-label>
					<mat-select
						matInput
						type="text"
						required
						formControlName="notif_name">
						<div *ngIf="notif_type == sms_type">
							<mat-option
								[value]="name"
								*ngFor="let name of supported_sms_temp_labels">
								{{ name | translate }}
							</mat-option>
						</div>

						<div *ngIf="notif_type == email_type">
							<mat-option
								[value]="name"
								*ngFor="let name of supported_email_temp_labels">
								{{ name | translate }}
							</mat-option>
						</div>
					</mat-select>
				</mat-form-field>
			</div>
		</form>

		<hr class="lk-list-separator" />

		<form
			[formGroup]="content_form_group"
			*ngIf="isFormValid() && notif_type == sms_type">
			<span *ngIf="notif_type == sms_type">
				{{ 'STATION.NOTIF_SMS_CONTENT_CTA' | translate }}
			</span>

			<mat-form-field
				style="min-width: 350px"
				class="lk-full-width lk-half-margin-top"
				appearance="fill"
				color="accent">
				<mat-label> content </mat-label>
				<textarea
					type="text"
					matInput
					formControlName="notif"
					required></textarea>
			</mat-form-field>
		</form>

		<div
			class="lk-full-width lk-margin-top"
			*ngIf="isFormValid()">
			<span *ngIf="notif_type == sms_type">
				{{ 'STATION.NOTIF_SMS_FILE_UPLOAD_CTA' | translate }}
			</span>
			<span *ngIf="notif_type == email_type">
				{{ 'STATION.NOTIF_EMAIL_FILE_UPLOAD_CTA' | translate }}
			</span>

			<button
				style="padding: 32px !important; background: rgba(0, 0, 0, 0.04)"
				class="lk-centered-row lk-full-width lk-border-radius lk-half-margin-top"
				mat-button
				*ngIf="notif_type == sms_type"
				(click)="smsInput.click()">
				<mat-icon color="accent"> upload </mat-icon>
				<input
					#smsInput
					type="file"
					accept="text/*"
					(change)="onSmsInput($event)"
					style="display: none" />
			</button>

			<button
				style="padding: 32px !important; background: rgba(0, 0, 0, 0.04)"
				class="lk-centered-row lk-full-width lk-border-radius lk-half-margin-top"
				mat-button
				*ngIf="notif_type == email_type"
				(click)="emailInput.click()">
				<mat-icon color="accent"> upload </mat-icon>
				<input
					#emailInput
					type="file"
					accept="text/*"
					(change)="onEmailInput($event)"
					style="display: none" />
			</button>
		</div>

		<button
			(click)="onConfirmClick()"
			mat-button
			class="lk-main-button lk-margin-top lk-full-width">
			{{ 'GENERAL.CONFIRM_CTA' | translate }}
		</button>
	</div>

	<div
		*ngIf="is_request_running"
		class="lk-padding lk-full-width lk-centered-row">
		<mat-spinner
			diameter="20"
			color="accent"></mat-spinner>
	</div>
</div>
