import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [],
	imports: [CommonModule],
})
export class CoreModule {}

export class Address {
	formatted_address: string;
	country: string;
	city: string;
	region: string;
	zip_code: string;
	lat_lng: number[];
}

export class Phone {
	prefix: string;
	number: string;
}

export class Position {
	latitude: number;
	longitude: number;
}
