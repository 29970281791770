import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationRoutingModule } from './invitation-routing.module';
import { SharedModule } from '../shared/shared.module';

import { InvitationComponent } from './invitation.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InvitationComponent],
  imports: [
    CommonModule,
    InvitationRoutingModule,
    SharedModule,
    MatButtonModule,
    TranslateModule,
    MatProgressSpinnerModule
  ]
})
export class InvitationModule { }
