import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationType } from 'src/app/core/notification.service';

@Component({
	selector: 'app-stations-notif-templates-item-container',
	templateUrl: './stations-notif-templates-item-container.component.html',
	styleUrls: ['./stations-notif-templates-item-container.component.scss'],
})
export class StationsNotifTemplatesItemContainerComponent {
	@Input() template_type: NotificationType = NotificationType.SMS;
	@Input() is_default: boolean = true;
	@Input() is_selected: boolean = false;
	@Input() color: string = 'lk-primary-dark-color';
	@Input() height: number = 120;
	@Input() pill_background: string = 'lk-primary-dark-background';
	@Input() pill_color: string = 'lk-primary-color';
	@Input() pill_text?: string;
	@Output() container_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() default_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() custom_update_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() custom_upload_click: EventEmitter<any> = new EventEmitter<any>();
	@Output() custom_delete_click: EventEmitter<void> = new EventEmitter<void>();
	sms_type: NotificationType = NotificationType.SMS;
	email_type: NotificationType = NotificationType.EMAIL;
	is_update_running: boolean = false;
	is_delete_running: boolean = false;

	getContainerStyle(): { [key: string]: number | string } {
		return {
			opacity: this.is_selected ? 1 : 0.3,
			height: `${this.height}px`,
		};
	}

	getContainerClass(): { [key: string]: boolean } {
		return {
			'lk-shadow__light': this.is_selected,
			'': !this.is_selected,
		};
	}
}
