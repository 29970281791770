<app-shared-app-toolbar
  [current_nav_item]="current_item"
  [refresh]="toolbar_refresh"
  (no_station_found)="onNoStationFound()"
  (station_build_click)="onStationBuildClick(false)"
  (station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section class="lk-section-with-toolbar">
  <app-shared-spinner-overlay *ngIf="is_request_running"></app-shared-spinner-overlay>
  
  <div class="lk-padding-md">
    <!--<div class="lk-row" *ngIf="stations">
      <h1 
        class="lk-no-margin lk-ellipsized-text"
        style="width: fit-content"> 
        {{ current_station.name }} 
      </h1>
      <button 
        mat-icon-button 
        [matMenuTriggerFor]="menu">
        <mat-icon> expand_more </mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button 
          mat-menu-item 
          *ngFor="let station of stations"
          (click)="onStationChangeClick(station)">
          {{ station.name }}
        </button>
      </mat-menu>
    </div>-->

    <div  
      class="lk-border-radius lk-shadow lk-padding-md lk-primary-background"
      style="width: fit-content">
      <h2 class="lk-no-margin lk-accent-color">
        {{ 'HIDDEN_SETTINGS.ADAPTER_CREDENTIALS_TITLE' | translate }}
      </h2>
      <p class="lk-primary-medium-color lk-no-margin lk-margin-bottom">
        {{ 'HIDDEN_SETTINGS.ADAPTER_CREDENTIALS_SUBTITLE' | translate }}
      </p>

      <form [formGroup]="form_group">
        <mat-form-field 
          appearance="fill" 
          class="lk-full-width"
          color="accent">
          <mat-label> Client ID </mat-label>
          <input  
            type="text" 
            required 
            matInput
            formControlName="client_id">
        </mat-form-field>

        <mat-form-field 
          appearance="fill" 
          class="lk-full-width lk-margin-top"
          color="accent">
          <mat-label> Client secret </mat-label>
          <input  
            type="text" 
            required 
            matInput
            formControlName="client_secret">
        </mat-form-field>

        <div class="lk-full-width lk-ended-row lk-margin-top">
          <button 
            class="lk-main-button" 
            mat-button 
            [disabled]="is_save_running" 
            [ngClass]="getDisabledClass()"
            (click)="onCredentialsUpdateClick()">
            <span *ngIf="!is_save_running"> {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} </span>
            <div  
              class="lk-spinner-layout" 
              *ngIf="is_save_running">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</section>