import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
	SUBSCRIPTION_SIGNIN_PATH,
	SUBSCRIPTION_SIGNUP_PATH,
} from 'src/app/core/navigation.service';
import { REGISTERED_USER_FLOW } from 'src/app/shared/constants';

@Component({
	selector: 'app-subscription-info',
	templateUrl: './subscription-info.component.html',
	styleUrls: ['./subscription-info.component.scss'],
})
export class SubscriptionInfoComponent implements OnInit {
	params?: Params;

	constructor(private router: Router, private route: ActivatedRoute) {
		this.route.queryParams.subscribe(params => {
			this.params = params;
		});
	}

	ngOnInit(): void {}

	onNextClick(): void {
		if (this.params.flow == REGISTERED_USER_FLOW) {
			this.router.navigate([SUBSCRIPTION_SIGNIN_PATH]);
		} else {
			this.router.navigate([SUBSCRIPTION_SIGNUP_PATH]);
		}
	}

	isNewFlow(): boolean {
		return this.params.flow != REGISTERED_USER_FLOW;
	}
}
