import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../core/authentication.service';
import { AUTH_SIGNIN_PATH } from '../core/navigation.service';

@Injectable({
	providedIn: 'root',
})
export class MfaSetupMechanismGuard implements CanActivate {
	constructor(
		private auth_service: AuthenticationService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const mfa_setup_mechanism = this.auth_service.mfa_setup_mechanism;
		if (mfa_setup_mechanism) {
			return true;
		} else {
			this.router.navigate([AUTH_SIGNIN_PATH]);
			return false;
		}
	}
}
