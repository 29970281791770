<app-shared-app-toolbar
	[current_nav_item]="current_item"
	[refresh]="toolbar_refresh"
	(no_station_found)="onNoStationFound()"
	(station_build_click)="onStationBuildClick(false)"
	(station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section class="lk-section-with-toolbar">
	<div
		*ngIf="!no_station_found"
		style="position: relative"
		class="lk-station-root-padding-md">
		<div
			id="lk-station-map-layout"
			class="lk-border-radius lk-shadow"></div>
		<div
			id="lk-station-map-overlay"
			class="lk-border-radius"></div>
	</div>

	<div
		*ngIf="!no_station_found"
		class="lk-full-width lk-padding">
		<div
			id="lk-station-basics-layout"
			class="lk-centered-column">
			<app-station-basics
				style="z-index: 998; position: relative"
				class="lk-full-width"
				[station]="station"
				[logo]="logo"
				(station_build_click)="onStationBuildClick(true)"
				(logo_build_click)="onLogoBuildClick($event)"
				(station_delete_click)="onStationDeleteClick()"></app-station-basics>
		</div>
	</div>

	<div
		id="lk-station-info-layout"
		class="lk-full-width lk-padding-md lk-centered-column"
		*ngIf="station">
		<div id="lk-station-navbar-layout">
			<app-station-navbar
				[station]="station"
				(navbar_item_change)="onNavbarItemChange($event)"></app-station-navbar>
		</div>

		<div
			class="lk-full-width lk-centered-row"
			*ngIf="navbar_item == navbar_items[0]">
			<div class="lk-half-width-lg">
				<app-station-team
					[station]="station"
					(self_delete)="onSelfDelete()"></app-station-team>
			</div>
		</div>

		<div
			class="lk-full-width lk-spaced-row lk-wrapped-flex"
			style="align-items: flex-start"
			*ngIf="navbar_item == navbar_items[1]">
			<div class="lk-half-width-lg">
				<app-station-notif [station]="station"></app-station-notif>
			</div>
			<div class="lk-half-width-lg lk-station-secondary-content">
				<app-station-notif-settings
					[station]="station"
					(refresh)="onNotifRefreshClick()"
					(start_spinner)="onNotifStartSpinner()"></app-station-notif-settings>
			</div>
		</div>

		<div
			class="lk-full-width lk-centered-row"
			*ngIf="navbar_item == navbar_items[2]">
			<div class="lk-half-width-lg">
				<app-station-pics [station]="station"></app-station-pics>
			</div>
		</div>

		<div
			class="lk-full-width lk-centered-row"
			*ngIf="navbar_item == navbar_items[3]">
			<div class="lk-half-width-lg">
				<app-station-unlock [station]="station"></app-station-unlock>
			</div>
		</div>

		<div
			class="lk-full-width lk-centered-row"
			*ngIf="navbar_item == navbar_items[4]">
			<div class="lk-half-width-lg">
				<app-station-lock [station]="station"></app-station-lock>
			</div>
		</div>

		<div
			class="lk-full-width lk-centered-row"
			*ngIf="navbar_item == navbar_items[5]">
			<div class="lk-half-width-lg">
				<app-station-vehicles-settings
					[station]="station"></app-station-vehicles-settings>
			</div>
		</div>
	</div>

	<div
		class="lk-section-container-layout lk-centered-column lk-padding-md"
		*ngIf="no_station_found">
		<div class="lk-centered-column-wrapper">
			<app-station-empty
				(stationBuildClick)="onStationBuildClick(false)"></app-station-empty>
		</div>
	</div>
</section>
