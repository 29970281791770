<div
	(click)="container_click.emit()"
	[ngStyle]="getContainerStyle()"
	style="padding-bottom: 12px"
	class="lk-full-width lk-primary-medium-background lk-padding lk-border-radius lk-centered-row lk-wrapped-flex lk-cursor-pointer">
	<div class="lk-spaced-row lk-full-width">
		<app-shared-pill
			class="lk-full-width"
			[background]="pill_background"
			[color]="pill_color"
			[text]="pill_text"></app-shared-pill>
	</div>

	<div
		class="lk-ended-row lk-full-width lk-half-top-margin"
		style="margin-right: -12px">
		<button
			*ngIf="is_default && template_type == sms_type"
			[disabled]="!is_selected"
			(click)="default_click.emit()"
			mat-icon-button>
			<mat-icon> visibility </mat-icon>
		</button>

		<button
			*ngIf="
				!is_default &&
				template_type == sms_type &&
				!is_update_running &&
				!is_delete_running
			"
			[disabled]="!is_selected"
			(click)="custom_update_click.emit()"
			mat-icon-button>
			<mat-icon class="lk-accent-color"> create </mat-icon>
		</button>

		<button
			*ngIf="
				!is_default &&
				template_type == email_type &&
				!is_update_running &&
				!is_delete_running
			"
			[disabled]="!is_selected"
			(click)="upload_input.click()"
			mat-icon-button>
			<mat-icon> upload </mat-icon>
			<input
				#upload_input
				type="file"
				accept="text/*"
				(change)="custom_upload_click.emit($event)"
				style="display: none" />
		</button>

		<button
			*ngIf="!is_default && !is_update_running && !is_delete_running"
			[disabled]="!is_selected"
			(click)="custom_delete_click.emit()"
			mat-icon-button>
			<mat-icon class="lk-warn-color"> delete </mat-icon>
		</button>
	</div>

	<div
		*ngIf="is_update_running || is_delete_running"
		class="lk-ended-row lk-full-width lk-half-padding">
		<mat-spinner
			color="accent"
			diameter="20"></mat-spinner>
	</div>
</div>
