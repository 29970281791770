import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionRoutingModule } from './subscription-routing.module';
import { TranslateModule } from '@ngx-translate/core';

import { SubscriptionStartComponent } from './subscription-start/subscription-start.component';
import { SubscriptionPaymentComponent } from './subscription-payment/subscription-payment.component';
import { SubscriptionSigninComponent } from './subscription-signin/subscription-signin.component';
import { SubscriptionSignupComponent } from './subscription-signup/subscription-signup.component';
import { SubscriptionInfoComponent } from './subscription-info/subscription-info.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SubscriptionStartDialogComponent } from './subscription-start-dialog/subscription-start-dialog.component';

@NgModule({
	declarations: [
		SubscriptionStartComponent,
		SubscriptionPaymentComponent,
		SubscriptionSigninComponent,
		SubscriptionSignupComponent,
		SubscriptionInfoComponent,
  SubscriptionStartDialogComponent,
	],
	imports: [
		CommonModule,
		SubscriptionRoutingModule,
		TranslateModule,
		MatIconModule,
		MatButtonModule,
	],
})
export class SubscriptionModule {}
