import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LOCAL_STATION_KEY} from '../../shared/constants';
import {LocalManagerService} from '../../core/local-manager.service';
import {PicturePolicy, Station} from '../../core/station.service';
import {SharedSingleActionDialogComponent} from '../../shared/shared-single-action-dialog/shared-single-action-dialog.component';
import {NO_PIC_POLICY_MESSAGE} from '../../shared/translations';
import {DialogService} from '../../core/dialog.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-station-settings-pictures',
  templateUrl: './station-settings-pictures.component.html',
  styleUrls: ['./station-settings-pictures.component.scss']
})
export class StationSettingsPicturesComponent implements OnInit {
  @Input() stationUpdateTrigger: BehaviorSubject<boolean>;
  @Output() policyUpdateClick: EventEmitter<PicturePolicy> = new EventEmitter<PicturePolicy>();
  policy: PicturePolicy;
  mandatory_policy: PicturePolicy = PicturePolicy.MANDATORY;
  mandatory_pre_policy: PicturePolicy = PicturePolicy.MANDATORY_PRE;
  mandatory_post_policy: PicturePolicy = PicturePolicy.MANDATORY_POST;
  optional_policy: PicturePolicy = PicturePolicy.OPTIONAL;
  disabled_policy: PicturePolicy = PicturePolicy.DISABLED;
  is_policy_mandatory: boolean = false;
  is_policy_mandatory_pre: boolean = false;
  is_policy_mandatory_post: boolean = false;
  is_policy_optional: boolean = false;
  is_policy_disabled: boolean = false;

  constructor(
    private local_manager: LocalManagerService, 
    private dialog_service: DialogService,
    private dialog: MatDialog
  ) {
    this.dialog_service.dialog = this.dialog;
  }

  ngOnInit() {
    this.stationUpdateTrigger.subscribe(
      update => {
        if (update) {
          let currentStation: Station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
          if (currentStation) {
            this.policy = currentStation.settings.pictures_policy;
            this.setPolicyFlags();
          }
        }
      }
    );
  }

  onToggleChange(event, policy: PicturePolicy) {
    if (event.checked) {
      this.policy = policy;
      this.setPolicyFlags();
    } else {
      this.policy = null;
    }
  }

  onPolicyUpdateClick() {
    if (this.policy) {
      this.policyUpdateClick.emit(this.policy);
    } else {
      this.dialog_service.openDialog(SharedSingleActionDialogComponent, {
        message: NO_PIC_POLICY_MESSAGE
      });
    }
  }

  setPolicyFlags() {
    switch (this.policy) {
      case PicturePolicy.MANDATORY:
        this.is_policy_mandatory      = true;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = false;
        break;
      case PicturePolicy.MANDATORY_PRE:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = true;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = false;
        break;
      case PicturePolicy.MANDATORY_POST:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = true;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = false;
        break;
      case PicturePolicy.OPTIONAL:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = true;
        this.is_policy_disabled       = false;
        break;
      case PicturePolicy.DISABLED:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = true;
        break;
    }
  }
}
