import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import {
	NotificationService,
	Notification,
	NotificationName,
	NotificationType,
} from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import {
	DEFAULT_TEMPLATES,
	LOCAL_STATION_KEY,
	PROD_STAGE,
} from 'src/app/shared/constants';
import {
	END_RENTAL_EMAIL_LABEL,
	END_RENTAL_SMS_LABEL,
	GENERAL_ERROR_MESSAGE,
	START_RENTAL_EMAIL_LABEL,
	START_RENTAL_SMS_LABEL,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';
import { StationAddNotifDialogComponent } from '../station-add-notif-dialog/station-add-notif-dialog.component';
import { StationNotifDetailsDialogComponent } from '../station-notif-details-dialog/station-notif-details-dialog.component';
import { StationNotifSmsTemplatesDialogComponent } from '../station-notif-sms-templates-dialog/station-notif-sms-templates-dialog.component';
import { TemplateItem } from '../stations-notif-templates/stations-notif-templates.component';

@Component({
	selector: 'app-station-notif',
	templateUrl: './station-notif.component.html',
	styleUrls: ['./station-notif.component.scss'],
})
export class StationNotifComponent implements OnInit {
	@Input() station: Station;
	template_items?: TemplateItem[];
	default_templates: Notification[] = DEFAULT_TEMPLATES;
	is_request_running: boolean = false;
	notifications: Notification[] = [];
	sms_type: NotificationType = NotificationType.SMS;
	is_production: boolean;
	current_notif_loading: Notification;

	constructor(
		private notif_service: NotificationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;

		if (
			this.station.settings
				.is_booking_vehicle_changed_custom_template_enabled &&
			!this.default_templates
				.map(dt => dt.name)
				.includes(NotificationName.BOOKING_VEHICLE_CHANGED)
		) {
			this.default_templates.splice(2, 0, {
				name: NotificationName.BOOKING_VEHICLE_CHANGED,
				type: NotificationType.SMS,
			});
			this.default_templates.push({
				name: NotificationName.BOOKING_VEHICLE_CHANGED,
				type: NotificationType.EMAIL,
			});
		}

		if (
			this.station.settings.is_booking_complete_notif_enabled &&
			this.station.settings.is_booking_complete_custom_template_enabled &&
			!this.default_templates
				.map(dt => dt.name)
				.includes(NotificationName.BOOKING_COMPLETE)
		) {
			this.default_templates.splice(3, 0, {
				name: NotificationName.BOOKING_COMPLETE,
				type: NotificationType.SMS,
			});
			this.default_templates.push({
				name: NotificationName.BOOKING_COMPLETE,
				type: NotificationType.EMAIL,
			});
		}

		this.listNotifications();
	}

	onReset(): void {
		this.listNotifications();
	}

	onAddTemplateClick(): void {
		this.dialog_service
			.openDialog(StationAddNotifDialogComponent, {
				station: this.station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.listNotifications();
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onNotificationInput(event: any, notification: Notification): void {
		let file: File = event.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			notification.content = reader.result.toString();
			this.updateNotification(notification);
		};
	}

	onNotifDeleteClick(notification: Notification): void {
		this.deleteNotification(notification);
	}

	onNotifDetailsClick(notification: Notification): void {
		this.dialog_service
			.openDialog(StationNotifDetailsDialogComponent, {
				notification: notification,
			})
			.afterClosed()
			.subscribe({
				next: dialog_result => {
					if (dialog_result && dialog_result.notif) {
						this.updateNotification(dialog_result.notif);
					}
				},
			});
	}

	onSMSDefaultsClick(): void {
		this.dialog_service.openDialog(StationNotifSmsTemplatesDialogComponent, {
			station: this.station,
		});
	}

	startSpinner(): void {
		this.is_request_running = true;
	}

	listNotifications(): void {
		this.is_request_running = true;
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;
		this.notif_service.listNotifications(station.id).subscribe({
			next: response => {
				this.notifications = response.data as Notification[];
				this.setTemplateItems();
				this.is_request_running = false;
			},
			error: error => {
				this.notifications = [];
				this.setTemplateItems();
				this.is_request_running = false;
			},
		});
	}

	setTemplateItems(): void {
		this.template_items = [];
		this.default_templates.forEach(default_template => {
			const custom_template: Notification | undefined =
				this.getCustomTemplateByName(
					default_template.name,
					default_template.type,
					this.station?.settings?.default_lang,
				);

			this.template_items?.push({
				name: default_template.name ? default_template.name : '',
				type: default_template.type
					? default_template.type
					: NotificationType.BOTH,
				default: default_template,
				custom: custom_template,
			});
		});
	}

	updateNotification(notification: Notification): void {
		this.current_notif_loading = notification;
		this.notif_service
			.updateNotification(this.station.id, notification)
			.subscribe(
				response => {
					this.current_notif_loading = null;
					this.listNotifications();
				},
				error => {
					this.current_notif_loading = null;
					if (
						error.response.data.error &&
						error.response.data.error.description
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					}
				},
			);
	}

	deleteNotification(notification: Notification) {
		this.current_notif_loading = notification;
		this.notif_service
			.removeNotification(this.station.id, notification)
			.subscribe({
				next: response => {
					this.current_notif_loading = null;
					this.listNotifications();
				},
				error: error => {
					this.current_notif_loading = null;
					if (
						error.response.data.error &&
						error.response.data.error.description
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					}
				},
			});
	}

	isCurrentNotifLoading(notification: Notification): boolean {
		return (
			this.current_notif_loading &&
			this.current_notif_loading.id == notification.id
		);
	}

	getCustomTemplateByName(
		name?: string,
		type?: NotificationType,
		lang?: string,
	): Notification | undefined {
		return this.notifications?.filter(ct => {
			return ct.name == name && ct.type == type;
		})[0];
	}
}
