import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import {
	ERROR_DESCRIPTION_500,
	HOURS_OF_THE_DAY,
	LOCAL_STATION_KEY,
	MINUTES_OF_THE_HOUR,
	PROD_STAGE,
	TIMEZONES,
	UNKNOWN_FLAG,
} from 'src/app/shared/constants';
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';
import { map, Observable, startWith } from 'rxjs';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';
import { IntervalErrorTypes } from 'src/app/booking/booking-time-builder-dialog/booking-time-builder-dialog.component';
import {
	BOOKING_PAST_ERROR_MESSAGE,
	BOOKING_MISMATCH_ERROR_MESSAGE,
	BOOKING_DURATION_ERROR_MESSAGE,
	GENERAL_ERROR_MESSAGE,
} from 'src/app/shared/translations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Vehicle } from 'src/app/core/vehicle.service';
import { Station } from 'src/app/core/station.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { KeysService } from 'src/app/core/keys.service';

@Component({
	selector: 'app-vehicle-direct-link-dialog',
	templateUrl: './vehicle-direct-link-dialog.component.html',
	styleUrls: ['./vehicle-direct-link-dialog.component.scss'],
})
export class VehicleDirectLinkDialogComponent implements OnInit {
	vehicle?: Vehicle;
	hours: string[] = HOURS_OF_THE_DAY;
	minutes: string[] = MINUTES_OF_THE_HOUR;
	timezones: string[] = TIMEZONES;
	direct_link_time_form_group: FormGroup =
		this.form_service.direct_link_time_form_group;

	country_prefixes: CountryPrefix[];
	filtered_options: Observable<CountryPrefix[]>;
	current_country_prefix: CountryPrefix;
	unknown_flag_src: string = UNKNOWN_FLAG;

	is_send_running: boolean = false;

	constructor(
		private dialog_ref: MatDialogRef<VehicleDirectLinkDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private form_service: FormService,
		private countries: CountriesService,
		private translate: TranslateService,
		private snackbar: MatSnackBar,
		private local_manager: LocalManagerService,
		private keys_service: KeysService,
	) {
		this.vehicle = data.vehicle;
	}

	ngOnInit(): void {
		this.direct_link_time_form_group.reset();

		const timezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone + ' (local time)';
		this.timezones = TIMEZONES;
		this.timezones = [timezone, ...this.timezones];
		this.direct_link_time_form_group
			.get('timezone')
			.setValue(this.timezones[0]);

		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.direct_link_time_form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		if (this.isFormValid()) {
			let full_phone;
			if (
				this.direct_link_time_form_group.get('phone_prefix')?.value &&
				this.direct_link_time_form_group.get('phone')?.value
			) {
				let phone_prefix =
					this.direct_link_time_form_group.get('phone_prefix')?.value;
				const phone_value =
					this.direct_link_time_form_group.get('phone')?.value;

				if (!phone_prefix.includes('+')) {
					phone_prefix = '+' + phone_prefix;
				}

				full_phone = `${phone_prefix} ${phone_value}`;
			}

			const start_date = new Date(
				this.direct_link_time_form_group.get('start_date').value,
			);

			const end_date = new Date(
				this.direct_link_time_form_group.get('end_date').value,
			);

			start_date.setHours(
				this.direct_link_time_form_group.get('start_hour').value,
				this.direct_link_time_form_group.get('start_minute').value,
				0,
				0,
			);
			end_date.setHours(
				this.direct_link_time_form_group.get('end_hour').value,
				this.direct_link_time_form_group.get('end_minute').value,
				0,
				0,
			);

			let type: string | undefined;
			if (this.direct_link_time_form_group.get('type')?.value == 1) {
				type = 'unlock';
			} else if (this.direct_link_time_form_group.get('type')?.value == 2) {
				type = 'lock';
			}

			const timezone: string =
				this.direct_link_time_form_group.get('timezone').value == 'GMT'
					? undefined
					: this.direct_link_time_form_group
							.get('timezone')
							.value.toString()
							.replace(' (local time)', '');

			const interval_error: IntervalErrorTypes = this.checkInterval(
				start_date,
				end_date,
				timezone,
			);

			if (interval_error == IntervalErrorTypes.NO_ERROR) {
				const station: Station =
					this.local_manager.getLocalObject(LOCAL_STATION_KEY);

				this.sendDirectLink(
					this.vehicle.id,
					station.id,
					start_date.getTime(),
					end_date.getTime(),
					full_phone,
					type,
				);
			} else {
				switch (interval_error) {
					case IntervalErrorTypes.PAST_ERROR:
						this.openSnackbar(BOOKING_PAST_ERROR_MESSAGE);
						break;
					case IntervalErrorTypes.MISMATCH_ERROR:
						this.openSnackbar(BOOKING_MISMATCH_ERROR_MESSAGE);
						break;
					case IntervalErrorTypes.DURATION_ERROR:
						this.openSnackbar(BOOKING_DURATION_ERROR_MESSAGE);
						break;
					default:
						this.dialog_ref.close();
				}
			}
		}
	}

	onCountryPrefixSelect(): void {
		const code: string =
			this.direct_link_time_form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.direct_link_time_form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	sendDirectLink(
		vehicle_id: string,
		station_id: string,
		nbf: number,
		exp: number,
		full_phone?: string,
		type?: string,
	) {
		this.is_send_running = true;
		this.keys_service
			.createDirectKey(station_id, vehicle_id, nbf, exp, full_phone, type)
			.subscribe({
				next: response => {
					this.dialog_ref.close({
						success: true,
						short_link: response.data.short_url,
						is_sent: full_phone != undefined,
					});
					this.is_send_running = false;
				},
				error: error => {
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_ref.close({
							error_message: error.response.data.error.description,
						});
					} else {
						this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
					}
					this.is_send_running = false;
				},
			});
	}

	checkInterval(
		startDate: Date,
		endDate: Date,
		timezone: string,
	): IntervalErrorTypes {
		const is_production: boolean = environment.STAGE == PROD_STAGE;

		/*if (zonedTimeToUtc(startDate, timezone).getTime() < new Date().getTime()) {
			return IntervalErrorTypes.PAST_ERROR;
		}*/

		if (
			zonedTimeToUtc(startDate, timezone).getTime() >
			zonedTimeToUtc(endDate, timezone).getTime()
		) {
			return IntervalErrorTypes.MISMATCH_ERROR;
		}

		/*if (
			is_production &&
			zonedTimeToUtc(endDate, timezone).getTime() -
				zonedTimeToUtc(startDate, timezone).getTime() <
				60 * 60 * 2 * 1000
		) {
			return IntervalErrorTypes.DURATION_ERROR;
		}*/

		return IntervalErrorTypes.NO_ERROR;
	}

	openSnackbar(message: string) {
		this.translate.get(message).subscribe((res: string) => {
			this.snackbar.open(res, '', {
				duration: 2000,
				panelClass: ['lk-error-snackbar'],
			});
		});
	}

	getTimezoneOffset(timezone: string) {
		return (
			'GMT' +
			(getTimezoneOffset(timezone.replace(' (local time)', '')) / 3600000 >= 0
				? '+'
				: '') +
			getTimezoneOffset(timezone.replace(' (local time)', '')) / 3600000
		);
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.direct_link_time_form_group);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': !this.isFormValid() || this.is_send_running,
			'lk-main-button': this.isFormValid() && !this.is_send_running,
		};
	}

	isLinkTypeSelected(): boolean {
		return this.direct_link_time_form_group.get('type')?.value;
	}
}
