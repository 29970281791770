import { Component, OnInit } from '@angular/core';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	ADAPTER_FAILED_ERROR_MESSAGE,
	ADAPTER_GENERIC_ERROR_MESSAGE,
	ADAPTER_OFFLINE_ERROR_MESSAGE,
	GENERAL_ERROR_MESSAGE,
	TOOLBAR_VEHICLES_NAV_ITEM_NAME,
	VEHICLE_DELETE_MESSAGE,
} from '../shared/translations';
import { BehaviorSubject } from 'rxjs';
import { Station } from '../core/station.service';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	LOCAL_STATION_KEY,
	ADAPTER_FAILED_ERROR_CODE,
	ERROR_DESCRIPTION_500,
	VEHICLE_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
	VEHICLE_DETAILS_NAVBAR_COMMANDS_ITEM_NAME,
	VEHICLE_DETAILS_NAVBAR_GALLERY_ITEM_NAME,
	VEHICLE_DETAILS_NAVBAR_MEASURES_ITEM_NAME,
	ADAPTER_OFFLINE_ERROR_CODE,
	VEHICLE_DETAILS_NAVBAR_LINKS_ITEM_NAME,
} from '../shared/constants';
import { LocalManagerService } from '../core/local-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
	Vehicle,
	VehicleService,
	VehicleStatus,
} from '../core/vehicle.service';
import { DialogService } from '../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { VehicleDeleteDialogComponent } from '../vehicle/vehicle-delete-dialog/vehicle-delete-dialog.component';
import { SharedInteractionErrorDialogComponent } from '../shared/shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import { STATION_PATH, VEHICLES_PATH } from '../core/navigation.service';
import {
	UnlockCommand,
	UnlockCommandsService,
	UnlockCommandStatus,
} from '../core/unlock-commands.service';
import {
	LockCommand,
	LockCommandsService,
	LockCommandStatus,
} from '../core/lock-commands.service';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';

@Component({
	selector: 'app-vehicle-details',
	templateUrl: './vehicle-details.component.html',
	styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent implements OnInit {
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	vehicle_subject: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(
		null,
	);
	current_item: string = TOOLBAR_VEHICLES_NAV_ITEM_NAME;
	current_navbar_item: string = VEHICLE_DETAILS_NAVBAR_GENERAL_ITEM_NAME;
	is_vehicle_loading: boolean = false;
	vehicle_id: string;
	vehicle: Vehicle;
	navbar_items: string[] = [
		VEHICLE_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
		VEHICLE_DETAILS_NAVBAR_COMMANDS_ITEM_NAME,
		VEHICLE_DETAILS_NAVBAR_MEASURES_ITEM_NAME,
		VEHICLE_DETAILS_NAVBAR_LINKS_ITEM_NAME,
		VEHICLE_DETAILS_NAVBAR_GALLERY_ITEM_NAME,
	];

	constructor(
		private local_manager: LocalManagerService,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private router: Router,
		private vehicle_service: VehicleService,
		private route: ActivatedRoute,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.vehicle_id = this.route.snapshot.paramMap.get('vehicle_id');
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit() {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service.current_read_index = 0;
		this.lock_commands_service.current_read_index = 0;
		if (station) {
			this.station_update_trigger.next(true);
			this.readVehicle();
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuildClick(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChangeClick(station: Station): void {
		this.router.navigate([VEHICLES_PATH]);
	}

	onRefreshClick() {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station) {
			this.readVehicle();
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	onVehicleDeleteClick() {
		this.dialog_service
			.openDialog(VehicleDeleteDialogComponent, { vehicle: this.vehicle })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.confirm) {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);
					this.is_vehicle_loading = true;
					this.vehicle_service
						.deleteVehicle(station.id, this.vehicle_id)
						.subscribe({
							next: response => {
								this.router.navigate([VEHICLES_PATH]);
								this.is_vehicle_loading = false;
								this.dialog_service.openInfoDialog(VEHICLE_DELETE_MESSAGE);
							},
							error: error => {
								this.is_vehicle_loading = false;
								this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
							},
						});
				}
			});
	}

	onVehicleUpdateClick(vehicle: Vehicle) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_vehicle_loading = true;
		this.vehicle_service.updateVehicle(station.id, vehicle).subscribe({
			next: response => {
				this.onRefreshClick();
			},
			error: error => {
				this.is_vehicle_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	onBackClick() {
		this.router.navigate([VEHICLES_PATH]);
	}

	onStartVehicleClick(vehicle: Vehicle): void {
		this.is_vehicle_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service
			.createUnlockCommand(station.id, vehicle.id)
			.subscribe({
				next: response => {
					const command: UnlockCommand = response.data as UnlockCommand;
					this.readUnlockCommand(command.id, vehicle.id);
				},
				error: error => {
					this.is_vehicle_loading = false;
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.code !=
							ADAPTER_CRED_NOT_FOUND_ERROR_CODE &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description ||
						error.response.data.error.description == ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					} else if (
						error.response.data.error.code == ADAPTER_CRED_NOT_FOUND_ERROR_CODE
					) {
						this.dialog_service.openInfoDialog(
							ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
						);
					}
				},
			});
	}

	onNavbarItemChange(item_name: string): void {
		this.current_navbar_item = item_name;
	}

	readUnlockCommand(command_id: string, vehicle_id: string) {
		this.is_vehicle_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service
			.readUnlockCommand(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					const command: UnlockCommand = response.data as UnlockCommand;
					if (
						command.status == UnlockCommandStatus.PENDING &&
						this.unlock_commands_service.current_read_index <=
							this.unlock_commands_service.max_read_retry
					) {
						this.unlock_commands_service.current_read_index++;
						setTimeout(() => {
							this.readUnlockCommand(command_id, vehicle_id);
						}, 5000);
					} else if (command.status == UnlockCommandStatus.SUCCEEDED) {
						this.unlock_commands_service.current_read_index = 0;
						this.is_vehicle_loading = false;
						this.onRefreshClick();
					} else if (command.status == UnlockCommandStatus.FAILED) {
						this.unlock_commands_service.current_read_index = 0;
						this.is_vehicle_loading = false;
						const command_error = command.error.code
							? command.error.code
							: command.error;
						switch (command_error) {
							case ADAPTER_FAILED_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_FAILED_ERROR_MESSAGE,
									},
								);
								break;
							case ADAPTER_OFFLINE_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_OFFLINE_ERROR_MESSAGE,
									},
								);
								break;
							default:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_GENERIC_ERROR_MESSAGE,
									},
								);
								break;
						}
					}
				},
				error: error => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicle_loading = false;
				},
			});
	}

	onStopVehicleClick(vehicle: Vehicle) {
		this.is_vehicle_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service
			.createLockCommand(station.id, vehicle.id)
			.subscribe({
				next: response => {
					const command: LockCommand = response.data as LockCommand;
					this.readLockCommand(command.id, vehicle.id);
				},
				error: error => {
					this.is_vehicle_loading = false;
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.code !=
							ADAPTER_CRED_NOT_FOUND_ERROR_CODE &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description ||
						error.response.data.error.description == ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					} else if (
						error.response.data.error.code == ADAPTER_CRED_NOT_FOUND_ERROR_CODE
					) {
						this.dialog_service.openInfoDialog(
							ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
						);
					}
				},
			});
	}

	readLockCommand(command_id: string, vehicle_id: string) {
		this.is_vehicle_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service
			.readLockCommand(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					const command: LockCommand = response.data as LockCommand;
					if (
						command.status == LockCommandStatus.PENDING &&
						this.lock_commands_service.current_read_index <=
							this.lock_commands_service.max_read_retry
					) {
						this.lock_commands_service.current_read_index++;
						setTimeout(() => {
							this.readLockCommand(command_id, vehicle_id);
						}, 5000);
					} else if (command.status == LockCommandStatus.SUCCEEDED) {
						this.lock_commands_service.current_read_index = 0;
						this.is_vehicle_loading = false;
						this.onRefreshClick();
					} else if (command.status == LockCommandStatus.FAILED) {
						this.lock_commands_service.current_read_index = 0;
						this.is_vehicle_loading = false;
						const command_error = command.error.code
							? command.error.code
							: command.error;
						switch (command_error) {
							case ADAPTER_FAILED_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_FAILED_ERROR_MESSAGE,
									},
								);
								break;
							case ADAPTER_OFFLINE_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_OFFLINE_ERROR_MESSAGE,
									},
								);
								break;
							default:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_GENERIC_ERROR_MESSAGE,
									},
								);
								break;
						}
					}
				},
				error: error => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicle_loading = false;
				},
			});
	}

	readVehicle() {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_vehicle_loading = true;
		this.vehicle_service.readVehicle(station.id, this.vehicle_id).subscribe({
			next: response => {
				this.is_vehicle_loading = false;
				this.vehicle = response.data as Vehicle;
				this.vehicle_subject.next(this.vehicle);
			},
			error: error => {
				this.is_vehicle_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle && vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle && vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}
}
