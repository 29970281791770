import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	BOOKING_DELETE_MESSAGE,
	GENERAL_ERROR_MESSAGE,
	NO_RESULT_FOUND_MESSAGE,
	TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
	VEHICLE_ALREADY_ASSIGNED_ERROR_MESSAGE,
} from '../shared/translations';
import { BehaviorSubject } from 'rxjs';
import { LocalManagerService } from '../core/local-manager.service';
import { Router } from '@angular/router';
import { Settings, Station } from '../core/station.service';
import {
	BOOKING_NOT_FOUND_ERROR_CODE,
	BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
	ERROR_DESCRIPTION_500,
	LOCAL_STATION_KEY,
	STATION_NOT_FOUND_ERROR_CODE,
	VEHICLE_ALREADY_ASSIGNED_ERROR_CODE,
} from '../shared/constants';
import { Booking, BookingService } from '../core/booking.service';
import { saveAs } from 'file-saver';
import { DialogService } from '../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { BookingUserBuilderDialogComponent } from './booking-user-builder-dialog/booking-user-builder-dialog.component';
import { BookingTimeBuilderDialogComponent } from './booking-time-builder-dialog/booking-time-builder-dialog.component';
import { BookingPhoneBuilderDialogComponent } from './booking-phone-builder-dialog/booking-phone-builder-dialog.component';
import { Vehicle } from '../core/vehicle.service';
import { BookingFiltersDialogComponent } from './booking-filters-dialog/booking-filters-dialog.component';
import { BookingSettingsUnlockDialogComponent } from './booking-settings-unlock-dialog/booking-settings-unlock-dialog.component';
import { BookingSettingsLockDialogComponent } from './booking-settings-lock-dialog/booking-settings-lock-dialog.component';
import { BookingSettingsGeneralDialogComponent } from './booking-settings-general-dialog/booking-settings-general-dialog.component';
import { AuthenticationService } from '../core/authentication.service';
import { BOOKING_DETAILS_PATH, STATION_PATH } from '../core/navigation.service';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';

/**
 * API
 * create booking
 * list bookings
 * delete booking
 * assign booking
 */
@Component({
	selector: 'app-booking',
	templateUrl: './booking.component.html',
	styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnInit {
	@ViewChild('loadMoreLayout') load_more_layout: ElementRef;
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	refresh_summary_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	booking_update_trigger: BehaviorSubject<Booking[]> = new BehaviorSubject<
		Booking[]
	>(null);
	current_item: string = TOOLBAR_BOOKINGS_NAV_ITEM_NAME;
	is_bookings_loading: boolean = false;
	is_export_running: boolean = false;
	is_last_page: boolean = false;
	is_page_loading: boolean = false;
	current_page: number = 1;
	filters_config: BookingFiltersConfig;
	bookings: Booking[];
	stored_bookings: Booking[];

	constructor(
		private local_manager: LocalManagerService,
		private router: Router,
		private auth_service: AuthenticationService,
		private booking_service: BookingService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit() {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station) {
			this.station_update_trigger.next(true);
			this.prepareBookingsLoading();
			this.listBookings(station.id);
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	@HostListener('document:scroll', ['$event'])
	public onViewportScroll(): void {
		const window_height = window.innerHeight;
		const bounding_rect =
			this.load_more_layout?.nativeElement?.getBoundingClientRect();
		if (
			bounding_rect &&
			bounding_rect.top >= 0 &&
			bounding_rect.bottom <= window_height &&
			!this.is_page_loading
		) {
			this.onLoadPageClick();
		}
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuildClick(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChangeClick(station: Station): void {
		this.onRefreshClick();
	}

	onRefreshClick() {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station) {
			this.prepareBookingsLoading();
			this.listBookings(station.id);
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	onAddBookingClick() {
		this.dialog_service
			.openDialog(BookingPhoneBuilderDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.user) {
					// User is already added
					let booking: Booking = new Booking();
					booking.user_id = dialogResult.user.id;
					this.dialog_service
						.openDialog(BookingTimeBuilderDialogComponent, { booking: booking })
						.afterClosed()
						.subscribe(dialogResult => {
							if (dialogResult && dialogResult.booking) {
								this.openSettingsDialog(dialogResult.booking);
							}
						});
				} else if (dialogResult && dialogResult.phone) {
					// User must be added
					this.dialog_service
						.openDialog(BookingUserBuilderDialogComponent, {
							phone_prefix: dialogResult.phone_prefix,
							phone: dialogResult.phone,
						})
						.afterClosed()
						.subscribe(dialogResult => {
							if (dialogResult && dialogResult.user) {
								// Now the user is added
								let booking: Booking = new Booking();
								booking.user_id = dialogResult.user.id;
								this.dialog_service
									.openDialog(BookingTimeBuilderDialogComponent, {
										booking: booking,
									})
									.afterClosed()
									.subscribe(dialogResult => {
										if (dialogResult && dialogResult.booking) {
											this.openSettingsDialog(dialogResult.booking);
										}
									});
							}
						});
				}
			});
	}

	onBookingsDownloadClick() {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_export_running = true;
		this.booking_service.export(station.id).subscribe({
			next: response => {
				const blob = new Blob([response.data], { type: 'text/csv' });
				saveAs(blob, 'bookings(' + new Date().toLocaleString() + ').csv');
				this.is_export_running = false;
			},
			error: error => {
				this.is_export_running = false;
			},
		});
	}

	onBookingClick(booking: Booking) {
		this.router.navigate([BOOKING_DETAILS_PATH + booking.id]);
	}

	onAssignVehicleClick(assignResult: { booking: Booking; vehicle: Vehicle }) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_bookings_loading = true;
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.booking_service
					.assignVehicle(
						station.id,
						assignResult.booking.id,
						assignResult.vehicle.id,
					)
					.subscribe({
						next: response => {
							this.onRefreshClick();
						},
						error: error => {
							this.manageAssignVehicleErrors(error);
						},
					});
			},
			error: error => {
				this.auth_service.signOut();
			},
		});
	}

	onDeleteBookingClick(booking: Booking) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_bookings_loading = true;
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.booking_service.deleteBooking(station.id, booking.id).subscribe({
					next: response => {
						this.onRefreshClick();
						this.dialog_service.openInfoDialog(BOOKING_DELETE_MESSAGE);
					},
					error: error => {
						if (
							error.response.data.error &&
							error.response.data.error.description &&
							error.response.data.error.description != ERROR_DESCRIPTION_500
						) {
							this.dialog_service.openInfoDialog(
								error.response.data.error.description,
							);
						} else {
							this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
						}
					},
				});
			},
			error: error => {
				this.auth_service.signOut();
			},
		});
	}

	onFiltersClick() {
		this.dialog_service
			.openDialog(BookingFiltersDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.filters) {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);
					this.stored_bookings = this.bookings;
					this.prepareBookingsLoading();
					this.filters_config.plate = dialogResult.filters.plate;
					this.filters_config.email = dialogResult.filters.email;
					this.filters_config.start_date = dialogResult.filters.start_date;
					this.filters_config.end_date = dialogResult.filters.end_date;
					this.listBookings(
						station.id,
						this.filters_config.plate,
						this.filters_config.email,
						this.filters_config.start_date,
						this.filters_config.end_date,
					);
				}
			});
	}

	onSearchChange(email: string) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.stored_bookings = this.bookings;
		this.prepareBookingsLoading();
		this.listBookings(station.id, null, email);
	}

	onLoadPageClick() {
		this.current_page++;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_page_loading = true;
		this.listBookings(
			station.id,
			this.filters_config.plate,
			this.filters_config.email,
			this.filters_config.start_date,
			this.filters_config.end_date,
		);
	}

	onSortStartDateClick(sortOption: string) {
		this.is_bookings_loading = true;
		this.is_last_page = false;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.bookings = [];
		this.current_page = 1;
		this.listBookings(
			station.id,
			this.filters_config.plate,
			this.filters_config.email,
			this.filters_config.start_date,
			this.filters_config.end_date,
		);
	}

	listBookings(
		stationId: string,
		plate?: string,
		email?: string,
		start_date?: number,
		end_date?: number,
	) {
		let isFiltered: boolean = false;
		if (plate || email || start_date || end_date) {
			isFiltered = true;
		}
		const sort = localStorage.getItem(BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY);
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.booking_service
					.listBookings(
						stationId,
						this.current_page,
						plate,
						email,
						start_date,
						end_date,
						sort,
					)
					.subscribe({
						next: response => {
							this.is_bookings_loading = false;
							this.is_page_loading = false;
							this.bookings = this.bookings
								? this.bookings.concat(response.data.items)
								: response.data.items;
							this.is_last_page =
								response.data.total_items <= this.bookings.length;
							this.booking_update_trigger.next(this.bookings);
							this.refresh_summary_trigger.next(true);

							setTimeout(() => {
								const window_height = window.innerHeight;
								const bounding_rect =
									this.load_more_layout?.nativeElement?.getBoundingClientRect();
								if (
									bounding_rect &&
									bounding_rect.top >= 0 &&
									bounding_rect.bottom <= window_height &&
									!this.is_page_loading
								) {
									this.onLoadPageClick();
								}
							}, 200);
						},
						error: error => {
							this.manageListBookingsErrors(error, isFiltered);
						},
					});
			},
			error: error => {
				this.auth_service.signOut();
			},
		});
	}

	createBooking(booking: Booking) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_bookings_loading = true;
		this.booking_service.createBooking(station.id, booking).subscribe({
			next: response => {
				this.prepareBookingsLoading();
				this.listBookings(station.id);
			},
			error: error => {
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	openSettingsDialog(booking: Booking) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		booking.settings = new Settings();
		booking.settings.default_lang = station.settings.default_lang;
		booking.settings.default_notification_type =
			station.settings.default_notification_type;
		booking.settings.pictures_policy = station.settings.pictures_policy;
		this.dialog_service
			.openDialog(BookingSettingsGeneralDialogComponent, { station: station })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.settings) {
					booking.settings.default_notification_type =
						dialogResult.settings.notifType;
					booking.settings.default_lang = dialogResult.settings.lang;
					booking.settings.pictures_policy = dialogResult.settings.policy;
					this.dialog_service
						.openDialog(BookingSettingsUnlockDialogComponent, {
							station: station,
						})
						.afterClosed()
						.subscribe(dialogResult => {
							if (dialogResult && dialogResult.unlock_settings) {
								booking.settings.unlock_settings = dialogResult.unlock_settings;
								booking.settings.unlock_settings.is_link_invalid = false;
								this.dialog_service
									.openDialog(BookingSettingsLockDialogComponent, {
										station: station,
									})
									.afterClosed()
									.subscribe(dialogResult => {
										if (dialogResult && dialogResult.lock_settings) {
											booking.settings.lock_settings =
												dialogResult.lock_settings;
											booking.settings.lock_settings.is_link_invalid = false;
											this.createBooking(booking);
										}
									});
							}
						});
				}
			});
	}

	manageListBookingsErrors(error: any, isFiltered: boolean): void {
		if (
			error.response.data.error &&
			error.response.data.error.description &&
			error.response.data.error.description != ERROR_DESCRIPTION_500 &&
			error.response.data.error.code != BOOKING_NOT_FOUND_ERROR_CODE &&
			error.response.data.error.code != STATION_NOT_FOUND_ERROR_CODE
		) {
			this.dialog_service.openInfoDialog(error.response.data.error.description);
		} else if (
			!error.response.data.error ||
			!error.response.data.error.description ||
			error.response.data.error.description == ERROR_DESCRIPTION_500
		) {
			this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
		} else if (error.response.data.error.code == BOOKING_NOT_FOUND_ERROR_CODE) {
			this.is_bookings_loading = false;
			this.is_page_loading = false;
			this.is_last_page = true;
			this.filters_config = new BookingFiltersConfig();
			if (isFiltered) {
				this.dialog_service.openInfoDialog(NO_RESULT_FOUND_MESSAGE);
				this.bookings = this.stored_bookings;
			} else {
				this.bookings = [];
			}
		} else if (error.response.data.error.code == STATION_NOT_FOUND_ERROR_CODE) {
			this.router.navigate([STATION_PATH]);
		}
	}

	manageAssignVehicleErrors(error: any) {
		if (
			error.response.data.error &&
			error.response.data.error.description &&
			error.response.data.error.description != ERROR_DESCRIPTION_500 &&
			error.response.data.error.code != VEHICLE_ALREADY_ASSIGNED_ERROR_CODE
		) {
			this.dialog_service.openInfoDialog(error.response.data.error.description);
		} else if (
			!error.response.data.error ||
			!error.response.data.error.description ||
			error.response.data.error.description == ERROR_DESCRIPTION_500
		) {
			this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
		} else if (
			error.response.data.error.code == VEHICLE_ALREADY_ASSIGNED_ERROR_CODE
		) {
			this.dialog_service.openInfoDialog(
				VEHICLE_ALREADY_ASSIGNED_ERROR_MESSAGE,
			);
		}
		this.onRefreshClick();
	}

	prepareCsvDownload(): any[] {
		let csvData: any[] = [];
		this.bookings.map(booking => {
			csvData.push({
				id: booking.id,
				user: booking.user.email,
				start_date: `${new Date(
					booking.start_date,
				).toLocaleDateString()} ${new Date(
					booking.start_date,
				).toLocaleTimeString()}`,
				end_date: `${new Date(
					booking.end_date,
				).toLocaleDateString()} ${new Date(
					booking.end_date,
				).toLocaleTimeString()}`,
				vehicle_plate: booking.vehicle ? booking.vehicle.plate : '',
				vehicle_brand: booking.vehicle ? booking.vehicle.brand : '',
				vehicle_model: booking.vehicle ? booking.vehicle.model : '',
			});
		});
		return csvData;
	}

	prepareBookingsLoading(stopReset?: boolean) {
		this.filters_config = new BookingFiltersConfig();
		this.is_bookings_loading = true;
		this.current_page = 1;
		this.bookings = stopReset ? this.bookings : [];
		this.is_last_page = false;
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_page_loading,
			'lk-main-button': !this.is_page_loading,
		};
	}
}

export class BookingFiltersConfig {
	plate: string;
	email: string;
	start_date: number;
	end_date: number;
}
