import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
	TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
	TOOLBAR_STATION_NAV_ITEM_NAME,
	TOOLBAR_STATION_SETTINGS_NAV_ITEM_NAME,
	TOOLBAR_VEHICLES_NAV_ITEM_NAME,
} from '../translations';
import { Station } from '../../core/station.service';
import {
	DEFAULT_STATION_LOGO,
	DEV_STAGE,
	LOCAL_STATION_KEY,
	PROD_STAGE,
} from '../constants';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../core/authentication.service';
import { LocalManagerService } from '../../core/local-manager.service';
import { DialogService } from 'src/app/core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedMenuComponent } from '../shared-menu/shared-menu.component';
import {
	BOOKINGS_PATH,
	STATION_SETTINGS_PATH,
} from 'src/app/core/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-shared-toolbar',
	templateUrl: './shared-toolbar.component.html',
	styleUrls: ['./shared-toolbar.component.scss'],
})
export class SharedToolbarComponent implements OnInit {
	@Input() stationUpdateTrigger: BehaviorSubject<boolean>;
	@Input() currentNavItem: string;
	logo: string;
	username: string = '';
	nav_items: NavItem[] = NAV_ITEMS;
	is_production: boolean;

	constructor(
		private router: Router,
		private dialogService: DialogService,
		private dialog: MatDialog,
		private authService: AuthenticationService,
		private localManager: LocalManagerService,
	) {
		this.dialogService.dialog = this.dialog;
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;

		if (this.stationUpdateTrigger) {
			this.stationUpdateTrigger.subscribe(updateLocal => {
				if (updateLocal != null && updateLocal) {
					let station: Station = this.localManager.getLocalObject(
						LOCAL_STATION_KEY,
					) as Station;
					if (station) {
						this.logo = station.logo_url
							? station.logo_url
							: DEFAULT_STATION_LOGO;
					} else {
						this.logo = DEFAULT_STATION_LOGO;
					}
				} else if (updateLocal != null && !updateLocal) {
					this.logo = DEFAULT_STATION_LOGO;
				}
			});
		}

		this.authService.getAuthenticatedUser().subscribe(response => {
			this.username = response.attributes.email;
		});
	}

	onMenuClick() {
		this.dialogService.openDialog(SharedMenuComponent);
	}

	onCloseDialog() {
		//this.dialog.closeAll();
	}

	onNavItemClick(item: NavItem) {
		this.router.navigate([item.path]);
	}

	onLogoClick() {
		this.router.navigate(['station']);
	}

	onSignoutClick() {
		this.authService.signOut();
	}

	getNavItemClass(item: NavItem) {
		return {
			'lk-accent-color': item.name == this.currentNavItem,
			'': item.name != this.currentNavItem,
		};
	}
}

export class NavItem {
	name: string;
	path: string;
	icon: string;
}

export const NAV_ITEMS: NavItem[] = [
	/*{
    name: TOOLBAR_STATION_NAV_ITEM_NAME,
    path: 'station',
    icon: 'lk-stations'
  },*/
	{
		name: TOOLBAR_VEHICLES_NAV_ITEM_NAME,
		path: 'vehicles',
		icon: 'lk-vehicles',
	},
	{
		name: TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
		path: BOOKINGS_PATH,
		icon: 'lk-bookings',
	},
	{
		name: TOOLBAR_STATION_SETTINGS_NAV_ITEM_NAME,
		path: STATION_SETTINGS_PATH,
		icon: 'lk-settings',
	},
];
