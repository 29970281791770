import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { DirectKey, KeysService } from 'src/app/core/keys.service';
import { Vehicle } from 'src/app/core/vehicle.service';
import {
	DIRECT_LINK_NOT_FOUND_ERROR_CODE,
	ERROR_DESCRIPTION_500,
} from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';
import { VehicleDirectLinkConfirmDialogComponent } from 'src/app/vehicle/vehicle-direct-link-confirm-dialog/vehicle-direct-link-confirm-dialog.component';
import { VehicleDirectLinkDialogComponent } from 'src/app/vehicle/vehicle-direct-link-dialog/vehicle-direct-link-dialog.component';

@Component({
	selector: 'app-vehicle-details-links',
	templateUrl: './vehicle-details-links.component.html',
	styleUrls: ['./vehicle-details-links.component.scss'],
})
export class VehicleDetailsLinksComponent implements OnInit {
	@Input() vehicle: Vehicle;
	links?: DirectKey[];
	is_links_request_running: boolean = false;
	is_disable_request_running: boolean = false;
	is_enable_request_running: boolean = false;
	enabling_link_id?: string;
	disabling_link_id?: string;

	constructor(
		private keys_service: KeysService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		if (this.vehicle?.station_id && this.vehicle?.id) {
			this.listDirectKeys(this.vehicle?.station_id, this.vehicle?.id);
		}
	}

	onRefresh(): void {
		if (this.vehicle?.station_id && this.vehicle?.id) {
			this.listDirectKeys(this.vehicle?.station_id, this.vehicle?.id);
		}
	}

	onDisableClick(link: DirectKey): void {
		this.disableDirectKey(link.id, link.station_id, link.vehicle_id);
	}

	onEnableClick(link: DirectKey): void {
		this.enableDirectKey(link.id, link.station_id, link.vehicle_id);
	}

	onDirectLinkClick(vehicle: Vehicle): void {
		this.dialog_service
			.openDialog(VehicleDirectLinkDialogComponent, {
				vehicle,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result?.success) {
					this.dialog_service.openDialog(
						VehicleDirectLinkConfirmDialogComponent,
						{
							link: dialog_result.short_link,
							is_link_sent: dialog_result.is_sent,
						},
					);
					this.onRefresh();
				} else if (!dialog_result?.success && dialog_result?.error_message) {
					this.dialog_service.openInfoDialog(
						dialog_result?.error_message || '',
					);
				}
			});
	}

	listDirectKeys(station_id: string, vehicle_id: string): void {
		this.is_links_request_running = true;
		this.keys_service.listDirectKeys(station_id, vehicle_id).subscribe({
			next: response => {
				this.links = response.data;
				this.is_links_request_running = false;
			},
			error: error => {
				if (
					error.response.data.error &&
					error.response.data.error.code == DIRECT_LINK_NOT_FOUND_ERROR_CODE
				) {
					this.links = [];
				} else if (
					error.response.data.error &&
					error.response.data.error.description &&
					error.response.data.error.description != ERROR_DESCRIPTION_500
				) {
					this.dialog_service.openInfoDialog(
						error.response.data.error.description,
					);
				} else {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
				}
				this.is_links_request_running = false;
			},
		});
	}

	disableDirectKey(
		direct_link_id: string,
		station_id: string,
		vehicle_id: string,
	): void {
		this.is_disable_request_running = true;
		this.disabling_link_id = direct_link_id;
		this.keys_service
			.disableDirectKey(direct_link_id, station_id, vehicle_id)
			.subscribe({
				next: response => {
					const link = response.data;
					this.listDirectKeys(link.station_id, link.vehicle_id);
					this.is_disable_request_running = false;
				},
				error: error => {
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					}
					this.is_disable_request_running = false;
				},
			});
	}

	enableDirectKey(
		direct_link_id: string,
		station_id: string,
		vehicle_id: string,
	): void {
		this.is_enable_request_running = true;
		this.enabling_link_id = direct_link_id;
		this.keys_service
			.enableDirectKey(direct_link_id, station_id, vehicle_id)
			.subscribe({
				next: response => {
					const link = response.data;
					this.listDirectKeys(link.station_id, link.vehicle_id);
					this.is_enable_request_running = false;
				},
				error: error => {
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					}
					this.is_enable_request_running = false;
				},
			});
	}

	isLinkEditable(link: DirectKey): boolean {
		const now = Date.now();
		if (now <= link?.exp && !link.consumed_at) {
			return true;
		}
		return false;
	}

	getDisabledClass(link: DirectKey) {
		return {
			'lk-vehicle-details-link-disabled-button': !this.isLinkEditable(link),
			'': this.isLinkEditable(link),
		};
	}

	getLinkType(link: DirectKey): string {
		if (link.url.includes('type=unlock')) {
			return 'unlock';
		}

		if (link.url.includes('type=lock')) {
			return 'lock';
		}

		return '';
	}
}
