import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';

export const LOCK_COMMANDS_API_NAME: string = 'lock_commands';
export const LOCK_COMMANDS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/lock-host-commands/';

@Injectable({
	providedIn: 'root',
})
export class LockCommandsService {
	max_read_retry: number = 10;
	current_read_index: number = 0;

	createLockCommand(stationId: string, vehicleId: string) {
		const apiName: string = LOCK_COMMANDS_API_NAME;
		const path: string = 'stations/' + stationId + '/vehicles/' + vehicleId;
		const init = { response: true };
		return from(API.post(apiName, path, init));
	}

	readLockCommand(commandId: string, stationId: string, vehicleId: string) {
		const apiName: string = LOCK_COMMANDS_API_NAME;
		const path: string =
			commandId + '/stations/' + stationId + '/vehicles/' + vehicleId;
		const init = { response: true };
		return from(API.get(apiName, path, init));
	}
}

export class LockCommand {
	id: string;
	object: string;
	source: string;
	station_id: string;
	vehicle_id: string;
	booking_id?: string;
	status: LockCommandStatus;
	error: {
		code: string;
		description: string;
	};
}

export enum LockCommandStatus {
	PENDING = 'pending',
	SUCCEEDED = 'succeeded',
	FAILED = 'failed',
}
