import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STATION_PATH } from '../core/navigation.service';
import { Router } from '@angular/router';
import {
	Station,
	StationQueryParams,
	StationService,
} from '../core/station.service';
import {
	ERROR_DESCRIPTION_500,
	LOCAL_STATION_KEY,
	STATION_NOT_FOUND_ERROR_CODE,
} from '../shared/constants';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';
import { LocalManagerService } from '../core/local-manager.service';
import { DialogService } from '../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { GENERAL_ERROR_MESSAGE } from '../shared/translations';

@Component({
	selector: 'app-hidden-stations',
	templateUrl: './hidden-stations.component.html',
	styleUrls: ['./hidden-stations.component.scss'],
})
export class HiddenStationsComponent implements OnInit {
	current_station?: Station;
	current_page: number = 1;
	total_items: number;
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	is_request_running: boolean = false;
	is_load_more_running: boolean = false;
	stations?: Station[];

	constructor(
		private router: Router,
		private local_manager: LocalManagerService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private station_service: StationService,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.current_page = 1;
		this.listStations(false, true);
	}

	onRefresh(): void {
		this.current_page = 1;
		this.listStations(false, true);
	}

	onStationChangeClick(station: Station): void {
		this.current_page = 1;
		this.listStations(false, true);
	}

	onStationListChangeClick(station: Station): void {
		localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(station));
		this.toolbar_refresh.next(true);
		this.is_request_running = true;
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuildClick(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onLoadMoreClick(): void {
		this.current_page++;
		this.is_load_more_running = true;
		this.listStations(true);
	}

	onSearchChange(value: string, is_alias_search?: boolean): void {
		this.current_page = 1;
		if (value != '' && value != ' ') {
			if (is_alias_search) {
				this.listStations(false, true, { alias: value });
			} else {
				this.listStations(false, true, { name: value });
			}
		} else {
			this.listStations(false, true);
		}
	}

	listStations(
		is_paginated?: boolean,
		force_reset?: boolean,
		query_params?: StationQueryParams,
	): void {
		this.is_request_running = !is_paginated;
		this.station_service
			.listStations(this.current_page, undefined, query_params)
			.subscribe({
				next: response => {
					this.is_request_running = false;
					this.is_load_more_running = false;

					if (this.stations && this.stations.length && !force_reset) {
						this.stations = this.stations.concat(response.data.items);
					} else {
						this.stations = response.data.items;
					}

					this.total_items = response.data.total_items;
					this.current_station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);
				},
				error: error => {
					this.is_request_running = false;
					this.is_load_more_running = false;
					this.stations = [];

					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.description != ERROR_DESCRIPTION_500 &&
						error.response.data.error.code != STATION_NOT_FOUND_ERROR_CODE
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description ||
						error.response.data.error.description == ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					}
				},
			});
	}

	isCurrentStation(station: Station): boolean {
		return this.current_station && this.current_station.id == station.id;
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_load_more_running,
			'lk-main-button': !this.is_load_more_running,
		};
	}
}
