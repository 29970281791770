import { Injectable } from '@angular/core';
import {
  STATION_BUILDER_LOCAL_NOTIF_KEY,
  STATION_BUILDER_LOCAL_STATION_KEY,
  STATION_BUILDER_LOCAL_TEAM_KEY, STATION_LOCAL_NOTIF_KEY,
  LOCAL_STATION_KEY,
  STATION_LOCAL_TEAM_KEY
} from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class LocalManagerService {
  setLocalObject(object: any, key: string) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  resetLocalObject(key: string) {
    localStorage.removeItem(key);
  }

  getLocalObject(key: string) {
    return localStorage.getItem(key)? JSON.parse(localStorage.getItem(key)) : null;
  }

  copyLocalStation(destination: LocalStationType) {
    switch (destination) {
      case LocalStationType.STATION:
        localStorage.setItem(LOCAL_STATION_KEY, localStorage.getItem(STATION_BUILDER_LOCAL_STATION_KEY));
        localStorage.setItem(STATION_LOCAL_TEAM_KEY, localStorage.getItem(STATION_BUILDER_LOCAL_TEAM_KEY));
        localStorage.setItem(STATION_LOCAL_NOTIF_KEY, localStorage.getItem(STATION_BUILDER_LOCAL_NOTIF_KEY));
        break;
      case LocalStationType.BUILDER:
        localStorage.setItem(STATION_BUILDER_LOCAL_STATION_KEY, localStorage.getItem(LOCAL_STATION_KEY));
        localStorage.setItem(STATION_BUILDER_LOCAL_TEAM_KEY, localStorage.getItem(STATION_LOCAL_TEAM_KEY));
        localStorage.setItem(STATION_BUILDER_LOCAL_NOTIF_KEY, localStorage.getItem(STATION_LOCAL_NOTIF_KEY));
        break;
    }
  }

  eraseLocalStation(destination: LocalStationType) {
    switch (destination) {
      case LocalStationType.STATION:
        localStorage.removeItem(LOCAL_STATION_KEY);
        localStorage.removeItem(STATION_LOCAL_TEAM_KEY);
        localStorage.removeItem(STATION_LOCAL_NOTIF_KEY);
        break;
      case LocalStationType.BUILDER:
        localStorage.removeItem(STATION_BUILDER_LOCAL_STATION_KEY);
        localStorage.removeItem(STATION_BUILDER_LOCAL_TEAM_KEY);
        localStorage.removeItem(STATION_BUILDER_LOCAL_NOTIF_KEY);
        break;
    }
  }
}

export enum LocalStationType {
  BUILDER = 'builder',
  STATION = 'station'
}
