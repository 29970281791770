import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
	MatDialogRef,
	MAT_DIALOG_DATA,
	MatDialog,
} from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Station } from 'src/app/core/station.service';
import {
	NotificationName,
	Notification,
	NotificationType,
	NotificationService,
} from 'src/app/core/notification.service';
import {
	DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_EN,
	DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_ES,
	DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_FR,
	DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_IT,
	DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_EN,
	DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_ES,
	DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_FR,
	DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_IT,
	DEFAULT_END_SMS_TEMPLATE_EN,
	DEFAULT_END_SMS_TEMPLATE_ES,
	DEFAULT_END_SMS_TEMPLATE_FR,
	DEFAULT_END_SMS_TEMPLATE_IT,
	DEFAULT_START_SMS_TEMPLATE_EN,
	DEFAULT_START_SMS_TEMPLATE_ES,
	DEFAULT_START_SMS_TEMPLATE_FR,
	DEFAULT_START_SMS_TEMPLATE_IT,
	DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_EN,
	DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_ES,
	DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_FR,
	DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_IT,
	ERROR_DESCRIPTION_500,
	SUPPORTED_LANGUAGES,
} from 'src/app/shared/constants';
import {
	BOOKING_COMPLETE_EMAIL_LABEL,
	BOOKING_COMPLETE_SMS_LABEL,
	BOOKING_VEHICLE_EDITED_EMAIL_LABEL,
	BOOKING_VEHICLE_EDITED_SMS_LABEL,
	END_RENTAL_EMAIL_LABEL,
	END_RENTAL_SMS_LABEL,
	GENERAL_ERROR_MESSAGE,
	NOTIF_ITEM_INFO_MESSAGE,
	START_RENTAL_EMAIL_LABEL,
	START_RENTAL_SMS_LABEL,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/core/dialog.service';

@Component({
	selector: 'app-stations-notif-templates-item-dialog',
	templateUrl: './stations-notif-templates-item-dialog.component.html',
	styleUrls: ['./stations-notif-templates-item-dialog.component.scss'],
})
export class StationsNotifTemplatesItemDialogComponent implements OnInit {
	station: Station;
	template: Notification;
	is_update_enabled: boolean = false;

	template_item_form_group: FormGroup =
		this.form_service.template_item_form_group;
	is_template_loading?: boolean = true;
	is_update_running?: boolean = false;

	template_content?: string;
	params_list: string[] = [
		'unlock link',
		'lock link',
		'direct link',
		'vehicle plate',
		'vehicle brand',
		'vehicle model',
		'user first name',
		'user phone',
		'booking start date',
		'booking end date',
		'station email',
		'station phone',
	];

	constructor(
		private form_service: FormService,
		private notif_service: NotificationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private dialog_ref: MatDialogRef<StationsNotifTemplatesItemDialogComponent>,
		private http: HttpClient,
		@Inject(MAT_DIALOG_DATA) data: any,
	) {
		this.station = data.station;
		this.template = data.template;
		this.is_update_enabled = data.is_update_enabled;
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.readTemplateContent();

		if (this.template.name == NotificationName.BOOKING_START) {
			const index_of_lock_link = this.params_list.indexOf('lock link');
			if (index_of_lock_link != -1)
				this.params_list.splice(index_of_lock_link, 1);
		}

		if (this.template.name == NotificationName.BOOKING_END) {
			const index_of_unlock_link = this.params_list.indexOf('unlock link');
			if (index_of_unlock_link != -1)
				this.params_list.splice(index_of_unlock_link, 1);
		}
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.isValidForm()) {
			const content = this.template_item_form_group.get('text')?.value;
			const encoded_content = `data:text/plain;base64,${window.btoa(
				unescape(encodeURIComponent(content)),
			)}`;
			this.template.content = encoded_content;
			this.updateTemplate(this.template);
		}
	}

	onParameterSelection(event: any): void {
		const current_value: string =
			this.template_item_form_group.get('text').value;
		const new_value: string = `${
			current_value ? current_value + ' ' : ''
		}{{{${event.value.toString().replaceAll(' ', '_')}}}}`;
		this.template_item_form_group.get('text').setValue(new_value);
		this.template_item_form_group.get('current_parameter').reset();
	}

	onInfoClick(): void {
		this.dialog_service.openInfoDialog(NOTIF_ITEM_INFO_MESSAGE);
	}

	updateTemplate(notification: Notification): void {
		this.is_update_running = true;
		this.notif_service
			.updateNotification(this.station.id, notification)
			.subscribe(
				response => {
					this.is_update_running = false;
					this.dialog_ref.close({ success: true });
				},
				error => {
					this.is_update_running = false;
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_ref.close({
							error_message: error.response.data.error.description,
						});
					} else {
						this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
					}
				},
			);
	}

	readTemplateContent(): void {
		const template_url: string = this.template?.content_url
			? this.template.content_url
			: this.getDefaultTemplateUrl();
		this.is_template_loading = true;
		this.http.get(template_url, { responseType: 'text' }).subscribe(data => {
			this.template_content = data;
			this.template_item_form_group
				.get('text')
				?.setValue(this.template_content);
			this.is_template_loading = false;
		});
	}

	isValidForm(): boolean {
		return this.form_service.validateForm(this.template_item_form_group);
	}

	getTemplateLabel(): string {
		return this.getNotificationLabel(
			this.template?.name as NotificationName,
			this.template?.type as NotificationType,
		);
	}

	getNotificationLabel(name: NotificationName, type: NotificationType): string {
		switch (name) {
			case NotificationName.BOOKING_START:
				return type == NotificationType.SMS
					? START_RENTAL_SMS_LABEL
					: START_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_END:
				return type == NotificationType.SMS
					? END_RENTAL_SMS_LABEL
					: END_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_VEHICLE_CHANGED:
				return type == NotificationType.SMS
					? BOOKING_VEHICLE_EDITED_SMS_LABEL
					: BOOKING_VEHICLE_EDITED_EMAIL_LABEL;
			case NotificationName.BOOKING_COMPLETE:
				return type == NotificationType.SMS
					? BOOKING_COMPLETE_SMS_LABEL
					: BOOKING_COMPLETE_EMAIL_LABEL;
			case NotificationName.QUICK_LINK:
				return 'Quick link SMS';
			default:
				return '';
		}
	}

	getStationFlag(): string {
		switch (this.station?.settings?.default_lang) {
			case SUPPORTED_LANGUAGES[0]:
				return '../../../assets/imgs/lk-flags/IT.svg';
			case SUPPORTED_LANGUAGES[1]:
				return '../../../assets/imgs/lk-flags/GB.svg';
			case SUPPORTED_LANGUAGES[2]:
				return '../../../assets/imgs/lk-flags/ES.svg';
			case SUPPORTED_LANGUAGES[3]:
				return '../../../assets/imgs/lk-flags/FR.svg';
			default:
				return '';
		}
	}

	getTemplateContent(): string | undefined {
		return this.template_content;
	}

	getDefaultTemplateUrl(): string {
		const base_path: string = `https://linkey-core-bucket-${environment.STAGE}.s3.amazonaws.com`;
		switch (this.station.settings?.default_lang) {
			case SUPPORTED_LANGUAGES[0]:
				if (this.template?.name == NotificationName.BOOKING_START) {
					return base_path + DEFAULT_START_SMS_TEMPLATE_IT;
				}

				if (this.template?.name == NotificationName.BOOKING_END) {
					return base_path + DEFAULT_END_SMS_TEMPLATE_IT;
				}

				if (this.template?.name == NotificationName.BOOKING_VEHICLE_CHANGED) {
					return base_path + DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_IT;
				}

				if (this.template?.name == NotificationName.BOOKING_COMPLETE) {
					return base_path + DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_IT;
				}

				if (this.template?.name == NotificationName.QUICK_LINK) {
					return base_path + DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_IT;
				}
			case SUPPORTED_LANGUAGES[1]:
				if (this.template?.name == NotificationName.BOOKING_START) {
					return base_path + DEFAULT_START_SMS_TEMPLATE_EN;
				}

				if (this.template?.name == NotificationName.BOOKING_END) {
					return base_path + DEFAULT_END_SMS_TEMPLATE_EN;
				}

				if (this.template?.name == NotificationName.BOOKING_VEHICLE_CHANGED) {
					return base_path + DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_EN;
				}

				if (this.template?.name == NotificationName.BOOKING_COMPLETE) {
					return base_path + DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_EN;
				}

				if (this.template?.name == NotificationName.QUICK_LINK) {
					return base_path + DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_EN;
				}
			case SUPPORTED_LANGUAGES[2]:
				if (this.template?.name == NotificationName.BOOKING_START) {
					return base_path + DEFAULT_START_SMS_TEMPLATE_ES;
				}

				if (this.template?.name == NotificationName.BOOKING_END) {
					return base_path + DEFAULT_END_SMS_TEMPLATE_ES;
				}

				if (this.template?.name == NotificationName.BOOKING_VEHICLE_CHANGED) {
					return base_path + DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_ES;
				}

				if (this.template?.name == NotificationName.BOOKING_COMPLETE) {
					return base_path + DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_ES;
				}

				if (this.template?.name == NotificationName.QUICK_LINK) {
					return base_path + DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_ES;
				}
			case SUPPORTED_LANGUAGES[3]:
				if (this.template?.name == NotificationName.BOOKING_START) {
					return base_path + DEFAULT_START_SMS_TEMPLATE_FR;
				}

				if (this.template?.name == NotificationName.BOOKING_END) {
					return base_path + DEFAULT_END_SMS_TEMPLATE_FR;
				}

				if (this.template?.name == NotificationName.BOOKING_VEHICLE_CHANGED) {
					return base_path + DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_FR;
				}

				if (this.template?.name == NotificationName.BOOKING_COMPLETE) {
					return base_path + DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_FR;
				}

				if (this.template?.name == NotificationName.QUICK_LINK) {
					return base_path + DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_FR;
				}
			default:
				return '';
		}
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_update_running || !this.isValidForm(),
			'lk-main-button': !this.is_update_running && this.isValidForm(),
		};
	}
}
