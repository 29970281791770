import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	ADAPTER_FAILED_ERROR_MESSAGE,
	ADAPTER_GENERIC_ERROR_MESSAGE,
	ADAPTER_OFFLINE_ERROR_MESSAGE,
	GENERAL_ERROR_MESSAGE,
	NO_RESULT_FOUND_MESSAGE,
	TOOLBAR_VEHICLES_NAV_ITEM_NAME,
	VEHICLE_DELETE_MESSAGE,
	VEHICLE_MANUAL_REQUEST_SENT_MESSAGE,
} from '../shared/translations';
import { BehaviorSubject } from 'rxjs';
import { Station, StationService } from '../core/station.service';
import { LocalManagerService } from '../core/local-manager.service';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	LOCAL_STATION_KEY,
	VEHICLE_NOT_FOUND_ERROR_CODE,
	ADAPTER_FAILED_ERROR_CODE,
	SUPPORTED_LANGUAGES,
	KM_LABEL,
	KM_TO_MILES_COEFF,
	MILES_LABEL,
	ERROR_DESCRIPTION_500,
	ADAPTER_OFFLINE_ERROR_CODE,
} from '../shared/constants';
import { Router } from '@angular/router';
import {
	Vehicle,
	VehicleOdometerUms,
	VehicleService,
} from '../core/vehicle.service';
import { DialogService } from '../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { VehicleBuilderDialogComponent } from './vehicle-builder-dialog/vehicle-builder-dialog.component';
import { VehicleFiltersDialogComponent } from './vehicle-filters-dialog/vehicle-filters-dialog.component';
import { saveAs } from 'file-saver';
import { SharedInteractionErrorDialogComponent } from '../shared/shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {
	STATION_PATH,
	VEHICLES_DETAILS_PATH,
	VEHICLES_MAP_PATH,
} from '../core/navigation.service';
import {
	UnlockCommand,
	UnlockCommandsService,
	UnlockCommandStatus,
} from '../core/unlock-commands.service';
import {
	LockCommand,
	LockCommandsService,
	LockCommandStatus,
} from '../core/lock-commands.service';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';
import { VehicleManualContactsDialogComponent } from './vehicle-manual-contacts-dialog/vehicle-manual-contacts-dialog.component';
import { VehicleManualInfoDialogComponent } from './vehicle-manual-info-dialog/vehicle-manual-info-dialog.component';

@Component({
	selector: 'app-vehicle',
	templateUrl: './vehicle.component.html',
	styleUrls: ['./vehicle.component.scss'],
})
export class VehicleComponent implements OnInit {
	@ViewChild('loadMoreLayout') load_more_layout: ElementRef;
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	refresh_summary_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	vehicle_update_trigger: BehaviorSubject<Vehicle[]> = new BehaviorSubject<
		Vehicle[]
	>(null);
	current_item: string = TOOLBAR_VEHICLES_NAV_ITEM_NAME;
	is_vehicles_loading: boolean = true;
	is_page_loading: boolean = false;
	is_last_page: boolean = false;
	current_page: number = 1;
	filters_config: VehicleFiltersConfig;
	vehicles: Vehicle[];
	stored_vehicles: Vehicle[];
	is_export_running: boolean = false;
	station?: Station;

	is_vehicle_map_visible: boolean = false;

	constructor(
		private local_manager: LocalManagerService,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private router: Router,
		private translate: TranslateService,
		private vehicle_service: VehicleService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private station_service: StationService,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service.current_read_index = 0;
		this.lock_commands_service.current_read_index = 0;
		if (station) {
			this.prepareVehiclesLoading();
			this.listVehicles(station.id);
			this.readStation(station.id);
		} else {
			//this.router.navigate([STATION_PATH]);
		}
	}

	@HostListener('document:scroll', ['$event'])
	public onViewportScroll(): void {
		const window_height = window.innerHeight;
		const bounding_rect =
			this.load_more_layout?.nativeElement?.getBoundingClientRect();
		if (
			bounding_rect &&
			bounding_rect.top >= 0 &&
			bounding_rect.bottom <= window_height &&
			!this.is_page_loading
		) {
			this.onLoadPageClick();
		}
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuildClick(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChangeClick(station: Station): void {
		this.onRefreshClick();
	}

	onRefreshClick(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station) {
			this.prepareVehiclesLoading();
			this.listVehicles(station.id);
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	onAddVehicleClick(): void {
		this.dialog_service
			.openDialog(VehicleBuilderDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.vehicle) {
					this.createVehicle(dialogResult.vehicle);
				}
			});
	}

	onMapClick(): void {
		this.router.navigate([VEHICLES_MAP_PATH]);
	}

	onVehicleDeleteClick(vehicle: Vehicle): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_vehicles_loading = true;
		this.vehicle_service.deleteVehicle(station.id, vehicle.id).subscribe({
			next: response => {
				this.onRefreshClick();
				this.dialog_service.openInfoDialog(VEHICLE_DELETE_MESSAGE);
			},
			error: error => {
				this.is_vehicles_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	onVehiclesDownloadClick(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_export_running = true;
		this.vehicle_service.export(station.id).subscribe({
			next: response => {
				const blob = new Blob([response.data], { type: 'text/csv' });
				saveAs(blob, 'vehicles(' + new Date().toLocaleString() + ').csv');
				this.is_export_running = false;
			},
			error: error => {
				this.is_export_running = false;
			},
		});
	}

	onVehicleClick(vehicle: Vehicle): void {
		this.router.navigate([VEHICLES_DETAILS_PATH + vehicle.id]);
	}

	onFiltersClick(): void {
		this.dialog_service
			.openDialog(VehicleFiltersDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.filters) {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);
					this.stored_vehicles = this.vehicles;
					this.prepareVehiclesLoading();
					this.filters_config.plate = dialogResult.filters.plate;
					this.filters_config.category = dialogResult.filters.category;
					this.filters_config.brand = dialogResult.filters.brand;
					this.filters_config.status = dialogResult.filters.status;
					this.listVehicles(
						station.id,
						this.filters_config.plate,
						this.filters_config.category,
						this.filters_config.brand,
						this.filters_config.status,
					);
				}
			});
	}

	onSearchChange(plate: string): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.prepareVehiclesLoading();
		this.listVehicles(station.id, plate);
	}

	onLoadPageClick(): void {
		this.current_page++;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_page_loading = true;
		this.listVehicles(
			station.id,
			this.filters_config.plate,
			this.filters_config.category,
			this.filters_config.brand,
			this.filters_config.status,
		);
	}

	onStartVehicleClick(vehicle: Vehicle): void {
		this.is_vehicles_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service
			.createUnlockCommand(station.id, vehicle.id)
			.subscribe({
				next: response => {
					const command: UnlockCommand = response.data as UnlockCommand;
					this.readUnlockCommand(command.id, vehicle.id);
				},
				error: error => {
					this.is_vehicles_loading = false;
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.code !=
							ADAPTER_CRED_NOT_FOUND_ERROR_CODE &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description ||
						error.response.data.error.description == ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					} else if (
						error.response.data.error.code == ADAPTER_CRED_NOT_FOUND_ERROR_CODE
					) {
						this.dialog_service.openInfoDialog(
							ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
						);
					}
				},
			});
	}

	readUnlockCommand(command_id: string, vehicle_id: string): void {
		this.is_vehicles_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service
			.readUnlockCommand(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					const command: UnlockCommand = response.data as UnlockCommand;
					if (
						command.status == UnlockCommandStatus.PENDING &&
						this.unlock_commands_service.current_read_index <=
							this.unlock_commands_service.max_read_retry
					) {
						this.unlock_commands_service.current_read_index++;
						setTimeout(() => {
							this.readUnlockCommand(command_id, vehicle_id);
						}, 5000);
					} else if (command.status == UnlockCommandStatus.SUCCEEDED) {
						this.unlock_commands_service.current_read_index = 0;
						this.is_vehicles_loading = false;
						this.prepareVehiclesLoading();
						this.listVehicles(
							station.id,
							this.filters_config.plate,
							this.filters_config.category,
							this.filters_config.brand,
							this.filters_config.status,
						);
					} else if (command.status == UnlockCommandStatus.FAILED) {
						this.unlock_commands_service.current_read_index = 0;
						this.is_vehicles_loading = false;
						const command_error = command.error.code
							? command.error.code
							: command.error;
						switch (command_error) {
							case ADAPTER_FAILED_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_FAILED_ERROR_MESSAGE,
									},
								);
								break;
							case ADAPTER_OFFLINE_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_OFFLINE_ERROR_MESSAGE,
									},
								);
								break;
							default:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_GENERIC_ERROR_MESSAGE,
									},
								);
								break;
						}
					}
				},
				error: error => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicles_loading = false;
				},
			});
	}

	onStopVehicleClick(vehicle: Vehicle): void {
		this.is_vehicles_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service
			.createLockCommand(station.id, vehicle.id)
			.subscribe({
				next: response => {
					const command: LockCommand = response.data as LockCommand;
					this.readLockCommand(command.id, vehicle.id);
				},
				error: error => {
					this.is_vehicles_loading = false;
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.code !=
							ADAPTER_CRED_NOT_FOUND_ERROR_CODE &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description ||
						error.response.data.error.description == ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					} else if (
						error.response.data.error.code == ADAPTER_CRED_NOT_FOUND_ERROR_CODE
					) {
						this.dialog_service.openInfoDialog(
							ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
						);
					}
				},
			});
	}

	onManualRequestClick(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(VehicleManualContactsDialogComponent, {
				station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					const station_name: string = dialog_result.station_name;
					const station_email: string = dialog_result.station_email;
					this.dialog_service
						.openDialog(VehicleManualInfoDialogComponent, {
							station,
							station_name,
							station_email,
						})
						.afterClosed()
						.subscribe(dialog_result => {
							if (dialog_result && dialog_result.success) {
								this.dialog_service.openInfoDialog(
									VEHICLE_MANUAL_REQUEST_SENT_MESSAGE,
								);
							}

							if (dialog_result && !dialog_result.success) {
								this.dialog_service.openInfoDialog(dialog_result.error_message);
							}
						});
				}
			});
	}

	readLockCommand(command_id: string, vehicle_id: string): void {
		this.is_vehicles_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service
			.readLockCommand(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					const command: LockCommand = response.data as LockCommand;
					if (
						command.status == LockCommandStatus.PENDING &&
						this.lock_commands_service.current_read_index <=
							this.lock_commands_service.max_read_retry
					) {
						this.lock_commands_service.current_read_index++;
						setTimeout(() => {
							this.readLockCommand(command_id, vehicle_id);
						}, 5000);
					} else if (command.status == LockCommandStatus.SUCCEEDED) {
						this.lock_commands_service.current_read_index = 0;
						this.is_vehicles_loading = false;
						this.prepareVehiclesLoading();
						this.listVehicles(
							station.id,
							this.filters_config.plate,
							this.filters_config.category,
							this.filters_config.brand,
							this.filters_config.status,
						);
					} else if (command.status == LockCommandStatus.FAILED) {
						this.lock_commands_service.current_read_index = 0;
						this.is_vehicles_loading = false;
						const command_error = command.error.code
							? command.error.code
							: command.error;
						switch (command_error) {
							case ADAPTER_FAILED_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_FAILED_ERROR_MESSAGE,
									},
								);
								break;
							case ADAPTER_OFFLINE_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_OFFLINE_ERROR_MESSAGE,
									},
								);
								break;
							default:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_GENERIC_ERROR_MESSAGE,
									},
								);
								break;
						}
					}
				},
				error: error => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicles_loading = false;
				},
			});
	}

	listVehicles(
		stationId: string,
		plate?: string,
		category?: string,
		brand?: string,
		status?: string,
	): void {
		let isFiltered: boolean = false;
		if (plate || category || brand || status) {
			isFiltered = true;
		}
		this.vehicle_service
			.listVehicles(
				stationId,
				this.current_page,
				plate,
				category,
				brand,
				status,
			)
			.subscribe({
				next: response => {
					this.is_vehicles_loading = false;
					this.is_page_loading = false;
					this.vehicles = this.vehicles
						? this.vehicles.concat(response.data.items)
						: response.data.items;
					this.is_last_page = response.data.total_items <= this.vehicles.length;
					this.vehicle_update_trigger.next(this.vehicles);
					this.refresh_summary_trigger.next(true);

					setTimeout(() => {
						const window_height = window.innerHeight;
						const bounding_rect =
							this.load_more_layout?.nativeElement?.getBoundingClientRect();
						if (
							bounding_rect &&
							bounding_rect.top >= 0 &&
							bounding_rect.bottom <= window_height &&
							!this.is_page_loading
						) {
							this.onLoadPageClick();
						}
					}, 200);
				},
				error: error => {
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.code != VEHICLE_NOT_FOUND_ERROR_CODE &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description ||
						error.response.data.error.description == ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					} else if (
						error.response.data.error.code == VEHICLE_NOT_FOUND_ERROR_CODE
					) {
						this.is_vehicles_loading = false;
						this.is_page_loading = false;
						this.is_last_page = true;
						this.filters_config = new VehicleFiltersConfig();
						if (isFiltered) {
							this.dialog_service.openInfoDialog(NO_RESULT_FOUND_MESSAGE);
							this.vehicles = this.stored_vehicles;
						} else {
							this.vehicles = [];
						}
					}
				},
			});
	}

	createVehicle(vehicle: Vehicle): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_vehicles_loading = true;
		this.vehicle_service.createVehicle(station.id, vehicle).subscribe({
			next: response => {
				this.onRefreshClick();
			},
			error: error => {
				this.is_vehicles_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	readStation(station_id: string): void {
		this.station_service.readStation(station_id).subscribe({
			next: response => {
				const station = response.data;
				this.is_vehicle_map_visible =
					(station.vehicle_collection_length || 0) <= 100;
			},
			error: () => {
				this.is_vehicle_map_visible = true;
			},
		});
	}

	prepareCsvDownload(): any[] {
		let csvData: any[] = [];
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		const odometer_um = station.settings?.odometer_um || VehicleOdometerUms.mi;
		this.vehicles.map(vehicle => {
			csvData.push({
				plate: vehicle.plate,
				category: vehicle.category,
				brand: vehicle.brand,
				model: vehicle.model,
				year: vehicle.year,
				status: vehicle.status,
				odometer: this.getOdometerValue(vehicle, odometer_um) + odometer_um,
				autonomy:
					this.getAutonomyValue(vehicle) +
					this.getAutonomyMeasurementUnit(vehicle),
				battery:
					this.getBatteryValue(vehicle) +
					this.getBatteryMeasurementUnit(vehicle),
			});
		});
		return csvData;
	}

	prepareVehiclesLoading(stopReset?: boolean): void {
		this.filters_config = new VehicleFiltersConfig();
		this.is_vehicles_loading = true;
		this.current_page = 1;
		this.vehicles = stopReset ? this.vehicles : [];
		this.is_last_page = false;
	}

	getOdometerValue(vehicle: Vehicle, odometer_um: string): number {
		if (odometer_um && odometer_um == VehicleOdometerUms.km) {
			return vehicle.stats.distance_covered.value / 1000;
		}

		if (odometer_um && odometer_um == VehicleOdometerUms.mi) {
			return (vehicle.stats.distance_covered.value / 1000) * KM_TO_MILES_COEFF;
		}

		return vehicle.stats.distance_covered.value / 1000;
	}

	getAutonomyValue(vehicle: Vehicle): string {
		if (
			vehicle.stats &&
			vehicle.stats.autonomy_percentage &&
			vehicle.stats.autonomy_percentage.value
		) {
			return vehicle.stats.autonomy_percentage.value.toFixed(2).toString();
		}
		return 'NA';
	}

	getAutonomyMeasurementUnit(vehicle: Vehicle): string {
		if (
			vehicle.stats &&
			vehicle.stats.autonomy_percentage &&
			vehicle.stats.autonomy_percentage.value
		) {
			return '%';
		}
		return '';
	}

	getBatteryValue(vehicle: Vehicle): string {
		if (
			vehicle.stats &&
			vehicle.stats.external_voltage &&
			vehicle.stats.external_voltage.value
		) {
			return vehicle.stats.external_voltage.value.toFixed(2).toString();
		}
		return 'NA';
	}

	getBatteryMeasurementUnit(vehicle: Vehicle): string {
		if (
			vehicle.stats &&
			vehicle.stats.external_voltage &&
			vehicle.stats.external_voltage.value
		) {
			return 'V';
		}
		return '';
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_page_loading,
			'lk-main-button': !this.is_page_loading,
		};
	}
}

export class VehicleFiltersConfig {
	plate: string;
	category: string;
	brand: string;
	status: string;
}
