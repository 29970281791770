import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PROD_STAGE, LOCAL_STATION_KEY } from '../../shared/constants';
import { LocalManagerService } from '../../core/local-manager.service';
import { Station } from '../../core/station.service';
import { Admin, AdminService } from '../../core/admin.service';
import { AuthenticationService } from '../../core/authentication.service';
import {
	Invitation,
	InvitationResponseType,
	InvitationService,
} from 'src/app/core/invitation.service';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { StationAddTeamDialogComponent } from '../station-add-team-dialog/station-add-team-dialog.component';

@Component({
	selector: 'app-station-team',
	templateUrl: './station-team.component.html',
	styleUrls: ['./station-team.component.scss'],
})
export class StationTeamComponent implements OnInit {
	@Input() station: Station;
	@Output() self_delete: EventEmitter<void> = new EventEmitter<void>();
	team: Admin[] = [];
	invitations: Invitation[] = [];
	is_team_loading: boolean = false;
	is_production: boolean;
	current_admin_email: string;

	constructor(
		private local_manager: LocalManagerService,
		private admin_service: AdminService,
		private invitation_service: InvitationService,
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;
		this.listAdmins();
	}

	onReset(): void {
		this.listAdmins();
	}

	onAddTeammateClick(): void {
		this.dialog_service
			.openDialog(StationAddTeamDialogComponent, {
				station_id: this.station.id,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.listAdmins();
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onDeleteMemberClick(admin: Admin): void {
		this.is_team_loading = true;
		const station: Station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.admin_service.removeAdminsFromStation(station.id, [admin.id]).subscribe({
			next: response => {
				this.is_team_loading = false;
				this.listAdmins();

				if (admin.email == this.current_admin_email) {
					this.self_delete.emit();
				}
			},
			error: error => {
				this.is_team_loading = false;
			},
		});
	}

	onDeleteInvitationClick(invitation: Invitation): void {
		this.is_team_loading = true;
		const station: Station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.invitation_service.deleteInvitation(station.id, invitation.id).subscribe({
			next: response => {
				this.is_team_loading = false;
				this.listAdmins();
			},
			error: error => {
				this.is_team_loading = false;
			},
		});
	}

	listAdmins(): void {
		const station: Station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_team_loading = true;
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.current_admin_email = response.attributes.email;
				this.admin_service.listAdminsOfStation(station.id).subscribe({
					next: response => {
						this.team = response.data.items;
						this.listInvitations();
					},
					error: error => {
						this.team = [];
						this.is_team_loading = false;
					},
				});
			},
			error: error => {
				this.is_team_loading = false;
				this.auth_service.signOut();
			},
		});
	}

	listInvitations(): void {
		const station: Station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_team_loading = true;
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				const current_admin_email: string = response.attributes.email;
				this.invitation_service.listInvitationsOfStation(station.id).subscribe({
					next: response => {
						this.invitations = response.data.filter((invitation: Invitation) => {
							return (
								invitation.response == InvitationResponseType.PENDING &&
								invitation.admin_email != current_admin_email
							);
						});
						this.is_team_loading = false;
					},
					error: error => {
						this.invitations = [];
						this.is_team_loading = false;
					},
				});
			},
			error: error => {
				this.is_team_loading = false;
				this.auth_service.signOut();
			},
		});
	}

	isOwner(admin: Admin): boolean {
		return admin.email == this.current_admin_email;
	}
}
