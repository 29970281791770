import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Booking } from 'src/app/core/booking.service';
import { Vehicle } from 'src/app/core/vehicle.service';
import {
	SUPPORTED_LANGUAGES,
	KM_LABEL,
	MILES_LABEL,
	KM_TO_MILES_COEFF,
} from 'src/app/shared/constants';

@Component({
	selector: 'app-booking-details-data',
	templateUrl: './booking-details-data.component.html',
	styleUrls: ['./booking-details-data.component.scss'],
})
export class BookingDetailsDataComponent implements OnInit {
	@Input() booking: Booking;

	constructor(private translate: TranslateService) {}

	ngOnInit(): void {}

	getOdometerValue(vehicle: Vehicle): number {
		switch (this.translate.getBrowserLang()) {
			case SUPPORTED_LANGUAGES[0]:
				return vehicle.stats.distance_covered.value / 1000;
			case SUPPORTED_LANGUAGES[1]:
				return (
					(vehicle.stats.distance_covered.value / 1000) * KM_TO_MILES_COEFF
				);
			default:
				return vehicle.stats.distance_covered.value / 1000;
		}
	}

	getOdometerMeasurementUnit(): string {
		switch (this.translate.getBrowserLang()) {
			case SUPPORTED_LANGUAGES[0]:
				return KM_LABEL;
			case SUPPORTED_LANGUAGES[1]:
				return MILES_LABEL;
			default:
				return KM_LABEL;
		}
	}
}
