<div
	id="lk-vehicle-details-commands-root-layout"
	class="lk-border-radius lk-shadow lk-padding lk-primary-background lk-hide-scrollbar lk-full-height">
	<div
		class="lk-centered-row lk-full-height"
		*ngIf="is_commands_request_running">
		<mat-spinner
			diameter="20"
			color="accent"></mat-spinner>
	</div>

	<div>
		<h3
			id="lk-vehicle-details-commands-title"
			*ngIf="!is_commands_request_running"
			class="lk-no-margin lk-subtitle-size lk-half-margin-bottom">
			{{ 'VEHICLE_DETAILS.COMMANDS_SECTION_LABEL' | translate }}
		</h3>

		<form
			*ngIf="!is_commands_request_running"
			class="lk-full-width lk-spaced-row lk-half-margin-bottom lk-wrapped-flex"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-vehicle-details-filter-field lk-half-margin-bottom">
				<mat-label> {{ 'BOOKING.START_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="created_from"
					[matDatepicker]="startPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-vehicle-details-filter-field lk-half-margin-bottom">
				<mat-label> {{ 'BOOKING.END_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="created_to"
					[matDatepicker]="endPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			</mat-form-field>

			<div class="lk-centered-row lk-wrapped-flex lk-spaced-row lk-full-width">
				<button
					(click)="onResetFiltersClick()"
					mat-button
					class="lk-secondary-button lk-vehicle-details-filter-button lk-half-margin-bottom">
					<span class="lk-no-margin lk-accent-color">
						{{ 'VEHICLE_DETAILS.COMMANDS_FILTERS_RESET_CTA' | translate }}
					</span>
				</button>

				<button
					(click)="onApplyFiltersClick()"
					mat-button
					class="lk-main-button lk-vehicle-details-filter-button lk-half-margin-bottom">
					<span class="lk-no-margin lk-primary-color">
						{{ 'VEHICLE_DETAILS.COMMANDS_FILTERS_CTA' | translate }}
					</span>
				</button>
			</div>
		</form>
	</div>

	<div
		style="
			position: relative;
			overflow-y: scroll;
			max-height: calc(100% - 180px);
		"
		class="lk-hide-scrollbar"
		*ngIf="!is_commands_request_running">
		<div
			id="lk-vehicle-details-commands-list"
			*ngFor="let command of commands"
			class="lk-vehicle-details-commands-list lk-full-width">
			<div class="lk-spaced-row">
				<div
					class="lk-row"
					style="align-items: flex-start; position: relative">
					<div
						class="lk-primary-medium-background"
						style="
							width: 4px;
							position: absolute;
							top: -12px;
							bottom: -12px;
							left: 4px;
						"></div>

					<div
						class="lk-primary-medium-background"
						style="
							width: 12px;
							height: 12px;
							border-radius: 50%;
							margin-top: 2px;
						"></div>

					<div>
						<p
							class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
							style="font-size: 12px; margin-left: 6px !important">
							{{ command.created_at | date: 'short':'':current_locale }}
						</p>

						<p
							*ngIf="!isCommandClickable(command)"
							[ngClass]="getCommandClass(command)"
							class="lk-no-margin lk-ellipsized-text"
							style="margin-left: 6px !important">
							{{ getCommandLabel(command) || '-' | translate }}
						</p>

						<a
							*ngIf="isCommandClickable(command)"
							[routerLink]="[getCommandBookingRoute(command)]"
							class="lk-no-margin lk-ellipsized-text lk-dark-link"
							style="margin-left: 6px !important">
							{{ getCommandLabel(command) || '-' | translate }}
						</a>
					</div>
				</div>
			</div>
			<hr
				class="lk-vehicle-details-commands-list-separator lk-list-separator" />
		</div>
	</div>

	<div
		*ngIf="!is_commands_request_running && commands && !commands.length"
		class="lk-centered-row">
		<p class="lk-primary-medium-color lk-centered-text">
			{{ 'VEHICLE_DETAILS.NO_COMMANDS_LABEL' | translate }}
		</p>
	</div>
</div>
