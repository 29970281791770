import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-shared-pill',
	templateUrl: './shared-pill.component.html',
	styleUrls: ['./shared-pill.component.scss'],
})
export class SharedPillComponent {
	@Input() background: string = 'lk-primary-dark-background';
	@Input() color: string = 'lk-primary-color';
	@Input() text?: string;

	getPillClass(): string {
		return `${this.background} ${this.color}`;
	}
}
