<div class="lk-full-height lk-full-width lk-spaced-row">
	<div
		id="lk-auth-signin-form-layout"
		class="lk-full-height lk-double-padding">
		<form
			id="lk-auth-signin-form"
			[formGroup]="form_group"
			class="lk-shadow lk-border-radius lk-double-padding lk-centered-row lk-wrapped-flex lk-primary-background lk-z-index">
			<div class="lk-spaced-row lk-full-width lk-double-margin-bottom">
				<div
					class="lk-primary-dark-background"
					style="width: 20%; height: 2px"></div>
				<img
					style="width: 50%"
					[src]="logo_src" />
				<div
					class="lk-primary-dark-background"
					style="width: 20%; height: 2px"></div>
			</div>

			<mat-form-field
				class="lk-full-width lk-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label>
					{{ 'GENERAL.EMAIL_LABEL' | translate }}
				</mat-label>
				<input
					id="auth-signin-username-input"
					type="email"
					matInput
					formControlName="username"
					placeholder="address@email.com"
					required />
			</mat-form-field>
			<mat-form-field
				class="lk-full-width"
				appearance="fill"
				color="accent">
				<mat-label>
					{{ 'GENERAL.PASSWORD_LABEL' | translate }}
				</mat-label>
				<input
					id="auth-signin-password-input"
					[type]="is_password_visible ? 'text' : 'password'"
					matInput
					formControlName="password"
					required />
				<mat-icon
					class="lk-cursor-pointer"
					matSuffix
					(click)="is_password_visible = !is_password_visible">
					{{ is_password_visible ? 'visibility_off' : 'visibility' }}
				</mat-icon>
			</mat-form-field>
			<span
				class="lk-right-text lk-full-width lk-primary-medium-color lk-cursor-pointer lk-cursor-link-hover"
				style="font-size: 14px; margin-top: 6px"
				(click)="onForgotPssClick()">
				{{ 'AUTHENTICATION.FORGOT_PSS_CTA' | translate }}
			</span>

			<button
				id="auth-signin-submit-button"
				mat-button
				class="lk-main-button lk-full-width lk-double-margin-top"
				(click)="onSigninClick()"
				[disabled]="is_signin_running"
				[ngClass]="getDisabledClass()">
				<h3
					class="lk-no-margin lk-primary-color"
					*ngIf="!is_signin_running">
					{{ 'AUTHENTICATION.SIGNIN_BUTTON_CTA' | translate }}
				</h3>
				<div
					class="lk-spinner-layout"
					*ngIf="is_signin_running">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>
			<!--<div class="lk-ended-row lk-full-width lk-half-margin-top">
				<span
					class="lk-right-text"
					style="font-size: 14px; margin-right: 6px">
					{{ 'AUTHENTICATION.ACCOUNT_NEED_CTA' | translate }}
				</span>
				<button
					id="auth-signin-signup-cta"
					(click)="onSignupClick()"
					class="lk-background-icon"
					mat-icon-button>
					<mat-icon class="lk-accent-color"> arrow_forward </mat-icon>
				</button>
			</div>-->
		</form>
	</div>

	<div
		id="lk-auth-signin-img-layout"
		class="lk-full-height lk-double-padding lk-centered-row"
		[ngStyle]="{
			'background-image': 'url(' + background_src + ')'
		}"></div>
</div>
