import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Invitation, InvitationResponseType, InvitationService} from '../core/invitation.service';
import {Router} from '@angular/router';
import { DialogService } from '../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { GENERAL_ERROR_MESSAGE } from '../shared/translations';
import { STATION_PATH } from '../core/navigation.service';
import { Station } from '../core/station.service';
import { LOCAL_STATION_KEY } from '../shared/constants';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
  current_item: string = '';
  is_invitation_loading: boolean = false;
  is_response_running: boolean = false;
  is_reject_running: boolean = false;
  pending_invitation: Invitation;

  constructor(
    private invitation_service: InvitationService, 
    private router: Router,
    private dialog_service: DialogService,
    private dialog: MatDialog
  ) {
    this.dialog_service.dialog = this.dialog;
  }

  ngOnInit(): void {
    this.listInvitations();
  }

  onStationChangeClick(station: Station): void {
    this.listInvitations();
  }

  onStationBuildClick(update_mode?: boolean): void {
    this.dialog_service.openDialog(StationBuilderDialogComponent, {
      update_mode,
      station: null
    }).afterClosed().subscribe(
      dialog_result => {
        if (dialog_result && dialog_result.success) {
          localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(dialog_result.station));
          this.toolbar_refresh.next(true);
        } else if (dialog_result && dialog_result.error_message) {
          this.dialog_service.openInfoDialog(dialog_result.error_message);
        }
      }
    );
  }

  onResponseClick(response: boolean): void {
    if (response) {
      this.is_response_running = true;
    } else {
      this.is_reject_running = true;
    }
    this.invitation_service.setInvitationResponse(this.pending_invitation.id,this.pending_invitation.station_id,response).subscribe({
      next: (response) => {
        this.router.navigate([STATION_PATH]);
        this.is_response_running = false;
        this.is_reject_running = false;
      },
      error: (error) => {
        this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
        this.is_response_running = false;
        this.is_reject_running = false;
      }
    });
  }

  listInvitations(): void {
    this.is_invitation_loading = true;
    this.invitation_service.listInvitationsOfAdmin().subscribe({
      next: (response) => {
        const pending_invitations = (response.data as Invitation[]).filter(i => {
          return i.response == InvitationResponseType.PENDING
        });
        if (pending_invitations && pending_invitations.length) {
          this.pending_invitation = pending_invitations[0];
        } else {
          this.router.navigate([STATION_PATH]);
        }
        this.is_invitation_loading = false;
      },
      error: (error) => {
        this.is_invitation_loading = false;
        this.router.navigate([STATION_PATH]);
      }
    });
  }

  getRejectDisabledClass(): {[key: string]: boolean} {
    return {
      'lk-disabled-button': this.is_response_running,
      'lk-main-button': !this.is_response_running
    }
  }

  getResponseDisabledClass(): {[key: string]: boolean} {
    return {
      'lk-disabled-button': this.is_response_running,
      'lk-main-button': !this.is_response_running
    }
  }
}
