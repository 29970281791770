import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StationRoutingModule } from './station-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { StationComponent } from './station.component';
import { StationTeamComponent } from './station-team/station-team.component';
import { StationNotifComponent } from './station-notif/station-notif.component';
import { StationBasicsComponent } from './station-basics/station-basics.component';
import { StationEmptyComponent } from './station-empty/station-empty.component';
import { StationDeleteDialogComponent } from './station-delete-dialog/station-delete-dialog.component';
import { StationNavbarComponent } from './station-navbar/station-navbar.component';
import { StationPicsComponent } from './station-pics/station-pics.component';
import { StationUnlockComponent } from './station-unlock/station-unlock.component';
import { StationLockComponent } from './station-lock/station-lock.component';
import { StationBuilderDialogComponent } from './station-builder-dialog/station-builder-dialog.component';
import { StationAddTeamDialogComponent } from './station-add-team-dialog/station-add-team-dialog.component';
import { StationAddNotifDialogComponent } from './station-add-notif-dialog/station-add-notif-dialog.component';
import { StationNotifSettingsComponent } from './station-notif-settings/station-notif-settings.component';
import { StationNotifDetailsDialogComponent } from './station-notif-details-dialog/station-notif-details-dialog.component';
import { StationNotifSmsTemplatesDialogComponent } from './station-notif-sms-templates-dialog/station-notif-sms-templates-dialog.component';
import { StationsNotifTemplatesComponent } from './stations-notif-templates/stations-notif-templates.component';
import { StationsNotifTemplatesItemComponent } from './stations-notif-templates-item/stations-notif-templates-item.component';
import { StationsNotifTemplatesItemAddDialogComponent } from './stations-notif-templates-item-add-dialog/stations-notif-templates-item-add-dialog.component';
import { StationsNotifTemplatesItemContainerComponent } from './stations-notif-templates-item-container/stations-notif-templates-item-container.component';
import { StationsNotifTemplatesItemDialogComponent } from './stations-notif-templates-item-dialog/stations-notif-templates-item-dialog.component';
import { StationNotifTestDialogComponent } from './station-notif-test-dialog/station-notif-test-dialog.component';
import { StationVehiclesSettingsComponent } from './station-vehicles-settings/station-vehicles-settings.component';

@NgModule({
	declarations: [
		StationComponent,
		StationTeamComponent,
		StationNotifComponent,
		StationBasicsComponent,
		StationEmptyComponent,
		StationDeleteDialogComponent,
		StationNavbarComponent,
		StationPicsComponent,
		StationUnlockComponent,
		StationLockComponent,
		StationBuilderDialogComponent,
		StationAddTeamDialogComponent,
		StationAddNotifDialogComponent,
		StationNotifSettingsComponent,
		StationNotifDetailsDialogComponent,
		StationNotifSmsTemplatesDialogComponent,
		StationsNotifTemplatesComponent,
		StationsNotifTemplatesItemComponent,
		StationsNotifTemplatesItemAddDialogComponent,
		StationsNotifTemplatesItemContainerComponent,
		StationsNotifTemplatesItemDialogComponent,
		StationNotifTestDialogComponent,
		StationVehiclesSettingsComponent,
	],
	imports: [
		CommonModule,
		StationRoutingModule,
		SharedModule,
		TranslateModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		MatMenuModule,
		MatChipsModule,
		MatInputModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatTabsModule,
		MatRadioModule,
		MatAutocompleteModule,
		MatTooltipModule,
		MatSnackBarModule,
		ReactiveFormsModule,
		FormsModule,
		GooglePlaceModule,
	],
})
export class StationModule {}
