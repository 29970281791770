import { NgModule }                           from '@angular/core';
import { Routes, RouterModule }               from '@angular/router';
import { STATION_BUILDER_BASICS_PATH, STATION_BUILDER_LANG_PATH, STATION_BUILDER_LOGO_PATH, STATION_BUILDER_NOTIF_PATH, STATION_BUILDER_PATH, STATION_BUILDER_SETTINGS_PATH, STATION_BUILDER_TEAM_PATH, STATION_PATH } from '../core/navigation.service';
import { AuthGuard } from '../shared/auth.guard';
import { StationBuilderBasicsComponent }      from './station-builder-basics/station-builder-basics.component';
import { StationBuilderLogoComponent }        from './station-builder-logo/station-builder-logo.component';
import { StationBuilderNotifLangComponent }   from './station-builder-notif-lang/station-builder-notif-lang.component';
import { StationBuilderNotifListComponent }   from './station-builder-notif-list/station-builder-notif-list.component';
import { StationBuilderNotifToggleComponent } from './station-builder-notif-toggle/station-builder-notif-toggle.component';
import { StationBuilderTeamComponent }        from './station-builder-team/station-builder-team.component';

const routes: Routes = [
  {
    path        : STATION_PATH + STATION_BUILDER_PATH,
    redirectTo  : STATION_PATH + STATION_BUILDER_BASICS_PATH,
    pathMatch   : 'full'
  },
  {
    path      : STATION_PATH + STATION_BUILDER_BASICS_PATH,
    component : StationBuilderBasicsComponent,
    canActivate: [AuthGuard]
  },
  {
    path      : STATION_PATH + '/:station_id' + STATION_BUILDER_LOGO_PATH,
    component : StationBuilderLogoComponent,
    canActivate: [AuthGuard]
  },
  {
    path      : STATION_PATH + '/:station_id' + STATION_BUILDER_TEAM_PATH,
    component : StationBuilderTeamComponent,
    canActivate: [AuthGuard]
  },
  {
    path      : STATION_PATH + '/:station_id' + STATION_BUILDER_SETTINGS_PATH,
    component : StationBuilderNotifToggleComponent,
    canActivate: [AuthGuard]
  },
  {
    path      : STATION_PATH + '/:station_id' + STATION_BUILDER_LANG_PATH,
    component : StationBuilderNotifLangComponent,
    canActivate: [AuthGuard]
  },
  {
    path      : STATION_PATH + '/:station_id' + STATION_BUILDER_NOTIF_PATH,
    component : StationBuilderNotifListComponent,
    canActivate: [AuthGuard]
  },
  {
    path      : STATION_PATH + '/:station_id' + STATION_BUILDER_NOTIF_PATH + '/:lang',
    component : StationBuilderNotifListComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StationBuilderRoutingModule { }
