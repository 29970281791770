import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormService} from '../../core/form.service';
import {BehaviorSubject} from 'rxjs';
import {LOCAL_STATION_KEY} from '../../shared/constants';
import {Station} from '../../core/station.service';
import {LocalManagerService} from '../../core/local-manager.service';

@Component({
  selector: 'app-station-settings-trigger-lock',
  templateUrl: './station-settings-trigger-lock.component.html',
  styleUrls: ['./station-settings-trigger-lock.component.scss']
})
export class StationSettingsTriggerLockComponent implements OnInit {
  @Input() stationUpdateTrigger: BehaviorSubject<boolean>;
  @Output() lockableAfterVehicleUnlockChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  is_lockable_after_vehicle_unlock: boolean;
  current_station: Station;
  lock_reminder_form_group: FormGroup;
  lock_link_form_group: FormGroup;
  lock_before_form_group: FormGroup;
  lock_after_form_group: FormGroup;
  lock_link_translate_param: { hours: string, minutes: string, time: string };
  lock_before_translate_param: { hours: string, minutes: string, time: string };
  lock_after_translate_param: { hours: string, minutes: string, time: string };

  constructor(
    private form_service: FormService, 
    private local_manager: LocalManagerService
  ) {
    this.lock_reminder_form_group = form_service.stationSettingsLockReminderFormGroup;
    this.lock_link_form_group = form_service.stationSettingsLockLinkFormGroup;
    this.lock_before_form_group = form_service.stationSettingsLockBeforeFormGroup;
    this.lock_after_form_group = form_service.stationSettingsLockAfterFormGroup;
  }

  ngOnInit() {
    this.stationUpdateTrigger.subscribe(
      update => {
        if (update) {
          this.current_station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
          this.initFormFields();
          this.setTranslationParams();
        }
      }
    );
  }

  onLockableAfterVehicleUnlockChange() {
    this.lockableAfterVehicleUnlockChange.emit(this.is_lockable_after_vehicle_unlock);
    if (!this.is_lockable_after_vehicle_unlock) {
      this.lock_reminder_form_group.get('time').disable();
      this.lock_link_form_group.get('time').enable();
      this.lock_before_form_group.get('time').enable();
    } else {
      this.lock_reminder_form_group.get('time').enable();
      this.lock_link_form_group.get('time').disable();
      this.lock_before_form_group.get('time').disable();

      this.lock_link_form_group.get('time').setValue('00:00');
      this.lock_before_form_group.get('time').setValue('00:00');
    }
  }

  onInputChange(event, formGroup: FormGroup) {
    if (!formGroup.get('time').value.includes(':')) {
      event.target.value = event.target.value + ':';
    }

    const target: string[] = event.target.value.split(":");
    if(target && target[0].length > 2) {
      event.target.value = target[0].slice(0, -1) + ':' + target[1];
    }
    if(target && target[1].length > 2) {
      event.target.value = target[0] + ':' + target[1].slice(0, -1);
    }

    this.setTranslationParams();
  }

  initFormFields() {
    // Flags
    this.is_lockable_after_vehicle_unlock  = this.current_station.settings.lock_settings?
      this.current_station.settings.lock_settings.is_lockable_after_vehicle_unlock : false;

    // Lock link
    let hoursAndMinutes = this.getHoursAndMinutesFromMillis(
      this.current_station.settings.lock_settings?
        this.current_station.settings.lock_settings.time_to_lock_link_before_booking_end : 3600000
    );
    this.lock_link_form_group.get('time').setValue(
      hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString()
    );

    // Before lock
    hoursAndMinutes = this.getHoursAndMinutesFromMillis(
      this.current_station.settings.lock_settings?
        this.current_station.settings.lock_settings.time_to_lock_availability_before_booking_end : 3600000
    );
    this.lock_before_form_group.get('time').setValue(
      hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString()
    );

    // After lock
    hoursAndMinutes = this.getHoursAndMinutesFromMillis(
      this.current_station.settings.lock_settings?
        this.current_station.settings.lock_settings.time_to_lock_availability_after_booking_end : 3600000
    );
    this.lock_after_form_group.get('time').setValue(
      hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString()
    );

    // Block fields
    this.onLockableAfterVehicleUnlockChange();
  }

  setTranslationParams() {
    this.lock_link_translate_param = {
      hours   : this.parseTimeFormGroup(this.lock_link_form_group)[0].toString(),
      minutes : this.parseTimeFormGroup(this.lock_link_form_group)[1].toString(),
      time    : this.getTimeLabel(this.lock_link_form_group) 
    };

    this.lock_before_translate_param = { 
      hours   : this.parseTimeFormGroup(this.lock_before_form_group)[0].toString(),
      minutes : this.parseTimeFormGroup(this.lock_before_form_group)[1].toString() ,
      time    : this.getTimeLabel(this.lock_before_form_group) 
    };

    this.lock_after_translate_param = { 
      hours   : this.parseTimeFormGroup(this.lock_after_form_group)[0].toString(),
      minutes : this.parseTimeFormGroup(this.lock_after_form_group)[1].toString(),
      time    : this.getTimeLabel(this.lock_after_form_group, true)  
    };
  }

  parseTimeFormGroup(formGroup: FormGroup): number[] {
    const time: string = formGroup.get('time').value;
    const columnIndex: number = time.indexOf(':');
    return [
      +time.substring(0, columnIndex),
      +time.substring(columnIndex+1, time.length)
    ];
  }

  getTimeLabel(formGroup: FormGroup, sum?: boolean): string {
    const MILLIS_IN_MIN       : number    = 60*1000;
    const MILLIS_IN_HOUR      : number    = 60*60*1000;
    const AFTERNOON_MILLIS    : number    = 18*MILLIS_IN_HOUR;
    const hoursAndMins        : number[]  = this.parseTimeFormGroup(formGroup);
    let hoursAndMinsMillis    : number    = hoursAndMins[0] * MILLIS_IN_HOUR + hoursAndMins[1] * MILLIS_IN_MIN;
    const time                : string[]  = sum?
      this.getHoursAndMinutesFromMillis(AFTERNOON_MILLIS + hoursAndMinsMillis) :
      this.getHoursAndMinutesFromMillis(AFTERNOON_MILLIS - hoursAndMinsMillis);
    return time[0] + ':' + time[1];
  }

  getHoursAndMinutesFromMillis(millis: number): string[] {
    let minutes = this.correctDigits(Math.floor((millis / (1000 * 60)) % 60)),
        hours   = this.correctDigits(Math.floor((millis / (1000 * 60 * 60))));
    return [hours,minutes];
  }

  correctDigits(num: number): string {
    if (num.toString().length <= 1) {
      return '0' + num;
    }
    return num.toString();
  }
}
