import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { String } from 'lodash';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';

export const BOOKINGS_SUMMARY_API_NAME: string = 'bookings_summary';
export const BOOKINGS_SUMMARY_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/bookings-summaries/';

@Injectable({
	providedIn: 'root',
})
export class BookingsSummaryService {
	readBookingsSummary(stationId: string) {
		let apiName: string = BOOKINGS_SUMMARY_API_NAME;
		let path: string = 'stations/' + stationId;
		let init = { response: true };
		return from(API.get(apiName, path, init));
	}
}

export class BookingsSummary {
	total: number;
	bookings_in_pending: number;
	bookings_in_progress: number;
	bookings_with_errors: number;
	bookings_closed: number;
}
