<div class="lk-dialog-max-width">
	<div class="lk-full-width lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-background-icon lk-z-index">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding">
		<div class="lk-row">
			<img
				class="lk-flag-mini lk-no-margin"
				[src]="getStationFlag()" />
			<h3 class="lk-no-margin lk-half-margin-left">
				{{ getTemplateLabel() | translate }}
			</h3>
		</div>

		<div
			*ngIf="!is_update_enabled"
			class="lk-full-width">
			<hr class="lk-stroke-dashed lk-full-width lk-margin-top" />
			<p
				*ngIf="!is_template_loading"
				style="white-space: pre-wrap"
				class="lk-no-margin">
				{{ getTemplateContent() }}
			</p>
			<div
				*ngIf="is_template_loading"
				class="lk-padding lk-centered-row">
				<mat-spinner
					color="accent"
					diameter="30"></mat-spinner>
			</div>
			<hr class="lk-stroke-dashed lk-full-width lk-margin-bottom" />
		</div>

		<form
			*ngIf="is_update_enabled"
			class="lk-full-width lk-margin-top"
			[formGroup]="template_item_form_group">
			<div
				*ngIf="is_template_loading"
				class="lk-padding lk-centered-row">
				<mat-spinner
					color="accent"
					diameter="30"></mat-spinner>
			</div>

			<div *ngIf="!is_template_loading">
				<mat-form-field
					style="width: calc(100% - 52px)"
					class="lk-full-width lk-params-list-select"
					appearance="fill"
					color="accent">
					<mat-select
						(selectionChange)="onParameterSelection($event)"
						matInput
						type="text"
						placeholder="{{ 'STATION.PARAMETERS_LIST_LABEL' | translate }}"
						formControlName="current_parameter">
						<mat-option
							[value]="param"
							*ngFor="let param of params_list">
							{{ param }}
						</mat-option>
					</mat-select>

					<button
						mat-icon-button
						matSuffix>
						<mat-icon class="lk-primary-medium-color"> add </mat-icon>
					</button>
				</mat-form-field>

				<button
					mat-icon-button
					matTooltip="{{ 'STATION.PARAMETERS_LIST_TOOLTIP' | translate }}"
					class="lk-background-icon lk-half-margin-left lk-hidden-before-lg">
					<mat-icon class="lk-accent-color"> info_outlined </mat-icon>
				</button>

				<button
					(click)="onInfoClick()"
					mat-icon-button
					class="lk-background-icon lk-half-margin-left lk-hidden-after-lg">
					<mat-icon class="lk-accent-color"> info_outlined </mat-icon>
				</button>
			</div>

			<mat-form-field
				*ngIf="!is_template_loading"
				class="lk-full-width lk-half-margin-top lk-stations-notif-templates-item-add-dialog-field"
				appearance="fill"
				color="accent">
				<mat-label> content </mat-label>
				<textarea
					style="min-height: 150px"
					type="text"
					matInput
					formControlName="text"
					required></textarea>
			</mat-form-field>

			<div class="lk-full-width lk-margin-top">
				<button
					(click)="onConfirmClick()"
					[disabled]="is_update_running || !isValidForm()"
					[ngClass]="getDisabledClass()"
					mat-button
					class="lk-main-button lk-margin-bottom lk-full-width">
					<span *ngIf="!is_update_running">
						{{ 'GENERAL.CONFIRM_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_update_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
