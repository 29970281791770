import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from '../shared/shared.module';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';

import { AuthenticationComponent } from './authentication.component';
import { AuthSigninComponent } from './auth-signin/auth-signin.component';
import { AuthSignupComponent } from './auth-signup/auth-signup.component';
import { AuthForgotPasswordDialogComponent } from './auth-forgot-password-dialog/auth-forgot-password-dialog.component';
import { AuthForgotPasswordConfirmDialogComponent } from './auth-forgot-password-confirm-dialog/auth-forgot-password-confirm-dialog.component';
import { AuthMfaSetupComponent } from './auth-mfa-setup/auth-mfa-setup.component';
import { AuthMfaVerifyComponent } from './auth-mfa-verify/auth-mfa-verify.component';
import { AuthMfaSetupSmsComponent } from './auth-mfa-setup-sms/auth-mfa-setup-sms.component';
import { AuthMfaSetupTotpComponent } from './auth-mfa-setup-totp/auth-mfa-setup-totp.component';
import { AuthMfaResetDialogComponent } from './auth-mfa-reset-dialog/auth-mfa-reset-dialog.component';
import { AuthMfaResetComponent } from './auth-mfa-reset/auth-mfa-reset.component';
import { AuthMfaResetConfirmDialogComponent } from './auth-mfa-reset-confirm-dialog/auth-mfa-reset-confirm-dialog.component';

@NgModule({
	declarations: [
		AuthenticationComponent,
		AuthSigninComponent,
		AuthSignupComponent,
		AuthForgotPasswordDialogComponent,
		AuthForgotPasswordConfirmDialogComponent,
		AuthMfaSetupComponent,
		AuthMfaVerifyComponent,
		AuthMfaSetupSmsComponent,
		AuthMfaSetupTotpComponent,
		AuthMfaResetDialogComponent,
		AuthMfaResetComponent,
  AuthMfaResetConfirmDialogComponent,
	],
	imports: [
		CommonModule,
		AuthenticationRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatAutocompleteModule,
		MatRadioModule,
		TranslateModule,
		HttpClientModule,
		QRCodeModule,
		SharedModule,
	],
	entryComponents: [
		AuthForgotPasswordDialogComponent,
		AuthForgotPasswordConfirmDialogComponent,
	],
})
export class AuthenticationModule {}
