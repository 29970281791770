import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-booking-details-data-item',
	templateUrl: './booking-details-data-item.component.html',
	styleUrls: ['./booking-details-data-item.component.scss'],
})
export class BookingDetailsDataItemComponent implements OnInit {
	@Input() primary_title: string;
	@Input() primary_value: number;
	@Input() primary_um: string;
	@Input() secondary_title: string;
	@Input() secondary_value: number;
	@Input() secondary_um: string;

	constructor() {}

	ngOnInit(): void {}
}
