import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import {
	Notification,
	NotificationName,
	NotificationType,
} from 'src/app/core/notification.service';
import {
	START_RENTAL_SMS_LABEL,
	START_RENTAL_EMAIL_LABEL,
	END_RENTAL_SMS_LABEL,
	END_RENTAL_EMAIL_LABEL,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-station-notif-details-dialog',
	templateUrl: './station-notif-details-dialog.component.html',
	styleUrls: ['./station-notif-details-dialog.component.scss'],
})
export class StationNotifDetailsDialogComponent implements OnInit {
	form_group: FormGroup;
	is_default: boolean;
	notifications: Notification[];
	notification: Notification;
	notif_name: string;
	content: string;
	is_content_loading: boolean = false;
	sms_type: NotificationType = NotificationType.SMS;

	constructor(
		private dialog_ref: MatDialogRef<StationNotifDetailsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private http: HttpClient,
		private form_service: FormService,
	) {
		this.is_default = data.is_default;
		this.notifications = data.notifications;
		this.notification = data.notification;
		this.notif_name = this.notification.name;
		this.form_group = this.form_service.notifUpdateFormGroup;
	}

	ngOnInit(): void {
		this.readNotificationContent(this.notification.content_url);
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.isValidForm()) {
			const content = this.form_group.get('notif').value;
			const encoded_content = `data:text/plain;base64,${btoa(content)}`;
			this.notification.content = encoded_content;
			this.dialog_ref.close({ notif: this.notification });
		}
	}

	readNotificationContent(content_url: string): void {
		this.is_content_loading = true;
		this.http.get(content_url, { responseType: 'text' }).subscribe(data => {
			this.content = data;
			this.form_group.get('notif').setValue(this.content);
			this.is_content_loading = false;
		});
	}

	isValidForm(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	getNotificationLabel(name: NotificationName, type: NotificationType): string {
		switch (name) {
			case NotificationName.BOOKING_START:
				return type == NotificationType.SMS
					? START_RENTAL_SMS_LABEL
					: START_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_END:
				return type == NotificationType.SMS
					? END_RENTAL_SMS_LABEL
					: END_RENTAL_EMAIL_LABEL;
		}
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': !this.isValidForm(),
			'lk-main-button': this.isValidForm(),
		};
	}
}
