<div 
  id="lk-booking-summary-root-layout" 
  class="lk-row lk-vert-padding lk-hide-scrollbar">
  <div class="lk-booking-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-margin-right lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"> 
      {{ 'BOOKING.SUMMARY_ALL_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && bookings_summary"> 
      {{ bookings_summary.total }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !bookings_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>
  <div class="lk-booking-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-margin-right lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"> 
      {{ 'BOOKING.SUMMARY_PENDING_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && bookings_summary"> 
      {{ bookings_summary.bookings_in_pending }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !bookings_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>
  <div class="lk-booking-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-margin-right lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"> 
      {{ 'BOOKING.SUMMARY_IN_PROGRESS_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && bookings_summary"> 
      {{ bookings_summary.bookings_in_progress }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !bookings_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>
  <div class="lk-booking-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-margin-right lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"> 
      {{ 'BOOKING.SUMMARY_COMPLETED_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && bookings_summary"> 
      {{ bookings_summary.bookings_closed }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !bookings_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>
  <div class="lk-booking-summary-content-layout lk-light-shadow lk-border-radius lk-padding lk-primary-background">
    <p class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"> 
      {{ 'BOOKING.SUMMARY_ERROR_LABEL' | translate | titlecase }} 
    </p>
    <h1 
      class="lk-no-margin" 
      *ngIf="!is_summary_loading && bookings_summary"> 
      {{ bookings_summary.bookings_with_errors }} 
    </h1>
    <div 
      *ngIf="is_summary_loading || !bookings_summary"
      class="lk-vert-half-padding">
      <mat-spinner 
        [diameter]="20" 
        color="accent"></mat-spinner>
    </div>
  </div>
</div>
