<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			class="lk-primary-medium-background"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-auth-forgot-password-confirmation-content-layout"
		class="lk-horiz-padding lk-margin-top">
		<p class="lk-no-margin lk-margin-bottom">
			{{ 'AUTHENTICATION.FORGOT_PSS_CONFIRM_MESSAGE' | translate }}
		</p>
		<form [formGroup]="form_group">
			<mat-form-field
				class="lk-full-width lk-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'GENERAL.CODE_LABEL' | translate }} </mat-label>
				<input
					type="text"
					matInput
					formControlName="code"
					placeholder="123456"
					required />
			</mat-form-field>

			<mat-form-field
				class="lk-full-width lk-margin-bottom"
				appearance="fill"
				color="accent"
				[ngClass]="
					form_group.get('password').hasError('minlength') ||
					form_group.get('password').hasError('pattern')
						? 'lk-form-field-with-error'
						: 'lk-form-field-without-error'
				">
				<mat-label> {{ 'GENERAL.PASSWORD_LABEL' | translate }} </mat-label>
				<input
					[type]="is_password_visible ? 'text' : 'password'"
					matInput
					[errorStateMatcher]="matcher"
					formControlName="password"
					required />
				<mat-icon
					class="lk-cursor-pointer"
					matSuffix
					(click)="is_password_visible = !is_password_visible">
					{{ is_password_visible ? 'visibility_off' : 'visibility' }}
				</mat-icon>
				<mat-error
					*ngIf="
						form_group.get('password').hasError('minlength') &&
						!form_group.get('password').hasError('required')
					">
					{{ 'AUTHENTICATION.PSS_MIN_LENGTH_ERROR' | translate }}
				</mat-error>
				<mat-error
					*ngIf="
						form_group.get('password').hasError('pattern') &&
						!form_group.get('password').hasError('minlength') &&
						!form_group.get('password').hasError('required')
					">
					{{ 'AUTHENTICATION.PSS_PATTERN_ERROR' | translate }}
				</mat-error>
			</mat-form-field>

			<div class="lk-full-width lk-centered-row lk-margin-bottom">
				<button
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()"
					[disabled]="is_forgot_pss_running"
					[ngClass]="getDisabledClass()">
					<h3
						class="lk-no-margin lk-primary-color"
						*ngIf="!is_forgot_pss_running">
						{{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }}
					</h3>
					<div
						class="lk-spinner-layout"
						*ngIf="is_forgot_pss_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
