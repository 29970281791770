<div
	id="lk-booking-details-unlock-link-root-layout"
	class="lk-border-radius lk-shadow lk-padding lk-primary-background lk-hide-scrollbar lk-full-height">
	<div class="lk-full-width lk-spaced-row">
		<h2 class="lk-no-margin lk-subtitle-size">Unlock link</h2>
		<button
			mat-icon-button
			[matMenuTriggerFor]="menu"
			#menuTrigger="matMenuTrigger"
			class="lk-background-icon">
			<mat-icon> more_horiz </mat-icon>
		</button>

		<mat-menu #menu="matMenu">
			<button
				(click)="onSendUnlockLinkClick(booking)"
				mat-menu-item>
				<mat-icon class="lk-accent-color"> lock_open </mat-icon>
				{{ 'BOOKING_DETAILS.SEND_UNLOCK_LINK_CTA' | translate }}
			</button>
		</mat-menu>
	</div>

	<div class="lk-double-margin-top">
		<div class="lk-spaced-row">
			<p
				class="lk-no-margin lk-primary-medium-color"
				style="font-size: 12px">
				{{ 'BOOKING.UNLOCK_LINK_VALIDITY_COLUMN_LABEL' | translate }}
			</p>
			<mat-slide-toggle
				style="margin-right: -12px"
				[(ngModel)]="!booking.settings.unlock_settings.is_link_invalid"
				[ngClass]="getToggleClass(!booking.settings.unlock_settings.is_link_invalid)"
				(change)="onBookingUnlockLinkValidityChange(booking)">
			</mat-slide-toggle>
		</div>
		<hr class="lk-list-separator" />

		<div class="lk-spaced-row">
			<p
				class="lk-no-margin lk-primary-medium-color"
				style="font-size: 12px">
				{{ 'BOOKING_DETAILS.UNLOCK_IMMEDIATE_LINK' | translate }}
			</p>
			<div
				id="lk-booking-details-link-on-assign-layout"
				class="lk-booking-details-links-circle"
				[ngClass]="getUnlockOnAssignClass(booking)"></div>
		</div>
		<hr class="lk-list-separator" />

		<div class="lk-spaced-row">
			<p
				class="lk-no-margin lk-primary-medium-color"
				style="font-size: 12px">
				{{ 'BOOKING_DETAILS.UNLOCK_UNTIL_END_LINK' | translate }}
			</p>
			<div
				id="lk-booking-details-link-till-end-layout"
				class="lk-booking-details-links-circle"
				[ngClass]="getUnlockTillEndClass(booking)"></div>
		</div>
		<hr class="lk-list-separator" />

		<div class="lk-spaced-row">
			<p
				class="lk-no-margin lk-primary-medium-color"
				style="font-size: 12px">
				{{ 'BOOKING_DETAILS.UNLOCK_DELIVERY_BEFORE' | translate }}
			</p>
			<p
				id="lk-booking-details-links-delivery-before-start-text"
				class="lk-no-margin lk-right-text"
				*ngIf="!is_unlockable_on_vehicle_ass">
				{{ getTimezonedTime(getUnlockDeliveryBefore()) | date: 'short':'':current_locale }}
				{{ getTimezoneOffset() }}
			</p>
			<p
				class="lk-no-margin"
				*ngIf="is_unlockable_on_vehicle_ass">
				NA
			</p>
		</div>
		<hr class="lk-list-separator" />

		<div class="lk-spaced-row">
			<p
				class="lk-no-margin lk-primary-medium-color"
				style="font-size: 12px">
				{{ 'BOOKING_DETAILS.UNLOCK_AVAILABILITY_BEFORE' | translate }}
			</p>
			<p
				id="lk-booking-details-links-availability-before-start-text"
				*ngIf="!is_unlockable_on_vehicle_ass"
				class="lk-no-margin lk-right-text">
				{{ getTimezonedTime(getUnlockAvailabilityBefore()) | date: 'short':'':current_locale }}
				{{ getTimezoneOffset() }}
			</p>
			<p
				class="lk-no-margin"
				*ngIf="is_unlockable_on_vehicle_ass">
				NA
			</p>
		</div>
		<hr class="lk-list-separator" />

		<div class="lk-spaced-row">
			<p
				class="lk-no-margin lk-primary-medium-color"
				style="font-size: 12px">
				{{ 'BOOKING_DETAILS.UNLOCK_AVAILABILITY_AFTER' | translate }}
			</p>
			<p
				id="lk-booking-details-links-availability-after-start-text"
				*ngIf="!is_unlockable_until_end"
				class="lk-no-margin lk-right-text">
				{{ getTimezonedTime(getUnlockAvailabilityAfter()) | date: 'short':'':current_locale }}
				{{ getTimezoneOffset() }}
			</p>
			<p
				class="lk-no-margin"
				*ngIf="is_unlockable_until_end">
				NA
			</p>
		</div>
		<hr class="lk-list-separator" />

		<div
			class="lk-spaced-row"
			*ngIf="booking.vehicle_id">
			<p
				class="lk-no-margin lk-primary-medium-color"
				style="font-size: 12px">
				{{ 'BOOKING_DETAILS.UNLOCK_LINK_LABEL' | translate }}
			</p>
			<div
				style="justify-content: flex-end"
				class="lk-row lk-wrapped-flex">
				<a
					id="lk-booking-details-links-unlock-link"
					*ngIf="unlock_link_short"
					[href]="unlock_link_short"
					class="lk-no-margin">
					{{ unlock_link_short }}
				</a>
				<button
					*ngIf="unlock_link_short"
					mat-icon-button
					(click)="onUnlockCopyClick()">
					<mat-icon style="font-size: 16px"> content_copy </mat-icon>
				</button>
				<mat-spinner
					*ngIf="!unlock_link_short"
					diameter="20"
					color="accent"></mat-spinner>
			</div>
		</div>
		<!--<hr   
      *ngIf="booking.vehicle_id"
      class="lk-list-separator">-->
	</div>

	<div
		*ngIf="is_sending"
		class="lk-full-width lk-full-height lk-centered-row"
		style="position: absolute; top: 0; left: 0; max-height: 100vh">
		<div class="lk-overlay-spinner-layout lk-padding lk-border-radius">
			<mat-spinner
				color="accent"
				diameter="30"></mat-spinner>
		</div>
	</div>
</div>
