import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	Notification as Template,
	NotificationName,
	NotificationType,
} from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import {
	SUPPORTED_LANGUAGES,
	DEFAULT_START_SMS_TEMPLATE_IT,
	DEFAULT_END_SMS_TEMPLATE_IT,
	DEFAULT_START_SMS_TEMPLATE_EN,
	DEFAULT_END_SMS_TEMPLATE_EN,
} from 'src/app/shared/constants';
import {
	START_RENTAL_SMS_LABEL,
	START_RENTAL_EMAIL_LABEL,
	END_RENTAL_SMS_LABEL,
	END_RENTAL_EMAIL_LABEL,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-station-notif-sms-templates-dialog',
	templateUrl: './station-notif-sms-templates-dialog.component.html',
	styleUrls: ['./station-notif-sms-templates-dialog.component.scss'],
})
export class StationNotifSmsTemplatesDialogComponent implements OnInit {
	station?: Station;
	notifications: Template[];
	sms_type: NotificationType = NotificationType.SMS;
	start_sms_content: string;
	end_sms_content: string;

	constructor(
		private dialog_ref: MatDialogRef<StationNotifSmsTemplatesDialogComponent>,
		private http: HttpClient,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.station = data.station;
	}

	ngOnInit(): void {
		this.setDefaultNotifications();
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	setDefaultNotifications(): Template[] {
		const base_path: string = `https://linkey-core-bucket-${environment.STAGE}.s3.amazonaws.com`;
		const station_lang: string = this.station.settings.default_lang;
		let start_content_url: string;
		let end_content_url: string;
		switch (station_lang) {
			case SUPPORTED_LANGUAGES[0]:
				start_content_url = base_path + DEFAULT_START_SMS_TEMPLATE_IT;
				end_content_url = base_path + DEFAULT_END_SMS_TEMPLATE_IT;
				break;
			case SUPPORTED_LANGUAGES[1]:
				start_content_url = base_path + DEFAULT_START_SMS_TEMPLATE_EN;
				end_content_url = base_path + DEFAULT_END_SMS_TEMPLATE_EN;
				break;
			default:
				start_content_url = base_path + DEFAULT_END_SMS_TEMPLATE_EN;
				end_content_url = base_path + DEFAULT_END_SMS_TEMPLATE_EN;
				break;
		}

		this.notifications = [];
		this.notifications.push({
			id: '',
			name: NotificationName.BOOKING_START,
			type: NotificationType.SMS,
			content_url: start_content_url,
			lang: station_lang,
		});
		this.notifications.push({
			id: '',
			name: NotificationName.BOOKING_END,
			type: NotificationType.SMS,
			content_url: end_content_url,
			lang: station_lang,
		});
		this.readNotificationContent(start_content_url, 'start');
		this.readNotificationContent(end_content_url, 'end');
		return this.notifications;
	}

	getNotificationLabel(name: NotificationName, type: NotificationType): string {
		switch (name) {
			case NotificationName.BOOKING_START:
				return type == NotificationType.SMS
					? START_RENTAL_SMS_LABEL
					: START_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_END:
				return type == NotificationType.SMS
					? END_RENTAL_SMS_LABEL
					: END_RENTAL_EMAIL_LABEL;
		}
	}

	getNotifContent(notif: Template): string {
		if (notif.name == NotificationName.BOOKING_START)
			return this.start_sms_content;
		if (notif.name == NotificationName.BOOKING_END) return this.end_sms_content;
	}

	readNotificationContent(
		content_url: string,
		sms_name: 'start' | 'end',
	): void {
		this.http.get(content_url, { responseType: 'text' }).subscribe(data => {
			if (sms_name == 'start') this.start_sms_content = data;
			if (sms_name == 'end') this.end_sms_content = data;
		});
	}
}
