import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-station-empty',
	templateUrl: './station-empty.component.html',
	styleUrls: ['./station-empty.component.scss'],
})
export class StationEmptyComponent {
	@Output() stationBuildClick: EventEmitter<void> = new EventEmitter<void>();

	constructor() {}

	onStationBuildClick() {
		this.stationBuildClick.emit();
	}
}
