<div class="lk-full-width lk-border-radius lk-shadow lk-padding-md lk-margin-top lk-primary-background">
  <h2 class="lk-no-margin lk-accent-color">
    {{ 'STATION.SETTINGS_PIC_POLICY_TITLE' | translate }}
  </h2>
  <p class="lk-primary-medium-color lk-no-margin lk-margin-bottom">
    {{ 'STATION.SETTINGS_PIC_POLICY_SUBTITLE' | translate }}
  </p>

  <div class="lk-column">
    <mat-slide-toggle 
      [(ngModel)]="is_policy_mandatory"
      (change)="onToggleChange($event, mandatory_policy)">
      {{ 'GENERAL.PICTURES_MANDATORY_POLICY_TITLE' | translate }}
      <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
      {{ 'GENERAL.PICTURES_MANDATORY_POLICY_SUBTITLE' |translate }} </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      class="lk-margin-top" 
      [(ngModel)]="is_policy_mandatory_pre"
      (change)="onToggleChange($event, mandatory_pre_policy)">
      {{ 'GENERAL.PICTURES_PICK_UP_POLICY_TITLE' | translate }}
      <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
      {{ 'GENERAL.PICTURES_PICK_UP_POLICY_SUBTITLE' |translate }} </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      class="lk-margin-top" 
      [(ngModel)]="is_policy_mandatory_post"
      (change)="onToggleChange($event, mandatory_post_policy)">
      {{ 'GENERAL.PICTURES_DROP_OFF_POLICY_TITLE' | translate }}
      <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
      {{ 'GENERAL.PICTURES_DROP_OFF_POLICY_SUBTITLE' |translate }} </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      class="lk-margin-top" 
      [(ngModel)]="is_policy_optional"
      (change)="onToggleChange($event, optional_policy)">
      {{ 'GENERAL.PICTURES_OPTIONAL_POLICY_TITLE' | translate }}
      <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
      {{ 'GENERAL.PICTURES_OPTIONAL_POLICY_SUBTITLE' |translate }} </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      class="lk-margin-top" 
      [(ngModel)]="is_policy_disabled"
      (change)="onToggleChange($event, disabled_policy)">
      {{ 'GENERAL.PICTURES_DISABLED_POLICY_TITLE' | translate }}
      <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
      {{ 'GENERAL.PICTURES_DISABLED_POLICY_SUBTITLE' |translate }} </p>
    </mat-slide-toggle>
  </div>

  <div class="lk-full-width lk-ended-row lk-margin-top">
    <button 
      class="lk-main-button" 
      mat-button 
      (click)="onPolicyUpdateClick()">
      <span> {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} </span>
    </button>
  </div>
</div>
