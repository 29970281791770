import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth.guard';
import { METABASE_PATH } from '../core/navigation.service';
import { MetabaseComponent } from './metabase/metabase.component';

const routes: Routes = [
	{
		path: METABASE_PATH,
		component: MetabaseComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MetabaseRoutingModule {}
