<div
	class="lk-border-radius lk-shadow lk-padding lk-full-height lk-primary-background">
	<h3 class="lk-no-margin lk-subtitle-size">
		{{ 'VEHICLE_DETAILS.MEASURES_SECTION_LABEL' | translate }}
	</h3>

	<div class="lk-full-width lk-spaced-row lk-wrapped-flex">
		<div
			class="lk-half-width lk-border-radius lk-primary-medium-background lk-padding lk-margin-top"
			style="min-width: fit-content">
			<p
				class="lk-no-margin lk-primary-medium-color lk-centered-text"
				style="font-size: 12px">
				{{ 'VEHICLE.ODOMETER_COLUMN_LABEL' | translate }}
			</p>
			<h3 class="lk-no-margin lk-centered-text">
				<span
					*ngIf="
						vehicle &&
						vehicle.stats &&
						vehicle.stats.distance_covered &&
						vehicle.stats.distance_covered.value
					">
					{{ getOdometerValue(vehicle) | number: '0.2-2' }}
					<span style="font-size: 12px">
						{{ odometer_um }}
					</span>
				</span>
				<span
					*ngIf="
						!vehicle ||
						!vehicle.stats ||
						!vehicle.stats.distance_covered ||
						!vehicle.stats.distance_covered.value
					">
					NA
				</span>
			</h3>
			<p
				*ngIf="
					vehicle &&
					vehicle.stats &&
					vehicle.stats.distance_covered &&
					vehicle.stats.distance_covered.updated_at
				"
				style="font-size: 10px"
				class="lk-no-margin lk-centered-text">
				@
				{{
					vehicle.stats.distance_covered.updated_at
						| date: 'short':'':current_locale
				}}
			</p>
		</div>

		<div
			class="lk-half-width lk-border-radius lk-primary-medium-background lk-padding lk-margin-top"
			style="min-width: fit-content">
			<p
				class="lk-no-margin lk-primary-medium-color lk-centered-text"
				style="font-size: 12px">
				{{ 'VEHICLE.FUEL_COLUMN_LABEL' | translate }}
			</p>
			<h3 class="lk-no-margin lk-centered-text">
				<span
					*ngIf="
						vehicle &&
						vehicle.stats &&
						vehicle.stats.autonomy_percentage &&
						vehicle.stats.autonomy_percentage.value
					">
					{{ vehicle.stats.autonomy_percentage.value }}
					<span style="font-size: 12px"> % </span>
				</span>
				<span
					*ngIf="
						!vehicle ||
						!vehicle.stats ||
						!vehicle.stats.autonomy_percentage ||
						!vehicle.stats.autonomy_percentage.value
					">
					NA
				</span>
			</h3>
			<p
				*ngIf="
					vehicle &&
					vehicle.stats &&
					vehicle.stats.autonomy_percentage &&
					vehicle.stats.autonomy_percentage.updated_at
				"
				style="font-size: 10px"
				class="lk-no-margin lk-centered-text">
				@
				{{
					vehicle.stats.autonomy_percentage.updated_at
						| date: 'short':'':current_locale
				}}
			</p>
		</div>

		<div
			class="lk-half-width lk-border-radius lk-primary-medium-background lk-padding lk-margin-top"
			style="min-width: fit-content">
			<p
				class="lk-no-margin lk-primary-medium-color lk-centered-text"
				style="font-size: 12px">
				{{ 'VEHICLE.BATTERY_COLUMN_LABEL' | translate }}
			</p>
			<h3 class="lk-no-margin lk-centered-text">
				<span
					*ngIf="
						vehicle &&
						vehicle.stats &&
						vehicle.stats.external_voltage &&
						vehicle.stats.external_voltage.value
					">
					{{ vehicle.stats.external_voltage.value | number: '0.2-2' }}
					<span style="font-size: 12px"> V </span>
				</span>
				<span
					*ngIf="
						!vehicle ||
						!vehicle.stats ||
						!vehicle.stats.external_voltage ||
						!vehicle.stats.external_voltage.value
					">
					NA
				</span>
			</h3>
			<p
				*ngIf="
					vehicle &&
					vehicle.stats &&
					vehicle.stats.external_voltage &&
					vehicle.stats.external_voltage.updated_at
				"
				style="font-size: 10px"
				class="lk-no-margin lk-centered-text">
				@
				{{
					vehicle.stats.external_voltage.updated_at
						| date: 'short':'':current_locale
				}}
			</p>
		</div>
	</div>
</div>
