import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Station } from 'src/app/core/station.service';

@Component({
  selector: 'app-station-builder-turo-dialog',
  templateUrl: './station-builder-turo-dialog.component.html',
  styleUrls: ['./station-builder-turo-dialog.component.scss']
})
export class StationBuilderTuroDialogComponent implements OnInit {
  station: Station;
  form_group: FormGroup = new FormBuilder().group({
    email: ['', [Validators.email]]
  });

  constructor(
    private dialogRef: MatDialogRef<StationBuilderTuroDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
    this.station = data.station;
  }

  ngOnInit() { 
    if (this.station && this.station.ext_email) {
      this.form_group.get('email').setValue(this.station.ext_email);
    }
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    this.dialogRef.close({ turo_email: this.form_group.get('email').value });
  }
}
