<div class="lk-border-radius lk-shadow lk-padding lk-full-height lk-primary-background">
	<div class="lk-full-width lk-spaced-row">
		<h3 class="lk-no-margin lk-subtitle-size">
			{{ 'VEHICLE_DETAILS.GALLERY_SECTION_LABEL' | translate }}
		</h3>
		<button
			mat-icon-button
			(click)="onAddImageClick()"
			class="lk-background-icon"
			matTooltip="{{ 'VEHICLE_DETAILS.ADD_IMAGE_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> add </mat-icon>
		</button>
	</div>

	<div
		class="lk-full-width lk-centered-row lk-margin-top"
		*ngIf="vehicle && (!vehicle.img_urls || vehicle.img_urls.length <= 0)">
		<button
			mat-button
			class="lk-main-button"
			(click)="onAddImageClick()">
			<h3 class="lk-no-margin lk-primary-color">
				{{ 'VEHICLE_DETAILS.ADD_IMAGE_CTA' | translate }}
			</h3>
		</button>
	</div>

	<div
		class="lk-full-width lk-spaced-row lk-wrapped-flex"
		*ngIf="vehicle && vehicle.img_urls && vehicle.img_urls.length > 0">
		<div
			class="lk-margin-top lk-half-width"
			style="position: relative"
			*ngFor="let img of images; let i = index">
			<img
				class="lk-full-width lk-border-radius"
				[src]="img.result" />
			<button
				id="lk-vehicle-details-gallery-remove-button"
				class="lk-primary-medium-background"
				mat-icon-button
				(click)="onRemoveImageClick(img)">
				<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
			</button>
		</div>
	</div>
</div>
