<app-shared-toolbar 
  [currentNavItem]="current_item" 
  [stationUpdateTrigger]="station_update_trigger"></app-shared-toolbar>

<section class="lk-section-with-toolbar lk-padding-md lk-spaced-column">
  <div class="lk-centered-column lk-full-width">
    <div 
      id="lk-station-builder-notif-form-layout" 
      class="lk-centered-column-wrapper">
      <div class="lk-centered-row lk-margin-bottom">
        <h1 class="lk-accent-color lk-no-margin lk-station-builder-step-text"> 4 </h1>
        <h1 class="lk-no-margin lk-half-margin-left"> 
          {{ 'STATION_BUILDER.STEP_NOTIF_TITLE' | translate }} 
        </h1>
      </div>
  
      <!--<app-station-builder-notif-lang-summary></app-station-builder-notif-lang-summary>-->
  
      <div class="lk-shadow lk-border-radius lk-padding-md lk-primary-background">
        <div 
          class="lk-centered-row lk-wrapped-flex" 
          *ngIf="!is_request_running">
          <div class="lk-full-width lk-centered-row lk-wrapped-flex">
            <div class="lk-station-builder-notif-half-width lk-slide-toggle-layout lk-half-padding">
              <mat-slide-toggle [(ngModel)]="is_sms_checked">
                {{ 'STATION_BUILDER.SMS_NOTIF_LABEL' | translate }}
                <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
                {{ 'STATION_BUILDER.SMS_NOTIF_DESCRIPTION' |translate }} </p>
              </mat-slide-toggle>
            </div>
            <div class="lk-station-builder-notif-half-width lk-slide-toggle-layout lk-half-padding">
              <mat-slide-toggle [(ngModel)]="is_email_checked">
                {{ 'STATION_BUILDER.EMAIL_NOTIF_LABEL' | translate }}
                <br> <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
                {{ 'STATION_BUILDER.EMAIL_NOTIF_DESCRIPTION' |translate }} </p>
              </mat-slide-toggle>
            </div>
          </div>
          
          <div class="lk-full-width lk-centered-row">
            <button 
              class="lk-main-button lk-double-margin-top lk-station-builder-notif-half-width"
              mat-button 
              (click)="onNextClick()" 
              *ngIf="!update_mode"
              [disabled]="is_station_loading" 
              [ngClass]="getDisabledClass()">
              <h3 
                class="lk-no-margin lk-primary-color" 
                *ngIf="!is_station_loading"> 
                {{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }} 
              </h3>
              <div 
                class="lk-spinner-layout" 
                *ngIf="is_station_loading">
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
            </button>
          
            <button 
              mat-button 
              class="lk-main-button lk-double-margin-top lk-station-builder-notif-half-width"
              (click)="onNextClick()" 
              *ngIf="update_mode"
              [disabled]="is_station_loading" 
              [ngClass]="getDisabledClass()">
              <h3 
                class="lk-no-margin lk-primary-color" 
                *ngIf="!is_station_loading"> 
                {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} 
              </h3>
              <div 
                class="lk-spinner-layout" 
                *ngIf="is_station_loading">
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
            </button>
          </div>
        </div>

        <div 
            class="lk-centered-row" 
            *ngIf="is_request_running">
            <mat-spinner 
              diameter="30"
              color="accent"></mat-spinner>
          </div>
      </div>
    </div>
  </div>

  <app-station-builder-steps [currentStep]="current_step"></app-station-builder-steps>
</section>
