import {Component, OnInit} from '@angular/core';
import {FormService} from '../../core/form.service';
import {FormGroup} from '@angular/forms';
import {Station, StationService} from '../../core/station.service';
import {DATE_FORMATS, DATE_FORMAT_LABELS, DEFAULT_PHONE_PREFIX, ERROR_DESCRIPTION_500, TIMEZONES} from '../../shared/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from 'src/app/core/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {
  GENERAL_ERROR_MESSAGE,
  STATION_BUILDER_BASICS_STEP, 
  TOOLBAR_STATION_NAV_ITEM_NAME} from 'src/app/shared/translations';
import {Address, Phone} from 'src/app/core/core.module';
import {BehaviorSubject} from 'rxjs';
import {getTimezoneOffset} from 'date-fns-tz';
import { STATION_BUILDER_LOGO_PATH, STATION_PATH } from 'src/app/core/navigation.service';

@Component({
  selector    : 'app-station-builder-basics',
  templateUrl : './station-builder-basics.component.html',
  styleUrls   : ['./station-builder-basics.component.scss']
})
export class StationBuilderBasicsComponent implements OnInit {
  station_id: string;
  station: Station;
  form_group: FormGroup;
  address: any;
  station_update_trigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  current_item: string = TOOLBAR_STATION_NAV_ITEM_NAME;
  update_mode: boolean = false;
  is_request_running: boolean = false;
  is_station_loading: boolean = false;
  current_step: string = STATION_BUILDER_BASICS_STEP;
  timezones: string[] = TIMEZONES;
  date_formats: { format: string; label: string }[] = [
    {
      format: DATE_FORMATS[0],
      label: DATE_FORMAT_LABELS[0]
    },
    {
      format: DATE_FORMATS[1],
      label: DATE_FORMAT_LABELS[1]
    }
  ];
  

  constructor(
    private form_service: FormService, 
    private route: ActivatedRoute,
    private station_service: StationService,
    private dialog_service: DialogService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.form_group = form_service.stationBuilderBasicsFormGroup;
    this.dialog_service.dialog = this.dialog;
    this.route.queryParams.subscribe(params => {
        if (params && params.update) {
          this.update_mode = params.update;
          this.station_id  = params.station_id;
        }
      }
    );
  }

  ngOnInit() {
    this.station_update_trigger.next(false);
    this.form_group.reset();

    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone + ' (local time)';
    this.timezones = TIMEZONES;
    this.timezones = [timezone,...this.timezones];
    this.form_group.get('ext_timezone').setValue(this.timezones[0]);

    this.form_group.get('phone_prefix').setValue(DEFAULT_PHONE_PREFIX);
    if (this.update_mode && this.station_id) {
      this.readStation();
    } else {
      this.station = new Station();
    }
  }

  onAddressChange(address: any) {
    this.address = address;
  }

  onNextClick() {
    if (this.form_service.validateForm(this.form_group)) {
      this.setStation();
      this.createStation();
    }
  }

  onUpdateClick() {
    console.log('Update click');
    if (this.form_service.validateForm(this.form_group)) {
      console.log('Correct form');
      this.setStation();
      this.updateStation();
    }
  }

  readStation() {
    this.is_request_running = true;
    this.station_service.readStation(this.station_id).subscribe(
      response => {
        this.station = response.data;
        this.is_request_running = false;
        this.setStationFormGroup();
      }, error => {
        this.router.navigate([STATION_PATH]);
        this.is_request_running = false;
      }
    );
  }

  createStation() {
    this.is_station_loading = true;
    this.station_service.createStation(this.station).subscribe({
      next: (response) => {
        this.station = response.data;
        this.is_station_loading = false;
        this.router.navigate([STATION_PATH + '/'+ this.station.id + STATION_BUILDER_LOGO_PATH]);
      },
      error: (error) => {
        this.is_station_loading = false;
        if (
          error.response.data.error 
          && error.response.data.error.description
          && error.response.data.error.description != ERROR_DESCRIPTION_500
        ) {
          this.dialog_service.openInfoDialog(error.response.data.error.description);
        } else {
          this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
        }
      }
    });
  }

  updateStation() {
    this.is_station_loading = true;
    this.station_service.updateStation(this.station).subscribe({
      next: (response) => {
        this.station = response.data;
        this.is_station_loading = false;
        this.router.navigate([STATION_PATH]);
      },
      error: (error) => {
        this.is_station_loading = false;
        if (
          error.response.data.error 
          && error.response.data.error.description
          && error.response.data.error.description != ERROR_DESCRIPTION_500
        ) {
          this.dialog_service.openInfoDialog(error.response.data.error.description);
        } else {
          this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
        }
      }
    });
  }

  setStationFormGroup() {
    this.form_group.get('name')            .setValue(this.station.name);
    this.form_group.get('email')           .setValue(this.station.email);
    this.form_group.get('ext_email')       .setValue(this.station.ext_email);
    this.form_group.get('ext_timezone')    .setValue(this.station.ext_timezone);
    this.form_group.get('ext_time_format') .setValue(this.station.ext_time_format);
    this.form_group.get('phone_prefix')    .setValue(this.station.phone.prefix);
    this.form_group.get('phone')           .setValue(this.station.phone.number);
    this.form_group.get('address')         .setValue(this.station.address.formatted_address);
  }

  setStation() {
    let timezone: string = undefined;
    if (
      this.form_group.get('ext_timezone')
      && this.form_group.get('ext_timezone').value
    ) {
      timezone = this.form_group.get('ext_timezone').value=='GMT'?undefined:this.form_group.get('ext_timezone').value.toString().replace(' (local time)','');
    }

    this.station.name             = this.form_group.get('name')            .value;
    this.station.email            = this.form_group.get('email')           .value;
    this.station.ext_email        = this.form_group.get('ext_email')       .value;
    this.station.ext_timezone     = timezone;
    this.station.ext_time_format  = this.form_group.get('ext_time_format') .value;
    this.station.phone            = new Phone();
    this.station.phone.prefix     = this.form_group.get('phone_prefix')    .value;
    this.station.phone.number     = this.form_group.get('phone')           .value;
    if (this.address) {
      this.setStationAddress(this.address);
    }
  }

  setStationAddress(address: any) {
    this.station.address                    = new Address();
    this.station.address.formatted_address  = address.formatted_address;
    this.station.address.lat_lng            = [
      address.geometry.location.lat(),
      address.geometry.location.lng()
    ];

    let localityComponent = address.address_components.filter(ac => {
      return ac.types.includes('locality');
    })[0];
    if (localityComponent && localityComponent.length > 0) {
      this.station.address.city = localityComponent.long_name;
    }

    let regionComponent = address.address_components.filter(ac => {
      return ac.types.includes('administrative_area_level_1');
    })[0];
    if (regionComponent) {
      this.station.address.region = regionComponent.long_name;
    }

    let countryComponent = address.address_components.filter(ac => {
      return ac.types.includes('country');
    })[0];
    if (countryComponent) {
      this.station.address.country = countryComponent.long_name;
    }
  }

  getDisabledClass() {
    return {
      'lk-disabled-button'  : this.is_station_loading,
      'lk-main-button'      : !this.is_station_loading
    }
  }

  getTimezoneOffset(timezone: string) {
    return 'GMT'
      + (getTimezoneOffset(timezone.replace(' (local time)',''))/3600000>=0?'+':'')
      + getTimezoneOffset(timezone.replace(' (local time)',''))/3600000;
  }
}
