<div class="lk-spaced-row">
	<h2 class="lk-no-margin lk-half-margin-bottom">
		{{ 'STATION.TEMPLATES_LABEL' | translate }}
	</h2>
</div>

<div class="lk-padding lk-light-shadow lk-border-radius lk-primary-background">
	<div *ngIf="!is_request_running">
		<app-stations-notif-templates
			[template_items]="template_items"
			(refresh)="onReset()"></app-stations-notif-templates>
	</div>

	<div
		class="lk-centered-row"
		*ngIf="is_request_running">
		<mat-spinner
			diameter="20"
			color="accent"></mat-spinner>
	</div>
</div>
