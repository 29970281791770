import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export const BOOKINGS_EVENTS_API_NAME: string = 'events';
export const BOOKINGS_EVENTS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/events/';

export const VEHICLES_COMMANDS_API_NAME: string = 'commands';
export const VEHICLES_COMMANDS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/commands/';

@Injectable({
	providedIn: 'root',
})
export class EventsService {
	constructor(private http: HttpClient) {}

	listEventsOfBooking(
		station_id: string,
		booking_id: string,
		created_from?: number,
		created_to?: number,
	): Observable<any> {
		const apiName: string = BOOKINGS_EVENTS_API_NAME;
		const path: string = 'stations/' + station_id + '/bookings/' + booking_id;

		let query_string_params = {};
		if (created_from) query_string_params['created_from'] = created_from;
		if (created_to) query_string_params['created_to'] = created_to;

		const init = {
			response: true,
			queryStringParameters: query_string_params,
		};
		return from(API.get(apiName, path, init));
	}

	listCommandsOfVehicle(
		station_id: string,
		vehicle_id: string,
		created_from?: number,
		created_to?: number,
	) {
		const apiName: string = VEHICLES_COMMANDS_API_NAME;
		const path: string = 'stations/' + station_id + '/vehicles/' + vehicle_id;

		let query_string_params = {};
		if (created_from) query_string_params['created_from'] = created_from;
		if (created_to) query_string_params['created_to'] = created_to;

		const init = {
			response: true,
			queryStringParameters: query_string_params,
		};
		return from(API.get(apiName, path, init));
	}
}

export class Event {
	id?: string;
	type?: string;
	data?: any;
	created_at?: number;
	updated_at?: number;
}

export class Command {
	id?: string;
	status?: string;
	source?: string;
	object?: string;
	booking_id?: string;
	processed_at?: number;
	created_at?: number;
	updated_at?: number;
}
