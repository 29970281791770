<div>
	<div class="lk-full-width lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background lk-z-index">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-half-margin-top lk-horiz-padding">
		<h2 class="lk-no-margin lk-centered-text">
			{{ 'SUBSCRIPTION.SELECT_CURRENCY_TITLE' | translate }}
		</h2>
		<p class="lk-no-margin lk-top-margin lk-centered-text">
			{{ 'SUBSCRIPTION.SELECT_CURRENCY_SUBTITLE' | translate }}
		</p>

		<div
			class="lk-full-width lk-spaced-row lk-vert-padding lk-subscription-start-currency__grid">
			<div
				(click)="onCurrencyclick('eur')"
				class="lk-border-radius lk-subscription-start-currency__grid-item">
				<img
					src="../../../assets/imgs/lk-flag-icons/european-union.png"
					class="lk-full-width" />
				<p
					class="lk-no-margin lk-half-margin-top lk-half-margin-bottom lk-centered-text">
					EUR
				</p>
			</div>

			<div
				(click)="onCurrencyclick('gbp')"
				class="lk-border-radius lk-subscription-start-currency__grid-item">
				<img
					src="../../../assets/imgs/lk-flag-icons/united-kingdom.png"
					class="lk-full-width" />
				<p
					class="lk-no-margin lk-half-margin-top lk-half-margin-bottom lk-centered-text">
					GBP
				</p>
			</div>

			<div
				(click)="onCurrencyclick('usd')"
				class="lk-border-radius lk-subscription-start-currency__grid-item">
				<img
					src="../../../assets/imgs/lk-flag-icons/united-states.png"
					class="lk-full-width" />
				<p
					class="lk-no-margin lk-half-margin-top lk-half-margin-bottom lk-centered-text">
					USD
				</p>
			</div>

			<div
				(aud)="onCurrencyclick('eur')"
				class="lk-border-radius lk-subscription-start-currency__grid-item">
				<img
					src="../../../assets/imgs/lk-flag-icons/australia.png"
					class="lk-full-width" />
				<p
					class="lk-no-margin lk-half-margin-top lk-half-margin-bottom lk-centered-text">
					AUD
				</p>
			</div>
		</div>
	</div>
</div>
