<div>
  <div class="lk-ended-row">
    <button 
      mat-icon-button 
      (click)="onCloseClick()" 
      class="lk-primary-medium-background">
      <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
    </button>
  </div>
  <div 
    id="lk-shared-interaction-error-content-layout" 
    class="lk-horiz-padding">
    <p> 
      {{ message | translate }}: 
      <a 
        class="lk-accent-color"
        href="mailto:support-linkey@2hire.io"> support-linkey@2hire.io 
      </a> 
    </p>
  </div>
</div>
