import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SharedSingleActionDialogComponent} from '../shared/shared-single-action-dialog/shared-single-action-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialog: MatDialog;

  public openDialog(component: any,data?: any) {
    const dialogConfig      = new MatDialogConfig();
    dialogConfig.autoFocus  = false;

    if (data != null) {
      dialogConfig.data = data;
    }

    return this.dialog.open(component, dialogConfig);
  }

  public openInfoDialog(message: string, title?: string, img_url?: string) {
    return this.openDialog(SharedSingleActionDialogComponent, { 
      message: message,
      title: title,
      img_url: img_url
    });
  }
}
