import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormService } from 'src/app/core/form.service';
import { Station, StationService } from 'src/app/core/station.service';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-hidden-stations-alias-dialog',
	templateUrl: './hidden-stations-alias-dialog.component.html',
	styleUrls: ['./hidden-stations-alias-dialog.component.scss'],
})
export class HiddenStationsAliasDialogComponent implements OnInit {
	station?: Station;
	form_group: FormGroup;
	is_save_running: boolean = false;

	constructor(
		private snackbar: MatSnackBar,
		private form_service: FormService,
		private station_service: StationService,
		private dialog_ref: MatDialogRef<HiddenStationsAliasDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.station = data.station;
		this.form_group = this.form_service.hidden_stations_alias_form_group;
	}

	ngOnInit(): void {
		if (this.station && this.station.alias) {
			this.form_group.get('alias').setValue(this.station.alias);
		}
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onAliasUpdateClick(): void {
		if (this.form_service.validateForm(this.form_group) && this.station) {
			const alias: string = this.form_group.get('alias').value;
			this.setAlias(alias);
		}
	}

	setAlias(alias: string): void {
		this.is_save_running = true;
		this.station_service.setAlias(this.station?.id, alias).subscribe({
			next: response => {
				this.dialog_ref.close({ success: true });
				this.is_save_running = false;
			},
			error: error => {
				this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
				this.is_save_running = false;
			},
		});
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_save_running,
			'lk-main-button': !this.is_save_running,
		};
	}
}
