import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	Notification,
	NotificationType,
} from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';

@Component({
	selector: 'app-stations-notif-templates',
	templateUrl: './stations-notif-templates.component.html',
	styleUrls: ['./stations-notif-templates.component.scss'],
})
export class StationsNotifTemplatesComponent implements OnInit {
	@Input() template_items?: TemplateItem[];
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();
	station?: Station;

	constructor() {
		this.station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;
	}

	ngOnInit(): void {}
}

export interface TemplateItem {
	name: string;
	type: NotificationType;
	default: Notification;
	custom?: Notification;
}
