import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Vehicle } from 'src/app/core/vehicle.service';

@Component({
  selector: 'app-vehicle-turo-dialog',
  templateUrl: './vehicle-turo-dialog.component.html',
  styleUrls: ['./vehicle-turo-dialog.component.scss']
})
export class VehicleTuroDialogComponent implements OnInit {
  vehicle: Vehicle;
  form_group: FormGroup = new FormBuilder().group({
    id: ['']
  });

  constructor(
    private dialog_ref: MatDialogRef<VehicleTuroDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.vehicle = data.vehicle;
  }

  ngOnInit() { 
    if (this.vehicle && this.vehicle.ext_id) {
      this.form_group.get('id').setValue(this.vehicle.ext_id);
    }
  }

  onCloseClick() {
    this.dialog_ref.close();
  }

  onConfirmClick() {
    this.dialog_ref.close({ turo_id: this.form_group.get('id').value });
  }
}
