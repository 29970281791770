import { Injectable } from '@angular/core';
import { Vehicle } from './vehicle.service';
import { Settings } from './station.service';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { User } from './user.service';

export const BOOKINGS_API_NAME: string = 'bookings';
export const BOOKINGS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/bookings/';

@Injectable({
	providedIn: 'root',
})
export class BookingService {
	createBooking(stationId: string, booking: Booking) {
		// create booking
		let apiName: string = BOOKINGS_API_NAME;
		let path: string = 'stations/' + stationId;
		let init = {
			response: true,
			body: booking,
		};
		return from(API.post(apiName, path, init));
	}

	readBooking(stationId: string, bookingId: string) {
		let apiName: string = BOOKINGS_API_NAME;
		let path: string = bookingId + '/stations/' + stationId;
		let init = { response: true };
		return from(API.get(apiName, path, init));
	}

	updateBooking(stationId: string, booking: Booking) {
		let apiName: string = BOOKINGS_API_NAME;
		let path: string = booking.id + '/stations/' + stationId;
		let init = {
			response: true,
			body: booking,
		};
		return from(API.put(apiName, path, init));
	}

	deleteBooking(stationId: string, bookingId: string) {
		let apiName: string = BOOKINGS_API_NAME;
		let path: string = bookingId + '/stations/' + stationId;
		let init = { response: true };
		return from(API.del(apiName, path, init));
	}

	listBookings(
		stationId: string,
		page?: number,
		plate?: string,
		email?: string,
		start_date?: number,
		end_date?: number,
		sort?: string,
	) {
		const BOOKINGS_PER_PAGE: number = 30;
		let apiName: string = BOOKINGS_API_NAME;
		let path: string = 'stations/' + stationId;
		let queryStringParams = {
			page: page ? page : 1,
			per_page: page ? BOOKINGS_PER_PAGE : 100000000,
		};
		if (plate) {
			queryStringParams['vehicle_plate'] = plate;
		}
		if (email) {
			queryStringParams['user_email'] = email;
		}
		if (start_date) {
			queryStringParams['start_date'] = start_date;
		}
		if (end_date) {
			queryStringParams['end_date'] = end_date;
		}
		if (sort) {
			queryStringParams['sort'] = sort;
		}
		let init = {
			response: true,
			queryStringParameters: queryStringParams,
		};
		return from(API.get(apiName, path, init));
	}

	assignVehicle(stationId: string, bookingId: string, vehicleId: string) {
		let apiName: string = BOOKINGS_API_NAME;
		let path: string =
			bookingId + '/stations/' + stationId + '/vehicles/' + vehicleId;
		let init = { response: true };
		return from(API.put(apiName, path, init));
	}

	changeDates(
		stationId: string,
		bookingId: string,
		startDate: number,
		endDate: number,
	) {
		let apiName: string = BOOKINGS_API_NAME;
		let path: string =
			bookingId +
			'/stations/' +
			stationId +
			'/start/' +
			startDate +
			'/end/' +
			endDate;
		let init = { response: true };
		return from(API.put(apiName, path, init));
	}

	sendUnlockLink(
		stationId: string,
		bookingId: string,
		vehicleId: string,
		userId: string,
	) {
		let apiName: string = BOOKINGS_API_NAME;
		let path: string =
			bookingId +
			'/stations/' +
			stationId +
			'/vehicles/' +
			vehicleId +
			'/users/' +
			userId +
			'/unlock-link/send';
		let init = { response: true };
		return from(API.post(apiName, path, init));
	}

	sendLockLink(
		stationId: string,
		bookingId: string,
		vehicleId: string,
		userId: string,
	) {
		let apiName: string = BOOKINGS_API_NAME;
		let path: string =
			bookingId +
			'/stations/' +
			stationId +
			'/vehicles/' +
			vehicleId +
			'/users/' +
			userId +
			'/lock-link/send';
		let init = { response: true };
		return from(API.post(apiName, path, init));
	}

	setUser(bookingId: string, stationId: string, userId: string) {
		let apiName: string = BOOKINGS_API_NAME;
		let path: string =
			bookingId + '/stations/' + stationId + '/users/' + userId;
		let init = { response: true };
		return from(API.put(apiName, path, init));
	}

	export(station__id: string) {
		let api_name: string = BOOKINGS_API_NAME;
		let path: string = `stations/${station__id}/export`;
		let init = {
			response: true,
			headers: {
				Accept: 'text/csv',
			},
			responseType: 'blob',
		};
		return from(API.get(api_name, path, init));
	}
}

export class Booking {
	id: string;
	ext_id: string;
	user: User;
	user_id: string;
	vehicle: Vehicle;
	vehicle_id: string;
	errors: BookingStatus[];
	start_date: number;
	end_date: number;
	timezone: string;
	settings: Settings;
	media: Media[];
	is_user_vehicle_unlocked: boolean;
	is_user_vehicle_locked: boolean;
	unlock_key_id: string;
	lock_key_id: string;
}

export class Interval {
	start_date: number;
	end_date: number;
	timezone: string;
}

export class Media {
	type: MediaType;
	link: string;
}

export enum BookingStatus {
	PENDING = 'pending',
	IN_PROGRESS = 'in_progress',
	COMPLETED = 'completed',
	EXPIRED_WO_VEHICLE = 'expired_without_vehicle',
	EXPIRED_WO_START = 'expired_without_start',
	EXPIRED_WO_STOP = 'expired_without_stop',
}

export enum MediaType {
	PRE_REPORT = 'pre_report',
	POST_REPORT = 'post_report',
}
