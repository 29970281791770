import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { STATION_PATH } from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import {
	VehiclesSummary,
	VehiclesSummaryService,
} from 'src/app/core/vehicles-summary.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';

@Component({
	selector: 'app-vehicle-summary',
	templateUrl: './vehicle-summary.component.html',
	styleUrls: ['./vehicle-summary.component.scss'],
})
export class VehicleSummaryComponent implements OnInit {
	@Input() refreshSummary: BehaviorSubject<boolean>;
	is_summary_loading: boolean = false;
	vehicles_summary: VehiclesSummary;

	constructor(
		private vehicles_summary_service: VehiclesSummaryService,
		private local_manager: LocalManagerService,
		private router: Router,
	) {}

	ngOnInit() {
		this.refreshSummary.subscribe(refresh => {
			if (refresh) {
				const station: Station =
					this.local_manager.getLocalObject(LOCAL_STATION_KEY);
				if (station && station.id) {
					this.is_summary_loading = true;
					//setTimeout(() => { this.readVehiclesSummary(station.id); }, 3000);
					this.readVehiclesSummary(station.id);
				} else {
					this.router.navigate([STATION_PATH]);
				}
			}
		});
	}

	readVehiclesSummary(stationId: string) {
		this.vehicles_summary_service.readVehiclesSummary(stationId).subscribe(
			response => {
				this.is_summary_loading = false;
				this.vehicles_summary = response.data;
			},
			error => {
				console.error(error);
				this.is_summary_loading = false;
				this.vehicles_summary = {
					total: 0,
					vehicles_in_locked: 0,
					vehicles_in_unlocked: 0,
					vehicles_in_moving: 0,
					vehicles_online: 0,
					vehicles_offline: 0,
				};
			},
		);
	}
}
