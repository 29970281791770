import { Component, Input, OnInit } from '@angular/core';
import { STATION_BUILDER_BASICS_STEP, STATION_BUILDER_CONTRACTS_STEP, STATION_BUILDER_LOGO_STEP, STATION_BUILDER_NOTIF_STEP, STATION_BUILDER_SERVICES_STEP, STATION_BUILDER_TEAM_STEP } from 'src/app/shared/translations';

@Component({
  selector: 'app-station-builder-steps',
  templateUrl: './station-builder-steps.component.html',
  styleUrls: ['./station-builder-steps.component.scss']
})
export class StationBuilderStepsComponent implements OnInit {
  @Input() currentStep: string;
  basicsStep: string = STATION_BUILDER_BASICS_STEP;
  logoStep: string = STATION_BUILDER_LOGO_STEP;
  teamStep: string = STATION_BUILDER_TEAM_STEP;
  notifStep: string = STATION_BUILDER_NOTIF_STEP;
  contractsStep: string = STATION_BUILDER_CONTRACTS_STEP;
  servicesStep: string = STATION_BUILDER_SERVICES_STEP;
  steps: string[] = [
    this.basicsStep,
    this.logoStep,
    this.teamStep,
    this.notifStep
  ];

  constructor() { }

  ngOnInit() {
  }

  isStepTextVisible(step: string) {
    return step == this.currentStep;
  }

  getBarClass(step: string) {
    return {
      'lk-accent-background':         step == this.currentStep,
      'lk-primary-medium-background': step != this.currentStep
    }
  }
}
