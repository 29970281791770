<div class="lk-spaced-row">
	<h2 class="lk-no-margin lk-half-margin-bottom">
		{{ 'STATION.NAVBAR_UNLOCK_ITEM_NAME' | translate }}
	</h2>
</div>

<div
	class="lk-double-padding lk-light-shadow lk-border-radius lk-primary-background">
	<mat-slide-toggle
		id="lk-station-settings-trigger-unlock-on-ass-toggle"
		[(ngModel)]="is_unlockable_on_vehicle_assignment"
		(change)="onUnlockableOnVehicleAssignmentChange()">
		<span style="white-space: initial">
			{{ 'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_ASSIGN' | translate }}
		</span>
		<br />
		<p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
			{{ 'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_ASSIGN_SUB' | translate }}
		</p>
	</mat-slide-toggle>

	<div class="lk-row lk-margin-top">
		<form
			[formGroup]="unlock_link_form_group"
			class="lk-row">
			<mat-form-field
				class="lk-custom-number-input"
				appearance="fill"
				color="accent">
				<input
					id="lk-station-settings-trigger-unlock-delivery-before-input"
					type="text"
					maxlength="5"
					matInput
					formControlName="time"
					(input)="onInputChange($event, unlock_link_form_group)" />
			</mat-form-field>
		</form>
		<div>
			<p
				class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-unlock-content-text">
				{{
					'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_LINK_TIME'
						| translate: unlock_link_time_translate_param
				}}
			</p>
			<p
				class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-unlock-content-text lk-primary-medium-color"
				*ngIf="unlock_link_time_translate_param.time.substring(0, 1) != '-'">
				{{
					'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_LINK_TIME_EX'
						| translate: unlock_link_time_translate_param
				}}
			</p>
		</div>
	</div>

	<mat-expansion-panel
		style="box-shadow: none"
		class="lk-half-margin-top">
		<mat-expansion-panel-header
			class="lk-station-settings-trigger-expansion-panel">
			<mat-panel-title>
				<span
					id="lk-station-settings-trigger-unlock-availability-panel"
					class="lk-primary-medium-color lk-station-settings-trigger-unlock-content-text">
					{{ 'GENERAL.ADVANCED_OPTIONS_LABEL' | translate }}
				</span>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="lk-row">
			<form
				[formGroup]="unlock_before_form_group"
				class="lk-row">
				<mat-form-field
					class="lk-custom-number-input"
					appearance="fill"
					color="accent">
					<input
						id="lk-station-settings-trigger-unlock-availability-before-input"
						type="text"
						maxlength="5"
						matInput
						formControlName="time"
						(input)="onInputChange($event, unlock_before_form_group)" />
				</mat-form-field>
			</form>
			<div>
				<p
					class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-unlock-content-text">
					{{
						'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_BEF_TIME'
							| translate: unlock_before_translate_param
					}}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-unlock-content-text lk-primary-medium-color"
					*ngIf="unlock_before_translate_param.time.substring(0, 1) != '-'">
					{{
						'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_BEF_TIME_EX'
							| translate: unlock_before_translate_param
					}}
				</p>
			</div>
		</div>
	</mat-expansion-panel>

	<p class="lk-accent-color lk-margin-top">
		{{ 'STATION.SETTINGS_TRIG_UNLOCK_AVAIL_SUBTITLE' | translate }}
	</p>
	<mat-slide-toggle
		id="lk-station-settings-trigger-unlock-till-end-toggle"
		[(ngModel)]="is_unlockable_until_booking_end"
		(change)="onUnlockableUntilBookingEndChange()">
		<span style="white-space: initial">
			{{ 'STATION.SETTINGS_TRIG_UNLOCK_TILL_END' | translate }}
		</span>
		<br />
		<p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
			{{ 'STATION.SETTINGS_TRIG_UNLOCK_TILL_END_SUB' | translate }}
		</p>
	</mat-slide-toggle>

	<div class="lk-row lk-margin-top">
		<form
			[formGroup]="unlock_after_form_group"
			class="lk-row">
			<mat-form-field
				class="lk-custom-number-input"
				appearance="fill"
				color="accent">
				<input
					id="lk-station-settings-trigger-unlock-availability-after-input"
					type="text"
					maxlength="5"
					matInput
					formControlName="time"
					(input)="onInputChange($event, unlock_after_form_group)" />
			</mat-form-field>
		</form>
		<div>
			<p
				class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-unlock-content-text">
				{{
					'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_AFT_TIME'
						| translate: unlock_after_translate_param
				}}
			</p>
			<p
				class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-unlock-content-text lk-primary-medium-color"
				*ngIf="unlock_after_translate_param.time.substring(0, 1) != '-'">
				{{
					'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_AFT_TIME_EX'
						| translate: unlock_after_translate_param
				}}
			</p>
			<p
				class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-unlock-content-text lk-primary-medium-color">
				{{ 'STATION.SETTINGS_TRIG_UNLOCK_VEHIC_AFT_TIME_DES' | translate }}
			</p>
		</div>
	</div>

	<div class="lk-full-width-lg lk-centered-row lk-margin-top">
		<button
			style="min-width: 50%"
			class="lk-main-button"
			mat-button
			[disabled]="is_request_running"
			[ngClass]="getDisabledClass()"
			(click)="onSaveClick()">
			<span *ngIf="!is_request_running">
				{{ 'GENERAL.SAVE_BUTTON_CTA' | translate }}
			</span>
			<div
				class="lk-spinner-layout"
				*ngIf="is_request_running">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
		</button>
	</div>
</div>
