<div class="lk-spaced-row lk-wrapped-flex lk-full-width">
	<div class="lk-spaced-row lk-full-width lk-hidden-after-md">
		<h2>Stations</h2>

		<button
			(click)="
				refresh.emit(); form_group.reset(); current_search_value = undefined
			"
			mat-icon-button
			class="lk-half-margin-left">
			<mat-icon> refresh </mat-icon>
		</button>
	</div>

	<h2 class="lk-hidden-before-md">Stations</h2>

	<div class="lk-row lk-full-width-md">
		<form
			[formGroup]="form_group"
			class="lk-full-width-md">
			<mat-form-field
				class="lk-full-width-md lk-hidden-stations-search-form-field"
				color="primary"
				appearance="fill">
				<mat-label> Search by name... </mat-label>
				<mat-icon
					matPrefix
					class="lk-primary-medium-color">
					search
				</mat-icon>
				<input
					matInput
					type="text"
					formControlName="name"
					(change)="
						search_change.emit($event.target.value);
						current_alias_search_value = undefined;
						current_search_value = form_group.get('name').value;
						form_group.reset()
					" />
			</mat-form-field>

			<mat-form-field
				class="lk-full-width-md lk-hidden-stations-search-form-field"
				color="primary"
				appearance="fill">
				<mat-label> Search by alias... </mat-label>
				<mat-icon
					matPrefix
					class="lk-primary-medium-color">
					search
				</mat-icon>
				<input
					matInput
					type="text"
					formControlName="alias"
					(change)="
						alias_search_change.emit($event.target.value);
						current_search_value = undefined;
						current_alias_search_value = form_group.get('alias').value;
						form_group.reset()
					" />
			</mat-form-field>
		</form>

		<button
			(click)="onResetMfaClick()"
			mat-icon-button
			matTooltip="Reset MFA"
			class="lk-half-margin-left lk-hidden-before-md">
			<mat-icon class="lk-accent-color"> sync_alt </mat-icon>
		</button>

		<button
			(click)="onAddAdminClick()"
			mat-icon-button
			matTooltip="Manage super admins"
			class="lk-hidden-before-md">
			<mat-icon class="lk-accent-color"> admin_panel_settings </mat-icon>
		</button>

		<button
			(click)="
				refresh.emit(); form_group.reset(); current_search_value = undefined
			"
			matTooltip="Refresh"
			mat-icon-button
			class="lk-hidden-before-md">
			<mat-icon> refresh </mat-icon>
		</button>
	</div>
</div>

<div
	*ngIf="
		(current_search_value && current_search_value != '') ||
		(current_alias_search_value && current_alias_search_value != '')
	"
	class="lk-full-width lk-column"
	style="align-items: flex-end">
	<hr class="lk-list-separator lk-full-width" />
	<div
		*ngIf="current_search_value && current_search_value != ''"
		class="lk-row">
		<span>
			<span class="lk-primary-medium-color"> name: </span>
			{{ current_search_value }}
		</span>
		<mat-icon
			(click)="refresh.emit(); current_search_value = undefined"
			class="lk-cursor-pointer lk-half-margin-left">
			backspace
		</mat-icon>
	</div>

	<div
		*ngIf="current_alias_search_value && current_alias_search_value != ''"
		class="lk-row">
		<span>
			<span class="lk-primary-medium-color"> alias: </span>
			{{ current_alias_search_value }}
		</span>
		<mat-icon
			(click)="refresh.emit(); current_alias_search_value = undefined"
			class="lk-cursor-pointer lk-half-margin-left">
			backspace
		</mat-icon>
	</div>
	<hr class="lk-list-separator lk-full-width" />
</div>
