import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Station } from 'src/app/core/station.service';
import { SUPPORTED_LANGUAGES } from 'src/app/shared/constants';
import { SUPPORTED_LANGUAGES_LABELS } from 'src/app/shared/translations';
import {Notification} from '../../core/notification.service';

@Component({
  selector: 'app-station-builder-notif-lang-summary',
  templateUrl: './station-builder-notif-lang-summary.component.html',
  styleUrls: ['./station-builder-notif-lang-summary.component.scss']
})
export class StationBuilderNotifLangSummaryComponent implements OnInit {
  @Input()  station: Station;
  @Input()  notifications: Notification[];
  @Input()  isClickable: boolean;
  @Output() langClick: EventEmitter<string> = new EventEmitter<string>();
  languages: string[] = SUPPORTED_LANGUAGES;

  constructor() { }

  ngOnInit() {}

  getLanguageLabel(lang: string) {
    switch (lang) {
      case SUPPORTED_LANGUAGES[0]:
        return SUPPORTED_LANGUAGES_LABELS[0];
      case SUPPORTED_LANGUAGES[1]:
        return SUPPORTED_LANGUAGES_LABELS[1];
    }
  }

  getLanguageCounter(lang: string) {
    if (this.station && this.notifications && this.notifications.length > 0) {
      return this.notifications.filter(n => {return n.lang == lang && n.content_url}).length;
    }
    return 0;
  }

  isDefaultLanguage(lang: string) {
    return this.station && this.station.settings.default_lang == lang;
  }

  isClickableClass() {
    return {
      'lk-cursor-pointer' : this.isClickable,
      ''                  : !this.isClickable
    };
  }
}
