import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';
import { FormService } from 'src/app/core/form.service';
import { UNKNOWN_FLAG } from 'src/app/shared/constants';

@Component({
	selector: 'app-auth-mfa-setup-sms',
	templateUrl: './auth-mfa-setup-sms.component.html',
	styleUrls: ['./auth-mfa-setup-sms.component.scss'],
})
export class AuthMfaSetupSmsComponent implements OnInit {
	form_group?: FormGroup;
	country_prefixes: CountryPrefix[];
	filtered_options: Observable<CountryPrefix[]>;
	current_country_prefix: CountryPrefix;
	unknown_flag_src: string = UNKNOWN_FLAG;
	is_mfa_setup_running: boolean = false;

	@Input() logo_src: string = '../../../assets/imgs/lk-logo.svg';

	constructor(
		private form_service: FormService,
		private countries: CountriesService,
	) {
		this.form_group = this.form_service.mfa_setup_form_group;
	}

	ngOnInit(): void {
		this.form_group.reset();
		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);
	}

	onCountryPrefixSelect(): void {
		const code: string = this.form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	onMfaSetupClick(): void {}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_mfa_setup_running || !this.isFormValid(),
			'lk-main-button': !this.is_mfa_setup_running && this.isFormValid(),
		};
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getCurrentPrefixFlag(): string {
		const phone_prefix: string = this.form_group.get('phone_prefix')?.value;
		let flag_path: string;
		if (phone_prefix) {
			const current_prefixes: CountryPrefix[] = this.filterPrefix(phone_prefix);
			if (current_prefixes && current_prefixes.length) {
				flag_path = current_prefixes[0]?.flag;
			}
		}
		return flag_path ? flag_path : UNKNOWN_FLAG;
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}
}
