<div 
  [ngStyle]="{ 'background-image' : img_url? 'url(' + img_url + ')' : '' }"
  id="lk-shared-single-action-dialog">
  <div class="lk-full-width lk-ended-row">
    <button
      mat-icon-button 
      (click)="onCloseClick()" 
      class="lk-primary-medium-background lk-z-index">
      <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
    </button>
  </div>

  <div 
    *ngIf="title"
    class="lk-half-margin-top lk-margin-left">
    <h2 class="lk-no-margin"> 
      {{ title | translate }} 
    </h2>
    <div style="width: 25%; height: 5px; background: black; margin-top: 6px"></div>
  </div>

  <div 
    id="lk-shared-single-action-content-layout"
    [ngStyle]="{ 'margin-top' : title && img_url? '128px' : '24px' }"
    class="lk-horiz-padding lk-margin-bottom">
    <p class="lk-no-margin"> {{ message | translate }} </p>
  </div>
</div>
