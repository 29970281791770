import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import {
	NotificationService,
	Notification,
	NotificationType,
	NotificationName,
} from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import {
	DEFAULT_LANGUAGE,
	ERROR_DESCRIPTION_500,
} from 'src/app/shared/constants';
import {
	START_RENTAL_SMS_LABEL,
	END_RENTAL_SMS_LABEL,
	START_RENTAL_EMAIL_LABEL,
	END_RENTAL_EMAIL_LABEL,
	GENERAL_ERROR_MESSAGE,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-station-add-notif-dialog',
	templateUrl: './station-add-notif-dialog.component.html',
	styleUrls: ['./station-add-notif-dialog.component.scss'],
})
export class StationAddNotifDialogComponent implements OnInit {
	station: Station;
	form_group: FormGroup;
	content_form_group: FormGroup;
	is_request_running: boolean = false;
	is_content_loading: boolean = false;
	supported_sms_temp_labels: string[] = [
		START_RENTAL_SMS_LABEL,
		END_RENTAL_SMS_LABEL,
	];
	supported_email_temp_labels: string[] = [
		START_RENTAL_EMAIL_LABEL,
		END_RENTAL_EMAIL_LABEL,
	];
	sms_type: NotificationType = NotificationType.SMS;
	email_type: NotificationType = NotificationType.EMAIL;
	notif_type: NotificationType = this.sms_type;

	constructor(
		private dialog_ref: MatDialogRef<StationAddNotifDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private form_service: FormService,
		private notif_service: NotificationService,
	) {
		this.form_group = this.form_service.addNotifDialogFormGroup;
		this.content_form_group = this.form_service.notifAddContentFormGroup;
		this.station = data.station;
	}

	ngOnInit(): void {
		this.form_group.reset();
		this.content_form_group.reset();
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.isContentFormValid()) {
			const notification: Notification = new Notification();
			switch (this.form_group.get('notif_name').value) {
				case START_RENTAL_SMS_LABEL:
					notification.name = NotificationName.BOOKING_START;
					break;
				case END_RENTAL_SMS_LABEL:
					notification.name = NotificationName.BOOKING_END;
					break;
			}

			const content = this.content_form_group.get('notif').value;
			const encoded_content = `data:text/plain;base64,${btoa(content)}`;
			const station_lang = this.station.settings.default_lang
				? this.station.settings.default_lang
				: DEFAULT_LANGUAGE;
			notification.content = encoded_content;
			notification.type = NotificationType.SMS;
			notification.lang = station_lang;
			this.addNotification(notification);
		}
	}

	onSmsInput(event: any): void {
		let file: File = event.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			const notification: Notification = new Notification();
			switch (this.form_group.get('notif_name').value) {
				case START_RENTAL_SMS_LABEL:
					notification.name = NotificationName.BOOKING_START;
					break;
				case END_RENTAL_SMS_LABEL:
					notification.name = NotificationName.BOOKING_END;
					break;
			}
			notification.content = reader.result.toString();
			notification.type = NotificationType.SMS;
			notification.lang = '';
			this.addNotification(notification);
		};
	}

	onEmailInput(event: any): void {
		let file: File = event.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			const notification: Notification = new Notification();
			switch (this.form_group.get('notif_name').value) {
				case START_RENTAL_EMAIL_LABEL:
					notification.name = NotificationName.BOOKING_START;
					break;
				case END_RENTAL_EMAIL_LABEL:
					notification.name = NotificationName.BOOKING_END;
					break;
			}
			notification.content = reader.result.toString();
			notification.type = NotificationType.EMAIL;
			notification.lang = '';
			this.addNotification(notification);
		};
	}

	addNotification(notification: Notification): void {
		this.is_request_running = true;
		this.notif_service
			.addNotification(this.station.id, notification)
			.subscribe({
				next: repsonse => {
					this.is_request_running = false;
					this.dialog_ref.close({ success: true });
				},
				error: error => {
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_ref.close({
							error_message: error.response.data.error.description,
						});
					} else {
						this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
					}
				},
			});
	}

	getContentDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button':
				!this.isContentFormValid() || this.is_content_loading,
			'lk-main-button': this.isContentFormValid() && !this.is_content_loading,
		};
	}

	getNotificationLabel(name: NotificationName, type: NotificationType): string {
		switch (name) {
			case NotificationName.BOOKING_START:
				return type == NotificationType.SMS
					? START_RENTAL_SMS_LABEL
					: START_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_END:
				return type == NotificationType.SMS
					? END_RENTAL_SMS_LABEL
					: END_RENTAL_EMAIL_LABEL;
		}
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	isContentFormValid(): boolean {
		return this.form_service.validateForm(this.content_form_group);
	}
}
