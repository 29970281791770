import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
	Booking,
	BookingService,
	BookingStatus,
} from '../../core/booking.service';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { BookingAddVehicleDialogComponent } from '../booking-add-vehicle-dialog/booking-add-vehicle-dialog.component';
import { Vehicle, VehicleStatus } from '../../core/vehicle.service';
import { zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { Station } from 'src/app/core/station.service';
import {
	BOOKING_START_DATE_SORT_ASC_OPTION,
	BOOKING_START_DATE_SORT_DESC_OPTION,
	BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
	BOOKING_TABLE_COLUMNS,
	BOOKING_TABLE_TURO_COLUMNS,
	PROD_STAGE,
	LOCAL_STATION_KEY,
	DATE_FORMATS,
	EN_LOCALE,
	IT_LOCALE,
} from 'src/app/shared/constants';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { BookingDeleteDialogComponent } from '../booking-delete-dialog/booking-delete-dialog.component';
import {
	BOOKING_COMPLETED_LABEL,
	BOOKING_ERROR_WO_START_LABEL,
	BOOKING_ERROR_WO_STOP_LABEL,
	BOOKING_ERROR_WO_VEHICLE_LABEL,
	BOOKING_IN_PROGRESS_LABEL,
	BOOKING_PENDING_LABEL,
	GENERAL_ERROR_MESSAGE,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/core/authentication.service';

@Component({
	selector: 'app-booking-table',
	templateUrl: './booking-table.component.html',
	styleUrls: ['./booking-table.component.scss'],
})
export class BookingTableComponent implements OnInit {
	@Input() bookingUpdateTrigger: BehaviorSubject<Booking[]>;
	@Output() bookingDeleteClick: EventEmitter<Booking> =
		new EventEmitter<Booking>();
	@Output() bookingClick: EventEmitter<Booking> = new EventEmitter<Booking>();
	@Output() filtersClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() sortStartDateClick: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() vehicleAddClick: EventEmitter<{
		booking: Booking;
		vehicle: Vehicle;
	}> = new EventEmitter<{ booking: Booking; vehicle: Vehicle }>();
	form_group: FormGroup;
	bookings: Booking[];
	update_time: number;
	is_link_loading: boolean = false;
	is_unlock_booking_invalid: boolean[] = [];
	is_lock_booking_invalid: boolean[] = [];
	displayed_columns: string[];
	desc_option: string = BOOKING_START_DATE_SORT_DESC_OPTION;
	asc_option: string = BOOKING_START_DATE_SORT_ASC_OPTION;
	pending_label: string = BOOKING_PENDING_LABEL;
	current_start_date_sort_option: string;
	is_production: boolean;
	current_locale: string;

	constructor(
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private auth_service: AuthenticationService,
		private booking_service: BookingService,
		private local_manager: LocalManagerService,
		private form_service: FormService,
	) {
		this.dialog_service.dialog = this.dialog;
		this.form_group = this.form_service.bookingSearchFormGroup;
		this.current_start_date_sort_option = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);

		const current_station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		switch (current_station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				break;
			default:
				this.current_locale = EN_LOCALE;
				break;
		}
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station.ext_email) {
			this.displayed_columns = BOOKING_TABLE_TURO_COLUMNS;
		} else {
			this.displayed_columns = BOOKING_TABLE_COLUMNS;
		}
		this.bookingUpdateTrigger.subscribe(bookings => {
			if (bookings) {
				this.update_time = new Date().getTime();
				this.bookings = bookings;
				this.is_unlock_booking_invalid = [];
				this.is_lock_booking_invalid = [];
				this.bookings.map(b =>
					this.is_unlock_booking_invalid.push(
						!b.settings.unlock_settings.is_link_invalid,
					),
				);
				this.bookings.map(b =>
					this.is_lock_booking_invalid.push(
						!b.settings.lock_settings.is_link_invalid,
					),
				);
			}
		});
		this.current_start_date_sort_option = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);
	}

	onAddVehicleClick(booking: Booking) {
		this.dialog_service
			.openDialog(BookingAddVehicleDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.vehicle) {
					this.vehicleAddClick.emit({
						booking: booking,
						vehicle: dialogResult.vehicle,
					});
				}
			});
	}

	onDeleteBookingClick(booking: Booking) {
		this.dialog_service
			.openDialog(BookingDeleteDialogComponent, { booking: booking })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.confirm) {
					this.bookingDeleteClick.emit(booking);
				}
			});
	}

	onSearchChange() {
		this.searchChange.emit(this.form_group.get('email').value);
	}

	onBookingClick(booking: Booking) {
		this.bookingClick.emit(booking);
	}

	onBookingUnlockLinkValidityChange(booking: Booking, index: number) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_link_loading = true;
		booking.settings.unlock_settings.is_link_invalid =
			!this.is_unlock_booking_invalid[index];
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.booking_service.updateBooking(station.id, booking).subscribe({
					next: response => {
						this.is_link_loading = false;
					},
					error: error => {
						this.is_link_loading = false;
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					},
				});
			},
			error: error => {
				this.auth_service.signOut();
			},
		});
	}

	onBookingLockLinkValidityChange(booking: Booking, index: number) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_link_loading = true;
		booking.settings.lock_settings.is_link_invalid =
			!this.is_lock_booking_invalid[index];
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.booking_service.updateBooking(station.id, booking).subscribe({
					next: response => {
						this.is_link_loading = false;
					},
					error: error => {
						this.is_link_loading = false;
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					},
				});
			},
			error: error => {
				this.auth_service.signOut();
			},
		});
	}

	onSortStartDateClick() {
		switch (this.current_start_date_sort_option) {
			case BOOKING_START_DATE_SORT_ASC_OPTION:
				this.current_start_date_sort_option =
					BOOKING_START_DATE_SORT_DESC_OPTION;
				break;
			case BOOKING_START_DATE_SORT_DESC_OPTION:
				this.current_start_date_sort_option = undefined;
				break;
			default:
				this.current_start_date_sort_option =
					BOOKING_START_DATE_SORT_ASC_OPTION;
				break;
		}
		if (this.current_start_date_sort_option) {
			localStorage.setItem(
				BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
				this.current_start_date_sort_option,
			);
		} else {
			localStorage.removeItem(BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY);
		}
		this.sortStartDateClick.emit(this.current_start_date_sort_option);
	}

	getBookingStatusLabel(booking: Booking) {
		if (!booking.errors || !booking.errors.length) {
			if (this.isPendingBooking(booking)) {
				return BOOKING_PENDING_LABEL;
			} else if (this.isCompletedBooking(booking)) {
				return BOOKING_COMPLETED_LABEL;
			} else {
				return BOOKING_IN_PROGRESS_LABEL;
			}
		} else {
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_VEHICLE)) {
				return BOOKING_ERROR_WO_VEHICLE_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_START)) {
				return BOOKING_ERROR_WO_START_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_STOP)) {
				return BOOKING_ERROR_WO_STOP_LABEL;
			}
		}
	}

	getBookingStatusClass(booking: Booking) {
		let statusLabel: string;
		switch (this.getBookingStatusLabel(booking)) {
			case BOOKING_PENDING_LABEL:
				statusLabel = BookingStatus.PENDING;
				break;
			case BOOKING_IN_PROGRESS_LABEL:
				statusLabel = BookingStatus.IN_PROGRESS;
				break;
			case BOOKING_COMPLETED_LABEL:
				statusLabel = BookingStatus.COMPLETED;
				break;
			case BOOKING_ERROR_WO_VEHICLE_LABEL:
				statusLabel = BookingStatus.EXPIRED_WO_VEHICLE;
				break;
			case BOOKING_ERROR_WO_START_LABEL:
				statusLabel = BookingStatus.EXPIRED_WO_START;
				break;
			case BOOKING_ERROR_WO_STOP_LABEL:
				statusLabel = BookingStatus.EXPIRED_WO_STOP;
				break;
			default:
				statusLabel = BookingStatus.PENDING;
				break;
		}
		return {
			'lk-orange-status': statusLabel == BookingStatus.PENDING,
			'lk-green-status': statusLabel == BookingStatus.COMPLETED,
			'lk-blue-status': statusLabel == BookingStatus.IN_PROGRESS,
			'lk-red-status':
				statusLabel == BookingStatus.EXPIRED_WO_VEHICLE ||
				statusLabel == BookingStatus.EXPIRED_WO_START ||
				statusLabel == BookingStatus.EXPIRED_WO_STOP,
		};
	}

	getTimezoneOffset(booking: Booking) {
		return (
			'GMT' +
			(getTimezoneOffset(booking.timezone) / 3600000 >= 0 ? '+' : '') +
			getTimezoneOffset(booking.timezone) / 3600000
		);
	}

	getTimezoneStartTime(booking: Booking) {
		return (
			booking.start_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getTimezoneEndTime(booking: Booking) {
		return (
			booking.end_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getToggleClass(isValid: boolean) {
		return {
			'lk-warned-toggle': !isValid,
			'': isValid,
		};
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}

	isPendingBooking(booking: Booking): boolean {
		const isUnlocked: boolean = booking.is_user_vehicle_unlocked;
		return !isUnlocked;
	}

	isCompletedBooking(booking: Booking): boolean {
		const lockAfterTime: number =
			booking.settings.lock_settings
				.time_to_lock_availability_after_booking_end;
		const currentTimezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone;
		const isInTime: boolean =
			new Date().getTime() >
			zonedTimeToUtc(
				booking.end_date + lockAfterTime,
				currentTimezone,
			).getTime();
		const isLocked: boolean = booking.is_user_vehicle_locked;
		return isInTime || isLocked;
	}

	isAddVehicleVisible(booking: Booking) {
		return this.getBookingStatusLabel(booking) == this.pending_label;
	}
}
