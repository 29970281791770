<div
  [ngClass]="getRootClass()"
  class="lk-horiz-half-padding">
  <div 
    class="lk-ended-row lk-half-margin-bottom" 
    *ngIf="!hideHeader">
    <form [formGroup]="form_group">
      <mat-form-field color="primary" appearance="fill">
        <mat-label> {{ 'VEHICLE.SEARCH_BY_PLATE_LABEL' | translate }} </mat-label>
        <mat-icon 
          matPrefix 
          class="lk-primary-medium-color"> search </mat-icon>
        <input 
          matInput 
          type="text" 
          formControlName="plate" 
          (change)="onSearchChange()">
        <mat-icon 
          matSuffix 
          class="lk-primary-medium-color lk-cursor-pointer" 
          (click)="onFiltersClick()"> 
          tune 
        </mat-icon>
      </mat-form-field>
    </form>
  </div>

  <div 
    class="lk-full-width lk-border-radius lk-padding lk-margin-bottom lk-shadow lk-cursor-pointer"
    id="lk-vehicle-list-item-layout" 
    *ngFor="let vehicle of vehicles" 
    (click)="onVehicleClick(vehicle)">
    <div class="lk-full-width lk-spaced-row">
      <p class="lk-no-margin">
        {{ vehicle.brand }} {{ vehicle.model }}
      </p>

      <div  
        class="lk-border-radius lk-primary-medium-background"
        [ngClass]="getVehicleStatusClass(vehicle)"
        style="margin-top: 4px; padding: 2px 12px; width: fit-content">
        <span *ngIf="vehicle.status">   {{ vehicle.status | lowercase }} </span>
        <span *ngIf="!vehicle.status">  unknown </span>
      </div>
    </div>

    <div>
      <p  
        class="lk-no-margin lk-small-text-size" 
        style="margin-top: 4px !important;">
        {{ vehicle.plate }} - {{ vehicle.color }}
      </p>
    </div>

    <div class="lk-spaced-row lk-margin-top lk-full-width">
      <button 
        mat-button 
        (click)="onStartVehicleClick(vehicle); $event.stopPropagation();"
        class="lk-main-button lk-vehicle-list-button lk-hidden-before-md">
        <div class="lk-centered-row">
          <mat-icon> lock_open </mat-icon>
          <p 
            class="lk-primary-color lk-ellipsized-text" 
            style="margin: 0 0 0 4px;"> 
            {{ 'VEHICLE_DETAILS.UNLOCK_VEHICLE' | translate }} 
          </p>
        </div>
      </button>

      <button 
        mat-button 
        (click)="onStartVehicleClick(vehicle); $event.stopPropagation();"
        class="lk-main-button lk-vehicle-list-button lk-hidden-after-md">
        <mat-icon *ngIf="!isVehicleUnlockInteracting(vehicle)"> lock_open </mat-icon>
        <div  
          class="lk-spinner-layout lk-vehicle-list-button-spinner" 
          *ngIf="isVehicleUnlockInteracting(vehicle)">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </button>

      <button 
        mat-button 
        (click)="onStopVehicleClick(vehicle); $event.stopPropagation();"
        class="lk-main-button lk-vehicle-list-button lk-hidden-before-md">
        <div class="lk-centered-row">
          <mat-icon> lock </mat-icon>
          <p 
            class="lk-primary-color lk-ellipsized-text" 
            style="margin: 0 0 0 4px;"> 
            {{ 'VEHICLE_DETAILS.LOCK_VEHICLE' | translate }} 
          </p>
        </div>
      </button>

      <button 
        mat-icon-button 
        (click)="onStopVehicleClick(vehicle); $event.stopPropagation();"
        class="lk-main-button lk-vehicle-list-button lk-hidden-after-md">
        <mat-icon *ngIf="!isVehicleLockInteracting(vehicle)"> lock </mat-icon>
        <div  
          class="lk-spinner-layout lk-vehicle-list-button-spinner" 
          *ngIf="isVehicleLockInteracting(vehicle)">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
</div>
