import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-hidden-stations-admin-remove-confirm-dialog',
	templateUrl: './hidden-stations-admin-remove-confirm-dialog.component.html',
	styleUrls: ['./hidden-stations-admin-remove-confirm-dialog.component.scss'],
})
export class HiddenStationsAdminRemoveConfirmDialogComponent implements OnInit {
	admin_email?: string;

	constructor(
		private dialog_ref: MatDialogRef<HiddenStationsAdminRemoveConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.admin_email = data.email;
	}

	ngOnInit(): void {}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		this.dialog_ref.close({ confirm: true });
	}
}
