<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-vehicle-manual-content-layout"
		class="lk-horiz-padding lk-hide-scrollbar">
		<h3>{{ 'VEHICLE.REQUEST_MANUAL_INFO_TITLE' | translate }}:</h3>

		<form
			[formGroup]="vehicle_request_manual_info_form_group"
			class="lk-margin-bottom lk-margin-top lk-spaced-row lk-wrapped-flex">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label>
					{{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="brand"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="model"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'VEHICLE.YEAR_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="number"
					formControlName="year"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label>
					{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="plate" />
			</mat-form-field>

			<button
				mat-button
				(click)="onAddVehicleClick()"
				[disabled]="is_send_request_running || !isValidForm()"
				[ngClass]="getVehicleDisabledClass()"
				class="lk-secondary-button lk-full-width lk-margin-top">
				<span class="lk-accent-color">
					{{ 'VEHICLE.REQUEST_MANUAL_ADD_VEHICLE_CTA' | translate }}
				</span>
			</button>
		</form>

		<p *ngIf="vehicles && vehicles.length">
			{{ 'VEHICLE.REQUEST_MANUAL_ADD_VEHICLE_DIPSATCH_LABEL' | translate }}.
		</p>

		<div
			*ngIf="vehicles && vehicles.length"
			class="lk-primary-medium-background lk-padding lk-row lk-wrapped-flex lk-border-radius">
			<div
				*ngFor="let vehicle of vehicles"
				style="padding: 4px 12px; border-radius: 45px"
				class="lk-quarter-margin lk-primary-background lk-row">
				{{ vehicleToString(vehicle, true) }}

				<mat-icon
					class="lk-half-margin-left lk-cursor-pointer"
					(click)="onRemoveVehicleClick(vehicle)">
					clear
				</mat-icon>
			</div>
		</div>

		<p
			*ngIf="vehicles && vehicles.length"
			class="lk-no-margin lk-margin-top">
			{{ 'VEHICLE.REQUEST_RESPONSE_DISCLAIMER' | translate }}
		</p>

		<button
			*ngIf="vehicles && vehicles.length"
			mat-button
			(click)="onSendClick()"
			[disabled]="is_send_request_running"
			[ngClass]="getSendDisabledClass()"
			class="lk-main-button lk-full-width lk-margin-top lk-margin-bottom">
			<mat-icon
				*ngIf="!is_send_request_running"
				class="lk-primary-color"
				matPrefix>
				directions_car
			</mat-icon>
			<span
				*ngIf="!is_send_request_running"
				class="lk-primary-color">
				{{ 'VEHICLE.REQUEST_MANUAL_SEND_CTA' | translate }}
			</span>
			<div
				class="lk-spinner-layout"
				*ngIf="is_send_request_running">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
		</button>
	</div>
</div>
