<div class="lk-spaced-row">
	<h2 class="lk-no-margin lk-half-margin-bottom">
		{{ 'STATION.NAVBAR_LOCK_ITEM_NAME' | translate }}
	</h2>
</div>

<div class="lk-double-padding lk-light-shadow lk-border-radius lk-primary-background">
	<mat-slide-toggle
		id="lk-station-settings-trigger-lock-after-unlock-toggle"
		[(ngModel)]="is_lockable_after_vehicle_unlock"
		(change)="onLockableAfterVehicleUnlockChange()">
		<span style="white-space: initial">
			{{ 'STATION.SETTINGS_TRIG_LOCK_AFTER_UNLOCK' | translate }}
		</span>
		<br />
		<p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
			{{ 'STATION.SETTINGS_TRIG_LOCK_AFTER_UNLOCK_SUB' | translate }}
		</p>
	</mat-slide-toggle>

	<div class="lk-row lk-margin-top">
		<form
			[formGroup]="lock_link_form_group"
			class="lk-row">
			<mat-form-field
				class="lk-custom-number-input"
				appearance="fill"
				color="accent">
				<input
					id="lk-station-settings-trigger-lock-delivery-input"
					type="text"
					maxlength="5"
					matInput
					formControlName="time"
					(input)="onInputChange($event, lock_link_form_group)" />
			</mat-form-field>
		</form>
		<div>
			<p class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-lock-content-text">
				{{ 'STATION.SETTINGS_TRIG_LOCK_LINK_TIME' | translate: lock_link_translate_param }}
			</p>
			<p
				class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-lock-content-text lk-primary-medium-color"
				*ngIf="lock_link_translate_param.time.substring(0, 1) != '-'">
				{{ 'STATION.SETTINGS_TRIG_LOCK_LINK_TIME_EX' | translate: lock_link_translate_param }}
			</p>
		</div>
	</div>

	<mat-expansion-panel
		style="box-shadow: none"
		class="lk-half-margin-top">
		<mat-expansion-panel-header class="lk-station-settings-trigger-expansion-panel">
			<mat-panel-title>
				<span
					id="lk-station-settings-trigger-lock-availability-panel"
					class="lk-primary-medium-color lk-station-settings-trigger-lock-content-text">
					{{ 'GENERAL.ADVANCED_OPTIONS_LABEL' | translate }}
				</span>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="lk-row">
			<form
				[formGroup]="lock_before_form_group"
				class="lk-row">
				<mat-form-field
					class="lk-custom-number-input"
					appearance="fill"
					color="accent">
					<input
						id="lk-station-settings-trigger-lock-availability-input"
						type="text"
						maxlength="5"
						matInput
						formControlName="time"
						(input)="onInputChange($event, lock_before_form_group)" />
				</mat-form-field>
			</form>
			<div>
				<p class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-lock-content-text">
					{{ 'STATION.SETTINGS_TRIG_LOCK_BEFORE_TIME' | translate: lock_before_translate_param }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-lock-content-text lk-primary-medium-color"
					*ngIf="lock_before_translate_param.time.substring(0, 1) != '-'">
					{{ 'STATION.SETTINGS_TRIG_LOCK_BEFORE_TIME_EX' | translate: lock_before_translate_param }}
				</p>
			</div>
		</div>
	</mat-expansion-panel>

	<p class="lk-accent-color lk-margin-top">
		{{ 'STATION.SETTINGS_TRIG_LOCK_AVAIL_SUBTITLE' | translate }}
	</p>
	<div class="lk-row lk-margin-top">
		<form
			[formGroup]="lock_after_form_group"
			class="lk-row">
			<mat-form-field
				class="lk-custom-number-input"
				appearance="fill"
				color="accent">
				<input
					id="lk-station-settings-trigger-lock-extra-time-input"
					type="text"
					maxlength="5"
					matInput
					formControlName="time"
					(input)="onInputChange($event, lock_after_form_group)" />
			</mat-form-field>
		</form>
		<div>
			<p class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-lock-content-text">
				{{ 'STATION.SETTINGS_TRIG_LOCK_AFTER_TIME' | translate: lock_after_translate_param }}
			</p>
			<p
				class="lk-no-margin lk-half-margin-left lk-station-settings-trigger-lock-content-text lk-primary-medium-color"
				*ngIf="lock_after_translate_param.time.substring(0, 1) != '-'">
				{{ 'STATION.SETTINGS_TRIG_LOCK_AFTER_TIME_EX' | translate: lock_after_translate_param }}
			</p>
		</div>
	</div>

	<div class="lk-full-width lk-centered-row lk-margin-top">
		<button
			style="min-width: 50%"
			class="lk-main-button"
			mat-button
			[disabled]="is_request_running"
			[ngClass]="getDisabledClass()"
			(click)="onSaveClick()">
			<span *ngIf="!is_request_running">
				{{ 'GENERAL.SAVE_BUTTON_CTA' | translate }}
			</span>
			<div
				class="lk-spinner-layout"
				*ngIf="is_request_running">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
		</button>
	</div>
</div>
