import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication.service';
import {
	METABASE_NAV_ITEM,
	NAV_ITEMS,
} from '../shared-app-toolbar/shared-app-toolbar.component';
import { NavItem } from '../shared-toolbar/shared-toolbar.component';
import { Station } from 'src/app/core/station.service';
import {
	AUTH_MFA_RESET_PATH,
	PROFILE_PATH,
} from 'src/app/core/navigation.service';

@Component({
	selector: 'app-shared-menu',
	templateUrl: './shared-menu.component.html',
	styleUrls: ['./shared-menu.component.scss'],
})
export class SharedMenuComponent implements OnInit {
	nav_items: NavItem[] = NAV_ITEMS;
	station?: Station;

	constructor(
		private auth_service: AuthenticationService,
		private router: Router,
		private dialog_ref: MatDialogRef<SharedMenuComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.station = data.station;
	}

	ngOnInit(): void {
		this.dialog_ref.updatePosition({ top: '88px' });
		if (this.station) this.setNavItems();
	}

	onSignoutClick() {
		this.auth_service.signOut();
		this.dialog_ref.close();
	}

	onNavItemClick(item: NavItem) {
		this.router.navigate([item.path]);
		this.dialog_ref.close();
	}

	onProfileclick(): void {
		this.router.navigate([PROFILE_PATH]);
		this.dialog_ref.close();
	}

	setNavItems(): void {
		if (this.station?.meta_dashboard) {
			this.nav_items = [...NAV_ITEMS, METABASE_NAV_ITEM];
		} else {
			this.nav_items = NAV_ITEMS;
		}
	}
}
