import {Injectable} from '@angular/core';
import {Address} from './core.module';
import {from} from 'rxjs';
import {API} from 'aws-amplify';
import {environment} from '../../environments/environment';

export const USERS_API_NAME: string        = 'users';
export const USERS_API_ENDPOINT: string    = environment.API_BASE_PATH + '/users/';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  createUser(stationId: string, user: User) {
    let apiName: string   = USERS_API_NAME;
    let path: string      = 'stations/' + stationId;
    let init              = {
      response:   true,
      body    :       user
    };
    return from(API.post(apiName, path, init));
  }

  readUserByEmail(email: string) {
    let apiName : string = USERS_API_NAME;
    let path    : string = 'email/' + email;
    let init             = { response : true };
    return from(API.get(apiName, path, init));
  }

  readUserByPhone(phone: string) {
    let apiName : string = USERS_API_NAME;
    let path    : string = 'phone/' + phone;
    let init             = { response : true };
    return from(API.get(apiName, path, init));
  }

  listUsers(stationId: string, email?: string) {
    let apiName: string             = USERS_API_NAME;
    let path: string                = 'stations/' + stationId;
    let queryStringParams           = {};
    if (email) {
      queryStringParams['email'] = email;
    }
    let init                        = {
      response                :  true,
      'queryStringParameters' :  queryStringParams
    };
    return from(API.get(apiName, path, init));
  }

  addUserToStation(stationId: string, userId: string) {
    let apiName: string   = USERS_API_NAME;
    let path: string      = userId + '/stations/' + stationId;
    let init              = { response:   true };
    return from(API.post(apiName, path, init));
  }

  setEmail(userId: string, newEmail: string) {
    let apiName : string    = USERS_API_NAME;
    let path    : string    = userId + '/email/' + newEmail;
    let init                = { response  : true };
    return from(API.put(apiName, path, init));
  }
}

export class User {
  id          : string;
  first_name  : string;
  last_name   : string;
  email       : string;
  phone       : { prefix: string, value: string };
  tax_code    : string;
  address     : Address;
  birth_date  : number;
}

export class Phone {
  
}