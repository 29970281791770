<div class="lk-spaced-row">
	<h2 class="lk-no-margin lk-half-margin-bottom">
		{{ 'STATION.NOTIF_SETTINGS_LABEL' | translate }}
	</h2>
</div>

<div
	class="lk-double-padding lk-light-shadow lk-border-radius lk-primary-background">
	<div class="lk-column">
		<mat-slide-toggle
			(change)="onSmsChange($event)"
			[(ngModel)]="is_sms_checked">
			{{ 'STATION_BUILDER.SMS_NOTIF_LABEL' | translate }}
			<br />
			<p
				class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label"
				style="max-width: 250px">
				{{ 'STATION_BUILDER.SMS_NOTIF_DESCRIPTION' | translate }}
			</p>
		</mat-slide-toggle>

		<mat-slide-toggle
			(change)="onEmailChange($event)"
			[(ngModel)]="is_email_checked"
			class="lk-margin-top">
			{{ 'STATION_BUILDER.EMAIL_NOTIF_LABEL' | translate }}
			<br />
			<p
				class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label"
				style="max-width: 250px">
				{{ 'STATION_BUILDER.EMAIL_NOTIF_DESCRIPTION' | translate }}
			</p>
		</mat-slide-toggle>

		<form
			[formGroup]="form_group"
			class="lk-margin-bottom lk-full-width lk-spaced-row lk-wrapped-flex">
			<div>
				<h3 class="lk-no-margin lk-margin-top">
					{{ 'STATION.SETTINGS_NOTIF_LANG_TITLE' | translate }}
				</h3>
				<p class="lk-primary-medium-color lk-no-margin">
					{{ 'STATION.SETTINGS_LANG_SUBTITLE' | translate }}
				</p>
			</div>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-top">
				<mat-label>
					{{ 'STATION.SETTINGS_NOTIF_LANG_CTA' | translate }}
				</mat-label>
				<mat-select
					formControlName="lang"
					required
					(selectionChange)="onLangSelectChange($event.value)">
					<mat-option
						*ngFor="let lang of languages"
						[value]="lang"
						class="lk-options-list-item">
						<img
							[src]="'../../../assets/imgs/lk-flag-icons/' + lang + '.png'" />
						{{ getLanguageLabel(lang) | translate }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</div>
</div>
