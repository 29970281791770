<div id="lk-booking-table-root-layout">
	<div
		id="lk-booking-table-header-layout"
		class="lk-spaced-row lk-half-margin-bottom lk-horiz-half-padding"
		style="align-items: flex-end">
		<span>
			{{ 'GENERAL.UPDATED_AT_LABEL' | translate }}:
			{{ update_time | date: 'short':'':current_locale }}
		</span>

		<div class="lk-row">
			<form [formGroup]="form_group">
				<mat-form-field
					color="primary"
					appearance="fill">
					<mat-label>
						{{ 'BOOKING.SEARCH_BY_EMAIL_LABEL' | translate }}
					</mat-label>
					<mat-icon
						matPrefix
						class="lk-primary-medium-color">
						search
					</mat-icon>
					<input
						matInput
						type="text"
						formControlName="email"
						(change)="onSearchChange()" />
					<mat-icon
						matSuffix
						class="lk-primary-medium-color lk-cursor-pointer"
						(click)="filtersClick.emit()">
						tune
					</mat-icon>
				</mat-form-field>
			</form>
		</div>
	</div>

	<div
		id="lk-booking-table-layout"
		class="lk-hide-scrollbar">
		<table
			mat-table
			[dataSource]="bookings"
			class="lk-full-width">
			<ng-container matColumnDef="id">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header lk-booking-table-header-top-left-border-radius">
					Linkey ID
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<div class="lk-row">
						{{
							booking.id.substr(0, 3) +
								booking.id.substr(booking.id.length - 8, booking.id.length)
						}}
						<img
							style="width: 50px"
							*ngIf="booking.ext_id"
							src="../../../assets/imgs/lk-turo-logo.png" />
					</div>
				</td>
			</ng-container>

			<ng-container matColumnDef="ext_id">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header lk-booking-table-header-top-left-border-radius">
					Turo ID
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<span *ngIf="booking.ext_id"> {{ booking.ext_id }} </span>
					<span *ngIf="!booking.ext_id"> - </span>
				</td>
			</ng-container>

			<ng-container matColumnDef="user_phone">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header lk-booking-table-header-top-left-border-radius">
					{{ 'BOOKING.USER_PHONE_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					+{{ booking.user.phone.prefix }} {{ booking.user.phone.value }}
				</td>
			</ng-container>

			<ng-container matColumnDef="user_name">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					{{ 'BOOKING.USER_NAME_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<span
						*ngIf="
							booking.user && booking.user.first_name && booking.user.last_name
						">
						{{ booking.user.first_name }} {{ booking.user.last_name }}
					</span>

					<span
						*ngIf="
							!booking.user ||
							!booking.user.first_name ||
							!booking.user.last_name
						">
						-
					</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="start">
				<th
					(click)="onSortStartDateClick()"
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header lk-cursor-pointer">
					<div style="display: flex; align-items: center">
						{{ 'BOOKING.START_COLUMN_LABEL' | translate }}
						<mat-icon
							class="lk-accent-color"
							*ngIf="
								current_start_date_sort_option &&
								current_start_date_sort_option == asc_option
							">
							expand_less
						</mat-icon>
						<mat-icon
							class="lk-accent-color"
							*ngIf="
								current_start_date_sort_option &&
								current_start_date_sort_option == desc_option
							">
							expand_more
						</mat-icon>
						<mat-icon *ngIf="!current_start_date_sort_option">
							swap_vert
						</mat-icon>
					</div>
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<span class="lk-ellipsized-text">
						{{
							getTimezoneStartTime(booking) | date: 'short':'':current_locale
						}}
					</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="end">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					{{ 'BOOKING.END_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<span class="lk-ellipsized-text">
						{{ getTimezoneEndTime(booking) | date: 'short':'':current_locale }}
					</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="timezone">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					Timezone
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<span class="lk-ellipsized-text">
						{{ getTimezoneOffset(booking) }}
					</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="vehicle">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					{{ 'BOOKING.VEHICLE_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<span *ngIf="booking.vehicle">
						{{ booking.vehicle.brand }} {{ booking.vehicle.model }}
					</span>
					<span *ngIf="!booking.vehicle"> - </span>
				</td>
			</ng-container>

			<ng-container matColumnDef="plate">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					{{ 'BOOKING.VEHICLE_PLATE_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<span *ngIf="booking.vehicle"> {{ booking.vehicle.plate }} </span>
					<span *ngIf="!booking.vehicle"> - </span>
				</td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					{{ 'BOOKING.STATUS_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<div class="lk-vehicle-table-online-circle">
						<div
							class="lk-border-radius"
							[ngClass]="getBookingStatusClass(booking)"
							style="padding: 2px 12px; width: fit-content">
							<span class="lk-ellipsized-text">
								{{ getBookingStatusLabel(booking) | translate }}
							</span>
						</div>
					</div>
				</td>
			</ng-container>

			<ng-container matColumnDef="vehicle_status">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					{{ 'BOOKING.VEHICLE_STATUS_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking"
					style="min-width: 80px">
					<div class="lk-vehicle-table-online-circle">
						<div
							class="lk-border-radius"
							[ngClass]="getVehicleStatusClass(booking.vehicle)"
							*ngIf="booking.vehicle && booking.vehicle.status"
							style="padding: 2px 12px; width: fit-content">
							<span> {{ booking.vehicle.status | lowercase }} </span>
						</div>
						<span *ngIf="!booking.vehicle || !booking.vehicle.status"> - </span>
					</div>
				</td>
			</ng-container>

			<ng-container matColumnDef="unlock_link_validity">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					{{ 'BOOKING.UNLOCK_LINK_VALIDITY_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking; let i = index">
					<mat-slide-toggle
						[(ngModel)]="is_unlock_booking_invalid[i]"
						[ngClass]="getToggleClass(is_unlock_booking_invalid[i])"
						(click)="$event.stopPropagation()"
						(change)="onBookingUnlockLinkValidityChange(booking, i)">
					</mat-slide-toggle>
				</td>
			</ng-container>

			<ng-container matColumnDef="lock_link_validity">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header">
					{{ 'BOOKING.LOCK_LINK_VALIDITY_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking; let i = index">
					<mat-slide-toggle
						[(ngModel)]="is_lock_booking_invalid[i]"
						[ngClass]="getToggleClass(is_lock_booking_invalid[i])"
						(click)="$event.stopPropagation()"
						(change)="onBookingLockLinkValidityChange(booking, i)">
					</mat-slide-toggle>
				</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="lk-table-header lk-booking-table-header-top-right-border-radius">
					{{ 'BOOKING.ACTIONS_COLUMN_LABEL' | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let booking">
					<div class="lk-centered-row">
						<button
							mat-icon-button
							*ngIf="isAddVehicleVisible(booking)"
							(click)="onAddVehicleClick(booking); $event.stopPropagation()"
							class="lk-background-icon"
							matTooltip="{{ 'VEHICLE.ADD_CTA' | translate }}">
							<mat-icon class="lk-accent-color"> directions_car </mat-icon>
						</button>

						<button
							mat-icon-button
							(click)="onDeleteBookingClick(booking); $event.stopPropagation()"
							class="lk-background-icon"
							style="margin-left: 4px"
							matTooltip="{{ 'BOOKING.DELETE_CTA' | translate }}">
							<mat-icon style="color: red"> delete </mat-icon>
						</button>
					</div>
				</td>
			</ng-container>

			<tr
				mat-header-row
				*matHeaderRowDef="displayed_columns"
				class="lk-booking-table-header-bottom-border-radius"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayed_columns"
				(click)="onBookingClick(row)"
				class="lk-booking-table-header-bottom-border-radius"></tr>
		</table>
	</div>

	<div
		*ngIf="is_link_loading"
		class="lk-full-width lk-full-height lk-centered-row"
		style="position: absolute; top: 0; left: 0">
		<div class="lk-overlay-spinner-layout lk-padding lk-border-radius">
			<mat-spinner
				color="accent"
				diameter="30"></mat-spinner>
		</div>
	</div>
</div>
