<div
	style="overflow-y: scroll"
	class="lk-border-radius lk-shadow lk-padding lk-full-height lk-primary-background lk-hide-scrollbar">
	<div class="lk-full-width lk-spaced-row">
		<h3 class="lk-no-margin lk-subtitle-size">
			{{ 'VEHICLE_DETAILS.GENERAL_SECTION_LABEL' | translate }}
		</h3>
		<button
			mat-icon-button
			(click)="onVehicleGeneralUpdateClick()"
			class="lk-background-icon"
			matTooltip="{{ 'VEHICLE_DETAILS.UPDATE_INFO_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> create </mat-icon>
		</button>
	</div>

	<div class="lk-half-margin-top">
		<div class="lk-double-margin-top">
			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					Linkey ID
				</p>

				<div class="lk-centered-row">
					<p
						id="lk-vehicle-details-general-id"
						class="lk-no-margin"
						*ngIf="vehicle && vehicle.brand">
						{{
							vehicle.id.substr(0, 3) +
								vehicle.id.substr(vehicle.id.length - 8, vehicle.id.length)
						}}
					</p>

					<div
						class="lk-vehicle-details-general-online-circle lk-half-margin-left"
						[ngClass]="getOnlineClass(vehicle)"></div>
				</div>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					Adapter ID
				</p>
				<p
					id="lk-vehicle-details-general-adapter-id"
					class="lk-no-margin lk-right-text"
					*ngIf="vehicle && vehicle.adapter && vehicle.adapter.id">
					{{ vehicle.adapter.id }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="vehicle && (!vehicle.adapter || !vehicle.adapter.id)">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.EXT_ID_LABEL' | translate }}
				</p>
				<p
					id="lk-vehicle-details-general-turo-id"
					class="lk-no-margin"
					*ngIf="vehicle && vehicle.ext_id">
					{{ vehicle.ext_id }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="vehicle && !vehicle.ext_id">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }}
				</p>
				<p
					id="lk-vehicle-details-general-plate"
					class="lk-no-margin"
					*ngIf="vehicle && vehicle.plate">
					{{ vehicle.plate }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }}
				</p>
				<p
					id="lk-vehicle-details-general-brand"
					class="lk-no-margin"
					*ngIf="vehicle && vehicle.brand">
					{{ vehicle.brand }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate }}
				</p>
				<p
					id="lk-vehicle-details-general-model"
					class="lk-no-margin"
					*ngIf="vehicle && vehicle.model">
					{{ vehicle.model }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.YEAR_COLUMN_LABEL' | translate }}
				</p>
				<p
					id="lk-vehicle-details-general-year"
					class="lk-no-margin"
					*ngIf="vehicle && vehicle.year">
					{{ vehicle.year }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate }}
				</p>
				<p
					id="lk-vehicle-details-general-category"
					class="lk-no-margin"
					*ngIf="vehicle && vehicle.category">
					{{ vehicle.category }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.COLOR_COLUMN_LABEL' | translate }}
				</p>
				<p
					id="lk-vehicle-details-general-color"
					class="lk-no-margin"
					*ngIf="vehicle && vehicle.color">
					{{ vehicle.color }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div
				class="lk-spaced-row lk-full-width"
				*ngIf="vehicle && vehicle.adapter && vehicle.adapter.qrcode"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.REF_CODE_COLUMN_LABEL' | translate }}
				</p>
				<p class="lk-no-margin">{{ vehicle.adapter.qrcode }}</p>
			</div>
			<hr
				class="lk-list-separator"
				*ngIf="vehicle && vehicle.adapter && vehicle.adapter.qrcode" />

			<div
				class="lk-spaced-row lk-full-width"
				*ngIf="vehicle && vehicle.adapter && vehicle.adapter.profile"
				style="align-items: baseline">
				<p class="lk-no-margin lk-primary-medium-color lk-small-text-size">
					{{ 'VEHICLE.PROFILE_COLUMN_LABEL' | translate }}
				</p>
				<p class="lk-no-margin">{{ vehicle.adapter.profile.substr(0, 8) }}</p>
			</div>
			<hr
				class="lk-list-separator"
				*ngIf="vehicle && vehicle.adapter && vehicle.adapter.profile" />
		</div>
	</div>
</div>
