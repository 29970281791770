import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-shared-interaction-error-dialog',
  templateUrl: './shared-interaction-error-dialog.component.html',
  styleUrls: ['./shared-interaction-error-dialog.component.scss']
})
export class SharedInteractionErrorDialogComponent implements OnInit {
  message: string;

  constructor(
    private dialog_ref: MatDialogRef<SharedInteractionErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.message = data.message;
  }

  ngOnInit(): void {
  }

  onCloseClick() {
    this.dialog_ref.close();
  }
}
