import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileRoutingModule } from './profile-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';

import { ProfileComponent } from './profile/profile.component';
import { ProfileAdminInfoComponent } from './profile-admin-info/profile-admin-info.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { ProfileSettingsMfaComponent } from './profile-settings-mfa/profile-settings-mfa.component';

@NgModule({
	declarations: [ProfileComponent, ProfileAdminInfoComponent, ProfileSettingsComponent, ProfileSettingsMfaComponent],
	imports: [
		CommonModule,
		ProfileRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatAutocompleteModule,
		MatRadioModule,
		TranslateModule,
		HttpClientModule,
		SharedModule,
	],
})
export class ProfileModule {}
