import {
	Notification,
	NotificationName,
	NotificationType,
} from 'src/app/core/notification.service';

/** I18N **/
export const DEFAULT_LANGUAGE: string = 'en';
export const SUPPORTED_LANGUAGES: string[] = ['it', 'en', 'es', 'fr'];
export const DEFAULT_PHONE_PREFIX: string = '39';
export const IT_LOCALE: string = 'it-IT';
export const EN_LOCALE: string = 'en-US';
export const UNKNOWN_FLAG: string = '../../../assets/imgs/lk-flags/unknown.svg';
export const IT_FLAG: string = '../../../assets/imgs/lk-flags/IT.svg';
export const EN_FLAG: string = '../../../assets/imgs/lk-flags/GB.svg';
export const ES_FLAG: string = '../../../assets/imgs/lk-flags/ES.svg';

export const DEFAULT_START_SMS_TEMPLATE_IT: string =
	'/sms-templates/vehicle-unlock/it.txt';
export const DEFAULT_START_SMS_TEMPLATE_EN: string =
	'/sms-templates/vehicle-unlock/en.txt';
export const DEFAULT_START_SMS_TEMPLATE_ES: string =
	'/sms-templates/vehicle-unlock/es.txt';
export const DEFAULT_START_SMS_TEMPLATE_FR: string =
	'/sms-templates/vehicle-unlock/fr.txt';

export const DEFAULT_END_SMS_TEMPLATE_IT: string =
	'/sms-templates/vehicle-lock/it.txt';
export const DEFAULT_END_SMS_TEMPLATE_EN: string =
	'/sms-templates/vehicle-lock/en.txt';
export const DEFAULT_END_SMS_TEMPLATE_ES: string =
	'/sms-templates/vehicle-lock/es.txt';
export const DEFAULT_END_SMS_TEMPLATE_FR: string =
	'/sms-templates/vehicle-lock/fr.txt';

export const DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_IT: string =
	'/sms-templates/vehicle-changed/it.txt';
export const DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_EN: string =
	'/sms-templates/vehicle-changed/en.txt';
export const DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_ES: string =
	'/sms-templates/vehicle-changed/es.txt';
export const DEFAULT_BOOKING_VEHICLE_CHANGED_SMS_TEMPLATE_FR: string =
	'/sms-templates/vehicle-changed/fr.txt';

export const DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_IT: string =
	'/sms-templates/booking-completed/it.txt';
export const DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_EN: string =
	'/sms-templates/booking-completed/en.txt';
export const DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_ES: string =
	'/sms-templates/booking-completed/es.txt';
export const DEFAULT_BOOKING_COMPLETE_SMS_TEMPLATE_FR: string =
	'/sms-templates/booking-completed/fr.txt';

export const DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_IT: string =
	'/sms-templates/vehicle-direct-link/it.txt';
export const DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_EN: string =
	'/sms-templates/vehicle-direct-link/en.txt';
export const DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_ES: string =
	'/sms-templates/vehicle-direct-link/es.txt';
export const DEFAULT_VEHICLE_DIRECT_LINK_SMS_TEMPLATE_FR: string =
	'/sms-templates/vehicle-direct-link/fr.txt';

/** STATION BUILDER **/
export const STATION_BUILDER_LOCAL_STATION_KEY: string = 'WIP_STATION';
export const STATION_BUILDER_LOCAL_TEAM_KEY: string = 'WIP_TEAM';
export const STATION_BUILDER_LOCAL_NOTIF_KEY: string = 'WIP_NOTIF';
export const STATION_BUILDER_BASICS_STEP_LABEL: string = 'basics';
export const STATION_BUILDER_LOGO_STEP_LABEL: string = 'logo';
export const STATION_BUILDER_TEAM_STEP_LABEL: string = 'team';
export const STATION_BUILDER_NOTIF_STEP_LABEL: string = 'notif';
export const STATION_BUILDER_NOTIF_LANG_STEP_LABEL: string = 'notif_lang';

/** STATION **/
export const LOCAL_STATION_KEY: string = 'STATION';
export const STATION_LOCAL_TEAM_KEY: string = 'TEAM';
export const STATION_LOCAL_NOTIF_KEY: string = 'NOTIF';
export const DEFAULT_STATION_LOGO: string =
	'../../../assets/imgs/lk-logo-placeholder.png';
export const CUSTOM_AUTH_LOGO_PATH = (
	stage: string,
	domain: string,
): string => {
	return `https://linkey-assets-bucket-${stage}.s3.amazonaws.com/white-label/${domain}.svg`;
};
export const CUSTOM_AUTH_LOGO_BACKGROUND_PATH = (
	stage: string,
	domain: string,
): string => {
	return `https://linkey-assets-bucket-${stage}.s3.amazonaws.com/white-label/${domain}-background.svg`;
};

export const CUSTOM_FAVICON_PATH = (stage: string, domain: string): string => {
	return `https://linkey-assets-bucket-${stage}.s3.amazonaws.com/white-label/${domain}-favicon.ico`;
};

/** MAP **/
export const MAP_DEFAULT_ZOOM: number = 5;
export const MAP_MAX_ZOOM: number = 20;
export const MAP_DEFAULT_BASE_POINT: number[] = [41.880848, 12.502913];
export const MAP_DEFAULT_URL: string =
	'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=';
export const MAP_SATELLITE_URL: string =
	'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=';
export const MAPBOX_API_KEY: string =
	'pk.eyJ1IjoibWFyY29yZXZvdHJlZSIsImEiOiJjazM4em94MGcwYW5vM2J0OWo2MThpdjZiIn0.Bt1rv4oXsFuT1duA98sEyw';
export const MAP_CUSTOM_MARKER_ICON = {
	iconUrl: '../../../assets/imgs/lk-vehicle-icons/lk-vehicle-medium.png',
	iconSize: [40, 42],
	shadowSize: [40, 42],
	iconAnchor: [20, 42],
	shadowAnchor: [20, 42],
	popupAnchor: [0, -42],
};
export const STREET_LAYER_TYPE: string = 'street_layer_type';
export const SATELLITE_LAYER_TYPE: string = 'satellite_layer_type';

/** API ERROR CODES **/
export const STATION_NOT_FOUND_ERROR_CODE: string = 'StationNotFound';
export const VEHICLE_NOT_FOUND_ERROR_CODE: string = 'VehicleNotFound';
export const BOOKING_NOT_FOUND_ERROR_CODE: string = 'BookingNotFound';
export const USER_NOT_FOUND_ERROR_CODE: string = 'UserNotFound';
export const VEHICLE_ALREADY_RUNNING_ERROR_CODE: string =
	'VehicleIsAlreadyRunning';
export const VEHICLE_ALREADY_FREE_ERROR_CODE: string = 'VehicleIsAlreadyFree';
export const USER_STATION_CONFLICT_ERROR_CODE: string =
	'StationAlreadyAssociated';
export const ADAPTER_CRED_NOT_FOUND_ERROR_CODE: string =
	'AdapterSyncServicesNotFound';
export const BOOKING_MEDIA_NOT_FOUND_ERROR_CODE: string =
	'BookingMediaNotFound';

export const PROGRESS_UPDATE_ERROR_CODE: string =
	'UnableToChangeStartDateOnBookingInRunning';
export const COMPLETED_UPDATE_ERROR_CODE: string =
	'UnableToChangeDatesOnBookingCompleted';
export const ERRORS_UPDATE_ERROR_CODE: string =
	'UnableToChangeDatesOnBookingError';
export const VEHICLE_ALREADY_ASSIGNED_ERROR_CODE: string =
	'BookingVehicleAlreadyAssigned';

export const ADAPTER_START_FAILED_ERROR_CODE: string = 'AdapterSyncStartFailed';
export const ADAPTER_STOP_FAILED_ERROR_CODE: string = 'AdapterSyncStopFailed';
export const ADAPTER_FAILED_ERROR_CODE: string =
	'UnableToInteractWithUnexpectedStateVehicle';
export const ADAPTER_OFFLINE_ERROR_CODE: string =
	'UnableToInteractWithOfflineVehicle';
export const ADAPTER_UNKNOWN_ERROR_CODE: string =
	'UnableToInteractForUnknownReason';
export const CODE_MISMATCH_ERROR_CODE: string = 'CodeMismatchException';

export const ERROR_DESCRIPTION_500: string = 'Sorry...';

export const AUTH_MFA_SETUP_ERROR_CODE: string = 'MFAMethodNotFoundException';

export const DIRECT_LINK_NOT_FOUND_ERROR_CODE: string = 'DirectLinkNotFound';

/** STAGES **/
export const PROD_STAGE: string = 'prod';
export const DEV_STAGE: string = 'dev';

/** S3 DEFAULT ACCESS LEVEL **/
export const S3_DEFAULT_ACCESS_LEVEL: string = 'public';

/** TIME **/
export const HOURS_OF_THE_DAY: string[] = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
];

export const MINUTES_OF_THE_HOUR: string[] = [
	'00',
	'05',
	'10',
	'15',
	'20',
	'25',
	'30',
	'35',
	'40',
	'45',
	'50',
	'55',
];

export const TIMEZONES: string[] = [
	'Pacific/Honolulu',
	'America/Anchorage',
	'America/Los_Angeles',
	'America/Tijuana',
	'America/Vancouver',
	'America/Phoenix',
	'America/Chihuahua',
	'America/Costa_Rica',
	'Pacific/Galapagos',
	'America/Chicago',
	'America/Mexico_City',
	'America/Bogota',
	'America/Cancun',
	'America/Montreal',
	'America/New_York',
	'America/Caracas',
	'America/Puerto_Rico',
	'America/Santiago',
	'America/Argentina/Buenos_Aires',
	'America/Argentina/Salta',
	'America/Fortaleza',
	'America/Sao_Paulo',
	'America/Montevideo',
	'America/Noronha',
	'Atlantic/Cape_Verde',
	'GMT',
	'Europe/London',
	'Europe/Berlin',
	'Europe/Paris',
	'Europe/Rome',
	'Europe/Madrid',
	'Europe/Vienna',
	'Africa/Cairo',
	'Africa/Johannesburg',
	'Asia/Amman',
	'Asia/Beirut',
	'Europe/Athens',
	'Europe/Bucharest',
	'Africa/Asmara',
	'Africa/Nairobi',
	'Asia/Baghdad',
	'Asia/Istanbul',
	'Asia/Riyadh',
	'Europe/Moscow',
	'Asia/Dubai',
	'Indian/Mauritius',
	'Asia/Ashgabat',
	'Asia/Samarkand',
	'Indian/Maldives',
	'Asia/Yekaterinburg',
	'Asia/Dhaka',
	'Indian/Chagos',
	'Asia/Bangkok',
	'Asia/Jakarta',
	'Asia/Hong_Kong',
	'Asia/Kuala_Lumpur',
	'Asia/Macau',
	'Asia/Manila',
	'Asia/Shanghai',
	'Asia/Singapore',
	'Australia/Perth',
	'Asia/Pyongyang',
	'Asia/Seoul',
	'Asia/Tokyo',
	'Asia/Vladivostok',
	'Australia/Brisbane',
	'Australia/Canberra',
	'Australia/Sydney',
	'Asia/Magadan',
	'Asia/Kamchatka',
];

export const DATE_FORMATS: string[] = [
	'MM/dd/yy hh:mm a',
	'dd/MM/yy HH:mm',
	'dd/MM/yy hh:mm a',
];
export const DATE_FORMAT_LABELS: string[] = [
	'MM/dd/yy 12h',
	'dd/MM/yy 24h',
	'dd/MM/yy 12h',
];

/** BOOKING SORT OPTIONS **/
export const BOOKING_START_DATE_SORT_ASC_OPTION: string = 'start_date asc';
export const BOOKING_START_DATE_SORT_DESC_OPTION: string = 'start_date desc';
export const BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY: string =
	'BOOKING_START_DATE_SORT_OPTION';

/** MEASUREMENT UNITS **/
export const KM_LABEL: string = 'Km';
export const MILES_LABEL: string = 'mi';
export const KM_TO_MILES_COEFF: number = 0.621371;
export const LITERS_LABEL: string = 'L';
export const PERCENTAGE_LABEL: string = '%';

/** TABLE COLUMNS **/
export const VEHICLE_TABLE_COLUMNS: string[] = [
	'online',
	'status',
	'plate',
	'category',
	'brand',
	'model',
	'color',
	'year',
	'odometer',
	'fuel',
	'battery',
	'actions',
];
export const VEHICLE_TABLE_TURO_COLUMNS: string[] = [
	'online',
	'ext_id',
	'status',
	'plate',
	'category',
	'brand',
	'model',
	'color',
	'year',
	'odometer',
	'fuel',
	'battery',
	'actions',
];
export const BOOKING_TABLE_COLUMNS: string[] = [
	'user_phone',
	'user_name',
	'status',
	'start',
	'end',
	'timezone',
	'plate',
	'vehicle',
	'actions',
];
export const BOOKING_TABLE_TURO_COLUMNS: string[] = [
	'ext_id',
	'user_phone',
	'user_name',
	'status',
	'start',
	'end',
	'timezone',
	'plate',
	'vehicle',
	'actions',
];

/** STATION NAVBAR **/
export const STATION_NAVBAR_TEAM_ITEM_NAME: string = 'team';
export const STATION_NAVBAR_NOTIF_ITEM_NAME: string = 'notif';
export const STATION_NAVBAR_PICS_ITEM_NAME: string = 'pics';
export const STATION_NAVBAR_UNLOCK_ITEM_NAME: string = 'unlock';
export const STATION_NAVBAR_LOCK_ITEM_NAME: string = 'lock';
export const STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME: string = 'data_settings';

/** BOOKING DETAILS NAVBAR **/
export const BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME: string = 'general';
export const BOOKING_DETAILS_NAVBAR_LINKS_ITEM_NAME: string = 'links';
export const BOOKING_DETAILS_NAVBAR_EVENTS_ITEM_NAME: string = 'events';
export const BOOKING_DETAILS_NAVBAR_GALLERY_ITEM_NAME: string = 'gallery';
export const BOOKING_DETAILS_NAVBAR_DATA_ITEM_NAME: string = 'data';
export const BOOKING_DETAILS_NAVBAR_PATH_ITEM_NAME: string = 'path';

/** VEHICLE DETAILS NAVBAR **/
export const VEHICLE_DETAILS_NAVBAR_GENERAL_ITEM_NAME: string = 'general';
export const VEHICLE_DETAILS_NAVBAR_COMMANDS_ITEM_NAME: string = 'commands';
export const VEHICLE_DETAILS_NAVBAR_LINKS_ITEM_NAME: string = 'links';
export const VEHICLE_DETAILS_NAVBAR_MEASURES_ITEM_NAME: string = 'measures';
export const VEHICLE_DETAILS_NAVBAR_GALLERY_ITEM_NAME: string = 'gallery';

/** UTILS **/
export const ACCENT_EVENT_TYPES: string[] = [
	'unlock_command.succeeded',
	'lock_command.succeeded',
];

export const BOOKING_EVENTS_WHITELIST: string[] = [
	'booking.created',
	'booking.vehicle_assigned',
	'unlock_key.sent',
	'unlock_key.visited',
	'lock_key.sent',
	'lock_key.visited',
	'unlock_command.succeeded',
	'unlock_command.failed',
	'lock_command.succeeded',
	'lock_command.failed',
];

export const DEFAULT_TEMPLATES: Notification[] = [
	{
		name: NotificationName.BOOKING_START,
		type: NotificationType.SMS,
	},
	{
		name: NotificationName.BOOKING_END,
		type: NotificationType.SMS,
	},
	{
		name: NotificationName.QUICK_LINK,
		type: NotificationType.SMS,
	},
	{
		name: NotificationName.BOOKING_START,
		type: NotificationType.EMAIL,
	},
	{
		name: NotificationName.BOOKING_END,
		type: NotificationType.EMAIL,
	},
];

export const NEW_USER_FLOW: string = 'new';
export const REGISTERED_USER_FLOW: string = 'registered';

export const SOFTWARE_TOKEN_MFA:
	| 'TOTP'
	| 'SMS'
	| 'NOMFA'
	| 'SMS_MFA'
	| 'SOFTWARE_TOKEN_MFA' = 'SOFTWARE_TOKEN_MFA';

export const SMS_MFA:
	| 'TOTP'
	| 'SMS'
	| 'NOMFA'
	| 'SMS_MFA'
	| 'SOFTWARE_TOKEN_MFA' = 'SMS_MFA';
