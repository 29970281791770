import { Component, OnInit } from '@angular/core';
import { FormService } from '../../core/form.service';
import { FormGroup } from '@angular/forms';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthForgotPasswordDialogComponent } from '../auth-forgot-password-dialog/auth-forgot-password-dialog.component';
import { AuthForgotPasswordConfirmDialogComponent } from '../auth-forgot-password-confirm-dialog/auth-forgot-password-confirm-dialog.component';
import { PASSWORD_RESET_SUCCESS_MESSAGE } from '../../shared/translations';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../core/authentication.service';
import {
	AUTH_MFA_SETUP_PATH,
	AUTH_MFA_VERIFY_PATH,
	AUTH_SIGNUP_PATH,
	VEHICLES_PATH,
} from 'src/app/core/navigation.service';
import {
	AUTH_MFA_SETUP_ERROR_CODE,
	CUSTOM_AUTH_LOGO_BACKGROUND_PATH,
	CUSTOM_AUTH_LOGO_PATH,
} from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-auth-signin',
	templateUrl: './auth-signin.component.html',
	styleUrls: ['./auth-signin.component.scss'],
})
export class AuthSigninComponent implements OnInit {
	form_group: FormGroup;
	is_password_visible: boolean = false;
	is_signin_running: boolean = false;
	is_force_setup_enabled?: boolean;

	logo_src: string = '../../../assets/imgs/lk-logo.svg';
	background_src: string =
		'../../../assets/imgs/lk-backgrounds/lk-default-auth-background.svg';

	constructor(
		private form_service: FormService,
		private router: Router,
		private route: ActivatedRoute,
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		/*this.auth_service.isAuthenticated().subscribe({
			next: () => {
				this.router.navigate([STATION_PATH]);
			},
			error: () => {},
		});*/

		this.route.queryParams.subscribe(params => {
			this.is_force_setup_enabled = params['force_setup'];
		});

		this.form_group = form_service.signinFormGroup;
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.form_group.reset();

		const base_path = location.origin
			.replace('http://', '')
			.replace('https://', '');

		const dot_index = base_path.indexOf('.');
		const double_dot_index = base_path.indexOf(':');
		let subdomain: string;

		if (dot_index != -1) {
			subdomain = base_path.substring(0, dot_index);
		} else if (double_dot_index != -1) {
			subdomain = base_path.substring(0, double_dot_index);
		}

		console.log(subdomain);

		if (subdomain && subdomain != 'manage') {
			this.logo_src = CUSTOM_AUTH_LOGO_PATH(environment.STAGE, subdomain);
			this.background_src = CUSTOM_AUTH_LOGO_BACKGROUND_PATH(
				environment.STAGE,
				subdomain,
			);
		}
	}

	onSigninClick(): void {
		if (this.form_service.validateForm(this.form_group)) {
			const username: string = this.form_group.get('username').value;
			const password: string = this.form_group.get('password').value;
			this.auth_service.pending_cognito_email = username;
			this.is_signin_running = true;
			this.auth_service.signIn(username, password).subscribe({
				next: response => {
					if (response.challengeName) {
						this.auth_service.pending_cognito_user = response;
						this.manageCognitoChallange(response.challengeName);
					} else {
						this.router.navigate([VEHICLES_PATH]);
						this.is_signin_running = false;
					}
				},
				error: error => {
					this.is_signin_running = false;
					if (error.code == AUTH_MFA_SETUP_ERROR_CODE) {
						this.auth_service.mfa_setup_mechanism = 'sms';
						this.router.navigate([AUTH_MFA_SETUP_PATH]);
					} else {
						this.dialog_service.openInfoDialog(error.message);
					}
				},
			});
		}
	}

	onSignupClick(): void {
		this.router.navigate([AUTH_SIGNUP_PATH]);
	}

	onForgotPssClick(): void {
		this.dialog_service
			.openDialog(AuthForgotPasswordDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.success) {
					this.onForgotPssRequestSent(dialogResult.email);
				} else if (dialogResult && !dialogResult.success) {
					this.dialog_service.openInfoDialog(dialogResult.error);
				}
			});
	}

	onForgotPssRequestSent(email: string): void {
		this.dialog_service
			.openDialog(AuthForgotPasswordConfirmDialogComponent, { email: email })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.success) {
					this.dialog_service.openInfoDialog(PASSWORD_RESET_SUCCESS_MESSAGE);
				} else if (dialogResult && !dialogResult.success) {
					this.dialog_service.openInfoDialog(dialogResult.error);
				}
			});
	}

	manageCognitoChallange(challenge_name: string): void {
		switch (challenge_name) {
			case 'MFA_SETUP':
				this.setupTOTP();
				break;
			case 'SOFTWARE_TOKEN_MFA':
				this.auth_service.mfa_setup_mechanism = 'totp';
				this.router.navigate([AUTH_MFA_VERIFY_PATH]);
				this.is_signin_running = false;
				break;
			case 'SMS_MFA':
				this.auth_service.mfa_setup_mechanism = 'sms';
				this.router.navigate([AUTH_MFA_VERIFY_PATH]);
				this.is_signin_running = false;
				break;
			default:
				this.dialog_service.openInfoDialog(
					`Unknown challenge: ${challenge_name}`,
				);
				this.is_signin_running = false;
				break;
		}
	}

	setupTOTP(): void {
		this.is_signin_running = true;
		this.auth_service.setupTOTP().subscribe({
			next: response => {
				this.auth_service.mfa_setup_mechanism = 'totp';
				this.auth_service.totp_setup_secret = response;
				this.router.navigate([AUTH_MFA_SETUP_PATH]);
				this.is_signin_running = false;
			},
			error: error => {
				this.dialog_service.openInfoDialog(error.message);
				this.is_signin_running = false;
			},
		});
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_signin_running,
			'lk-main-button': !this.is_signin_running,
		};
	}
}
