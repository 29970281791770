<div style="min-width: 350px; max-height: 500px">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-horiz-padding lk-margin-bottom">
		<h2>Super Admins</h2>

		<hr class="lk-list-separator" />
		<div
			*ngIf="!is_list_running"
			class="lk-full-width"
			style="max-height: 200px; overflow-y: scroll">
			<div
				*ngFor="let admin of super_admins"
				class="lk-spaced-row">
				{{ admin.email }}
				<button
					mat-icon-button
					(click)="onRemoveClick(admin)">
					<mat-icon>cancel</mat-icon>
				</button>
			</div>

			<p
				*ngIf="!super_admins.length"
				class="lk-centered-text lk-no-margin">
				No super admin found
			</p>
		</div>

		<div
			*ngIf="is_list_running"
			class="lk-full-width lk-centered-row">
			<mat-spinner
				[diameter]="20"
				color="accent"></mat-spinner>
		</div>
		<hr class="lk-list-separator" />

		<form
			[formGroup]="form_group"
			class="lk-margin-top">
			<mat-form-field
				appearance="fill"
				class="lk-full-width"
				color="accent">
				<mat-label> Email </mat-label>
				<input
					type="text"
					matInput
					formControlName="email" />
			</mat-form-field>

			<div class="lk-full-width lk-ended-row lk-margin-top">
				<button
					class="lk-main-button"
					mat-button
					[disabled]="is_save_running"
					[ngClass]="getDisabledClass()"
					(click)="onConfirmClick()">
					<span *ngIf="!is_save_running">
						{{ 'Add super admin' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_save_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
