<div>
  <div class="lk-ended-row">
    <button 
      mat-icon-button 
      (click)="onCloseClick()" 
      class="lk-primary-medium-background">
      <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
    </button>
  </div>
  <div 
    id="lk-vehicle-filters-content-layout" 
    class="lk-horiz-padding lk-hide-scrollbar">
    <form 
      class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-bottom"
      [formGroup]="form_group">
      <h3 class="lk-full-width lk-no-margin lk-half-margin-bottom"> 
        {{ 'VEHICLE.FILTER_TITLE' | translate }} 
      </h3>

      <mat-form-field 
        appearance="fill" 
        color="accent" 
        class="lk-third-width lk-half-margin-bottom">
        <mat-label> {{ 'VEHICLE.CURRENT_FILTER_LABEL' | translate }} </mat-label>
        <mat-select formControlName="current">
          <mat-option value="plate">    {{ 'VEHICLE.PLATE_COLUMN_LABEL'     | translate }} </mat-option>
          <mat-option value="category"> {{ 'VEHICLE.CATEGORY_COLUMN_LABEL'  | translate }} </mat-option>
          <mat-option value="brand">    {{ 'VEHICLE.BRAND_COLUMN_LABEL'     | translate }} </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field 
        appearance="fill" 
        color="accent" 
        *ngIf="isPlateVisible()"
        class="lk-three-third-width lk-half-margin-bottom">
        <mat-label> {{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }} </mat-label>
        <input 
          matInput 
          type="text" 
          formControlName="plate">
      </mat-form-field>

      <mat-form-field 
        appearance="fill" 
        color="accent" 
        *ngIf="isCategoryVisible()"
        class="lk-three-third-width lk-half-margin-bottom">
        <mat-label> {{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate }} </mat-label>
        <input 
          matInput 
          type="text" 
          formControlName="category">
      </mat-form-field>

      <mat-form-field 
        appearance="fill" 
        color="accent" 
        *ngIf="isBrandVisible()"
        class="lk-three-third-width lk-half-margin-bottom">
        <mat-label> {{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }} </mat-label>
        <input matInput type="text" formControlName="brand">
      </mat-form-field>

      <h3 class="lk-full-width lk-half-margin-bottom"> 
        {{ 'VEHICLE.FILTER_SUBTITLE' | translate }} 
      </h3>

      <mat-radio-group 
        formControlName="status" 
        class="lk-column">
        <mat-radio-button 
          *ngFor="let status of states" 
          [value]="status" 
          style="padding: 4px 0"> 
          {{ status }} 
        </mat-radio-button>
      </mat-radio-group>

      <div class="lk-full-width lk-ended-row">
        <span 
          class="lk-cursor-pointer" 
          (click)="onFiltersResetClick()">
          <u> {{ 'GENERAL.DELETE_FILTERS_CTA' | translate }} </u>
        </span>
      </div>

      <div class="lk-centered-row lk-full-width lk-margin-top">
        <button 
          mat-button 
          class="lk-main-button" 
          (click)="onConfirmClick()">
          <span> {{ 'GENERAL.CONFIRM_CTA' | translate }} </span>
        </button>
      </div>
    </form>
  </div>
</div>
