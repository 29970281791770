import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SUBSCRIPTION_INFO_PATH } from 'src/app/core/navigation.service';

@Component({
	selector: 'app-subscription-payment',
	templateUrl: './subscription-payment.component.html',
	styleUrls: ['./subscription-payment.component.scss'],
})
export class SubscriptionPaymentComponent implements OnInit {
	params?: Params;

	constructor(private router: Router, private route: ActivatedRoute) {
		this.route.queryParams.subscribe(params => {
			this.params = params;
		});
	}

	ngOnInit(): void {}

	onPaymentClickClick(): void {
		this.router.navigate([SUBSCRIPTION_INFO_PATH], {
			queryParams: this.params,
		});
	}
}
