<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-vehicle-details-delete-content-layout"
		class="lk-horiz-padding">
		<p>{{ 'STATION.DELETE_CONFIRM_MESSAGE' | translate }}</p>
	</div>

	<div class="lk-full-width lk-centered-row">
		<button
			mat-button
			[disabled]="is_station_deleting"
			[ngClass]="getDisabledClass()"
			class="lk-main-button lk-margin-bottom lk-margin-top"
			(click)="onConfirmClick()">
			<span *ngIf="!is_station_deleting">
				{{ 'GENERAL.CONFIRM_CTA' | translate }}
			</span>
			<div
				class="lk-spinner-layout"
				*ngIf="is_station_deleting">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
		</button>
	</div>
</div>
