import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-profile-admin-info',
	templateUrl: './profile-admin-info.component.html',
	styleUrls: ['./profile-admin-info.component.scss'],
})
export class ProfileAdminInfoComponent implements OnInit {
	@Input() email?: string;
	@Input() phone?: string;

	constructor() {}

	ngOnInit(): void {}
}
