<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-vehicle-manual-content-layout"
		class="lk-horiz-padding lk-hide-scrollbar">
		<h3 class="lk-no-margin lk-margin-top">
			{{ 'VEHICLE.REQUEST_MANUAL_CONTACTS_TITLE' | translate }}
		</h3>

		<p class="lk-no-margin lk-primary-medium-color">
			{{ 'VEHICLE.REQUEST_MANUAL_CONTACTS_SUBTITLE' | translate }}
		</p>

		<form
			[formGroup]="vehicle_request_manual_contacts_form_group"
			class="lk-double-margin-bottom lk-margin-top">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.STATION_NAME_LABEL' | translate }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="station_name"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="station_email"
					required />
			</mat-form-field>

			<button
				mat-button
				(click)="onNextClick()"
				class="lk-main-button lk-full-width lk-margin-top">
				<span class="lk-primary-color">
					{{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }}
				</span>
			</button>
		</form>
	</div>
</div>
