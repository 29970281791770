import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PROFILE_PATH } from '../core/navigation.service';
import { AuthGuard } from '../shared/auth.guard';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
	{
		path: PROFILE_PATH,
		component: ProfileComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ProfileRoutingModule {}
