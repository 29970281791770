<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-booking-filters-content-layout"
		class="lk-horiz-padding lk-hide-scrollbar">
		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-bottom"
			[formGroup]="form_group">
			<h3 class="lk-full-width lk-no-margin lk-half-margin-bottom">
				{{ 'BOOKING.FILTER_TITLE' | translate }}
			</h3>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'BOOKING.CURRENT_FILTER_LABEL' | translate }}
				</mat-label>
				<mat-select formControlName="current">
					<mat-option value="plate">
						{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate | titlecase }}
					</mat-option>
					<mat-option value="email">
						{{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				*ngIf="isPlateVisible()"
				class="lk-three-third-width lk-half-margin-bottom">
				<mat-label> {{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="plate" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				*ngIf="isEmailVisible()"
				class="lk-three-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					matInput
					type="email"
					formControlName="email" />
			</mat-form-field>

			<h3 class="lk-full-width lk-half-margin-bottom">
				{{ 'BOOKING.FILTER_SUBTITLE' | translate }}
			</h3>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'BOOKING.START_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="start_date"
					[matDatepicker]="startPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'BOOKING.END_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="end_date"
					[matDatepicker]="endPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			</mat-form-field>

			<div class="lk-full-width lk-ended-row">
				<span
					class="lk-cursor-pointer"
					(click)="onFiltersResetClick()">
					<u> {{ 'GENERAL.DELETE_FILTERS_CTA' | translate }} </u>
				</span>
			</div>

			<div class="lk-centered-row lk-full-width lk-margin-top">
				<button
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()">
					<span> {{ 'GENERAL.CONFIRM_CTA' | translate }} </span>
				</button>
			</div>
		</form>
	</div>
</div>
