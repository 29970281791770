import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import {
	Notification,
	NotificationName,
	NotificationService,
	NotificationType,
} from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import {
	LOCAL_STATION_KEY,
	SUPPORTED_LANGUAGES,
} from 'src/app/shared/constants';
import { TemplateItem } from '../stations-notif-templates/stations-notif-templates.component';
import {
	BOOKING_COMPLETE_EMAIL_LABEL,
	BOOKING_COMPLETE_SMS_LABEL,
	BOOKING_VEHICLE_EDITED_EMAIL_LABEL,
	BOOKING_VEHICLE_EDITED_SMS_LABEL,
	END_RENTAL_EMAIL_LABEL,
	END_RENTAL_SMS_LABEL,
	GENERAL_ERROR_MESSAGE,
	START_RENTAL_EMAIL_LABEL,
	START_RENTAL_SMS_LABEL,
	TEST_NOTIF_SUCCESS_MESSAGE,
} from 'src/app/shared/translations';
import { StationsNotifTemplatesItemDialogComponent } from '../stations-notif-templates-item-dialog/stations-notif-templates-item-dialog.component';
import { DialogService } from 'src/app/core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { StationsNotifTemplatesItemContainerComponent } from '../stations-notif-templates-item-container/stations-notif-templates-item-container.component';
import { StationsNotifTemplatesItemAddDialogComponent } from '../stations-notif-templates-item-add-dialog/stations-notif-templates-item-add-dialog.component';
import { StationNotifTestDialogComponent } from '../station-notif-test-dialog/station-notif-test-dialog.component';

@Component({
	selector: 'app-stations-notif-templates-item',
	templateUrl: './stations-notif-templates-item.component.html',
	styleUrls: ['./stations-notif-templates-item.component.scss'],
})
export class StationsNotifTemplatesItemComponent implements OnInit {
	@ViewChildren(StationsNotifTemplatesItemContainerComponent)
	containers?: QueryList<StationsNotifTemplatesItemContainerComponent>;
	@Input() is_expanded: boolean = false;
	@Input() template?: TemplateItem;
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();
	is_default_selected: boolean = true;
	station?: Station;
	sms_type: NotificationType = NotificationType.SMS;
	current_notif_loading: boolean = false;

	constructor(
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private notif_service: NotificationService,
	) {
		this.dialog_service.dialog = this.dialog;
		this.station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;
	}

	ngOnInit(): void {
		this.is_default_selected =
			!this.template?.custom || this.template?.custom?.is_disabled;
	}

	onAddTemplateClick(): void {
		this.dialog_service
			.openDialog(StationsNotifTemplatesItemAddDialogComponent, {
				station: this.station,
				template_name: this.template?.name,
				template_type: this.template?.type,
			})
			?.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.refresh.emit();
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onTemplateClick(is_default_selected: boolean): void {
		if (this.is_default_selected != is_default_selected) {
			this.is_default_selected = is_default_selected;

			if (is_default_selected) {
				this.disableTemplate(this.template?.custom);
			} else {
				this.enableTemplate(this.template?.custom);
			}
		}
	}

	onDefaultClick(): void {
		this.dialog_service.openDialog(StationsNotifTemplatesItemDialogComponent, {
			is_update_enabled: false,
			station: this.station,
			template: this.template?.default,
		});
	}

	onCustomUpdateClick(): void {
		this.dialog_service
			.openDialog(StationsNotifTemplatesItemDialogComponent, {
				is_update_enabled: true,
				station: this.station,
				template: this.template?.custom,
			})
			?.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.refresh.emit();
				} else if (dialog_result && !dialog_result.success) {
					if (
						dialog_result.error.response.data.error &&
						dialog_result.error.response.data.error.description
					) {
						this.dialog_service.openInfoDialog(
							dialog_result.error.response.data.error.description,
						);
					} else {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					}
				}
			});
	}

	onCustomUploadClick(event: any): void {
		const file: File = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			if (this.template && this.template.custom) {
				this.template.custom.content = reader.result?.toString();
				this.updateTemplate(this.template.custom);
			}
		};
	}

	onCustomDeleteClick(): void {
		this.deleteTemplate(this.template.custom);
	}

	onTestClick(): void {
		if (this.is_default_selected) {
			this.dialog_service
				.openDialog(StationNotifTestDialogComponent, {
					type: this.template.type,
					name: this.template.name,
				})
				.afterClosed()
				.subscribe(dialog_result => {
					if (dialog_result && dialog_result.success) {
						this.dialog_service.openInfoDialog(TEST_NOTIF_SUCCESS_MESSAGE);
					}

					if (dialog_result && !dialog_result.success) {
						this.dialog_service.openInfoDialog(dialog_result.error_message);
					}
				});
		}

		if (!this.is_default_selected) {
			this.dialog_service
				.openDialog(StationNotifTestDialogComponent, {
					type: this.template.type,
					name: this.template.name,
					template_id: this.template.custom.id,
				})
				.afterClosed()
				.subscribe(dialog_result => {
					if (dialog_result && dialog_result.success) {
						this.dialog_service.openInfoDialog(TEST_NOTIF_SUCCESS_MESSAGE);
					}

					if (dialog_result && !dialog_result.success) {
						this.dialog_service.openInfoDialog(dialog_result.error_message);
					}
				});
		}
	}

	updateTemplate(notification: Notification): void {
		const custom_container:
			| StationsNotifTemplatesItemContainerComponent
			| undefined = this.containers?.get(1);

		if (custom_container) {
			custom_container.is_update_running = true;
			this.notif_service
				.updateNotification(this.station.id, notification)
				.subscribe(
					response => {
						custom_container.is_update_running = false;
						this.refresh.emit();
					},
					error => {
						custom_container.is_update_running = false;
						if (
							error.response.data.error &&
							error.response.data.error.description
						) {
							this.dialog_service.openInfoDialog(
								error.response.data.error.description,
							);
						} else {
							this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
						}
					},
				);
		}
	}

	deleteTemplate(notification: Notification): void {
		const custom_container:
			| StationsNotifTemplatesItemContainerComponent
			| undefined = this.containers?.get(1);
		if (this.template?.custom && this.station?.id && custom_container) {
			custom_container.is_update_running = true;
			this.notif_service
				.removeNotification(this.station.id, notification)
				.subscribe({
					next: response => {
						custom_container.is_update_running = false;
						this.refresh.emit();
					},
					error: error => {
						custom_container.is_update_running = false;
						if (
							error.response.data.error &&
							error.response.data.error.description
						) {
							this.dialog_service.openInfoDialog(
								error.response.data.error.description,
							);
						} else {
							this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
						}
					},
				});
		}
	}

	enableTemplate(notification?: Notification): void {
		if (notification?.id && this.station?.id) {
			this.notif_service
				.enableNotification(notification.id, this.station.id)
				.subscribe({
					next: response => {
						this.is_default_selected = false;
					},
					error: error => {
						if (
							error.response.data.error &&
							error.response.data.error.description
						) {
							this.dialog_service.openInfoDialog(
								error.response.data.error.description,
							);
						} else {
							this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
						}
						this.is_default_selected = true;
					},
				});
		}
	}

	disableTemplate(notification?: Notification): void {
		if (notification?.id && this.station?.id) {
			this.notif_service
				.disableNotification(notification.id, this.station.id)
				.subscribe({
					next: response => {
						this.is_default_selected = true;
					},
					error: error => {
						if (
							error.response.data.error &&
							error.response.data.error.description
						) {
							this.dialog_service.openInfoDialog(
								error.response.data.error.description,
							);
						} else {
							this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
						}
						this.is_default_selected = false;
					},
				});
		}
	}

	getStationFlag(): string {
		switch (this.station?.settings?.default_lang) {
			case SUPPORTED_LANGUAGES[0]:
				return '../../../assets/imgs/lk-flags/IT.svg';
			case SUPPORTED_LANGUAGES[1]:
				return '../../../assets/imgs/lk-flags/GB.svg';
			case SUPPORTED_LANGUAGES[2]:
				return '../../../assets/imgs/lk-flags/ES.svg';
			case SUPPORTED_LANGUAGES[3]:
				return '../../../assets/imgs/lk-flags/FR.svg';
			default:
				return '';
		}
	}

	getTemplateLabel(): string {
		return this.getNotificationLabel(
			this.template?.name as NotificationName,
			this.template?.type as NotificationType,
		);
	}

	getNotificationLabel(name: NotificationName, type: NotificationType): string {
		switch (name) {
			case NotificationName.BOOKING_START:
				return type == NotificationType.SMS
					? START_RENTAL_SMS_LABEL
					: START_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_END:
				return type == NotificationType.SMS
					? END_RENTAL_SMS_LABEL
					: END_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_VEHICLE_CHANGED:
				return type == NotificationType.SMS
					? BOOKING_VEHICLE_EDITED_SMS_LABEL
					: BOOKING_VEHICLE_EDITED_EMAIL_LABEL;
			case NotificationName.BOOKING_COMPLETE:
				return type == NotificationType.SMS
					? BOOKING_COMPLETE_SMS_LABEL
					: BOOKING_COMPLETE_EMAIL_LABEL;
			case NotificationName.QUICK_LINK:
				return 'Quick link SMS';
			default:
				return '';
		}
	}
}
