<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-hidden-stations-reset-mfa-content-layout"
		class="lk-horiz-padding lk-margin-bottom">
		<p>
			Enter the email you want to reset the MFS config to and the phone number
			you want to associate.
		</p>

		<form
			[formGroup]="form_group"
			class="lk-spaced-row lk-wrapped-flex">
			<mat-form-field
				appearance="fill"
				class="lk-full-width lk-half-margin-bottom"
				color="accent">
				<mat-label> Email </mat-label>
				<input
					type="text"
					required
					matInput
					formControlName="email" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				floatLabel="always"
				color="accent"
				class="lk-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
				</mat-label>
				<img
					*ngIf="current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="unknown_flag_src" />

				<input
					id="lk-booking-phone-builder-prefix-input"
					type="text"
					matInput
					formControlName="phone_prefix"
					required
					[matAutocomplete]="autocountry" />
				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								style="min-width: 32px; height: 24px"
								class="lk-half-margin-right"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="
										max-width: calc(100% - 6px);
										font-size: 10px;
										line-height: 18px;
									"
									class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-no-margin lk-ellipsized-text">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-three-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					id="lk-booking-phone-builder-phone-input"
					matInput
					type="tel"
					formControlName="phone"
					required />
			</mat-form-field>

			<div class="lk-full-width lk-centered-row lk-margin-top">
				<button
					class="lk-main-button lk-full-width"
					mat-button
					[disabled]="is_mfa_setup_running"
					[ngClass]="getDisabledClass()"
					(click)="onConfirmClick()">
					<span *ngIf="!is_mfa_setup_running">
						{{ 'GENERAL.CONFIRM_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_mfa_setup_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
