import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiddenSettingsRoutingModule } from './hidden-settings-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { HiddenSettingsComponent } from './hidden-settings.component';

@NgModule({
  declarations: [
    HiddenSettingsComponent
  ],
  imports: [
    CommonModule,
    HiddenSettingsRoutingModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule, 
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatMenuModule
  ]
})
export class HiddenSettingsModule { }
