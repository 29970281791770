<div style="min-width: 350px">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-hidden-stations-settings-dialog-form"
		class="lk-horiz-padding lk-hidden-stations-settings-dialog-toggle-layout">
		<form
			[formGroup]="form_group"
			class="lk-column">
			<h3>Frontoffice</h3>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="unlock_confirmation">
				Enable/Disable unlock confirmation
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="area_control">
				Enable/Disable area control
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="interact_after_close">
				Enable/Disable checklist
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="parking_input">
				Enable/Disable parking input
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="custom_logo">
				Enable/Disable custom logo
			</mat-slide-toggle>

			<div
				*ngIf="isCustomLogoEnabled()"
				id="lk-hidden-station-settings-logo-layout">
				<mat-spinner
					*ngIf="!custom_logo"
					color="accent"
					diameter="20"></mat-spinner>
				<img
					*ngIf="custom_logo"
					class="lk-full-width lk-cursor-pointer"
					[src]="custom_logo"
					(click)="fileInput.click()" />

				<input
					#fileInput
					type="file"
					accept="image/png"
					(change)="onLogoUpdateClick($event)"
					style="display: none" />
			</div>

			<h3>Notifications</h3>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="auto_close">
				Enable/Disable auto close
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="booking_closure_notif">
				Enable/Disable booking closure notif.
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="booking_closure_custom_notif">
				Enable/Disable customization for the booking closure notif.
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="vehicle_change_custom_notif">
				Enable/Disable customization for the vehicle change notif.
			</mat-slide-toggle>

			<mat-form-field
				appearance="fill"
				class="lk-full-width lk-half-margin-bottom"
				color="accent">
				<mat-label> SMS sender alias (11 chars max) </mat-label>
				<input
					type="text"
					matInput
					formControlName="sms_alias"
					[errorStateMatcher]="matcher" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-full-width lk-half-margin-bottom"
				color="accent">
				<mat-label> EMAIL sender alias </mat-label>
				<input
					type="text"
					matInput
					formControlName="email_alias" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-full-width"
				color="accent">
				<mat-label> Low voltage notification threshold [V] </mat-label>
				<input
					type="number"
					matInput
					formControlName="low_voltage_threshold" />
			</mat-form-field>

			<h3>Station navbar</h3>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="team_mgmt">
				Enable/Disable station team management
			</mat-slide-toggle>

			<div class="lk-full-width lk-ended-row lk-vert-padding">
				<button
					class="lk-main-button lk-full-width"
					mat-button
					[disabled]="!isFormValid() || is_save_running"
					[ngClass]="
						!isFormValid() || is_save_running ? 'lk-disabled-button' : ''
					"
					(click)="onSettingsUpdateClick()">
					<span
						*ngIf="!is_save_running"
						class="lk-primary-color">
						{{ 'GENERAL.SAVE_BUTTON_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_save_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
