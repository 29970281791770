<div class="lk-full-height lk-full-width lk-spaced-row">
	<div
		*ngIf="mfa_setup_mechanism == 'sms'"
		class="lk-full-height lk-double-padding lk-auth-mfa-setup-form-layout">
		<app-auth-mfa-setup-sms
			style="margin: auto"
			[logo_src]="logo_src"></app-auth-mfa-setup-sms>
	</div>

	<div
		*ngIf="mfa_setup_mechanism == 'totp'"
		class="lk-full-height lk-double-padding lk-auth-mfa-setup-form-layout">
		<app-auth-mfa-setup-totp
			style="margin: auto"
			[logo_src]="logo_src"></app-auth-mfa-setup-totp>
	</div>

	<div
		id="lk-auth-mfa-setup-img-layout"
		class="lk-full-height lk-double-padding lk-centered-row"
		[ngStyle]="{
			'background-image': 'url(' + background_src + ')'
		}"></div>
</div>
