<div class="lk-full-width lk-full-height lk-centered-column">
	<img
		class="lk-empty-ill"
		src="../../../assets/imgs/lk-empty-resource-ill.svg" />

	<h1>{{ 'VEHICLE.NO_VEHICLES_FOUND_TITLE' | translate }}</h1>

	<div
		style="max-width: 450px"
		class="lk-full-width">
		<h3 class="lk-full-width">
			{{ 'VEHICLE.INSTALL_CTA' | translate }}
		</h3>

		<h3 class="lk-full-width">
			{{ 'VEHICLE.REQUEST_MANUAL_LABEL_PREFIX' | translate }}
			<span class="lk-accent-color">
				{{ 'VEHICLE.REQUEST_MANUAL_LABEL_BODY' | translate }}
			</span>
			{{ 'VEHICLE.REQUEST_MANUAL_LABEL_SUFFIX' | translate }}:
		</h3>

		<h3 class="lk-full-width">
			{{ 'VEHICLE.REQUEST_RESPONSE_DISCLAIMER' | translate }}
		</h3>

		<button
			mat-button
			(click)="onSendClick()"
			class="lk-main-button lk-full-width">
			<mat-icon
				class="lk-primary-color"
				matPrefix>
				directions_car
			</mat-icon>
			<span class="lk-primary-color">
				{{ 'VEHICLE.REQUEST_MANUAL_CTA' | translate }}
			</span>
		</button>
	</div>
</div>
