<div id="lk-station-notif-sms-templates-dialog-root-layout">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-horiz-padding">
		<div
			*ngFor="let notif of notifications"
			class="lk-margin-bottom">
			<h2 class="lk-no-margin lk-half-margin-bottom">
				{{ getNotificationLabel(notif.name, sms_type) | translate }}
			</h2>
			<div class="lk-border-radius lk-primary-medium-background lk-padding">
				<span style="white-space: pre-wrap">
					{{ getNotifContent(notif) }}
				</span>
			</div>
		</div>
	</div>
</div>
