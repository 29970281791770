<div class="lk-shared-spinner-root-layout lk-full-width lk-full-height lk-centered-row lk-shared-spinner-opacity"></div>
<div class="lk-shared-spinner-root-layout lk-full-width lk-full-height lk-centered-row">
	<div 
		class="lk-shared-spinner-layout lk-padding lk-border-radius"
		*ngIf="show_spinner">
		<mat-spinner 
			color="accent" 
			diameter="30"></mat-spinner>
	</div>
</div>


