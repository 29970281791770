<div>
    <div class="lk-ended-row">
      <button 
        mat-icon-button 
        (click)="onCloseClick()" 
        class="lk-primary-medium-background">
        <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
      </button>
    </div>
    <div 
      id="lk-vehicle-details-delete-content-layout" 
      class="lk-horiz-padding">
      <p> {{ 'BOOKING.DELETE_CONFIRM_MESSAGE' | translate }} </p>
    </div>

    <div class="lk-full-width lk-centered-row">
      <button 
        mat-button 
        class="lk-main-button lk-margin-bottom lk-margin-top"
        (click)="onConfirmClick()">
        <span> {{ 'GENERAL.CONFIRM_CTA' | translate }} </span>
      </button>
    </div>
</div>
  