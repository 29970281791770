<form
	[formGroup]="form_group"
	class="lk-shadow lk-border-radius lk-double-padding lk-spaced-row lk-wrapped-flex lk-primary-background lk-z-index lk-auth-mfa-setup-form">
	<div class="lk-spaced-row lk-full-width">
		<div
			class="lk-primary-dark-background"
			style="width: 20%; height: 2px"></div>
		<img
			style="width: 50%"
			[src]="logo_src" />
		<div
			class="lk-primary-dark-background"
			style="width: 20%; height: 2px"></div>
	</div>

	<span
		*ngIf="!is_app_setup"
		class="lk-double-margin-top">
		{{ 'AUTHENTICATION.MFA_SETUP_TOTP_CTA' | translate }}
	</span>

	<div
		*ngIf="!is_app_setup"
		class="lk-primary-medium-background lk-margin-top lk-margin-bottom lk-row"
		style="padding: 6px; box-sizing: border-box; border-radius: 4px">
		<button
			(click)="onCopyClick(setup_secret)"
			mat-icon-button>
			<mat-icon> content_copy </mat-icon>
		</button>
		<p
			class="lk-accent-color"
			style="word-break: break-all; margin: 0">
			<b> {{ setup_secret }} </b>
		</p>
	</div>

	<div
		*ngIf="!is_app_setup"
		class="lk-centered-row lk-full-width">
		<qrcode
			#parent
			[qrdata]="getQRData()"
			[allowEmptyString]="true"
			[cssClass]="'center'"
			[colorDark]="'#000000ff'"
			[colorLight]="'#ffffffff'"
			[elementType]="'img'"
			[errorCorrectionLevel]="'M'"
			[margin]="4"
			[scale]="1"
			[width]="200"></qrcode>
	</div>

	<button
		*ngIf="!is_app_setup"
		mat-button
		class="lk-main-button lk-full-width lk-margin-top"
		(click)="onAppSetupClick()">
		<h3 class="lk-no-margin lk-primary-color">
			{{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }}
		</h3>
	</button>

	<span
		*ngIf="is_app_setup"
		class="lk-double-margin-top">
		{{ 'AUTHENTICATION.MFA_SETUP_TOTP_CONFIRM_CTA' | translate }}
	</span>

	<mat-form-field
		*ngIf="is_app_setup"
		appearance="fill"
		color="accent"
		style="font-size: 28px"
		class="lk-full-width lk-half-margin-bottom lk-form-field-large lk-margin-top">
		<mat-label>
			{{ 'GENERAL.CODE_LABEL' | translate | titlecase }}
		</mat-label>
		<input
			matInput
			type="text"
			formControlName="token"
			required />
	</mat-form-field>

	<button
		*ngIf="is_app_setup"
		id="auth-mfa-verify-submit-button"
		mat-button
		class="lk-main-button lk-full-width lk-double-margin-top"
		(click)="onMfaSetupClick()"
		[disabled]="is_mfa_setup_running || !isFormValid()"
		[ngClass]="getDisabledClass()">
		<h3
			class="lk-no-margin lk-primary-color"
			*ngIf="!is_mfa_setup_running">
			{{ 'GENERAL.CONFIRM_CTA' | translate }}
		</h3>
		<div
			class="lk-spinner-layout"
			*ngIf="is_mfa_setup_running">
			<mat-spinner [diameter]="20"></mat-spinner>
		</div>
	</button>

	<div
		*ngIf="is_app_setup"
		(click)="onBackClick()"
		class="lk-row">
		<button mat-icon-button>
			<mat-icon class="lk-accent-color"> arrow_back </mat-icon>
		</button>
		<span
			style="font-size: 12px"
			class="lk-accent-color">
			{{ 'AUTHENTICATION.MFA_SETUP_TOTP_BACK_CTA' | translate }}
		</span>
	</div>
</form>
