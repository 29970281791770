<div id="lk-station-notif-details-dialog-root-layout">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		class="lk-centered-row lk-double-padding"
		*ngIf="is_content_loading">
		<mat-spinner
			diameter="30"
			color="accent"></mat-spinner>
	</div>

	<form
		[formGroup]="form_group"
		class="lk-horiz-padding"
		*ngIf="!is_content_loading">
		<h2 class="lk-no-margin lk-half-margin-bottom">
			{{ getNotificationLabel(notif_name, sms_type) | translate }}
		</h2>

		<mat-form-field
			style="min-width: 350px"
			class="lk-full-width lk-margin-bottom"
			appearance="fill"
			color="accent">
			<mat-label> content </mat-label>
			<textarea
				style="min-height: 200px"
				type="text"
				matInput
				formControlName="notif"
				required></textarea>
		</mat-form-field>

		<button
			(click)="onConfirmClick()"
			[disabled]="!isValidForm()"
			[ngClass]="getDisabledClass()"
			mat-button
			class="lk-main-button lk-margin-bottom lk-full-width">
			{{ 'GENERAL.CONFIRM_CTA' | translate }}
		</button>
	</form>
</div>
