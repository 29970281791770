<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-booking-phone-builder-content-layout"
		class="lk-horiz-padding">
		<h3>{{ 'BOOKING.ADD_PHONE_DIALOG_TITLE' | translate }}</h3>
		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				floatLabel="always"
				color="accent"
				class="lk-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
				</mat-label>
				<img
					*ngIf="current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="unknown_flag_src" />

				<input
					id="lk-booking-phone-builder-prefix-input"
					type="text"
					matInput
					formControlName="phone_prefix"
					required
					[matAutocomplete]="autocountry" />
				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								style="min-width: 32px; height: 24px"
								class="lk-half-margin-right"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="
										max-width: calc(100% - 6px);
										font-size: 10px;
										line-height: 18px;
									"
									class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-no-margin lk-ellipsized-text">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<!--<mat-form-field 
        appearance="fill" 
        color="accent" 
        class="lk-third-width lk-half-margin-bottom">
        <mat-label> {{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase  }} </mat-label>
        <mat-icon 
          matPrefix 
          class="lk-primary-medium-color"> add </mat-icon>
        <input 
          id="lk-booking-phone-builder-prefix-input"
          matInput 
          type="tel" 
          formControlName="phone_prefix" 
          required>
      </mat-form-field>-->

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-three-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					id="lk-booking-phone-builder-phone-input"
					matInput
					type="tel"
					formControlName="phone"
					required />
			</mat-form-field>

			<div class="lk-centered-row lk-full-width lk-margin-top">
				<button
					id="lk-booking-phone-builder-confirm-button"
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()"
					[disabled]="is_request_loading"
					[ngClass]="getDisabledClass()">
					<span *ngIf="!is_request_loading">
						{{ 'GENERAL.CONFIRM_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_request_loading">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
