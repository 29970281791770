import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Booking, BookingStatus } from 'src/app/core/booking.service';
import { DialogService } from 'src/app/core/dialog.service';
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';
import { BookingTimeBuilderDialogComponent } from 'src/app/booking/booking-time-builder-dialog/booking-time-builder-dialog.component';
import { NotificationType } from 'src/app/core/notification.service';
import {
	DATE_FORMATS,
	EN_LOCALE,
	IT_LOCALE,
	LOCAL_STATION_KEY,
	PROD_STAGE,
	SUPPORTED_LANGUAGES,
} from 'src/app/shared/constants';
import {
	BOOKING_COMPLETED_LABEL,
	BOOKING_ERROR_WO_START_LABEL,
	BOOKING_ERROR_WO_STOP_LABEL,
	BOOKING_ERROR_WO_VEHICLE_LABEL,
	BOOKING_IN_PROGRESS_LABEL,
	BOOKING_PENDING_LABEL,
	SUPPORTED_LANGUAGES_LABELS,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Station } from 'src/app/core/station.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';

@Component({
	selector: 'app-booking-details-general',
	templateUrl: './booking-details-general.component.html',
	styleUrls: ['./booking-details-general.component.scss'],
})
export class BookingDetailsGeneralComponent implements OnInit {
	@Input() booking: Booking;
	@Output() bookingUpdateClick: EventEmitter<Booking> =
		new EventEmitter<Booking>();
	@Output() updateEmailClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() updatePhoneClick: EventEmitter<void> = new EventEmitter<void>();
	pending_label: string = BOOKING_PENDING_LABEL;
	progress_label: string = BOOKING_IN_PROGRESS_LABEL;
	is_production: boolean;
	current_locale: string;

	constructor(
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private local_manager: LocalManagerService,
	) {
		this.dialog_service.dialog = this.dialog;
		const current_station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		switch (current_station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				break;
			default:
				this.current_locale = EN_LOCALE;
				break;
		}
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;
	}

	onBookingUpdateClick() {
		this.dialog_service
			.openDialog(BookingTimeBuilderDialogComponent, { booking: this.booking })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.booking) {
					this.bookingUpdateClick.emit(dialogResult.booking);
				}
			});
	}

	onUpdateEmailClick() {
		this.updateEmailClick.emit();
	}

	onUpdatePhoneClick() {
		this.updatePhoneClick.emit();
	}

	getTimezoneOffset(booking: Booking) {
		return (
			'GMT' +
			(getTimezoneOffset(booking.timezone) / 3600000 >= 0 ? '+' : '') +
			getTimezoneOffset(booking.timezone) / 3600000
		);
	}

	getTimezoneStartTime(booking: Booking) {
		return (
			booking.start_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getTimezoneEndTime(booking: Booking) {
		return (
			booking.end_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getNotifType(booking: Booking) {
		switch (booking.settings.default_notification_type) {
			case NotificationType.BOTH:
				return 'SMS, EMAIL';
			case NotificationType.SMS:
				return 'SMS';
			case NotificationType.EMAIL:
				return 'EMAIL';
		}
	}

	getNotifLang(booking: Booking) {
		switch (booking.settings.default_lang) {
			case SUPPORTED_LANGUAGES[0]:
				return SUPPORTED_LANGUAGES_LABELS[0];
			case SUPPORTED_LANGUAGES[1]:
				return SUPPORTED_LANGUAGES_LABELS[1];
			case SUPPORTED_LANGUAGES[2]:
				return SUPPORTED_LANGUAGES_LABELS[2];
			case SUPPORTED_LANGUAGES[3]:
				return SUPPORTED_LANGUAGES_LABELS[3];
			default:
				return SUPPORTED_LANGUAGES_LABELS[2];
		}
	}

	getBookingStatusLabel(booking: Booking) {
		if (!booking.errors || !booking.errors.length) {
			if (this.isPendingBooking(booking)) {
				return BOOKING_PENDING_LABEL;
			} else if (this.isCompletedBooking(booking)) {
				return BOOKING_COMPLETED_LABEL;
			} else {
				return BOOKING_IN_PROGRESS_LABEL;
			}
		} else {
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_VEHICLE)) {
				return BOOKING_ERROR_WO_VEHICLE_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_START)) {
				return BOOKING_ERROR_WO_START_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_STOP)) {
				return BOOKING_ERROR_WO_STOP_LABEL;
			}
		}
	}

	isPendingBooking(booking: Booking): boolean {
		const isUnlocked: boolean = booking.is_user_vehicle_unlocked;
		return !isUnlocked;
	}

	isCompletedBooking(booking: Booking): boolean {
		const lockAfterTime: number =
			booking.settings.lock_settings
				.time_to_lock_availability_after_booking_end;
		const currentTimezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone;
		const isInTime: boolean =
			new Date().getTime() >
			zonedTimeToUtc(
				booking.end_date + lockAfterTime,
				currentTimezone,
			).getTime();
		const isLocked: boolean = booking.is_user_vehicle_locked;
		return isInTime || isLocked;
	}

	isUpdateGeneralVisible(booking: Booking) {
		/*if (this.isProduction) {
      return false;
    } else {
      return this.getBookingStatusLabel(booking) == this.pendingLabel || this.getBookingStatusLabel(booking) == this.progressLabel;
    }*/
		return (
			this.getBookingStatusLabel(booking) == this.pending_label ||
			this.getBookingStatusLabel(booking) == this.progress_label
		);
	}
}
