<div>
  <div class="lk-ended-row">
    <button 
      mat-icon-button 
      (click)="onCloseClick()" 
      class="lk-primary-medium-background">
      <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
    </button>
  </div>
  <div 
    id="lk-vehicle-details-image-content-layout" 
    class="lk-horiz-padding lk-margin-top">
    <button 
      mat-button 
      id="lk-vehicle-details-image-dialog-input-button" 
      (click)="fileInput.click()"
      class="lk-full-width lk-border-radius lk-centered-column" 
      *ngIf="!is_picture_loading && !image">
      <mat-icon class="lk-accent-color"> cloud_upload </mat-icon>
      <h3 class="lk-accent-color lk-no-margin"> upload </h3>
      <input 
        #fileInput 
        type="file" 
        accept="image/*" 
        (change)="onFileInput($event)" 
        style="display:none;">
    </button>

    <div 
      id="lk-vehicle-details-image-dialog-img-layout" 
      class="lk-full-width lk-centered-column"
      *ngIf="!is_picture_loading && image">
      <img 
        id="lk-vehicle-details-image-dialog-img" 
        class="lk-border-radius" 
        [src]="image">
      <p 
        class="lk-cursor-link-hover lk-cursor-pointer lk-centered-text" 
        (click)="updateInput.click()">
        {{ 'VEHICLE_DETAILS.UPDATE_IMAGE_CTA' | translate }} </p>
      <input 
        #updateInput 
        type="file" 
        accept="image/*" 
        (change)="onFileInput($event)" 
        style="display:none;">
    </div>

    <div 
      class="lk-full-width lk-padding lk-centered-row" 
      *ngIf="is_picture_loading">
      <mat-spinner 
        diameter="40" 
        color="accent"></mat-spinner>
    </div>

    <button 
      mat-button 
      class="lk-main-button lk-full-width lk-margin-bottom lk-margin-top"
      (click)="onConfirmClick()" 
      [disabled]="is_picture_loading" 
      [ngClass]="getDisabledClass()">
      <span> {{ 'GENERAL.CONFIRM_CTA' | translate }} </span>
    </button>
  </div>
</div>
