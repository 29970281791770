import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormErrorStateMatcher, FormService } from '../../core/form.service';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
	SIGNUP_PASSWORD_MISMATCH_ERROR,
	SIGNUP_SUCCESS_MESSAGE,
	SIGNUP_SUCCESS_TITLE,
} from '../../shared/translations';
import { AuthenticationService } from '../../core/authentication.service';
import { AUTH_SIGNIN_PATH } from 'src/app/core/navigation.service';
import {
	CUSTOM_AUTH_LOGO_PATH,
	CUSTOM_AUTH_LOGO_BACKGROUND_PATH,
} from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-auth-signup',
	templateUrl: './auth-signup.component.html',
	styleUrls: ['./auth-signup.component.scss'],
})
export class AuthSignupComponent implements OnInit {
	form_group: FormGroup;
	matcher: FormErrorStateMatcher;
	is_password_visible: boolean = false;
	is_signup_running: boolean = false;

	logo_src: string = '../../../assets/imgs/lk-logo.svg';
	background_src: string =
		'../../../assets/imgs/lk-backgrounds/lk-default-auth-background.svg';

	constructor(
		private form_service: FormService,
		private router: Router,
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.form_group = form_service.signupFormGroup;
		this.matcher = form_service.matcher;
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit() {
		this.form_group.reset();

		const base_path = location.origin
			.replace('http://', '')
			.replace('https://', '');

		const dot_index = base_path.indexOf('.');
		const double_dot_index = base_path.indexOf(':');
		let subdomain: string;

		if (dot_index != -1) {
			subdomain = base_path.substring(0, dot_index);
		} else if (double_dot_index != -1) {
			subdomain = base_path.substring(0, double_dot_index);
		}

		console.log(subdomain);

		if (subdomain && subdomain != 'manage') {
			this.logo_src = CUSTOM_AUTH_LOGO_PATH(environment.STAGE, subdomain);
			this.background_src = CUSTOM_AUTH_LOGO_BACKGROUND_PATH(
				environment.STAGE,
				subdomain,
			);
		}
	}

	onSignupClick() {
		if (this.form_service.validateForm(this.form_group)) {
			const username: string = this.form_group.get('username').value;
			const password: string = this.form_group.get('password').value;
			const passwordConfirm: string =
				this.form_group.get('password_confirm').value;
			if (password != passwordConfirm) {
				this.dialog_service.openInfoDialog(SIGNUP_PASSWORD_MISMATCH_ERROR);
			} else {
				this.is_signup_running = true;
				this.auth_service.signUp(username, password).subscribe({
					next: response => {
						this.dialog_service.openInfoDialog(
							SIGNUP_SUCCESS_MESSAGE,
							SIGNUP_SUCCESS_TITLE,
							'../../../assets/imgs/lk-rocket-ill.png',
						);
						this.is_signup_running = false;
						this.router.navigate([AUTH_SIGNIN_PATH]);
					},
					error: error => {
						this.is_signup_running = false;
						this.dialog_service.openInfoDialog(error.message);
					},
				});
			}
		}
	}

	onSigninClick() {
		this.router.navigate([AUTH_SIGNIN_PATH]);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_signup_running,
			'lk-main-button': !this.is_signup_running,
		};
	}
}
