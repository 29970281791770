<div style="max-height: 80vh; min-width: 700px; max-width: 700px">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-horiz-padding lk-margin-bottom">
		<app-hidden-stations-checklist-dialog-header
			[checklist]="checklist"
			(clear)="
				onClearChecklistClick()
			"></app-hidden-stations-checklist-dialog-header>

		<form
			[formGroup]="form_group"
			class="lk-margin-top"
			style="position: relative">
			<div
				class="lk-margin-bottom lk-ended-row"
				style="position: absolute; top: 42px; right: 0; z-index: 1">
				<mat-form-field
					appearance="fill"
					color="accent"
					style="width: 124px"
					class="lk-form-field-no-padding-top">
					<img
						matPrefix
						style="min-width: 16px; height: 12px"
						class="lk-half-margin-right"
						[src]="getCurrentLangPrefixImg()" />

					<mat-select formControlName="current_lang">
						<mat-option value="en">
							<img
								matPrefix
								style="min-width: 16px; height: 12px"
								class="lk-half-margin-right"
								[src]="getCurrentLangPrefixImg('en')" />
							EN
						</mat-option>
						<mat-option value="es">
							<img
								matPrefix
								style="min-width: 16px; height: 12px"
								class="lk-half-margin-right"
								[src]="getCurrentLangPrefixImg('es')" />
							ES
						</mat-option>
						<mat-option value="it"
							><img
								matPrefix
								style="min-width: 16px; height: 12px"
								class="lk-half-margin-right"
								[src]="getCurrentLangPrefixImg('it')" />
							IT
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div style="min-height: 250px; max-height: 400px; overflow-y: scroll">
				<quill-editor formControlName="current_message"></quill-editor>
			</div>

			<div class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top">
				<button
					style="max-width: 300px"
					class="lk-secondary-button lk-full-width lk-half-margin-bottom"
					mat-button
					[disabled]="is_save_running"
					(click)="onAddMessageClick()">
					<span> Add message </span>
				</button>

				<button
					style="max-width: 300px"
					class="lk-main-button lk-full-width lk-half-margin-bottom"
					mat-button
					[disabled]="is_save_running"
					(click)="onConfirmClick()">
					<span *ngIf="!is_save_running">
						{{ 'GENERAL.SAVE_BUTTON_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_save_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
