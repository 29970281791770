<div
	class="lk-border-radius lk-shadow lk-padding lk-full-height lk-primary-background">
	<h3 class="lk-no-margin lk-subtitle-size lk-half-margin-bottom">
		{{ 'VEHICLE_DETAILS.SENT_LINKS_LABEL' | translate }}
	</h3>

	<button
		(click)="onDirectLinkClick(vehicle)"
		mat-button
		class="lk-margin-bottom lk-full-width lk-vehicle-details-links-add-button">
		<mat-icon
			matPrefix
			class="lk-accent-color">
			add
		</mat-icon>
		<span class="lk-accent-color">
			{{ 'VEHICLE_DETAILS.DIRECT_LINK_CTA' | translate }}
		</span>
	</button>

	<div
		class="lk-padding lk-centered-row"
		*ngIf="is_links_request_running">
		<mat-spinner
			diameter="20"
			color="accent"></mat-spinner>
	</div>

	<div
		*ngIf="!is_links_request_running && links.length"
		style="max-height: calc(100% - 140px); overflow-y: scroll">
		<div
			*ngFor="let link of links"
			class="lk-spaced-row lk-half-margin-bottom">
			<div
				class="lk-row"
				style="align-items: flex-start">
				<!--<div
					style="width: 18px; height: 18px; border-radius: 50%"
					[matTooltip]="
						(link.status == 'enabled'
							? 'VEHICLE_DETAILS.LINK_STATUS_ENABLED_LABEL'
							: 'VEHICLE_DETAILS.LINK_STATUS_DISABLED_LABEL'
						) | translate
					"
					[ngClass]="
						link.status == 'enabled'
							? 'lk-vehicle-details-links-circle-enabled'
							: 'lk-vehicle-details-links-circle-disabled'
					"
					class="lk-vehicle-details-links-circle-active"></div>-->

				<div class="lk-column">
					<span
						*ngIf="getLinkType(link) != ''"
						style="font-size: 12px; font-weight: 900">
						{{ getLinkType(link) | titlecase }} link
					</span>
					<span style="font-size: 12px">
						{{ link.nbf | date: 'short' }} -
						{{ link.exp | date: 'short' }}
					</span>
					<a
						[href]="link.short_url"
						target="_blank">
						{{ link.short_url }}
					</a>
					<span
						style="font-size: 12px"
						[ngClass]="
							link.status == 'enabled' && isLinkEditable(link)
								? 'lk-vehicle-green-color'
								: 'lk-vehicle-red-color'
						">
						<span class="lk-primary-dark-color"> Status: </span>
						{{
							(link.status == 'enabled' && isLinkEditable(link)
								? 'VEHICLE_DETAILS.LINK_STATUS_ENABLED_LABEL'
								: 'VEHICLE_DETAILS.LINK_STATUS_DISABLED_LABEL'
							)
								| translate
								| titlecase
						}}
					</span>

					<span style="font-size: 12px">
						<span class="lk-primary-dark-color">
							{{ 'GENERAL.CREATED_AT_LABEL' | translate }}:
							{{ link.created_at | date: 'short' }}
						</span>
					</span>
				</div>
			</div>

			<div>
				<button
					*ngIf="link.status == 'enabled'"
					(click)="onDisableClick(link)"
					[matTooltip]="
						(isLinkEditable(link) ? '' : 'VEHICLE_DETAILS.EXPIRED_LINK')
							| translate
					"
					mat-button
					[ngClass]="getDisabledClass(link)"
					[disabled]="!isLinkEditable(link)"
					style="padding: 0 16px !important"
					class="lk-primary-medium-background">
					<span
						*ngIf="!is_disable_request_running || link.id != disabling_link_id">
						{{ 'VEHICLE_DETAILS.DISABLE_CTA' | translate | lowercase }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_disable_request_running && link.id == disabling_link_id">
						<mat-spinner
							[diameter]="20"
							color="accent"></mat-spinner>
					</div>
				</button>

				<button
					*ngIf="link.status == 'disabled'"
					(click)="onEnableClick(link)"
					[matTooltip]="
						(isLinkEditable(link) ? '' : 'VEHICLE_DETAILS.EXPIRED_LINK')
							| translate
					"
					mat-button
					[ngClass]="getDisabledClass(link)"
					[disabled]="!isLinkEditable(link)"
					class="lk-primary-medium-background">
					<span
						*ngIf="!is_enable_request_running || link.id != enabling_link_id">
						{{ 'VEHICLE_DETAILS.ENABLE_CTA' | translate | lowercase }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_enable_request_running && link.id == enabling_link_id">
						<mat-spinner
							[diameter]="20"
							color="accent"></mat-spinner>
					</div>
				</button>
			</div>
		</div>
	</div>

	<div *ngIf="!is_links_request_running && !links.length">
		<p class="lk-centered-text">
			<i>{{ 'VEHICLE_DETAILS.NO_LINK_FOUND' | translate }}</i>
		</p>
	</div>
</div>
