<div class="lk-full-width lk-full-height lk-centered-column">
	<img
		class="lk-empty-ill"
		src="../../../assets/imgs/lk-empty-resource-ill.svg" />

	<h1>{{ 'STATION.NO_STATIONS_FOUND_TITLE' | translate }}</h1>

	<button
		mat-button
		class="lk-main-button"
		(click)="onStationBuildClick()">
		<h3 class="lk-no-margin lk-primary-color">
			{{ 'STATION.ADD_STATION_CTA' | translate }}
		</h3>
	</button>
</div>
