import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { PicturePolicy, Station, StationService } from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
  selector: 'app-station-pics',
  templateUrl: './station-pics.component.html',
  styleUrls: ['./station-pics.component.scss']
})
export class StationPicsComponent implements OnInit {
  @Input() station: Station;
  policy: PicturePolicy;
  mandatory_policy: PicturePolicy = PicturePolicy.MANDATORY;
  mandatory_pre_policy: PicturePolicy = PicturePolicy.MANDATORY_PRE;
  mandatory_post_policy: PicturePolicy = PicturePolicy.MANDATORY_POST;
  optional_policy: PicturePolicy = PicturePolicy.OPTIONAL;
  disabled_policy: PicturePolicy = PicturePolicy.DISABLED;
  is_policy_mandatory: boolean = false;
  is_policy_mandatory_pre: boolean = false;
  is_policy_mandatory_post: boolean = false;
  is_policy_optional: boolean = false;
  is_policy_disabled: boolean = false;
  is_request_running: boolean = false;

  constructor(
    private dialog_service: DialogService,
    private dialog: MatDialog,
    private station_service: StationService
  ) { 
    this.dialog_service.dialog = this.dialog;
  }

  ngOnInit(): void {
    this.setPolicyFlags();
  }

  onReset(station: Station): void {
    this.station = station;
    this.setPolicyFlags();
  }

  onToggleChange(event: any, policy: PicturePolicy): void {
    if (event.checked) {
      this.station.settings.pictures_policy = policy;
      this.setPolicyFlags();
      this.updateStation(this.station);
    } else {
      event.source.checked = true;
    }
  }

  setPolicyFlags(): void {
    switch (this.station.settings.pictures_policy) {
      case PicturePolicy.MANDATORY:
        this.is_policy_mandatory      = true;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = false;
        break;
      case PicturePolicy.MANDATORY_PRE:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = true;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = false;
        break;
      case PicturePolicy.MANDATORY_POST:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = true;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = false;
        break;
      case PicturePolicy.OPTIONAL:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = true;
        this.is_policy_disabled       = false;
        break;
      case PicturePolicy.DISABLED:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = true;
        break;
      default:
        this.is_policy_mandatory      = false;
        this.is_policy_mandatory_pre  = false;
        this.is_policy_mandatory_post = false;
        this.is_policy_optional       = false;
        this.is_policy_disabled       = true;
        break;
    }
  }

  updateStation(station: Station): void {
    this.is_request_running = true;
    this.station_service.updateStation(station).subscribe({
      next: (response) => {
        this.is_request_running = false;
        localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(response.data));
      },
      error: (error) => {
        this.is_request_running = false;
        this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
      }
    });
  }
}
