<div
	id="lk-station-team-root-layout"
	class="lk-spaced-row">
	<h2 class="lk-no-margin lk-half-margin-bottom">
		{{ 'STATION.NAVBAR_TEAM_ITEM_NAME' | translate }}
	</h2>
	<button
		id="lk-station-team-update-button"
		(click)="onAddTeammateClick()"
		class="lk-background-icon lk-half-margin-bottom"
		mat-icon-button>
		<mat-icon class="lk-accent-color"> add </mat-icon>
	</button>
</div>

<div
	class="lk-double-padding lk-light-shadow lk-border-radius lk-primary-background">
	<p
		*ngIf="!is_team_loading && !invitations.length && !team.length"
		class="lk-primary-medium-color lk-centered-text">
		{{ 'STATION.TEAM_EMPTY_TEXT' | translate }}
	</p>

	<span
		*ngIf="!is_team_loading && invitations.length"
		style="font-size: 12px"
		class="lk-primary-medium-color">
		{{ 'STATION.TEAM_INVITATIONS_LABEL' | translate }}
	</span>
	<div
		*ngIf="!is_team_loading && invitations.length"
		class="lk-full-width lk-row lk-wrapped-flex">
		<div
			*ngFor="let invitation of invitations"
			class="lk-chips-layout lk-primary-medium-background lk-centered-row lk-half-margin-bottom lk-half-margin-right">
			<span
				style="font-size: 12px"
				class="lk-primary-medium-color">
				{{ invitation.admin_email }}
			</span>
			<mat-icon
				(click)="onDeleteInvitationClick(invitation)"
				style="margin-left: 6px"
				class="lk-cursor-pointer lk-primary-medium-color">
				delete
			</mat-icon>
		</div>
	</div>

	<span
		*ngIf="!is_team_loading && team.length"
		style="font-size: 12px"
		class="lk-primary-medium-color">
		{{ 'STATION.TEAM_MEMBERS_LABEL' | translate }}
	</span>
	<div
		*ngIf="!is_team_loading && team.length"
		class="lk-full-width lk-row lk-wrapped-flex">
		<div
			*ngFor="let admin of team"
			class="lk-chips-layout lk-station-team-member-layout lk-centered-row lk-half-margin-bottom lk-half-margin-right">
			<span style="font-size: 12px">
				{{ admin.email }}
			</span>
			<mat-icon
				(click)="onDeleteMemberClick(admin)"
				style="margin-left: 6px"
				class="lk-cursor-pointer">
				delete
			</mat-icon>
		</div>
	</div>

	<div
		class="lk-centered-row"
		*ngIf="is_team_loading">
		<mat-spinner
			diameter="20"
			color="accent"></mat-spinner>
	</div>
</div>
