import { Injectable } from '@angular/core';

export const AUTH_SIGNIN_PATH: string = 'authenticate/signin';
export const AUTH_SIGNUP_PATH: string = 'authenticate/signup';
export const AUTH_MFA_SETUP_PATH: string = 'authenticate/mfa/setup';
export const AUTH_MFA_VERIFY_PATH: string = 'authenticate/mfa/verify';
export const AUTH_MFA_RESET_PATH: string = 'authenticate/mfa/reset';

export const BOOKINGS_PATH: string = 'bookings';
export const BOOKING_DETAILS_PATH: string = 'booking/';

export const HIDDEN_SETTINGS_PATH: string = 'hidden/settings';
export const HIDDEN_STATIONS_PATH: string = 'hidden/stations';

export const INVITATION_PATH: string = 'invitation';

export const STATION_PATH: string = 'station';
export const STATION_BUILDER_PATH: string = '/builder';
export const STATION_BUILDER_BASICS_PATH: string = '/builder/basics';
export const STATION_BUILDER_LOGO_PATH: string = '/builder/logo';
export const STATION_BUILDER_TEAM_PATH: string = '/builder/team';
export const STATION_BUILDER_SETTINGS_PATH: string = '/builder/notif/settings';
export const STATION_BUILDER_LANG_PATH: string = '/builder/notif/lang';
export const STATION_BUILDER_NOTIF_PATH: string = '/builder/notif/templates';

export const STATION_SETTINGS_PATH: string = 'station/settings';

export const VEHICLES_PATH: string = 'vehicles';
export const VEHICLES_MAP_PATH: string = 'vehicles/map';
export const VEHICLES_DETAILS_PATH: string = 'vehicle/';

export const PROFILE_PATH: string = 'profile';

export const METABASE_PATH: string = 'metabase';

export const SUBSCRIPTION_START_PATH: string = 'subscription/start';
export const SUBSCRIPTION_PAYMENT_PATH: string = 'subscription/payment';
export const SUBSCRIPTION_INFO_PATH: string = 'subscription/path';
export const SUBSCRIPTION_SIGNIN_PATH: string = 'subscription/signin';
export const SUBSCRIPTION_SIGNUP_PATH: string = 'subscription/signup';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	constructor() {}
}
