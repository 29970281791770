<div class="lk-horiz-half-padding">
	<div
		class="lk-spaced-row lk-half-margin-bottom"
		*ngIf="!hideHeader">
		<button
			(click)="onSortStartDateClick()"
			*ngIf="
				current_start_date_sort_option &&
				current_start_date_sort_option == asc_option
			"
			mat-icon-button>
			<mat-icon class="lk-accent-color"> expand_less </mat-icon>
		</button>

		<button
			(click)="onSortStartDateClick()"
			*ngIf="
				current_start_date_sort_option &&
				current_start_date_sort_option == desc_option
			"
			mat-icon-button>
			<mat-icon class="lk-accent-color"> expand_more </mat-icon>
		</button>

		<button
			(click)="onSortStartDateClick()"
			*ngIf="!current_start_date_sort_option"
			mat-icon-button>
			<mat-icon class="lk-primary-medium-color"> swap_vert </mat-icon>
		</button>

		<form [formGroup]="form_group">
			<mat-form-field
				color="primary"
				appearance="fill">
				<mat-label>
					{{ 'BOOKING.SEARCH_BY_EMAIL_LABEL' | translate }}
				</mat-label>
				<mat-icon
					matPrefix
					class="lk-primary-medium-color">
					search
				</mat-icon>
				<input
					matInput
					type="text"
					formControlName="email"
					(change)="onSearchChange()" />
				<mat-icon
					matSuffix
					class="lk-primary-medium-color lk-cursor-pointer"
					(click)="filtersClick.emit()">
					tune
				</mat-icon>
			</mat-form-field>
		</form>
	</div>

	<div
		class="lk-full-width lk-border-radius lk-padding lk-margin-bottom lk-shadow lk-cursor-pointer"
		id="lk-booking-list-item-layout"
		*ngFor="let booking of bookings"
		(click)="onBookingClick(booking)">
		<div
			class="lk-full-width lk-spaced-row"
			style="margin-top: -12px">
			<h3 class="lk-no-margin">
				{{ getUserIdentifier(booking) }}
			</h3>
			<button
				mat-icon-button
				style="margin-right: -12px"
				matTooltip="{{ 'VEHICLE_DETAILS.DELETE_VEHICLE' | translate }}"
				(click)="onBookingDeleteClick(booking); $event.stopPropagation()">
				<mat-icon style="color: red"> delete </mat-icon>
			</button>
		</div>

		<div>
			<div
				class="lk-border-radius"
				style="padding: 2px 12px; width: fit-content"
				[ngClass]="getStatusClass(booking)">
				<span> {{ getBookingStatusLabel(booking) | translate }} </span>
			</div>
			<p
				class="lk-no-margin lk-half-margin-top lk-primary-medium-color"
				style="font-size: 12px">
				{{ getTimezoneStartTime(booking) | date: 'short' }} -
				{{ getTimezoneEndTime(booking) | date: 'short' }}
				{{ getTimezoneOffset(booking) }}
			</p>
		</div>

		<div class="lk-centered-row lk-full-width lk-margin-top">
			<button
				*ngIf="isAddVehicleVisible(booking)"
				mat-button
				class="lk-main-button lk-full-width"
				(click)="onAddVehicleClick(booking); $event.stopPropagation()">
				<p
					*ngIf="!booking.vehicle_id"
					class="lk-primary-color lk-no-margin">
					{{ 'VEHICLE.ADD_CTA' | translate }}
				</p>

				<p
					*ngIf="booking.vehicle_id"
					class="lk-primary-color lk-no-margin">
					{{ 'VEHICLE.CHANGE_CTA' | translate }}
				</p>
			</button>
		</div>
	</div>
</div>
