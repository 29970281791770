<div id="lk-station-notif-test-dialog-root-layout">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-horiz-padding">
		<form
			*ngIf="type == sms_type"
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="test_sms_form_group">
			<span class="lk-margin-bottom lk-full-width">
				{{ 'STATION.TEST_NOTIF_ADD_PHONE_CTA' | translate }}:
			</span>

			<mat-form-field
				appearance="fill"
				floatLabel="always"
				color="accent"
				class="lk-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
				</mat-label>
				<img
					*ngIf="current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="unknown_flag_src" />

				<input
					type="text"
					matInput
					formControlName="phone_prefix"
					required
					[matAutocomplete]="autocountry" />
				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								style="min-width: 32px; height: 24px"
								class="lk-half-margin-right"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="
										max-width: calc(100% - 6px);
										font-size: 10px;
										line-height: 18px;
									"
									class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-no-margin lk-ellipsized-text">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-three-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					id="lk-booking-phone-builder-phone-input"
					matInput
					type="tel"
					formControlName="phone"
					required />
			</mat-form-field>

			<div class="lk-full-width lk-margin-top">
				<button
					(click)="onConfirmClick()"
					[disabled]="is_test_running || !isValidForm()"
					[ngClass]="getDisabledClass()"
					mat-button
					class="lk-main-button lk-margin-bottom lk-full-width">
					<span
						*ngIf="!is_test_running"
						class="lk-primary-color">
						{{ 'STATION.TEST_NOTIF_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_test_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>

		<form
			*ngIf="type == email_type"
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="test_email_form_group">
			<span class="lk-margin-bottom lk-full-width">
				{{ 'STATION.TEST_NOTIF_ADD_EMAIL_CTA' | translate }}:
			</span>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					matInput
					type="email"
					formControlName="email" />
			</mat-form-field>

			<div class="lk-full-width lk-margin-top">
				<button
					(click)="onConfirmClick()"
					[disabled]="is_test_running || !isValidForm()"
					[ngClass]="getDisabledClass()"
					mat-button
					class="lk-main-button lk-margin-bottom lk-full-width">
					<span
						*ngIf="!is_test_running"
						class="lk-primary-color">
						{{ 'STATION.TEST_NOTIF_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_test_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
