import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import {
	CUSTOM_AUTH_LOGO_PATH,
	CUSTOM_AUTH_LOGO_BACKGROUND_PATH,
} from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-auth-mfa-setup',
	templateUrl: './auth-mfa-setup.component.html',
	styleUrls: ['./auth-mfa-setup.component.scss'],
})
export class AuthMfaSetupComponent implements OnInit {
	mfa_setup_mechanism?: 'sms' | 'totp';

	logo_src: string = '../../../assets/imgs/lk-logo.svg';
	background_src: string =
		'../../../assets/imgs/lk-backgrounds/lk-default-auth-background.svg';

	constructor(
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.mfa_setup_mechanism = this.auth_service.mfa_setup_mechanism;

		const base_path = location.origin
			.replace('http://', '')
			.replace('https://', '');

		const dot_index = base_path.indexOf('.');
		const double_dot_index = base_path.indexOf(':');
		let subdomain: string;

		if (dot_index != -1) {
			subdomain = base_path.substring(0, dot_index);
		} else if (double_dot_index != -1) {
			subdomain = base_path.substring(0, double_dot_index);
		}

		console.log(subdomain);

		if (subdomain && subdomain != 'manage') {
			this.logo_src = CUSTOM_AUTH_LOGO_PATH(environment.STAGE, subdomain);
			this.background_src = CUSTOM_AUTH_LOGO_BACKGROUND_PATH(
				environment.STAGE,
				subdomain,
			);
		}
	}
}
