import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-vehicle-direct-link-confirm-dialog',
	templateUrl: './vehicle-direct-link-confirm-dialog.component.html',
	styleUrls: ['./vehicle-direct-link-confirm-dialog.component.scss'],
})
export class VehicleDirectLinkConfirmDialogComponent implements OnInit {
	link?: string;
	is_link_sent?: boolean = false;

	constructor(
		private dialog_ref: MatDialogRef<VehicleDirectLinkConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private snackbar: MatSnackBar,
	) {
		this.link = data.link;
		this.is_link_sent = data.is_link_sent;
	}

	ngOnInit(): void {}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onCopyClick(content?: string): void {
		if (content) {
			if (navigator.clipboard) {
				navigator.clipboard.writeText(content).then(() => {
					this.showSnackbar('Copied!', 'check_circle_outlined');
				});
			} else {
				const text_area = document.createElement('textarea');
				text_area.value = content;
				document.body.appendChild(text_area);
				text_area.focus();
				text_area.select();

				try {
					const successful = document.execCommand('copy');
					if (successful) {
						this.showSnackbar('Copied!', 'check_circle_outlined');
					}
				} catch (err) {}
				document.body.removeChild(text_area);
			}
		}
	}

	showSnackbar(message: string, icon: string): void {
		this.snackbar.open(message, '', {
			duration: 2 * 1000,
			data: { message, icon },
		});
	}
}
