import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SUBSCRIPTION_PAYMENT_PATH } from 'src/app/core/navigation.service';
import { NEW_USER_FLOW } from 'src/app/shared/constants';

@Component({
	selector: 'app-subscription-start-dialog',
	templateUrl: './subscription-start-dialog.component.html',
	styleUrls: ['./subscription-start-dialog.component.scss'],
})
export class SubscriptionStartDialogComponent implements OnInit {
	constructor(
		private dialog_ref: MatDialogRef<SubscriptionStartDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private router: Router,
	) {}

	ngOnInit(): void {}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onCurrencyclick(currency: string): void {
		this.router.navigate([SUBSCRIPTION_PAYMENT_PATH], {
			queryParams: { flow: NEW_USER_FLOW },
		});
		this.dialog_ref.close();
	}
}
