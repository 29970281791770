<mat-expansion-panel class="lk-vert-half-padding-md">
	<mat-expansion-panel-header>
		<mat-panel-title class="lk-spaced-row">
			<h3 *ngIf="!checklist || !checklist.messages?.length">
				No checklist message added yet
			</h3>

			<h3 *ngIf="checklist && checklist.messages?.length">
				<span
					style="font-size: 24px"
					class="lk-accent-color">
					<b> {{ checklist.messages.length }} </b>
				</span>
				message{{ checklist.messages.length > 1 ? 's' : '' }} added
			</h3>

			<span
				(click)="clear.emit(); $event.stopPropagation()"
				class="lk-cursor-pointer lk-warn-color lk-right-text">
				<u>clear checklist</u>
			</span>
		</mat-panel-title>
	</mat-expansion-panel-header>

	<div class="lk-half-padding lk-centered-text">
		<button
			mat-button
			(click)="onLangItemClick('en')"
			[ngClass]="getCurrentLangClass('en')">
			EN
		</button>

		<button
			mat-button
			(click)="onLangItemClick('es')"
			[ngClass]="getCurrentLangClass('es')">
			ES
		</button>

		<button
			mat-button
			(click)="onLangItemClick('it')"
			[ngClass]="getCurrentLangClass('it')">
			IT
		</button>
	</div>

	<div class="lk-horiz-double-padding-md">
		<div
			*ngFor="let message of getCurrentLangMessages()"
			[innerHTML]="escapeMessage(message)"></div>
	</div>
</mat-expansion-panel>
