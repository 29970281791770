import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
@Component({
	selector: 'app-vehicle-empty',
	templateUrl: './vehicle-empty.component.html',
	styleUrls: ['./vehicle-empty.component.scss'],
})
export class VehicleEmptyComponent implements OnInit {
	@Output() vehicle_build_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() manual_request_click: EventEmitter<void> = new EventEmitter<void>();
	vehicle_add_contact_form_group: FormGroup =
		this.form_service.vehicle_add_contact_form_group;
	is_send_request_running: boolean = false;

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {}

	onSendClick(): void {
		this.manual_request_click.emit();
	}

	onVehicleBuildClick() {
		this.vehicle_build_click.emit();
	}
}
