<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		class="lk-centered-row lk-double-padding"
		*ngIf="is_request_running">
		<mat-spinner
			diameter="30"
			color="accent"></mat-spinner>
	</div>

	<div
		*ngIf="!is_request_running"
		id="lk-booking-add-vehicle-content-layout"
		class="lk-horiz-padding lk-hide-scrollbar">
		<h3>{{ 'BOOKING.ADD_VEHICLE_DIALOG_TITLE' | translate }}</h3>
		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label>
					{{ 'BOOKING.VEHICLE_PLATE_COLUMN_LABEL' | translate }}
				</mat-label>
				<input
					id="lk-booking-add-vehicle-plate-input"
					type="text"
					matInput
					formControlName="plate"
					[matAutocomplete]="auto" />
				<mat-autocomplete
					autoActiveFirstOption
					#auto="matAutocomplete">
					<mat-option
						*ngFor="let vehicle of filtered_vehicles | async"
						[value]="vehicle.plate"
						style="line-height: 24px">
						<div class="lk-row">
							<div
								class="lk-booking-add-vehicle-online-circle lk-half-margin-right"
								[ngClass]="getOnlineClass(vehicle)"></div>
							{{ vehicle.plate }}
							<div
								class="lk-border-radius lk-half-margin-left"
								[ngClass]="getVehicleStatusClass(vehicle)"
								style="padding: 2px 12px; width: fit-content"
								*ngIf="vehicle && vehicle.status">
								<span> {{ vehicle.status | lowercase }} </span>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<div class="lk-centered-column lk-full-width lk-margin-bottom">
				<h2
					*ngIf="vehicle"
					class="lk-centered-text lk-no-margin">
					{{ vehicle.plate }}
				</h2>
				<span
					*ngIf="vehicle"
					class="lk-primary-medium-color lk-centered-text">
					{{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }} &
					{{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate }}:
				</span>
				<span
					*ngIf="vehicle"
					class="lk-centered-text">
					{{ vehicle.brand }} {{ vehicle.model }}
				</span>
			</div>

			<div class="lk-centered-row lk-full-width">
				<button
					id="lk-booking-add-vehicle-confirm-button"
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()"
					[disabled]="!this.getNextDisabledCondition()"
					[ngClass]="getNextDisabledClass()">
					<span class="lk-primary-color">
						{{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }}
					</span>
				</button>
			</div>
		</form>
	</div>
</div>
