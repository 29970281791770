import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LOCAL_STATION_KEY} from '../../shared/constants';
import {LocalManagerService} from '../../core/local-manager.service';
import {LockSettings, Station, UnlockSettings} from '../../core/station.service';
import {FormService} from '../../core/form.service';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-station-settings-trigger',
  templateUrl: './station-settings-trigger.component.html',
  styleUrls: ['./station-settings-trigger.component.scss']
})
export class StationSettingsTriggerComponent implements OnInit {
  @Input()  stationUpdateTrigger: BehaviorSubject<boolean>;
  @Output() triggerUpdateClick: EventEmitter<{unlock: UnlockSettings, lock: LockSettings}> = new EventEmitter<{unlock: UnlockSettings; lock: LockSettings}>();
  current_station: Station;
  is_unlockable_on_vehicle_assignment: boolean;
  is_unlockable_until_booking_end: boolean;
  is_lockable_after_vehicle_unlock: boolean;

  constructor(
    private local_manager: LocalManagerService, 
    private form_service: FormService
  ) {}

  ngOnInit() {
    this.stationUpdateTrigger.subscribe(
      update => {
        if (update) {
          this.current_station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
          this.initFlags();
        }
      }
    );
  }

  onUnlockableOnVehicleAssignmentChange(flag: boolean) {
    this.is_unlockable_on_vehicle_assignment = flag;
  }

  onUnlockableUntilBookingEndChange(flag: boolean) {
    this.is_unlockable_until_booking_end = flag;
  }

  onLockableAfterVehicleUnlockChange(flag: boolean) {
    this.is_lockable_after_vehicle_unlock = flag;
  }

  onTriggerUpdateClick() {
    let hoursAndMinutes: number[];

    // Unlock settings
    let unlockSettings: UnlockSettings = new UnlockSettings();
    unlockSettings.is_unlockable_on_vehicle_assignment = this.is_unlockable_on_vehicle_assignment;
    unlockSettings.is_unlockable_until_booking_end_date = this.is_unlockable_until_booking_end;
    hoursAndMinutes = this.parseTimeFormGroup(this.form_service.stationSettingsUnlockLinkFormGroup);
    unlockSettings.time_to_unlock_link_before_booking_start = this.getMillisFromHoursAndMin(hoursAndMinutes[0],hoursAndMinutes[1]);
    hoursAndMinutes = this.parseTimeFormGroup(this.form_service.stationSettingsUnlockBeforeFormGroup);
    unlockSettings.time_to_unlock_availability_before_booking_start = this.getMillisFromHoursAndMin(hoursAndMinutes[0],hoursAndMinutes[1]);
    hoursAndMinutes = this.parseTimeFormGroup(this.form_service.stationSettingsUnlockAfterFormGroup);
    unlockSettings.time_to_unlock_availability_after_booking_start = this.getMillisFromHoursAndMin(hoursAndMinutes[0],hoursAndMinutes[1]);

    // Lock settings
    let lockSettings: LockSettings = new LockSettings()
    lockSettings.is_lockable_after_vehicle_unlock = this.is_lockable_after_vehicle_unlock;
    hoursAndMinutes = this.parseTimeFormGroup(this.form_service.stationSettingsLockReminderFormGroup);
    hoursAndMinutes = this.parseTimeFormGroup(this.form_service.stationSettingsLockLinkFormGroup);
    lockSettings.time_to_lock_link_before_booking_end = this.getMillisFromHoursAndMin(hoursAndMinutes[0],hoursAndMinutes[1]);
    hoursAndMinutes = this.parseTimeFormGroup(this.form_service.stationSettingsLockBeforeFormGroup);
    lockSettings.time_to_lock_availability_before_booking_end = this.getMillisFromHoursAndMin(hoursAndMinutes[0],hoursAndMinutes[1]);
    hoursAndMinutes = this.parseTimeFormGroup(this.form_service.stationSettingsLockAfterFormGroup);
    lockSettings.time_to_lock_availability_after_booking_end = this.getMillisFromHoursAndMin(hoursAndMinutes[0],hoursAndMinutes[1]);

    this.triggerUpdateClick.emit({
      unlock: unlockSettings,
      lock: lockSettings
    });
  }

  initFlags() {
    this.is_unlockable_on_vehicle_assignment = this.current_station.settings.unlock_settings?
      this.current_station.settings.unlock_settings.is_unlockable_on_vehicle_assignment : false;
    this.is_unlockable_until_booking_end = this.current_station.settings.unlock_settings?
      this.current_station.settings.unlock_settings.is_unlockable_until_booking_end_date : false;
    this.is_lockable_after_vehicle_unlock = this.current_station.settings.lock_settings?
      this.current_station.settings.lock_settings.is_lockable_after_vehicle_unlock : false;
  }

  parseTimeFormGroup(formGroup: FormGroup): number[] {
    const time: string = formGroup.get('time').value;
    const columnIndex: number = time.indexOf(':');
    return [
      +time.substr(0, columnIndex),
      +time.substr(columnIndex+1, time.length)
    ];
  }

  getMillisFromHoursAndMin(hours: number, minutes: number) {
    const HOUR_IN_MILLIS  : number = 3600000;
    const MINUTE_IN_MILLIS: number = 60000;
    return hours * HOUR_IN_MILLIS + minutes * MINUTE_IN_MILLIS;
  }
}
