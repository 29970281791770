/** DIALOGS **/
export const GENERAL_ERROR_MESSAGE: string = 'GENERAL.ERROR_MESSAGE';
export const PASSWORD_RESET_SUCCESS_MESSAGE: string =
	'AUTHENTICATION.PASSWORD_RESET_SUCCESS_MESSAGE';
export const SIGNUP_SUCCESS_TITLE: string =
	'AUTHENTICATION.SIGNUP_SUCCESS_TITLE';
export const SIGNUP_SUCCESS_MESSAGE: string =
	'AUTHENTICATION.SIGNUP_SUCCESS_MESSAGE';
export const NO_NOTIF_TYPE_MESSAGE: string =
	'STATION_BUILDER.NO_NOTIF_TYPE_SELECTED_MESSAGE';
export const SIGNUP_PASSWORD_MISMATCH_ERROR: string =
	'AUTHENTICATION.SIGNUP_PASSWORD_MISMATCH_ERROR';
export const STATION_DELETE_MESSAGE: string = 'STATION.DELETE_SUCCESS_MESSAGE';
export const VEHICLE_DELETE_MESSAGE: string = 'VEHICLE.DELETE_SUCCESS_MESSAGE';
export const BOOKING_DELETE_MESSAGE: string = 'BOOKING.DELETE_SUCCESS_MESSAGE';
export const NO_PIC_POLICY_MESSAGE: string = 'STATION.NO_PIC_POLICY_ERROR';
export const NO_RESULT_FOUND_MESSAGE: string = 'GENERAL.NO_RESULTS_FOUND';
export const VEHICLE_ALREADY_RUNNING_ERROR_MESSAGE: string =
	'VEHICLE.ALREADY_RUNNING_ERROR';
export const VEHICLE_ALREADY_FREE_ERROR_MESSAGE: string =
	'VEHICLE.ALREADY_FREE_ERROR';
export const CODE_MISMATCH_ERROR_MESSAGE: string =
	'AUTHENTICATION.CODE_MISMATCH_ERROR_MESSAGE';
export const BOOKING_PAST_ERROR_MESSAGE: string = 'BOOKING.PAST_ERROR';
export const BOOKING_MISMATCH_ERROR_MESSAGE: string = 'BOOKING.MISMATCH_ERROR';
export const BOOKING_DURATION_ERROR_MESSAGE: string = 'BOOKING.DURATION_ERROR';
export const ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE: string =
	'VEHICLE.ADAPTER_CRED_NOT_FOUND_ERROR';
export const PROGRESS_UPDATE_ERROR_MESSAGE: string =
	'BOOKING_DETAILS.PROGRESS_UPDATE_ERROR';
export const COMPLETED_UPDATE_ERROR_MESSAGE: string =
	'BOOKING_DETAILS.COMPLETED_UPDATE_ERROR';
export const ERRORS_UPDATE_ERROR_MESSAGE: string =
	'BOOKING_DETAILS.ERRORS_UPDATE_ERROR';
export const VEHICLE_ALREADY_ASSIGNED_ERROR_MESSAGE: string =
	'VEHICLE_DETAILS.VEHICLE_ALREADY_ASSIGNED_ERROR';
export const USER_EMAIL_UPDATE_SUCCESS_MESSAGE: string =
	'BOOKING_DETAILS.USER_EMAIL_UPDATE_SUCCESS_MESSAGE';

export const MFA_RESET_SUCCESS_MESSAGE: string =
	'AUTHENTICATION.MFA_RESET_SUCCESS_MESSAGE';

export const DIRECT_LINK_SENT_MESSAGE: string =
	'VEHICLE.DIRECT_LINK_SENT_MESSAGE';

export const ADAPTER_FAILED_ERROR_MESSAGE: string =
	'VEHICLE_DETAILS.FAILED_VEHICLE_ERROR';
export const ADAPTER_OFFLINE_ERROR_MESSAGE: string =
	'VEHICLE_DETAILS.OFFLINE_VEHICLE_ERROR';
export const ADAPTER_GENERIC_ERROR_MESSAGE: string =
	'VEHICLE_DETAILS.GENERIC_VEHICLE_ERROR';

export const SEND_UNLOCK_LINK_SUCCESS_MESSAGE: string =
	'BOOKING_DETAILS.SEND_UNLOCK_SUCCESS';
export const SEND_UNLOCK_LINK_FAILURE_MESSAGE: string =
	'BOOKING_DETAILS.SEND_UNLOCK_FAILURE';
export const SEND_LOCK_LINK_SUCCESS_MESSAGE: string =
	'BOOKING_DETAILS.SEND_LOCK_SUCCESS';
export const SEND_LOCK_LINK_FAILURE_MESSAGE: string =
	'BOOKING_DETAILS.SEND_LOCK_FAILURE';

export const TEST_NOTIF_SUCCESS_MESSAGE: string =
	'STATION.TEST_NOTIF_SUCCESS_MESSAGE';

export const NOTIF_ITEM_INFO_MESSAGE: string =
	'STATION.PARAMETERS_LIST_TOOLTIP';

export const VEHICLE_MANUAL_REQUEST_SENT_MESSAGE: string =
	'VEHICLE.REQUEST_SENT_MESSAGE';

/** TOOLBAR **/
export const TOOLBAR_STATION_NAV_ITEM_NAME: string =
	'TOOLBAR.STATION_NAV_ITEM_NAME';
export const TOOLBAR_STATION_SETTINGS_NAV_ITEM_NAME: string =
	'TOOLBAR.STATION_SETTINGS_NAV_ITEM_NAME';
export const TOOLBAR_BOOKINGS_NAV_ITEM_NAME: string =
	'TOOLBAR.BOOKINGS_NAV_ITEM_NAME';
export const TOOLBAR_VEHICLES_NAV_ITEM_NAME: string =
	'TOOLBAR.VEHICLES_NAV_ITEM_NAME';
export const TOOLBAR_METABASE_NAV_ITEM_NAME: string = 'Data';

/** STATION BUILDER **/
export const STATION_BUILDER_BASICS_STEP: string =
	'STATION_BUILDER.STEP_BASICS_NAME';
export const STATION_BUILDER_LOGO_STEP: string =
	'STATION_BUILDER.STEP_LOGO_NAME';
export const STATION_BUILDER_TEAM_STEP: string =
	'STATION_BUILDER.STEP_TEAM_NAME';
export const STATION_BUILDER_NOTIF_STEP: string =
	'STATION_BUILDER.STEP_NOTIF_NAME';
export const STATION_BUILDER_CONTRACTS_STEP: string =
	'STATION_BUILDER.STEP_CONTRACTS_NAME';
export const STATION_BUILDER_SERVICES_STEP: string =
	'STATION_BUILDER.STEP_SERVICES_NAME';

export const STATION_BUILDER_NOTIF_TOGGLE_STEP: string =
	'STATION_BUILDER.STEP_NOTIF_TOGGLE_NAME';
export const STATION_BUILDER_NOTIF_LANG_STEP: string =
	'STATION_BUILDER.STEP_NOTIF_LANG_NAME';
export const STATION_BUILDER_NOTIF_LIST_STEP: string =
	'STATION_BUILDER.STEP_NOTIF_LIST_NAME';

export const START_RENTAL_SMS_LABEL: string =
	'STATION_BUILDER.START_RENTAL_SMS_LABEL';
export const READY_BOOKING_EDIT_SMS_LABEL: string =
	'STATION_BUILDER.READY_BOOKING_EDIT_SMS_LABEL';
export const BOOKING_VEHICLE_EDITED_SMS_LABEL: string =
	'STATION_BUILDER.BOOKING_VEHICLE_EDITED_SMS_LABEL';
export const BOOKING_COMPLETE_SMS_LABEL: string =
	'STATION_BUILDER.BOOKING_COMPLETE_SMS_LABEL';
export const BOOKING_DATES_EDITED_SMS_LABEL: string =
	'STATION_BUILDER.BOOKING_DATES_EDITED_SMS_LABEL';
export const FINISHING_BOOKING_SMS_LABEL: string =
	'STATION_BUILDER.FINISHING_BOOKING_SMS_LABEL';
export const END_RENTAL_SMS_LABEL: string =
	'STATION_BUILDER.END_RENTAL_SMS_LABEL';
export const RUNNING_BOOKING_EDIT_SMS_LABEL: string =
	'STATION_BUILDER.RUNNING_BOOKING_EDIT_SMS_LABEL';
export const PENDING_BOOKING_EDIT_SMS_LABEL: string =
	'STATION_BUILDER.PENDING_BOOKING_EDIT_SMS_LABEL';
export const BOOKING_CANCELED_EDIT_SMS_LABEL: string =
	'STATION_BUILDER.BOOKING_CANCELED_EDIT_SMS_LABEL';
export const START_RENTAL_EMAIL_LABEL: string =
	'STATION_BUILDER.START_RENTAL_EMAIL_LABEL';
export const END_RENTAL_EMAIL_LABEL: string =
	'STATION_BUILDER.END_RENTAL_EMAIL_LABEL';
export const BOOKING_VEHICLE_EDITED_EMAIL_LABEL: string =
	'STATION_BUILDER.BOOKING_VEHICLE_EDITED_EMAIL_LABEL';
export const BOOKING_DATES_EDITED_EMAIL_LABEL: string =
	'STATION_BUILDER.BOOKING_DATES_EDITED_EMAIL_LABEL';
export const BOOKING_CANCELED_EMAIL_LABEL: string =
	'STATION_BUILDER.BOOKING_CANCELED_EMAIL_LABEL';
export const BOOKING_COMPLETE_EMAIL_LABEL: string =
	'STATION_BUILDER.BOOKING_COMPLETE_EMAIL_LABEL';

/** STATION SETTINGS **/
export const SETTINGS_TTUL_TITLE: string = 'STATION.SETTINGS_TTUL_TITLE';
export const SETTINGS_TTUL_SUBTITLE: string = 'STATION.SETTINGS_TTUL_SUBTITLE';
export const SETTINGS_TTUL_EXAMPLE: string = 'STATION.SETTINGS_TTUL_EXAMPLE';
export const SETTINGS_TTUA_TITLE: string = 'STATION.SETTINGS_TTUA_TITLE';
export const SETTINGS_TTUA_SUBTITLE: string = 'STATION.SETTINGS_TTUA_SUBTITLE';
export const SETTINGS_TTUA_EXAMPLE: string = 'STATION.SETTINGS_TTUA_EXAMPLE';
export const SETTINGS_TTLL_TITLE: string = 'STATION.SETTINGS_TTLL_TITLE';
export const SETTINGS_TTLL_SUBTITLE: string = 'STATION.SETTINGS_TTLL_SUBTITLE';
export const SETTINGS_TTLL_EXAMPLE: string = 'STATION.SETTINGS_TTLL_EXAMPLE';
export const SETTINGS_TTLA_TITLE: string = 'STATION.SETTINGS_TTLA_TITLE';
export const SETTINGS_TTLA_SUBTITLE: string = 'STATION.SETTINGS_TTLA_SUBTITLE';
export const SETTINGS_TTLA_EXAMPLE: string = 'STATION.SETTINGS_TTLA_EXAMPLE';

/** VEHICLES **/
export const VEHICLE_ADD_CONTACT_MESSAGE: string =
	'VEHICLE.ADD_CONTACT_FORM_MESSAGE';
export const VEHICLES_MANUAL_REQUEST_SUBJECT: string = 'Manual request for';
export const VEHICLES_MANUAL_REQUEST_DESCRIPTION_INTRO: string = "Hello, I'm";
export const VEHICLES_MANUAL_REQUEST_DESCRIPTION_BODY: string =
	"I'm requesting the installation manual for the following vehicles";

/** BOOKINGS  **/
export const BOOKING_ALL_LABEL: string = 'BOOKING.SUMMARY_ALL_LABEL';
export const BOOKING_PENDING_LABEL: string = 'BOOKING.SUMMARY_PENDING_LABEL';
export const BOOKING_IN_PROGRESS_LABEL: string =
	'BOOKING.SUMMARY_IN_PROGRESS_LABEL';
export const BOOKING_COMPLETED_LABEL: string = 'BOOKING.SINGLE_COMPLETED_LABEL';
export const BOOKING_ERROR_LABEL: string = 'BOOKING.SUMMARY_ERROR_LABEL';
export const BOOKING_ERROR_WO_VEHICLE_LABEL: string =
	'BOOKING.EXP_WO_VEHICLE_LABEL';
export const BOOKING_ERROR_WO_START_LABEL: string =
	'BOOKING.EXP_WO_START_LABEL';
export const BOOKING_ERROR_WO_STOP_LABEL: string = 'BOOKING.EXPO_WO_STOP_LABEL';

/** I18N **/
export const DEFAULT_LANGUAGE_LABEL: string = 'GENERAL.LANG_NOTIF_EN';
export const SUPPORTED_LANGUAGES_LABELS: string[] = [
	'GENERAL.LANG_NOTIF_IT',
	'GENERAL.LANG_NOTIF_EN',
	'GENERAL.LANG_NOTIF_ES',
	'GENERAL.LANG_NOTIF_FR',
];

/** PICTURES POLICY **/
export const PICTURES_MANDATORY_POLICY_TITLE: string =
	'GENERAL.PICTURES_MANDATORY_POLICY_TITLE';
export const PICTURES_PICK_UP_POLICY_TITLE: string =
	'GENERAL.PICTURES_PICK_UP_POLICY_TITLE';
export const PICTURES_DROP_OFF_POLICY_TITLE: string =
	'GENERAL.PICTURES_DROP_OFF_POLICY_TITLE';
export const PICTURES_OPTIONAL_POLICY_TITLE: string =
	'GENERAL.PICTURES_OPTIONAL_POLICY_TITLE';
export const PICTURES_DISABLED_POLICY_TITLE: string =
	'GENERAL.PICTURES_DISABLED_POLICY_TITLE';

/** STATION NAVBAR LABELS **/
export const STATION_NAVBAR_TEAM_LABEL: string =
	'STATION.NAVBAR_TEAM_ITEM_NAME';
export const STATION_NAVBAR_NOTIF_LABEL: string =
	'STATION.NAVBAR_NOTIF_ITEM_NAME';
export const STATION_NAVBAR_PICS_LABEL: string =
	'STATION.NAVBAR_PICS_ITEM_NAME';
export const STATION_NAVBAR_UNLOCK_LABEL: string =
	'STATION.NAVBAR_UNLOCK_ITEM_NAME';
export const STATION_NAVBAR_LOCK_LABEL: string =
	'STATION.NAVBAR_LOCK_ITEM_NAME';
export const STATION_NAVBAR_DATA_SETTINGS_LABEL: string =
	'STATION.NAVBAR_DATA_SETTINGS_ITEM_NAME';

/** BOOKING DETAILS NAVBAR LABELS **/
export const BOOKING_DETAILS_NAVBAR_GENERAL_LABEL: string =
	'BOOKING_DETAILS.NAVBAR_GENERAL_LABEL';
export const BOOKING_DETAILS_NAVBAR_LINKS_LABEL: string =
	'BOOKING_DETAILS.NAVBAR_LINKS_LABEL';
export const BOOKING_DETAILS_NAVBAR_EVENTS_LABEL: string =
	'BOOKING_DETAILS.NAVBAR_EVENTS_LABEL';
export const BOOKING_DETAILS_NAVBAR_GALLERY_LABEL: string =
	'BOOKING_DETAILS.NAVBAR_GALLERY_LABEL';
export const BOOKING_DETAILS_NAVBAR_DATA_LABEL: string =
	'BOOKING_DETAILS.NAVBAR_DATA_LABEL';
export const BOOKING_DETAILS_NAVBAR_PATH_LABEL: string =
	'BOOKING_DETAILS.NAVBAR_PATH_LABEL';

/** VEHICLE DETAILS NAVBAR LABELS **/
export const VEHICLE_DETAILS_NAVBAR_GENERAL_LABEL: string =
	'VEHICLE_DETAILS.NAVBAR_GENERAL_LABEL';
export const VEHICLE_DETAILS_NAVBAR_COMMANDS_LABEL: string =
	'VEHICLE_DETAILS.NAVBAR_COMMANDS_LABEL';
export const VEHICLE_DETAILS_NAVBAR_MEASURES_LABEL: string =
	'VEHICLE_DETAILS.NAVBAR_MEASURES_LABEL';
export const VEHICLE_DETAILS_NAVBAR_GALLERY_LABEL: string =
	'VEHICLE_DETAILS.NAVBAR_GALLERY_LABEL';
export const VEHICLE_DETAILS_NAVBAR_LINK_LABEL: string =
	'VEHICLE_DETAILS.NAVBAR_LINK_LABEL';

/** COMMANDS LABEL  **/
export const COMMAND_HOST_UNLOCK_SUCCEEDED: string =
	'VEHICLE_DETAILS.COMMAND_HOST_UNLOCK_SUCCEEDED';
export const COMMAND_HOST_UNLOCK_FAILED: string =
	'VEHICLE_DETAILS.COMMAND_HOST_UNLOCK_FAILED';
export const COMMAND_HOST_LOCK_SUCCEEDED: string =
	'VEHICLE_DETAILS.COMMAND_HOST_LOCK_SUCCEEDED';
export const COMMAND_HOST_LOCK_FAILED: string =
	'VEHICLE_DETAILS.COMMAND_HOST_LOCK_FAILED';
export const COMMAND_GUEST_UNLOCK_SUCCEEDED: string =
	'VEHICLE_DETAILS.COMMAND_GUEST_UNLOCK_SUCCEEDED';
export const COMMAND_GUEST_UNLOCK_FAILED: string =
	'VEHICLE_DETAILS.COMMAND_GUEST_UNLOCK_FAILED';
export const COMMAND_GUEST_LOCK_SUCCEEDED: string =
	'VEHICLE_DETAILS.COMMAND_GUEST_LOCK_SUCCEEDED';
export const COMMAND_GUEST_LOCK_FAILED: string =
	'VEHICLE_DETAILS.COMMAND_GUEST_LOCK_FAILED';

/** EVENTS LABEL **/
export const CREATED_EVENT_LABEL: string =
	'BOOKING_DETAILS.CREATED_EVENT_LABEL';
export const VEHICLE_ASSIGNED_EVENT_LABEL: string =
	'BOOKING_DETAILS.VEHICLE_ASSIGNED_EVENT_LABEL';
export const UNLOCK_KEY_SENT_EVENT_LABEL: string =
	'BOOKING_DETAILS.UNLOCK_KEY_SENT_EVENT_LABEL';
export const UNLOCK_KEY_VISITED_EVENT_LABEL: string =
	'BOOKING_DETAILS.UNLOCK_KEY_VISITED_EVENT_LABEL';
export const LOCK_KEY_SENT_EVENT_LABEL: string =
	'BOOKING_DETAILS.LOCK_KEY_SENT_EVENT_LABEL';
export const LOCK_KEY_VISITED_EVENT_LABEL: string =
	'BOOKING_DETAILS.LOCK_KEY_VISITED_EVENT_LABEL';
export const UNLOCK_SUCCEEDED_EVENT_LABEL: string =
	'BOOKING_DETAILS.UNLOCK_SUCCEEDED_EVENT_LABEL';
export const UNLOCK_FAILED_EVENT_LABEL: string =
	'BOOKING_DETAILS.UNLOCK_FAILED_EVENT_LABEL';
export const LOCK_SUCCEEDED_EVENT_LABEL: string =
	'BOOKING_DETAILS.LOCK_SUCCEEDED_EVENT_LABEL';
export const LOCK_FAILED_EVENT_LABEL: string =
	'BOOKING_DETAILS.LOCK_FAILED_EVENT_LABEL';

export const DEFAULT_CHECKLIST = [
	{
		en: '<p>Please pay attention! Make sure to follow these steps before locking the vehicle:</p><ol><li>Make sure you have parked in a safe and legal space</li><li>Check the interiors of the vehicle to avoid forgetting any personal items</li><li>Make sure the doors are unlocked</li><li>Place the car keys inside the vehicle where you have found them at the beginning of you rental. Important: DO NOT PRESS LOCK ON THE KEY</li><li>Exit from the vehicle and close the doors</li><li>Press on the confirmation button below to lock the car doors</li><li>Please make sure the doors are locked before leaving</li></ol>',
	},
	{
		es: '<p>¡Presta atención! Asegúrate de seguir estos pasos antes de cerrar el vehículo:</p><ol><li>Asegúrate de haber aparcado en un lugar seguro y legal</li><li>Revisa el interior del vehículo para evitar olvidar ningún objeto personal</li><li>Asegúrate de que las puertas estén desbloqueadas</li><li>Coloca las llaves del coche dentro del vehículo donde las encontraste al principio del alquiler. Importante: NO PRESIONES EL BLOQUEO DE LA LLAVE</li><li>Sal del vehículo y cierra las puertas</li><li>Pulsa el botón de confirmación que aparece a continuación para bloquear las puertas del coche</li><li>Asegúrate de que las puertas estén bloqueadas antes de salir</li></ol>',
	},
	{
		it: "<p>Si prega di prestare attenzione! Assicurarsi di seguire questi passaggi prima di chiudere a chiave il veicolo:</p><ol><li>Assicurarsi di aver parcheggiato in uno spazio sicuro e legale</li><li>Controllare gli interni del veicolo per evitare di dimenticare oggetti personali</li><li>Assicurarsi che le portiere siano sbloccate</li><li>Riporre le chiavi dell'auto all'interno del veicolo dove le hai trovate all'inizio del noleggio. Importante: NON PREMERE IL BLOCCO SULLA CHIAVE</li><li>Esci dal veicolo e chiudi le portiere</li><li>Premere il pulsante di conferma qui sotto per bloccare le portiere dell'auto</li><li>Assicurarsi che le portiere siano bloccate prima di partire</li></ol>",
	},
];
