import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialog,
} from '@angular/material/dialog';
import {
	Vehicle,
	VehicleCategory,
	VehicleStatus,
} from '../../core/vehicle.service';
import { DialogService } from 'src/app/core/dialog.service';
import { VehicleTuroDialogComponent } from '../vehicle-turo-dialog/vehicle-turo-dialog.component';
import { environment } from 'src/environments/environment';
import { PROD_STAGE } from 'src/app/shared/constants';

@Component({
	selector: 'app-vehicle-builder-dialog',
	templateUrl: './vehicle-builder-dialog.component.html',
	styleUrls: ['./vehicle-builder-dialog.component.scss'],
})
export class VehicleBuilderDialogComponent implements OnInit {
	form_group: FormGroup;
	vehicle: Vehicle;
	is_production: boolean;
	locked_status: VehicleStatus = VehicleStatus.LOCKED;
	unlocked_status: VehicleStatus = VehicleStatus.UNLOCKED;
	categories: string[] = [
		VehicleCategory.COMMERCIAL.toString(),
		VehicleCategory.LARGE.toString(),
		VehicleCategory.VAN.toString(),
		VehicleCategory.WAGON.toString(),
		VehicleCategory.MEDIUM.toString(),
		VehicleCategory.PREMIUM.toString(),
		VehicleCategory.SMALL.toString(),
		VehicleCategory.SUV.toString(),
	];

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private dialog_ref: MatDialogRef<VehicleBuilderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.dialog_service.dialog = this.dialog;
		this.form_group = this.form_service.vehicleBuilderFormGroup;
		if (data && data.vehicle) {
			this.vehicle = data.vehicle;
		}
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;
		if (!this.vehicle) {
			this.form_group.reset();
		} else {
			this.initFormGroup();
		}

		if (this.vehicle) {
			this.form_group.get('adapter_id').disable();
		} else {
			this.form_group.get('adapter_id').enable();
		}
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onTuroConfigClick() {
		this.dialog_service
			.openDialog(VehicleTuroDialogComponent, { vehicle: this.vehicle })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.turo_id) {
					this.form_group.get('ext_id').setValue(dialogResult.turo_id);
				}
			});
	}

	onConfirmClick() {
		if (this.form_service.validateForm(this.form_group)) {
			if (!this.vehicle) {
				let vehicle: Vehicle = this.buildVehicle();
				this.dialog_ref.close({ vehicle: vehicle });
			} else {
				this.updateVehicle();
				this.dialog_ref.close({ vehicle: this.vehicle });
			}
		}
	}

	onExtIdInput(event: any) {}

	buildVehicle() {
		let vehicle: Vehicle = new Vehicle();
		vehicle.plate = this.form_group.get('plate').value;
		vehicle.category = this.form_group.get('category').value;
		vehicle.brand = this.form_group.get('brand').value;
		vehicle.model = this.form_group.get('model').value;
		vehicle.color = this.form_group.get('color').value;
		vehicle.year = this.form_group.get('year').value;
		vehicle.ext_id = this.form_group.get('ext_id').value;
		vehicle.status = this.form_group.get('initial_status').value
			? this.form_group.get('initial_status').value
			: VehicleStatus.LOCKED;
		vehicle.adapter = {
			id: this.form_group.get('adapter_id').value,
			qrcode: this.form_group.get('ref_code').value,
			profile: this.form_group.get('profile').value,
		};
		return vehicle;
	}

	initFormGroup() {
		this.form_group.get('ext_id').setValue(this.vehicle.ext_id);
		this.form_group.get('plate').setValue(this.vehicle.plate);
		this.form_group.get('adapter_id').setValue(this.vehicle.adapter.id);
		this.form_group.get('ref_code').setValue(this.vehicle.adapter.qrcode);
		this.form_group.get('profile').setValue(this.vehicle.adapter.profile);
		this.form_group.get('category').setValue(this.vehicle.category);
		this.form_group.get('brand').setValue(this.vehicle.brand);
		this.form_group.get('model').setValue(this.vehicle.model);
		this.form_group.get('color').setValue(this.vehicle.color);
		this.form_group.get('year').setValue(this.vehicle.year);
	}

	updateVehicle() {
		this.vehicle.ext_id = this.form_group.get('ext_id').value;
		this.vehicle.plate = this.form_group.get('plate').value;
		this.vehicle.adapter.id = this.form_group.get('adapter_id').value;
		this.vehicle.adapter.qrcode = this.form_group.get('ref_code').value;
		this.vehicle.adapter.profile = this.form_group.get('profile').value;
		this.vehicle.category = this.form_group.get('category').value;
		this.vehicle.brand = this.form_group.get('brand').value;
		this.vehicle.model = this.form_group.get('model').value;
		this.vehicle.color = this.form_group.get('color').value;
		this.vehicle.year = this.form_group.get('year').value;
	}
}
