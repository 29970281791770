<div class="lk-section-step-container-layout lk-margin-top lk-spaced-row">
  <div 
    class="lk-section-step-layout lk-centered-column" 
    *ngFor="let step of steps">
    <span 
      class="lk-section-step-text lk-ellipsized-text" 
      *ngIf="isStepTextVisible(step)"> 
      {{ step | translate }} 
    </span>
    <div  
      class="lk-section-step-bar lk-accent-background lk-full-width" 
      [ngClass]="getBarClass(step)"></div>
  </div>
</div>