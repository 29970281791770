<div
	style="padding: 20px; box-sizing: border-box"
	class="lk-full-width lk-full-height">
	<div class="lk-full-width lk-justified-row">
		<img
			style="height: 50px"
			src="../../../assets/imgs/lk-logo.svg" />
	</div>

	<h1 class="lk-full-width lk-centered-text lk-double-margin-top">
		{{ 'SUBSCRIPTION.START_THANK_YOU_TITLE' | translate }}
	</h1>
	<p
		class="lk-full-width lk-centered-text"
		[innerHTML]="'SUBSCRIPTION.START_THANK_YOU_SUBTITLE' | translate"></p>

	<div class="lk-full-width lk-justified-row">
		<div
			style="max-width: 800px; padding: 28px; box-sizing: border-box"
			class="lk-full-width lk-shadow lk-border-radius lk-primary-background lk-margin-top lk-double-margin-bottom">
			<h2 class="lk-no-margin lk-half-margin-top">
				{{ 'SUBSCRIPTION.START_PURCHASE_CTA' | translate }}
			</h2>
			<p class="lk-no-margin lk-margin-top">
				{{ 'SUBSCRIPTION.START_PURCHASE_LABEL' | translate }}
			</p>

			<p class="lk-no-margin lk-margin-top">
				<i
					><b> {{ 'SUBSCRIPTION.START_QUANTITY_LABEL' | translate }} </b></i
				>
			</p>

			<p class="lk-no-margin lk-margin-top">
				{{ 'SUBSCRIPTION.START_ALREADY_USER_LABEL' | translate }}
			</p>

			<div
				class="lk-full-width lk-ended-row lk-wrapped-flex lk-double-margin-top">
				<button
					(click)="onAlreadyUserClick()"
					mat-button
					class="lk-grey-button lk-full-width-md lk-half-margin">
					{{ 'SUBSCRIPTION.START_ALREADY_USER_CTA' | translate }}
				</button>

				<button
					(click)="onNewUserClick()"
					mat-button
					class="lk-main-button lk-full-width-md lk-half-margin">
					{{ 'SUBSCRIPTION.START_NEW_USER_CTA' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
