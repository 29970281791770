<div  
  id="lk-station-builder-notif-lang-upper-layout"
  class="lk-full-width lk-wrapped-flex lk-half-margin-bottom" 
  style="display: flex">
  <div  *ngFor="let lang of languages" >
    <div 
      class="lk-station-builder-notif-lang-item-layout lk-shadow lk-centered-column
        lk-half-margin-top lk-half-margin-right lk-border-radius lk-primary-background"
      style="align-items: flex-start; justify-content: center" 
      (click)="langClick.emit(lang)"
      [ngClass]="isClickableClass()"
      *ngIf="getLanguageCounter(lang) > 0">
      <div class="lk-spaced-row">
        <h3 class="lk-no-margin lk-primary-medium-color"> 
          {{ getLanguageLabel(lang) | translate }} 
        </h3>
        <h3 class="lk-no-margin lk-primary-medium-color lk-double-margin-left"> 
          ({{ getLanguageCounter(lang) }}) 
        </h3>
      </div>
      <p  
        class="lk-no-margin lk-accent-color" style="font-size: 12px" 
        *ngIf="isDefaultLanguage(lang)"> 
        default 
      </p>
    </div>
  </div>
</div>

<div 
  id="lk-station-builder-notif-lang-left-layout"
  class="lk-full-width lk-half-margin-bottom" 
  style="display: flex; flex-direction: column">
  <div  *ngFor="let lang of languages" >
    <div 
      class="lk-station-builder-notif-lang-item-layout lk-shadow lk-primary-background
        lk-border-radius lk-half-margin-bottom lk-centered-column"
      style="align-items: flex-start; justify-content: center" 
      (click)="langClick.emit(lang)"
      [ngClass]="isClickableClass()"
      *ngIf="getLanguageCounter(lang) > 0">
      <div class="lk-spaced-row">
        <h3 class="lk-no-margin lk-primary-medium-color"> 
          {{ getLanguageLabel(lang) | translate }} 
        </h3>
        <h3 class="lk-no-margin lk-primary-medium-color lk-double-margin-left"> 
          ({{ getLanguageCounter(lang) }}) 
        </h3>
      </div>
      <p  
        class="lk-no-margin lk-accent-color" style="font-size: 12px" 
        *ngIf="isDefaultLanguage(lang)"> 
        default 
      </p>
    </div>
  </div>
</div>