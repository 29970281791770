import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormService } from '../../core/form.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { Booking } from '../../core/booking.service';
import {
	DATE_FORMATS,
	EN_LOCALE,
	HOURS_OF_THE_DAY,
	IT_LOCALE,
	LOCAL_STATION_KEY,
	MINUTES_OF_THE_HOUR,
	PROD_STAGE,
	SUPPORTED_LANGUAGES,
	TIMEZONES,
} from '../../shared/constants';
import { zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import { environment } from '../../../environments/environment';
import {
	BOOKING_DURATION_ERROR_MESSAGE,
	BOOKING_MISMATCH_ERROR_MESSAGE,
	BOOKING_PAST_ERROR_MESSAGE,
} from 'src/app/shared/translations';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';

@Component({
	selector: 'app-booking-time-builder-dialog',
	templateUrl: './booking-time-builder-dialog.component.html',
	styleUrls: ['./booking-time-builder-dialog.component.scss'],
})
export class BookingTimeBuilderDialogComponent implements OnInit {
	booking: Booking;
	form_group: FormGroup;
	hours: string[] = HOURS_OF_THE_DAY;
	minutes: string[] = MINUTES_OF_THE_HOUR;
	timezones: string[] = TIMEZONES;
	initial_start_date: number;
	initial_end_date: number;
	initial_timezone: string;

	constructor(
		private formService: FormService,
		private snackbar: MatSnackBar,
		private dateAdapter: DateAdapter<any>,
		private translate: TranslateService,
		private dialogRef: MatDialogRef<BookingTimeBuilderDialogComponent>,
		@Inject(LOCALE_ID) public locale: string,
		@Inject(MAT_DIALOG_DATA) data,
		private local_manager: LocalManagerService,
	) {
		this.form_group = this.formService.bookingTimeBuilderFormGroup;
		this.booking = data.booking;

		if (this.booking) {
			this.initial_start_date = this.booking.start_date;
			this.initial_end_date = this.booking.end_date;
			this.initial_timezone = this.booking.timezone;
		}

		const current_station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		switch (current_station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.dateAdapter.setLocale(EN_LOCALE);
				break;
			case DATE_FORMATS[1]:
				this.dateAdapter.setLocale(IT_LOCALE);
				break;
			default:
				this.dateAdapter.setLocale(EN_LOCALE);
				break;
		}
	}

	ngOnInit() {
		this.form_group.reset();

		const timezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone + ' (local time)';
		this.timezones = TIMEZONES;
		this.timezones = [timezone, ...this.timezones];
		this.form_group.get('timezone').setValue(this.timezones[0]);

		if (this.booking.start_date && this.booking.timezone) {
			const startDate: Date = new Date(this.getTimezoneStartTime(this.booking));
			const startMinuteIndex: number = Math.floor(startDate.getMinutes() / 5);
			this.form_group.get('start_date').setValue(startDate);
			this.form_group
				.get('start_hour')
				.setValue(this.hours[startDate.getHours()]);
			this.form_group
				.get('start_minute')
				.setValue(this.minutes[startMinuteIndex == -1 ? 0 : startMinuteIndex]);
			this.form_group.get('timezone').setValue(this.booking.timezone);
		}

		if (this.booking.end_date) {
			const endDate: Date = new Date(this.getTimezoneEndTime(this.booking));
			const endMinuteIndex: number = Math.floor(endDate.getMinutes() / 5);
			this.form_group.get('end_date').setValue(endDate);
			this.form_group.get('end_hour').setValue(this.hours[endDate.getHours()]);
			this.form_group
				.get('end_minute')
				.setValue(this.minutes[endMinuteIndex == -1 ? 0 : endMinuteIndex]);
			this.form_group.get('timezone').setValue(this.booking.timezone);
		}

		if (this.booking.is_user_vehicle_unlocked) {
			this.form_group.get('start_date').disable();
			this.form_group.get('start_hour').disable();
			this.form_group.get('start_minute').disable();
		} else {
			this.form_group.get('start_date').enable();
			this.form_group.get('start_hour').enable();
			this.form_group.get('start_minute').enable();
		}

		if (this.booking.is_user_vehicle_locked) {
			this.form_group.get('end_date').disable();
			this.form_group.get('end_hour').disable();
			this.form_group.get('end_minute').disable();
			this.form_group.get('timezone').disable();
		} else {
			this.form_group.get('end_date').enable();
			this.form_group.get('end_hour').enable();
			this.form_group.get('end_minute').enable();
			this.form_group.get('timezone').enable();
		}

		if (this.booking.timezone) {
			this.form_group.get('timezone').disable();
		} else {
			this.form_group.get('timezone').enable();
		}
	}

	onCloseClick() {
		this.dialogRef.close();
	}

	onConfirmClick() {
		if (this.formService.validateForm(this.form_group)) {
			let startDate = new Date(this.form_group.get('start_date').value);
			let endDate = new Date(this.form_group.get('end_date').value);
			startDate.setHours(
				this.form_group.get('start_hour').value,
				this.form_group.get('start_minute').value,
				0,
				0,
			);
			endDate.setHours(
				this.form_group.get('end_hour').value,
				this.form_group.get('end_minute').value,
				0,
				0,
			);
			let timezone: string =
				this.form_group.get('timezone').value == 'GMT'
					? undefined
					: this.form_group
							.get('timezone')
							.value.toString()
							.replace(' (local time)', '');

			const intervalError: IntervalErrorTypes = this.checkInterval(
				startDate,
				endDate,
				timezone,
			);
			if (intervalError == IntervalErrorTypes.NO_ERROR) {
				this.booking.start_date = zonedTimeToUtc(startDate, timezone).getTime();
				this.booking.end_date = zonedTimeToUtc(endDate, timezone).getTime();
				this.booking.timezone = timezone;
				this.dialogRef.close({ booking: this.booking });
			} else {
				switch (intervalError) {
					case IntervalErrorTypes.PAST_ERROR:
						this.openSnackbar(BOOKING_PAST_ERROR_MESSAGE);
						break;
					case IntervalErrorTypes.MISMATCH_ERROR:
						this.openSnackbar(BOOKING_MISMATCH_ERROR_MESSAGE);
						break;
					case IntervalErrorTypes.DURATION_ERROR:
						this.openSnackbar(BOOKING_DURATION_ERROR_MESSAGE);
						break;
					default:
						this.dialogRef.close();
				}
			}
		}
	}

	openSnackbar(message: string) {
		this.translate.get(message).subscribe((res: string) => {
			this.snackbar.open(res, '', {
				duration: 2000,
				panelClass: ['lk-error-snackbar'],
			});
		});
	}

	checkInterval(
		startDate: Date,
		endDate: Date,
		timezone: string,
	): IntervalErrorTypes {
		const isCreate: boolean =
			!this.initial_start_date &&
			!this.initial_end_date &&
			!this.initial_timezone;
		const isUpdatingStart: boolean =
			this.initial_start_date &&
			this.initial_start_date != zonedTimeToUtc(startDate, timezone).getTime();
		const isUpdatingEnd: boolean =
			this.initial_end_date &&
			this.initial_end_date != zonedTimeToUtc(endDate, timezone).getTime();
		const isUpdatingTimezone: boolean =
			this.initial_timezone && this.initial_timezone != timezone;

		if (isUpdatingStart) {
			if (
				zonedTimeToUtc(startDate, timezone).getTime() < new Date().getTime()
			) {
				return IntervalErrorTypes.PAST_ERROR;
			}
			if (
				zonedTimeToUtc(startDate, timezone).getTime() >
				zonedTimeToUtc(endDate, timezone).getTime()
			) {
				return IntervalErrorTypes.MISMATCH_ERROR;
			}
			return IntervalErrorTypes.NO_ERROR;
		}

		const is_production: boolean = environment.STAGE == PROD_STAGE;
		if (isUpdatingEnd) {
			if (
				zonedTimeToUtc(startDate, timezone).getTime() >
				zonedTimeToUtc(endDate, timezone).getTime()
			) {
				return IntervalErrorTypes.MISMATCH_ERROR;
			}
			if (
				is_production &&
				zonedTimeToUtc(endDate, timezone).getTime() -
					zonedTimeToUtc(startDate, timezone).getTime() <
					60 * 60 * 2 * 1000
			) {
				return IntervalErrorTypes.DURATION_ERROR;
			}
			return IntervalErrorTypes.NO_ERROR;
		}

		if (isUpdatingTimezone) {
			return IntervalErrorTypes.NO_ERROR;
		}

		if (isCreate) {
			if (
				zonedTimeToUtc(startDate, timezone).getTime() < new Date().getTime()
			) {
				return IntervalErrorTypes.PAST_ERROR;
			}
			if (
				zonedTimeToUtc(startDate, timezone).getTime() >
				zonedTimeToUtc(endDate, timezone).getTime()
			) {
				return IntervalErrorTypes.MISMATCH_ERROR;
			}
			if (
				is_production &&
				zonedTimeToUtc(endDate, timezone).getTime() -
					zonedTimeToUtc(startDate, timezone).getTime() <
					60 * 60 * 2 * 1000
			) {
				return IntervalErrorTypes.DURATION_ERROR;
			}
			return IntervalErrorTypes.NO_ERROR;
		}

		return IntervalErrorTypes.NO_CHANGES;
	}

	getTimezoneOffset(timezone: string) {
		return (
			'GMT' +
			(getTimezoneOffset(timezone.replace(' (local time)', '')) / 3600000 >= 0
				? '+'
				: '') +
			getTimezoneOffset(timezone.replace(' (local time)', '')) / 3600000
		);
	}

	getTimezoneStartTime(booking: Booking) {
		return (
			booking.start_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getTimezoneEndTime(booking: Booking) {
		return (
			booking.end_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}
}

export enum IntervalErrorTypes {
	PAST_ERROR = 'IntervalPastError',
	MISMATCH_ERROR = 'IntervalMismatchError',
	DURATION_ERROR = 'IntervalDurationError',
	NO_CHANGES = 'IntervalNoChanges',
	NO_ERROR = 'IntervalClear',
}
