<div class="lk-spaced-row">
  <h2 class="lk-no-margin lk-half-margin-bottom"> 
    {{ 'STATION.NAVBAR_PICS_ITEM_NAME' | translate }} 
  </h2>
</div>

<div class="lk-double-padding lk-light-shadow lk-border-radius lk-primary-background">
  <div class="lk-column">
    <mat-slide-toggle 
      [(ngModel)]="is_policy_mandatory"
      (change)="onToggleChange($event, mandatory_policy)">
      <span style="white-space: initial;">
        {{ 'GENERAL.PICTURES_MANDATORY_POLICY_TITLE' | translate }}
      </span>
      <br> 
      <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
        {{ 'GENERAL.PICTURES_MANDATORY_POLICY_SUBTITLE' |translate }} 
      </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      class="lk-margin-top" 
      [(ngModel)]="is_policy_mandatory_pre"
      (change)="onToggleChange($event, mandatory_pre_policy)">
      <span style="white-space: initial;">
        {{ 'GENERAL.PICTURES_PICK_UP_POLICY_TITLE' | translate }}
      </span>
      <br> 
      <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
        {{ 'GENERAL.PICTURES_PICK_UP_POLICY_SUBTITLE' |translate }} 
      </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      class="lk-margin-top" 
      [(ngModel)]="is_policy_mandatory_post"
      (change)="onToggleChange($event, mandatory_post_policy)">
      <span style="white-space: initial;">
        {{ 'GENERAL.PICTURES_DROP_OFF_POLICY_TITLE' | translate }}
      </span>
      <br> 
      <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
        {{ 'GENERAL.PICTURES_DROP_OFF_POLICY_SUBTITLE' |translate }} 
      </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      class="lk-margin-top" 
      [(ngModel)]="is_policy_optional"
      (change)="onToggleChange($event, optional_policy)">
      <span style="white-space: initial;">
        {{ 'GENERAL.PICTURES_OPTIONAL_POLICY_TITLE' | translate }}
      </span>
      <br> 
      <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
        {{ 'GENERAL.PICTURES_OPTIONAL_POLICY_SUBTITLE' |translate }} 
      </p>
    </mat-slide-toggle>

    <mat-slide-toggle 
      class="lk-margin-top" 
      [(ngModel)]="is_policy_disabled"
      (change)="onToggleChange($event, disabled_policy)">
      <span style="white-space: initial;">
        {{ 'GENERAL.PICTURES_DISABLED_POLICY_TITLE' | translate }}
      </span>
      <br> 
      <p class="lk-primary-medium-color lk-no-margin lk-slide-toggle-label">
        {{ 'GENERAL.PICTURES_DISABLED_POLICY_SUBTITLE' |translate }} 
      </p>
    </mat-slide-toggle>
  </div>
</div>