import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { VEHICLES_PATH } from 'src/app/core/navigation.service';
import { CODE_MISMATCH_ERROR_CODE } from 'src/app/shared/constants';
import { CODE_MISMATCH_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-auth-mfa-setup-totp',
	templateUrl: './auth-mfa-setup-totp.component.html',
	styleUrls: ['./auth-mfa-setup-totp.component.scss'],
})
export class AuthMfaSetupTotpComponent implements OnInit {
	form_group?: FormGroup;
	setup_secret?: string;
	is_mfa_setup_running: boolean = false;
	is_app_setup?: boolean = false;
	pending_cognito_email?: string;

	@Input() logo_src: string = '../../../assets/imgs/lk-logo.svg';

	constructor(
		private form_service: FormService,
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private router: Router,
		private snackbar: MatSnackBar,
	) {
		this.form_group = this.form_service.mfa_setup_totp_form_group;
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.pending_cognito_email = this.auth_service.pending_cognito_email;
		this.form_group.reset();
		if (!this.auth_service.totp_setup_secret) {
			this.dialog_service.openInfoDialog('MFA setup secret not found');
		} else {
			this.setup_secret = this.auth_service.totp_setup_secret;
		}
	}

	onMfaSetupClick(): void {
		const token = this.form_group.get('token')?.value;
		this.verifyTOTPSetup(token);
	}

	onAppSetupClick(): void {
		this.is_app_setup = true;
	}

	onBackClick(): void {
		this.is_app_setup = false;
	}

	onCopyClick(content?: string): void {
		if (content) {
			if (navigator.clipboard) {
				navigator.clipboard.writeText(content).then(() => {
					this.showSnackbar('Copied!', 'check_circle_outlined');
				});
			} else {
				const text_area = document.createElement('textarea');
				text_area.value = content;
				document.body.appendChild(text_area);
				text_area.focus();
				text_area.select();

				try {
					const successful = document.execCommand('copy');
					if (successful) {
						this.showSnackbar('Copied!', 'check_circle_outlined');
					}
				} catch (err) {}
				document.body.removeChild(text_area);
			}
		}
	}

	showSnackbar(message: string, icon: string): void {
		this.snackbar.open(message, '', {
			duration: 2 * 1000,
			data: { message, icon },
		});
	}

	verifyTOTPSetup(token: string): void {
		this.is_mfa_setup_running = true;
		this.auth_service.verifyTOTP(token).subscribe({
			next: () => {
				this.router.navigate([VEHICLES_PATH]);
				this.is_mfa_setup_running = false;
			},
			error: error => {
				if (error.code == CODE_MISMATCH_ERROR_CODE) {
					this.dialog_service.openInfoDialog(CODE_MISMATCH_ERROR_MESSAGE);
				} else {
					this.dialog_service.openInfoDialog(error.message);
				}
				this.is_mfa_setup_running = false;
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_mfa_setup_running || !this.isFormValid(),
			'lk-main-button': !this.is_mfa_setup_running && this.isFormValid(),
		};
	}

	getQRData(): string {
		return `otpauth://totp/${
			this.pending_cognito_email ? this.pending_cognito_email : 'admin_access'
		}?secret=${this.setup_secret}&issuer=Linkey`;
	}
}
