import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { STATION_SETTINGS_PATH } from '../core/navigation.service';
import { AuthGuard } from '../shared/auth.guard';
import { StationSettingsComponent } from './station-settings.component';

const routes: Routes = [
  {
    path: STATION_SETTINGS_PATH,
    component: StationSettingsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StationSettingsRoutingModule { }
