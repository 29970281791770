import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
	Vehicle,
	VehicleOdometerUms,
	VehicleStatus,
} from '../../core/vehicle.service';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import {
	KM_LABEL,
	KM_TO_MILES_COEFF,
	MILES_LABEL,
	LOCAL_STATION_KEY,
	SUPPORTED_LANGUAGES,
	VEHICLE_TABLE_COLUMNS,
	VEHICLE_TABLE_TURO_COLUMNS,
	DATE_FORMATS,
	EN_LOCALE,
	IT_LOCALE,
	PROD_STAGE,
} from 'src/app/shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { Station } from 'src/app/core/station.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { environment } from 'src/environments/environment';
import { VehicleDirectLinkDialogComponent } from '../vehicle-direct-link-dialog/vehicle-direct-link-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { DIRECT_LINK_SENT_MESSAGE } from 'src/app/shared/translations';
import { VehicleDirectLinkConfirmDialogComponent } from '../vehicle-direct-link-confirm-dialog/vehicle-direct-link-confirm-dialog.component';

@Component({
	selector: 'app-vehicle-table',
	templateUrl: './vehicle-table.component.html',
	styleUrls: ['./vehicle-table.component.scss'],
})
export class VehicleTableComponent implements OnInit {
	@Input() vehicleUpdateTrigger: BehaviorSubject<Vehicle[]>;
	@Output() vehicleDeleteClick: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();
	@Output() vehicleClick: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
	@Output() filtersClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() vehicleStartClick: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();
	@Output() vehicleStopClick: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();
	form_group: FormGroup;
	vehicles: Vehicle[];
	update_time: number;
	odometer_um?: string;
	current_locale: string;
	displayed_columns: string[] = [
		'online',
		'ext_id',
		'status',
		'plate',
		'category',
		'brand',
		'model',
		'color',
		'year',
		'odometer',
		'fuel',
		'battery',
		'actions',
	];
	is_production: boolean;

	constructor(
		private translate: TranslateService,
		private form_service: FormService,
		private local_manager: LocalManagerService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
		this.form_group = this.form_service.vehicleSearchFormGroup;
		const current_station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		switch (current_station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				break;
			default:
				this.current_locale = EN_LOCALE;
				break;
		}
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.odometer_um = station.settings?.odometer_um || VehicleOdometerUms.mi;
		if (station.ext_email) {
			this.displayed_columns = VEHICLE_TABLE_TURO_COLUMNS;
		} else {
			this.displayed_columns = VEHICLE_TABLE_COLUMNS;
		}

		this.vehicleUpdateTrigger.subscribe(vehicles => {
			if (vehicles) {
				this.update_time = new Date().getTime();
				this.vehicles = vehicles;
			}
		});
	}

	onVehicleDeleteClick(vehicle: Vehicle) {
		this.vehicleDeleteClick.emit(vehicle);
	}

	onVehicleClick(vehicle: Vehicle) {
		this.vehicleClick.emit(vehicle);
	}

	onFiltersClick() {
		this.filtersClick.emit();
	}

	onSearchChange() {
		this.searchChange.emit(this.form_group.get('plate').value);
	}

	onStartClick(vehicle: Vehicle) {
		this.vehicleStartClick.emit(vehicle);
	}

	onStopClick(vehicle: Vehicle) {
		this.vehicleStopClick.emit(vehicle);
	}

	onDirectLinkClick(vehicle: Vehicle): void {
		this.dialog_service
			.openDialog(VehicleDirectLinkDialogComponent, {
				vehicle,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result?.success) {
					this.dialog_service.openDialog(
						VehicleDirectLinkConfirmDialogComponent,
						{
							link: dialog_result.short_link,
							is_link_sent: dialog_result.is_sent,
						},
					);
				} else if (!dialog_result?.success && dialog_result?.error_message) {
					this.dialog_service.openInfoDialog(
						dialog_result?.error_message || '',
					);
				}
			});
	}

	getOnlineClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-table-online-circle-enabled':
				vehicle?.stats?.online && vehicle?.stats?.online.value,
			'lk-vehicle-table-online-circle-disabled':
				!vehicle?.stats?.online || !vehicle?.stats?.online.value,
		};
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}

	getOdometerValue(vehicle: Vehicle): number {
		if (this.odometer_um && this.odometer_um == VehicleOdometerUms.km) {
			return vehicle.stats.distance_covered.value / 1000;
		}

		if (this.odometer_um && this.odometer_um == VehicleOdometerUms.mi) {
			return (vehicle.stats.distance_covered.value / 1000) * KM_TO_MILES_COEFF;
		}

		return vehicle.stats.distance_covered.value / 1000;
	}

	getPercentageValue(vehicle: Vehicle): number {
		return Math.round(vehicle.stats.autonomy_percentage.value);
	}
}
