<div class="lk-spaced-row">
	<h2 class="lk-no-margin lk-half-margin-bottom">
		{{ 'STATION.NAVBAR_DATA_SETTINGS_ITEM_NAME' | translate }}
	</h2>
</div>

<div
	class="lk-double-padding lk-light-shadow lk-border-radius lk-primary-background">
	<form [formGroup]="form_group">
		<p class="lk-no-margin lk-half-margin-bottom">
			{{ 'STATION.VEHICLES_SETTINGS_UM_LABEL' | translate }}
		</p>

		<mat-form-field
			appearance="fill"
			color="accent"
			class="lk-margin-bottom">
			<mat-label>
				{{ 'GENERAL.MU_LABEL' | translate }}
			</mat-label>
			<mat-select formControlName="odometer_um">
				<mat-option [value]="mi_um">
					{{ 'GENERAL.MILES_MU_LABEL' | translate }} [mi]
				</mat-option>
				<mat-option [value]="km_um">
					{{ 'GENERAL.KM_MU_LABEL' | translate }} [km]
				</mat-option>
			</mat-select>
		</mat-form-field>

		<p class="lk-no-margin lk-half-margin-bottom">
			{{ 'STATION.SETTINGS_DATE_FORMAT' | translate }}
		</p>

		<mat-form-field
			class="lk-margin-bottom"
			appearance="fill"
			color="accent">
			<mat-label> {{ 'STATION.SETTINGS_DATE_FORMAT' | translate }} </mat-label>
			<mat-select formControlName="date_format">
				<mat-option
					*ngFor="let date of date_formats"
					[value]="date.format">
					{{ date.label }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>

	<div class="lk-full-width-lg lk-centered-row lk-margin-top">
		<button
			style="min-width: 50%"
			class="lk-main-button"
			mat-button
			[disabled]="is_request_running"
			[ngClass]="getDisabledClass()"
			(click)="onSaveClick()">
			<span *ngIf="!is_request_running">
				{{ 'GENERAL.SAVE_BUTTON_CTA' | translate }}
			</span>
			<div
				class="lk-spinner-layout"
				*ngIf="is_request_running">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
		</button>
	</div>
</div>
