import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Booking } from 'src/app/core/booking.service';

@Component({
  selector: 'app-booking-delete-dialog',
  templateUrl: './booking-delete-dialog.component.html',
  styleUrls: ['./booking-delete-dialog.component.scss']
})
export class BookingDeleteDialogComponent implements OnInit {
  booking: Booking;

  constructor(
    private dialogRef: MatDialogRef<BookingDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
    this.booking = data.booking;
  }

  ngOnInit(): void {
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    this.dialogRef.close({ confirm: true });
  }
}
