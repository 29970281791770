import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormErrorStateMatcher, FormService } from '../../core/form.service';
import { AuthenticationService } from '../../core/authentication.service';

@Component({
	selector: 'app-auth-forgot-password-confirm-dialog',
	templateUrl: './auth-forgot-password-confirm-dialog.component.html',
	styleUrls: ['./auth-forgot-password-confirm-dialog.component.scss'],
})
export class AuthForgotPasswordConfirmDialogComponent implements OnInit {
	form_group: FormGroup;
	matcher: FormErrorStateMatcher;
	email: string;
	is_password_visible: boolean = false;
	is_forgot_pss_running: boolean = false;

	constructor(
		private dialog_ref: MatDialogRef<AuthForgotPasswordConfirmDialogComponent>,
		private form_service: FormService,
		private auth_service: AuthenticationService,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.form_group = this.form_service.forgotPasswordConfirmationFormGroup;
		this.matcher = this.form_service.matcher;
		this.email = data.email;
	}

	ngOnInit() {
		this.form_group.reset();
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		if (this.form_service.validateForm(this.form_group)) {
			let code: string = this.form_group.get('code').value;
			let password: string = this.form_group.get('password').value;
			this.is_forgot_pss_running = true;
			this.auth_service.forgotPasswordSubmit(this.email, code, password).subscribe({
				next: response => {
					this.is_forgot_pss_running = false;
					this.dialog_ref.close({ success: true });
				},
				error: error => {
					this.is_forgot_pss_running = false;
					this.dialog_ref.close({ success: false, error: error.message });
				},
			});
		}
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_forgot_pss_running,
			'lk-main-button': !this.is_forgot_pss_running,
		};
	}
}
