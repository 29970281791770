import {Component, Inject, OnInit} from '@angular/core';
import {FormService} from '../../core/form.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VehicleStatus} from '../../core/vehicle.service';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-vehicle-filters-dialog',
  templateUrl: './vehicle-filters-dialog.component.html',
  styleUrls: ['./vehicle-filters-dialog.component.scss']
})
export class VehicleFiltersDialogComponent implements OnInit {
  form_group: FormGroup;
  states: string[] = [VehicleStatus.UNLOCKED, VehicleStatus.LOCKED];

  constructor(
    private formService: FormService,
    private dialogRef: MatDialogRef<VehicleFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.form_group = this.formService.vehicleFiltersFormGroup;
  }

  ngOnInit(): void {
    this.form_group.reset();
    this.form_group.get('current').setValue('plate');
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if(this.formService.validateForm(this.form_group)) {
      const plate: string = this.form_group.get('plate').value;
      const category: string = this.form_group.get('category').value;
      const brand: string = this.form_group.get('brand').value;
      const status: string = this.form_group.get('status').value;
      this.dialogRef.close({ filters: {
          plate: plate,
          category: category,
          brand: brand,
          status: status
      }});
    }
  }

  onFiltersResetClick() {
    this.form_group.reset();
    this.form_group.get('current').setValue('plate');
  }

  isPlateVisible() {
    return this.form_group.get('current').value == 'plate';
  }

  isCategoryVisible() {
    return this.form_group.get('current').value == 'category';
  }

  isBrandVisible() {
    return this.form_group.get('current').value == 'brand';
  }
}
