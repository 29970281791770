import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';
import { VehicleService, VehicleRequest } from 'src/app/core/vehicle.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-vehicle-manual-dialog',
	templateUrl: './vehicle-manual-contacts-dialog.component.html',
	styleUrls: ['./vehicle-manual-contacts-dialog.component.scss'],
})
export class VehicleManualContactsDialogComponent implements OnInit {
	station?: Station;
	is_send_request_running: boolean = false;
	vehicle_request_manual_contacts_form_group: FormGroup =
		this.form_service.vehicle_request_manual_contacts_form_group;

	constructor(
		private dialog_ref: MatDialogRef<VehicleManualContactsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private form_service: FormService,
		private local_manager: LocalManagerService,
		private vehicle_service: VehicleService,
	) {
		this.station = data.station;
	}

	ngOnInit(): void {
		if (this.station) {
			this.vehicle_request_manual_contacts_form_group
				.get('station_name')
				.setValue(this.station.name);
			this.vehicle_request_manual_contacts_form_group
				.get('station_email')
				.setValue(this.station.email);
		}
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onNextClick(): void {
		if (this.isValidForm()) {
			/*const station_name: string = this.getStationName();
			const subject: string = `Manual request for ${station_name}`;
			const description: string =
				this.vehicle_request_manual_contacts_form_group.get('message').value;
			const email: string =
				this.vehicle_request_manual_contacts_form_group.get('email').value;
			const phone: string =
				this.vehicle_request_manual_contacts_form_group.get('phone').value;
			const request: VehicleRequest = this.buildRequest(
				station_name,
				subject,
				description,
				email,
				phone,
			);
			this.sendRequest(request);*/
			const station_name: string =
				this.vehicle_request_manual_contacts_form_group.get(
					'station_name',
				).value;
			const station_email: string =
				this.vehicle_request_manual_contacts_form_group.get(
					'station_email',
				).value;
			this.dialog_ref.close({ success: true, station_name, station_email });
		}
	}

	sendRequest(request: VehicleRequest): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station?.id) {
			this.is_send_request_running = true;
			this.vehicle_service.sendRequest(station.id, request).subscribe({
				next: response => {
					this.dialog_ref.close({ success: true });
					this.is_send_request_running = false;
				},
				error: error => {
					this.is_send_request_running = false;
					this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
				},
			});
		}
	}

	buildRequest(
		station_name: string,
		subject: string,
		description: string,
		email: string,
		phone: string,
	): VehicleRequest {
		const request: VehicleRequest = new VehicleRequest();
		request.station_name = station_name;
		request.subject = subject;
		request.description = description;
		request.email = email;
		request.phone = phone;
		return request;
	}

	getStationName(): string {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		return station?.name || '-';
	}

	getStationPhone(): string {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		return `+${station?.phone?.prefix || '-'} ${station?.phone?.number || ''}`;
	}

	getStationEmail(): string {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		return station?.email || '-';
	}

	isValidForm(): boolean {
		return this.form_service.validateForm(
			this.vehicle_request_manual_contacts_form_group,
		);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_send_request_running || !this.isValidForm(),
			'lk-main-button': !this.is_send_request_running && this.isValidForm(),
		};
	}
}
