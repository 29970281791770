import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VEHICLES_PATH } from './core/navigation.service';

const routes: Routes = [
	{
		path: '',
		redirectTo: VEHICLES_PATH,
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
