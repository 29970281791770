<app-shared-app-toolbar
	[current_nav_item]="current_item"
	[refresh]="toolbar_refresh"
	(no_station_found)="onNoStationFound()"
	(station_build_click)="onStationBuildClick(false)"
	(station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section class="lk-section-with-toolbar">
	<div
		class="lk-section-container-layout lk-centered-column lk-padding-md"
		*ngIf="!is_bookings_loading && bookings && bookings.length <= 0">
		<div class="lk-centered-column-wrapper">
			<app-booking-empty
				(bookingBuildClick)="onAddBookingClick()"></app-booking-empty>
		</div>
	</div>

	<div
		class="lk-padding-md"
		*ngIf="
			!bookings ||
			(!bookings.length && is_bookings_loading) ||
			(bookings && bookings.length)
		">
		<div class="lk-spaced-row lk-horiz-half-padding">
			<h1 class="lk-no-margin">{{ 'BOOKING.TITLE' | translate }}</h1>
			<div class="lk-row">
				<button
					mat-icon-button
					(click)="onRefreshClick()"
					class="lk-background-icon lk-booking-icon-button"
					matTooltip="{{ 'BOOKING.REFRESH_CTA' | translate }}">
					<mat-icon class="lk-accent-color"> refresh </mat-icon>
				</button>
				<button
					id="lk-booking-add-button"
					mat-icon-button
					(click)="onAddBookingClick()"
					class="lk-background-icon lk-booking-icon-button"
					matTooltip="{{ 'BOOKING.ADD_CTA' | translate }}">
					<mat-icon class="lk-accent-color"> add </mat-icon>
				</button>
				<button
					mat-icon-button
					(click)="onBookingsDownloadClick()"
					class="lk-background-icon lk-booking-icon-button"
					matTooltip="{{ 'BOOKING.DOWNLOAD_CTA' | translate }}">
					<mat-icon
						*ngIf="!is_export_running"
						class="lk-accent-color">
						download
					</mat-icon>
					<mat-spinner
						*ngIf="is_export_running"
						color="accent"
						style="margin: auto"
						[diameter]="20"></mat-spinner>
				</button>
			</div>
		</div>

		<app-booking-summary
			[refreshSummary]="refresh_summary_trigger"></app-booking-summary>
		<app-booking-table
			*ngIf="!is_bookings_loading"
			id="lk-booking-table"
			[bookingUpdateTrigger]="booking_update_trigger"
			(bookingClick)="onBookingClick($event)"
			(filtersClick)="onFiltersClick()"
			(searchChange)="onSearchChange($event)"
			(bookingDeleteClick)="onDeleteBookingClick($event)"
			(sortStartDateClick)="onSortStartDateClick($event)"
			(vehicleAddClick)="onAssignVehicleClick($event)"></app-booking-table>
		<app-booking-list
			id="lk-booking-list"
			[bookingUpdateTrigger]="booking_update_trigger"
			(bookingClick)="onBookingClick($event)"
			(filtersClick)="onFiltersClick()"
			(searchChange)="onSearchChange($event)"
			(bookingDeleteClick)="onDeleteBookingClick($event)"
			(sortStartDateClick)="onSortStartDateClick($event)"
			(vehicleAddClick)="onAssignVehicleClick($event)"></app-booking-list>

		<div
			#loadMoreLayout
			class="lk-full-width lk-centered-row"
			*ngIf="!is_bookings_loading && !is_last_page">
			<button
				mat-button
				class="lk-main-button"
				(click)="onLoadPageClick()"
				[disabled]="is_page_loading"
				[ngClass]="getDisabledClass()">
				<span *ngIf="!is_page_loading">
					{{ 'GENERAL.LOAD_MORE_CTA' | translate }}
				</span>
				<div
					class="lk-spinner-layout"
					*ngIf="is_page_loading">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>
		</div>
	</div>

	<div
		*ngIf="is_bookings_loading"
		class="lk-full-width lk-full-height lk-centered-row"
		style="position: fixed; top: 0; left: 0">
		<div class="lk-overlay-spinner-layout lk-padding lk-border-radius">
			<mat-spinner
				color="accent"
				diameter="30"></mat-spinner>
		</div>
	</div>
</section>
