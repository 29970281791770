<div class="lk-full-width lk-full-height lk-double-padding lk-centered-column">
	<div class="lk-centered-column-wrapper lk-column">
		<img
			style="height: 50px"
			src="../../../assets/imgs/lk-logo.svg" />
		<button
			style="width: 200px; height: fit-content"
			(click)="onPaymentClickClick()"
			mat-button
			class="lk-main-button lk-margin-top">
			Mock payment
		</button>
	</div>
</div>
