<div id="lk-booking-details-gallery-root-layout">
  <div class="lk-spaced-row">
    <p 
      *ngIf="type == pre_report_type"
      class="lk-primary-medium-color lk-no-margin lk-half-margin-left"> 
      Pre-reports 
    </p>
    <p 
      *ngIf="type == post_report_type"
      class="lk-primary-medium-color lk-no-margin lk-half-margin-left"> 
      Post-reports 
    </p>

    <button 
      mat-icon-button 
      (click)="onCloseClick()" 
      class="lk-primary-medium-background">
      <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
    </button>
  </div>

  <div class="lk-full-width lk-centered-row lk-margin-top">
    <button 
      [disabled]="getLeftDisabledCond()"
      [ngClass]="getLeftDisabledClass()"
      (click)="onPreviousClick()"
      mat-icon-button 
      class="lk-primary-medium-background"> 
      <mat-icon class="lk-accent-color"> arrow_back </mat-icon> 
    </button>

    <h2 
      style="width: 124px;"
      class="lk-centered-text lk-no-margin lk-margin-left lk-margin-right"> 
      {{ gallery[current_index].name.replace("_", " ") }} 
    </h2>

    <button 
      [disabled]="getRightDisabledCond()"
      [ngClass]="getRightDisabledClass()"
      (click)="onNextClick()"
      class="lk-primary-medium-background"
      mat-icon-button> 
      <mat-icon class="lk-accent-color"> arrow_forward </mat-icon> 
    </button>
  </div>

  <div class="lk-centered-row lk-half-margin-top lk-margin-bottom">
    <img 
      class="lk-border-radius"
      [src]="gallery[current_index].content_url">
  </div>
</div>