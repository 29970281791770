<div class="lk-full-width lk-half-margin-bottom">
	<div
		style="overflow-x: scroll"
		class="lk-row lk-hide-scrollbar lk-cursor-pointer">
		<div
			*ngFor="let item of items"
			(click)="onItemClick(item)"
			[ngClass]="getItemBackgroundClass(item)"
			style="margin: 0 12px">
			<span class="lk-ellipsized-text">
				{{ getItemLabel(item) | translate | titlecase }}
			</span>
		</div>
	</div>
</div>
