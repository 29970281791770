import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-metabase-loaded',
	templateUrl: './metabase-loaded.component.html',
	styleUrls: ['./metabase-loaded.component.scss'],
})
export class MetabaseLoadedComponent implements OnInit {
	@Input() metabase_address?: SafeUrl;

	constructor() {}

	ngOnInit(): void {}
}
