<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			class="lk-primary-medium-background"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-auth-mfa-reset-content-layout"
		class="lk-horiz-padding lk-margin-top">
		<p
			[innerHTML]="
				'AUTHENTICATION.MFA_RESET_CONFIRM_PHONE_MESSAGE' | translate: { phone }
			"></p>

		<div class="lk-full-width lk-centered-row lk-margin-bottom">
			<button
				mat-button
				class="lk-main-button lk-full-width"
				(click)="onConfirmClick()">
				<h3 class="lk-no-margin lk-primary-color">
					{{ 'GENERAL.CONFIRM_CTA' | translate }}
				</h3>
			</button>
		</div>
	</div>
</div>
