import { Component, OnInit } from '@angular/core';
import { FormService } from '../../core/form.service';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../core/authentication.service';

@Component({
	selector: 'app-auth-forgot-password-dialog',
	templateUrl: './auth-forgot-password-dialog.component.html',
	styleUrls: ['./auth-forgot-password-dialog.component.scss'],
})
export class AuthForgotPasswordDialogComponent implements OnInit {
	formGroup: FormGroup;
	isForgotPssRunning: boolean = false;

	constructor(
		private dialogRef: MatDialogRef<AuthForgotPasswordDialogComponent>,
		private formService: FormService,
		private authService: AuthenticationService,
	) {
		this.formGroup = this.formService.forgotPasswordEmailFormGroup;
		this.formGroup.reset();
	}

	ngOnInit() {
		this.formGroup.reset();
	}

	onCloseClick() {
		this.dialogRef.close();
	}

	onConfirmClick() {
		if (this.formService.validateForm(this.formGroup)) {
			let email: string = this.formGroup.get('username').value;
			this.isForgotPssRunning = true;
			this.authService.forgotPassword(email).subscribe({
				next: response => {
					this.isForgotPssRunning = false;
					this.dialogRef.close({ success: true, email: email });
				},
				error: error => {
					this.isForgotPssRunning = false;
					this.dialogRef.close({ success: false, error: error.message });
				},
			});
		}
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.isForgotPssRunning,
			'lk-main-button': !this.isForgotPssRunning,
		};
	}
}
