import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationType} from '../../core/notification.service';
import {BehaviorSubject} from 'rxjs';
import {LocalManagerService} from '../../core/local-manager.service';
import {LOCAL_STATION_KEY} from '../../shared/constants';
import {FormGroup} from '@angular/forms';
import {NO_NOTIF_TYPE_MESSAGE} from '../../shared/translations';
import {SharedSingleActionDialogComponent} from '../../shared/shared-single-action-dialog/shared-single-action-dialog.component';
import {DialogService} from '../../core/dialog.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-station-settings-notif',
  templateUrl: './station-settings-notif.component.html',
  styleUrls: ['./station-settings-notif.component.scss']
})
export class StationSettingsNotifComponent implements OnInit {
  @Input()  stationUpdateTrigger: BehaviorSubject<boolean>;
  @Output() notifUpdateClick: EventEmitter<NotificationType> = new EventEmitter<NotificationType>();
  is_sms_checked: boolean = true;
  is_email_checked: boolean = true;
  form_group: FormGroup;

  constructor(
    private local_manager: LocalManagerService,
    private dialog_service: DialogService, 
    private dialog: MatDialog
  ) {
    this.dialog_service.dialog = this.dialog;
  }

  ngOnInit() {
    this.stationUpdateTrigger.subscribe(
      update => {
        if (update) {
          let currentStation = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
          if (currentStation) {
            this.is_sms_checked = currentStation.settings.default_notification_type == NotificationType.SMS ||
              currentStation.settings.default_notification_type == NotificationType.BOTH;
            this.is_email_checked = currentStation.settings.default_notification_type == NotificationType.EMAIL ||
              currentStation.settings.default_notification_type == NotificationType.BOTH;
          }
        }
      }
    );
  }

  onNotifUpdateClick() {
    if (this.is_sms_checked || this.is_email_checked) {
      let type: NotificationType;
      if (this.is_sms_checked && this.is_email_checked) {
        type = NotificationType.BOTH;
      } else {
        type = this.is_sms_checked? NotificationType.SMS : NotificationType.EMAIL;
      }
      this.notifUpdateClick.emit(type);
    } else {
      this.dialog_service.openDialog(SharedSingleActionDialogComponent, {
        message: NO_NOTIF_TYPE_MESSAGE
      });
    }
  }
}
