import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BookingsSummary, BookingsSummaryService } from 'src/app/core/bookings-summary.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { STATION_PATH } from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnInit {
  @Input() refreshSummary : BehaviorSubject<boolean>;
  is_summary_loading: boolean = false;
  bookings_summary: BookingsSummary;

  constructor(
    private bookings_summary_service: BookingsSummaryService, 
    private local_manager: LocalManagerService, 
    private router: Router) { }

  ngOnInit() { 
    this.refreshSummary.subscribe(
      refresh => {
        if (refresh) {
          const station: Station  = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
          if (station && station.id) {
            this.is_summary_loading = true;
            //setTimeout(() => { this.readBookingsSummary(station.id); }, 3000);
            this.readBookingsSummary(station.id);
          } else {
            this.router.navigate([STATION_PATH]);
          }
        }
      }
    );
  }

  readBookingsSummary(stationId: string) {
    this.bookings_summary_service.readBookingsSummary(stationId).subscribe({
      next: (response) => {
        this.is_summary_loading = false;
        this.bookings_summary  = response.data;
      },
      error: (error) => {
        this.is_summary_loading = false;
        this.bookings_summary  = {
          total                 : 0,
          bookings_in_pending   : 0,
          bookings_in_progress  : 0,
          bookings_with_errors  : 0,
          bookings_closed       : 0
        };
      }
    });
  }
}
