import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Vehicle, VehicleStatus } from '../../core/vehicle.service';
import { FormService } from '../../core/form.service';
import { FormGroup } from '@angular/forms';
import { Station } from 'src/app/core/station.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import {
	UnlockCommand,
	UnlockCommandsService,
	UnlockCommandStatus,
} from 'src/app/core/unlock-commands.service';
import {
	LOCAL_STATION_KEY,
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	ERROR_DESCRIPTION_500,
	ADAPTER_FAILED_ERROR_CODE,
	ADAPTER_OFFLINE_ERROR_CODE,
} from 'src/app/shared/constants';
import {
	GENERAL_ERROR_MESSAGE,
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	ADAPTER_FAILED_ERROR_MESSAGE,
	ADAPTER_GENERIC_ERROR_MESSAGE,
	ADAPTER_OFFLINE_ERROR_MESSAGE,
} from 'src/app/shared/translations';
import { DialogService } from 'src/app/core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedInteractionErrorDialogComponent } from 'src/app/shared/shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import {
	LockCommand,
	LockCommandsService,
	LockCommandStatus,
} from 'src/app/core/lock-commands.service';

@Component({
	selector: 'app-vehicle-list',
	templateUrl: './vehicle-list.component.html',
	styleUrls: ['./vehicle-list.component.scss'],
})
export class VehicleListComponent implements OnInit {
	@Input() vehicleUpdateTrigger: BehaviorSubject<Vehicle[]>;
	@Input() hideHeader: boolean;
	@Input() horiz_mode: boolean;
	@Output() vehicleDeleteClick: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();
	@Output() vehicleClick: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
	@Output() filtersClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() vehicleStartClick: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();
	@Output() vehicleStopClick: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();
	form_group: FormGroup;
	vehicles: Vehicle[];
	is_vehicle_unlock_interacting: boolean[] = [];
	is_vehicle_lock_interacting: boolean[] = [];

	constructor(
		private form_service: FormService,
		private local_manager: LocalManagerService,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.form_group = this.form_service.vehicleSearchFormGroup;
	}

	ngOnInit() {
		this.vehicleUpdateTrigger.subscribe(vehicles => {
			if (vehicles) {
				this.vehicles = vehicles;
				this.is_vehicle_unlock_interacting = Array(vehicles.length).fill(false);
				this.is_vehicle_lock_interacting = Array(vehicles.length).fill(false);
			}
		});
	}

	onVehicleDeleteClick(vehicle: Vehicle) {
		this.vehicleDeleteClick.emit(vehicle);
	}

	onVehicleClick(vehicle: Vehicle) {
		this.vehicleClick.emit(vehicle);
	}

	/*onStartVehicleClick(vehicle: Vehicle) {
    this.vehicleStartClick.emit(vehicle);
  }*/
	onStartVehicleClick(vehicle: Vehicle): void {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle.id);
		this.is_vehicle_unlock_interacting[index] = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service
			.createUnlockCommand(station.id, vehicle.id)
			.subscribe({
				next: response => {
					const command: UnlockCommand = response.data as UnlockCommand;
					this.readUnlockCommand(command.id, vehicle.id);
				},
				error: error => {
					this.is_vehicle_unlock_interacting[index] = false;
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.code !=
							ADAPTER_CRED_NOT_FOUND_ERROR_CODE &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description ||
						error.response.data.error.description == ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					} else if (
						error.response.data.error.code == ADAPTER_CRED_NOT_FOUND_ERROR_CODE
					) {
						this.dialog_service.openInfoDialog(
							ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
						);
					}
				},
			});
	}

	readUnlockCommand(command_id: string, vehicle_id: string) {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle_id);
		this.is_vehicle_unlock_interacting[index] = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service
			.readUnlockCommand(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					const command: UnlockCommand = response.data as UnlockCommand;
					if (
						command.status == UnlockCommandStatus.PENDING &&
						this.unlock_commands_service.current_read_index <=
							this.unlock_commands_service.max_read_retry
					) {
						this.unlock_commands_service.current_read_index++;
						setTimeout(() => {
							this.readUnlockCommand(command_id, vehicle_id);
						}, 5000);
					} else if (command.status == UnlockCommandStatus.SUCCEEDED) {
						this.unlock_commands_service.current_read_index = 0;
						this.is_vehicle_unlock_interacting[index] = false;
						this.vehicleStartClick.emit();
					} else if (command.status == UnlockCommandStatus.FAILED) {
						this.unlock_commands_service.current_read_index = 0;
						this.is_vehicle_unlock_interacting[index] = false;
						const command_error = command.error.code
							? command.error.code
							: command.error;
						switch (command_error) {
							case ADAPTER_FAILED_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_FAILED_ERROR_MESSAGE,
									},
								);
								break;
							case ADAPTER_OFFLINE_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_OFFLINE_ERROR_MESSAGE,
									},
								);
								break;
							default:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_GENERIC_ERROR_MESSAGE,
									},
								);
								break;
						}
					}
				},
				error: error => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicle_unlock_interacting[index] = false;
				},
			});
	}

	/*onStopVehicleClick(vehicle: Vehicle) {
    this.vehicleStopClick.emit(vehicle);
  }*/
	onStopVehicleClick(vehicle: Vehicle) {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle.id);
		this.is_vehicle_lock_interacting[index] = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service
			.createLockCommand(station.id, vehicle.id)
			.subscribe({
				next: response => {
					const command: LockCommand = response.data as LockCommand;
					this.readLockCommand(command.id, vehicle.id);
				},
				error: error => {
					this.is_vehicle_lock_interacting[index] = false;
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.code !=
							ADAPTER_CRED_NOT_FOUND_ERROR_CODE &&
						error.response.data.error.description != ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description ||
						error.response.data.error.description == ERROR_DESCRIPTION_500
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					} else if (
						error.response.data.error.code == ADAPTER_CRED_NOT_FOUND_ERROR_CODE
					) {
						this.dialog_service.openInfoDialog(
							ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
						);
					}
				},
			});
	}

	readLockCommand(command_id: string, vehicle_id: string) {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle_id);
		this.is_vehicle_lock_interacting[index] = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service
			.readLockCommand(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					const command: LockCommand = response.data as LockCommand;
					if (
						command.status == LockCommandStatus.PENDING &&
						this.lock_commands_service.current_read_index <=
							this.lock_commands_service.max_read_retry
					) {
						this.lock_commands_service.current_read_index++;
						setTimeout(() => {
							this.readLockCommand(command_id, vehicle_id);
						}, 5000);
					} else if (command.status == LockCommandStatus.SUCCEEDED) {
						this.lock_commands_service.current_read_index = 0;
						this.is_vehicle_lock_interacting[index] = false;
						this.vehicleStopClick.emit();
					} else if (command.status == LockCommandStatus.FAILED) {
						this.lock_commands_service.current_read_index = 0;
						this.is_vehicle_lock_interacting[index] = false;
						const command_error = command.error.code
							? command.error.code
							: command.error;
						switch (command_error) {
							case ADAPTER_FAILED_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_FAILED_ERROR_MESSAGE,
									},
								);
								break;
							case ADAPTER_OFFLINE_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_OFFLINE_ERROR_MESSAGE,
									},
								);
								break;
							default:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_GENERIC_ERROR_MESSAGE,
									},
								);
								break;
						}
					}
				},
				error: error => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicle_lock_interacting[index] = false;
				},
			});
	}
	prepareVehiclesLoading() {
		throw new Error('Method not implemented.');
	}
	listVehicles(id: string, plate: any, category: any, brand: any, status: any) {
		throw new Error('Method not implemented.');
	}

	onFiltersClick() {
		this.filtersClick.emit();
	}

	onSearchChange() {
		this.searchChange.emit(this.form_group.get('plate').value);
	}

	isVehicleUnlockInteracting(vehicle: Vehicle): boolean {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle.id);
		if (index != -1) {
			return this.is_vehicle_unlock_interacting[index];
		}
		return false;
	}

	isVehicleLockInteracting(vehicle: Vehicle): boolean {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle.id);
		if (index != -1) {
			return this.is_vehicle_lock_interacting[index];
		}
		return false;
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}

	getRootClass() {
		return {
			'lk-vehicle-list-horiz-root-layout': this.horiz_mode,
			'': !this.horiz_mode,
		};
	}
}
