import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {from} from 'rxjs';
import {API} from 'aws-amplify';

export const INVITATIONS_API_NAME     : string    = 'invitations';
export const INVITATIONS_API_ENDPOINT : string    = environment.API_BASE_PATH + '/admin-invitations/';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  batchCreateInvitations(stationId: string, emails: string[]) {
    let apiName: string   = INVITATIONS_API_NAME;
    let path: string      = 'stations/' + stationId + '/batch';
    let init              = {
      response:   true,
      body:       emails
    };
    return from(API.post(apiName, path, init));
  }

  listInvitationsOfAdmin() {
    let apiName: string   = INVITATIONS_API_NAME;
    let path: string      = '';
    let init              = {
      response:   true
    };
    return from(API.get(apiName, path, init));
  }

  listInvitationsOfStation(stationId: string) {
    let apiName: string   = INVITATIONS_API_NAME;
    let path: string      = 'stations/' + stationId;
    let init              = {
      response:   true
    };
    return from(API.get(apiName, path, init));
  }

  setInvitationResponse(invitationId: string, stationId: string, response: boolean) {
    let apiName: string   = INVITATIONS_API_NAME;
    let path: string      = invitationId + '/stations/' + stationId + '/response';
    let init              = {
      response:   true,
      body:       { response: response? InvitationResponseType.ACCEPTED : InvitationResponseType.DECLINED }
    };
    return from(API.put(apiName, path, init));
  }

  deleteInvitation(stationId: string, invitationId: string) {
    let apiName: string   = INVITATIONS_API_NAME;
    let path: string      = invitationId + '/stations/' + stationId;
    let init              = { response:   true };
    return from(API.del(apiName, path, init));
  }
}

export class Invitation {
  id              : string;
  admin_email     : string;
  expire_on       : number;
  is_signed_admin : boolean;
  station_id      : string;
  station_name    : string;
  invitation_sender_id    : string;
  invitation_sender_email : string;
  response                : InvitationResponseType;
}

export enum InvitationResponseType {
  PENDING   = 'pending',
  ACCEPTED  = 'accepted',
  DECLINED  = 'declined',
  EXPIRED   = 'expired'
}
