<div class="lk-full-width lk-full-height lk-centered-column">
  <img 
    class="lk-empty-ill" 
    src="../../../assets/imgs/lk-empty-resource-ill.svg">
  
  <h1> {{ 'BOOKING.NO_BOOKINGS_FOUND_TITLE' | translate }} </h1>

  <button 
    mat-button 
    class="lk-main-button" 
    (click)="onBookingBuildClick()">
    <h3 class="lk-no-margin lk-primary-color"> 
      {{ 'BOOKING.ADD_BOOKING_CTA' | translate }} 
    </h3>
  </button>
</div>

