import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-shared-single-action-dialog',
  templateUrl: './shared-single-action-dialog.component.html',
  styleUrls: ['./shared-single-action-dialog.component.scss']
})
export class SharedSingleActionDialogComponent implements OnInit {
  message: string;
  title: string;
  img_url: string;

  constructor(
    private dialog_ref: MatDialogRef<SharedSingleActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.message = data.message;
    this.title = data.title;
    this.img_url = data.img_url;
  }

  ngOnInit() { }

  onCloseClick() {
    this.dialog_ref.close();
  }

  onConfirmClick() {
    this.dialog_ref.close({confirm: true});
  }
}
