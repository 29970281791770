<div
	(click)="station_click.emit()"
	style="position: relative"
	class="lk-full-width lk-border-radius lk-padding lk-margin-bottom lk-shadow lk-cursor-pointer lk-primary-background lk-row">
	<div
		id="lk-hidden-stations-list-item-logo-layout"
		class="lk-primary-medium-background lk-centered-row">
		<img
			*ngIf="station_logo"
			class="lk-full-width"
			[src]="station_logo" />
	</div>

	<div
		id="lk-hidden-stations-list-item-info-layout"
		class="lk-half-margin-left">
		<h3 class="lk-ellipsized-text lk-no-margin">{{ station_name }}</h3>

		<p
			style="font-size: 12px"
			class="lk-no-margin lk-primary-medium-color">
			<span *ngIf="station.alias"> # </span>
			{{ station?.alias || '' }}
		</p>

		<div class="lk-row">
			<mat-icon
				(click)="$event.stopPropagation(); onCopyClick(station_id)"
				class="lk-primary-medium-color">
				content_copy
			</mat-icon>
			<p class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
				{{
					station_id.substr(0, 3) +
						station_id.substr(station_id.length - 8, station_id.length)
				}}
			</p>
		</div>
	</div>

	<div
		class="lk-row"
		style="position: absolute; top: 6px; right: 6px">
		<div
			class="lk-row"
			*ngIf="is_current_station">
			<span
				style="font-size: 12px; margin-right: 6px"
				class="lk-accent-color">
				current
			</span>
			<mat-icon class="lk-accent-color"> check_circle </mat-icon>
		</div>

		<button
			mat-icon-button
			[matMenuTriggerFor]="hstation_menu"
			(click)="$event.stopPropagation()">
			<mat-icon> more_vert </mat-icon>
		</button>

		<mat-spinner
			*ngIf="is_vehicles_loading"
			diameter="20"
			color="accent"></mat-spinner>

		<mat-menu #hstation_menu="matMenu">
			<button
				mat-menu-item
				(click)="onAddVehicleClick(station_id)">
				<mat-icon> add </mat-icon>
				Add vehicle
			</button>

			<button
				mat-menu-item
				(click)="onSettingsClick()">
				<mat-icon> settings </mat-icon>
				Settings
			</button>

			<button
				mat-menu-item
				(click)="onChecklistClick()">
				<mat-icon> checklist </mat-icon>
				Checklist
			</button>

			<button
				mat-menu-item
				(click)="onAddAliasClick()">
				<mat-icon> create </mat-icon>
				Update alias
			</button>

			<button
				mat-menu-item
				(click)="onAddCredentialsClick()">
				<mat-icon> key </mat-icon>
				Add credentials
			</button>
		</mat-menu>
	</div>
</div>
