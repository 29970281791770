import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';
import {
	LOCAL_STATION_KEY,
	DATE_FORMATS,
	EN_LOCALE,
	IT_LOCALE,
} from 'src/app/shared/constants';

@Component({
	selector: 'app-booking-filters-dialog',
	templateUrl: './booking-filters-dialog.component.html',
	styleUrls: ['./booking-filters-dialog.component.scss'],
})
export class BookingFiltersDialogComponent implements OnInit {
	form_group: FormGroup;

	constructor(
		private form_service: FormService,
		private dialog_ref: MatDialogRef<BookingFiltersDialogComponent>,
		private dateAdapter: DateAdapter<any>,
		private local_manager: LocalManagerService,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.form_group = this.form_service.bookingFiltersFormGroup;

		const current_station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		switch (current_station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.dateAdapter.setLocale(EN_LOCALE);
				break;
			case DATE_FORMATS[1]:
				this.dateAdapter.setLocale(IT_LOCALE);
				break;
			default:
				this.dateAdapter.setLocale(EN_LOCALE);
				break;
		}
	}

	ngOnInit() {
		this.form_group.reset();
		this.form_group.get('current').setValue('plate');
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		if (this.form_service.validateForm(this.form_group)) {
			let plate: string = this.form_group.get('plate').value;
			let email: string = this.form_group.get('email').value;
			let start_date: string = this.form_group.get('start_date').value;
			let end_date: string = this.form_group.get('end_date').value;
			this.dialog_ref.close({
				filters: {
					plate: plate,
					email: email,
					start_date: new Date(start_date).getTime(),
					end_date: new Date(end_date).getTime(),
				},
			});
		}
	}

	onFiltersResetClick() {
		this.form_group.reset();
		this.form_group.get('current').setValue('plate');
	}

	isPlateVisible() {
		return this.form_group.get('current').value == 'plate';
	}

	isEmailVisible() {
		return this.form_group.get('current').value == 'email';
	}
}
