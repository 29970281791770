import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_SIGNUP_PATH } from 'src/app/core/navigation.service';

@Component({
	selector: 'app-subscription-signup',
	templateUrl: './subscription-signup.component.html',
	styleUrls: ['./subscription-signup.component.scss'],
})
export class SubscriptionSignupComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit(): void {}

	onGoToStationClick(): void {
		this.router.navigate([AUTH_SIGNUP_PATH]);
	}
}
