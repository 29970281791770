import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthenticationService } from '../core/authentication.service';
import { STATION_PATH } from '../core/navigation.service';

@Injectable({
	providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
	constructor(
		private auth_service: AuthenticationService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.auth_service.isAuthenticated().pipe(
			map(e => {
				if (e) {
					this.router.navigate([STATION_PATH]);
					return false;
				} else {
					return true;
				}
			}),
		);
	}
}
