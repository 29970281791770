import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/core/dialog.service';
import { SUBSCRIPTION_INFO_PATH } from 'src/app/core/navigation.service';
import { REGISTERED_USER_FLOW } from 'src/app/shared/constants';
import { SubscriptionStartDialogComponent } from '../subscription-start-dialog/subscription-start-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-subscription-start',
	templateUrl: './subscription-start.component.html',
	styleUrls: ['./subscription-start.component.scss'],
})
export class SubscriptionStartComponent implements OnInit {
	constructor(
		private router: Router,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {}

	onAlreadyUserClick(): void {
		this.router.navigate([SUBSCRIPTION_INFO_PATH], {
			queryParams: { flow: REGISTERED_USER_FLOW },
		});
	}

	onNewUserClick(): void {
		//this.dialog_service.openDialog(SubscriptionStartDialogComponent);
		let url: string = 'https://buy.stripe.com/test_fZecQd2ER0n786I3cc';
		if (environment.STAGE == 'prod')
			url = 'https://buy.stripe.com/eVadRFez480J9tm3cH';
		window.open(url, '_self');
	}
}
