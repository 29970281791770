import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-station-builder-notif-dialog',
  templateUrl: './station-builder-notif-dialog.component.html',
  styleUrls: ['./station-builder-notif-dialog.component.scss']
})
export class StationBuilderNotifDialogComponent implements OnInit {

  constructor(
    private dialog_ref: MatDialogRef<StationBuilderNotifDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {}

  onCloseClick() {
    this.dialog_ref.close();
  }

  onAddClick() {
    this.dialog_ref.close({add: true});
  }

  onStoreClick() {
    this.dialog_ref.close({store: true});
  }
}
