<app-shared-toolbar 
  [currentNavItem]="current_item" 
  [stationUpdateTrigger]="station_update_trigger"></app-shared-toolbar>

<section class="lk-section-with-toolbar lk-padding-md lk-spaced-column">
  <div class="lk-centered-column lk-full-width">
    <div 
      id="lk-station-builder-notif-form-layout" 
      class="lk-centered-column-wrapper">
      <div class="lk-centered-row lk-margin-bottom">
        <h1 class="lk-accent-color lk-no-margin lk-station-builder-step-text"> 4 </h1>
        <h1 class="lk-no-margin lk-half-margin-left"> 
          {{ 'STATION_BUILDER.STEP_NOTIF_TITLE' | translate }} 
        </h1>
      </div>
  
      <app-station-builder-notif-lang-summary 
        [station]="station" 
        *ngIf="!is_request_running"
        [notifications]="notifications"></app-station-builder-notif-lang-summary>
  
      <div class="lk-shadow lk-border-radius lk-padding-md lk-centered-row lk-wrapped-flex lk-primary-background">
        <form 
          [formGroup]="form_group" 
          *ngIf="!is_request_running"
          class="lk-centered-column">
          <mat-form-field 
            appearance="fill" 
            color="accent">
            <mat-label> {{ 'GENERAL.LANG_NOTIF_MESSAGE' | translate }} </mat-label>
            <mat-select formControlName="lang">
              <mat-option 
                *ngFor="let lang of languages" 
                [value]="lang" 
                class="lk-options-list-item">
                <img [src]="'../../../assets/imgs/lk-flag-icons/' + lang + '.png'">
                {{ getLanguageLabel(lang) | translate }} 
              </mat-option>
            </mat-select>
          </mat-form-field>
        
          <button 
            mat-button 
            (click)="onNextClick()"
            class="lk-main-button lk-double-margin-top">
            <h3 class="lk-no-margin lk-primary-color"> {{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }} </h3>
          </button>
        </form>

        <div 
          class="lk-centered-row"
          *ngIf="is_request_running">
          <mat-spinner
            color="accent"
            diameter="30"></mat-spinner>
        </div>
      </div>
    </div>
  </div>

  <app-station-builder-steps [currentStep]="current_step"></app-station-builder-steps>
</section>
