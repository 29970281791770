<app-shared-app-toolbar
	[current_nav_item]="current_item"
	[refresh]="toolbar_refresh"
	(no_station_found)="onNoStationFound()"
	(station_build_click)="onStationBuildClick(false)"
	(station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section class="lk-section-with-toolbar">
	<div
		class="lk-section-container-layout lk-centered-column lk-padding-md"
		*ngIf="!is_vehicles_loading && vehicles && vehicles.length <= 0">
		<div class="lk-centered-column-wrapper">
			<app-vehicle-empty
				(vehicle_build_click)="onAddVehicleClick()"
				(manual_request_click)="onManualRequestClick()"></app-vehicle-empty>
		</div>
	</div>

	<div
		class="lk-padding-md"
		*ngIf="
			!vehicles ||
			(!vehicles.length && is_vehicles_loading) ||
			(vehicles && vehicles.length)
		">
		<div class="lk-spaced-row lk-horiz-half-padding">
			<h1 class="lk-no-margin">{{ 'VEHICLE.TITLE' | translate }}</h1>
			<div class="lk-row">
				<button
					mat-icon-button
					class="lk-background-icon lk-vehicle-icon-button"
					(click)="onRefreshClick()"
					matTooltip="{{ 'VEHICLE.REFRESH_CTA' | translate }}">
					<mat-icon class="lk-accent-color"> refresh </mat-icon>
				</button>
				<!--<button
					id="lk-vehicle-add-button"
					mat-icon-button
					class="lk-background-icon lk-vehicle-icon-button"
					(click)="onAddVehicleClick()"
					matTooltip="{{ 'VEHICLE.ADD_CTA' | translate }}">
					<mat-icon class="lk-accent-color"> add </mat-icon>
				</button>-->
				<button
					id="lk-vehicle-add-button"
					mat-icon-button
					class="lk-background-icon lk-vehicle-icon-button"
					(click)="onManualRequestClick()"
					matTooltip="{{ 'VEHICLE.REQUEST_MANUAL_CTA' | translate }}">
					<mat-icon class="lk-accent-color"> directions_car </mat-icon>
				</button>
				<button
					*ngIf="is_vehicle_map_visible"
					mat-icon-button
					class="lk-background-icon lk-vehicle-icon-button"
					(click)="onMapClick()"
					matTooltip="{{ 'VEHICLE.MAP_CTA' | translate }}">
					<mat-icon class="lk-accent-color"> map </mat-icon>
				</button>
				<button
					mat-icon-button
					class="lk-background-icon lk-vehicle-icon-button"
					(click)="onVehiclesDownloadClick()"
					matTooltip="{{ 'VEHICLE.DOWNLOAD_CTA' | translate }}">
					<mat-icon
						*ngIf="!is_export_running"
						class="lk-accent-color">
						download
					</mat-icon>
					<mat-spinner
						*ngIf="is_export_running"
						color="accent"
						style="margin: auto"
						[diameter]="20"></mat-spinner>
				</button>
			</div>
		</div>
		<app-vehicle-summary
			[refreshSummary]="refresh_summary_trigger"></app-vehicle-summary>
		<app-vehicle-table
			*ngIf="!is_vehicles_loading"
			id="lk-vehicle-table"
			(vehicleClick)="onVehicleClick($event)"
			(vehicleDeleteClick)="onVehicleDeleteClick($event)"
			(searchChange)="onSearchChange($event)"
			(filtersClick)="onFiltersClick()"
			(vehicleStartClick)="onStartVehicleClick($event)"
			(vehicleStopClick)="onStopVehicleClick($event)"
			[vehicleUpdateTrigger]="vehicle_update_trigger"></app-vehicle-table>
		<app-vehicle-list
			id="lk-vehicle-list"
			(vehicleDeleteClick)="onVehicleDeleteClick($event)"
			(vehicleClick)="onVehicleClick($event)"
			(searchChange)="onSearchChange($event)"
			(filtersClick)="onFiltersClick()"
			(vehicleStartClick)="onRefreshClick()"
			(vehicleStopClick)="onRefreshClick()"
			[vehicleUpdateTrigger]="vehicle_update_trigger"></app-vehicle-list>

		<div
			#loadMoreLayout
			class="lk-full-width lk-centered-row"
			*ngIf="!is_vehicles_loading && !is_last_page">
			<button
				mat-button
				class="lk-main-button"
				(click)="onLoadPageClick()"
				[disabled]="is_page_loading"
				[ngClass]="getDisabledClass()">
				<span *ngIf="!is_page_loading">
					{{ 'GENERAL.LOAD_MORE_CTA' | translate }}
				</span>
				<div
					class="lk-spinner-layout"
					*ngIf="is_page_loading">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>
		</div>
	</div>

	<div
		*ngIf="is_vehicles_loading"
		class="lk-full-width lk-full-height lk-centered-row"
		style="position: fixed; top: 0; left: 0">
		<div class="lk-overlay-spinner-layout lk-padding lk-border-radius">
			<mat-spinner
				color="accent"
				diameter="30"></mat-spinner>
		</div>
	</div>
</section>
