<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-booking-user-builder-content-layout"
		class="lk-horiz-padding lk-hide-scrollbar">
		<h3>{{ 'BOOKING.ADD_USER_DIALOG_TITLE' | translate }}</h3>
		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label>
					{{ 'BOOKING.USER_FIRST_NAME_LABEL' | translate }}
				</mat-label>
				<input
					id="lk-booking-user-builder-firstname-input"
					matInput
					type="text"
					formControlName="first_name"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label>
					{{ 'BOOKING.USER_LAST_NAME_LABEL' | translate }}
				</mat-label>
				<input
					id="lk-booking-user-builder-lastname-input"
					matInput
					type="text"
					formControlName="last_name"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					id="lk-booking-user-builder-email-input"
					matInput
					type="email"
					formControlName="email" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label> {{ 'BOOKING.USER_TAX_CODE_LABEL' | translate }} </mat-label>
				<input
					id="lk-booking-user-builder-tax-code-input"
					matInput
					type="text"
					formControlName="tax_code" />
			</mat-form-field>

			<div class="lk-centered-row lk-full-width lk-margin-top">
				<button
					id="lk-booking-user-builder-confirm-button"
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()"
					[disabled]="is_request_loading"
					[ngClass]="getDisabledClass()">
					<span *ngIf="!is_request_loading">
						{{ 'GENERAL.CONFIRM_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_request_loading">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
