import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormService} from '../../core/form.service';
import {FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {LOCAL_STATION_KEY} from '../../shared/constants';
import {Station} from '../../core/station.service';
import {LocalManagerService} from '../../core/local-manager.service';

@Component({
  selector: 'app-station-settings-trigger-unlock',
  templateUrl: './station-settings-trigger-unlock.component.html',
  styleUrls: ['./station-settings-trigger-unlock.component.scss']
})
export class StationSettingsTriggerUnlockComponent implements OnInit {
  @Input()  stationUpdateTrigger: BehaviorSubject<boolean>;
  @Output() unlockableOnVehicleAssignmentChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() unlockableUntilBookingEndChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  is_unlockable_on_vehicle_assignment: boolean;
  is_unlockable_until_booking_end: boolean;
  current_station: Station;
  unlock_link_form_group: FormGroup;
  unlock_before_form_group: FormGroup;
  unlock_after_form_group: FormGroup;
  unlock_link_time_translate_param: { hours: string, minutes: string, time: string };
  unlock_before_translate_param: { hours: string, minutes: string, time: string };
  unlock_after_translate_param: { hours: string, minutes: string, time: string };

  constructor(
    private form_service: FormService, 
    private local_manager: LocalManagerService
  ) {
    this.unlock_link_form_group = this.form_service.stationSettingsUnlockLinkFormGroup;
    this.unlock_before_form_group = this.form_service.stationSettingsUnlockBeforeFormGroup;
    this.unlock_after_form_group = this.form_service.stationSettingsUnlockAfterFormGroup;
  }

  ngOnInit() {
    this.stationUpdateTrigger.subscribe(
      update => {
        if (update) {
          this.current_station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
          this.initFormFields();
          this.setTranslationParams();
        }
      }
    );
  }

  onUnlockableOnVehicleAssignmentChange() {
    this.unlockableOnVehicleAssignmentChange.emit(this.is_unlockable_on_vehicle_assignment);
    if (this.is_unlockable_on_vehicle_assignment) {
      this.unlock_link_form_group.get('time').disable();
      this.unlock_before_form_group.get('time').disable();

      this.unlock_link_form_group.get('time').setValue('00:00');
      this.unlock_before_form_group.get('time').setValue('00:00');
      this.setTranslationParams();
    } else {
      this.unlock_link_form_group.get('time').enable();
      this.unlock_before_form_group.get('time').enable();
    }
  }

  onUnlockableUntilBookingEndChange() {
    this.unlockableUntilBookingEndChange.emit(this.is_unlockable_until_booking_end);
    if (this.is_unlockable_until_booking_end) {
      this.unlock_after_form_group.get('time').disable();

      this.unlock_after_form_group.get('time').setValue('00:00');
      this.setTranslationParams();
    } else {
      this.unlock_after_form_group.get('time').enable();
    }
  }

  onInputChange(event, formGroup: FormGroup) {
    if (!formGroup.get('time').value.includes(':')) {
      const columnIndex: number = Math.ceil(formGroup.get('time').value.length/2);
      event.target.value = event.target.value.substr(0, columnIndex) + ':' + event.target.value.substr(columnIndex + 1);
    }

    const target: string[] = event.target.value.split(":");
    if(target && target[0].length > 2) {
      event.target.value = target[0].slice(0, -1) + ':' + target[1];
    }
    if(target && target[1].length > 2) {
      event.target.value = target[0] + ':' + target[1].slice(0, -1);
    }
    
    this.setTranslationParams();
  }

  initFormFields() {
    // Flags
    this.is_unlockable_on_vehicle_assignment = this.current_station.settings.unlock_settings?
      this.current_station.settings.unlock_settings.is_unlockable_on_vehicle_assignment : false;
    this.is_unlockable_until_booking_end = this.current_station.settings.unlock_settings?
      this.current_station.settings.unlock_settings.is_unlockable_until_booking_end_date : false;

    // Unlock link
    let hoursAndMinutes = this.convertUnlockLinkTime();
    this.unlock_link_form_group.get('time').setValue(
      hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString()
    );

    // Unlock before
    hoursAndMinutes = this.convertUnlockBefore();
    this.unlock_before_form_group.get('time').setValue(
      hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString()
    );

    // Unlock after
    hoursAndMinutes = this.convertUnlockAfter();
    this.unlock_after_form_group.get('time').setValue(
      hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString()
    );

    // Block fields
    this.onUnlockableOnVehicleAssignmentChange();
    this.onUnlockableUntilBookingEndChange();
  }

  setTranslationParams() {
    this.unlock_link_time_translate_param   = { 
      hours   : this.parseTimeFormGroup(this.unlock_link_form_group)[0].toString(),
      minutes : this.parseTimeFormGroup(this.unlock_link_form_group)[1].toString(),
      time    : this.getTimeLabel(this.unlock_link_form_group) 
    };

    this.unlock_before_translate_param     = { 
      hours   : this.parseTimeFormGroup(this.unlock_before_form_group)[0].toString(),
      minutes : this.parseTimeFormGroup(this.unlock_before_form_group)[1].toString(),
      time    : this.getTimeLabel(this.unlock_before_form_group)  
    };

    this.unlock_after_translate_param      = { 
      hours   : this.parseTimeFormGroup(this.unlock_after_form_group)[0].toString(),
      minutes : this.parseTimeFormGroup(this.unlock_after_form_group)[1].toString(),
      time    : this.getTimeLabel(this.unlock_after_form_group, true)   
    };
  }

  convertUnlockLinkTime() {
    return this.getHoursAndMinutesFromMillis(
      this.current_station.settings.unlock_settings?
      this.current_station.settings.unlock_settings.time_to_unlock_link_before_booking_start : 3600000
    );
  }

  convertUnlockBefore() {
    return this.getHoursAndMinutesFromMillis(
      this.current_station.settings.unlock_settings?
      this.current_station.settings.unlock_settings.time_to_unlock_availability_before_booking_start : 3600000
    );
  }

  convertUnlockAfter() {
    return this.getHoursAndMinutesFromMillis(
      this.current_station.settings.unlock_settings?
      this.current_station.settings.unlock_settings.time_to_unlock_availability_after_booking_start : 3600000
    );
  }

  parseTimeFormGroup(formGroup: FormGroup): number[] {
    const time            : string = formGroup.get('time').value;
    const columnIndex     : number = time.indexOf(':');
    return [
      +time.substring(0, columnIndex),
      +time.substring(columnIndex+1, time.length)
    ];
  }

  getTimeLabel(formGroup: FormGroup, sum?: boolean): string {
    const MILLIS_IN_MIN       : number    = 60*1000;
    const MILLIS_IN_HOUR      : number    = 60*60*1000;
    const NOON_MILLIS         : number    = 12*MILLIS_IN_HOUR;
    const hoursAndMins        : number[]  = this.parseTimeFormGroup(formGroup);
    let hoursAndMinsMillis    : number    = hoursAndMins[0] * MILLIS_IN_HOUR + hoursAndMins[1] * MILLIS_IN_MIN;
    const time                : string[]  = sum?
      this.getHoursAndMinutesFromMillis(NOON_MILLIS + hoursAndMinsMillis) :
      this.getHoursAndMinutesFromMillis(NOON_MILLIS - hoursAndMinsMillis);
    return time[0] + ':' + time[1];
  }

  getHoursAndMinutesFromMillis(millis: number): string[] {
    let minutes = this.correctDigits(Math.floor((millis / (1000 * 60)) % 60)),
        hours   = this.correctDigits(Math.floor((millis / (1000 * 60 * 60))));
    return [hours,minutes];
  }

  correctDigits(num: number): string {
    if (num.toString().length <= 1) {
      return '0' + num;
    }
    return num.toString();
  }
}
