<app-shared-app-toolbar
	[refresh]="toolbar_refresh"
	(no_station_found)="onNoStationFound()"
	(station_build_click)="onStationBuildClick(false)"
	(station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section class="lk-section-with-toolbar">
	<app-shared-spinner-overlay
		*ngIf="is_request_running"></app-shared-spinner-overlay>

	<div class="lk-full-width lk-full-height lk-padding lk-row lk-wrapped-flex">
		<div
			class="lk-full-width lk-half-margin-right lk-half-margin-left lk-half-margin-bottom">
			<app-hidden-stations-header
				(refresh)="onRefresh()"
				(search_change)="onSearchChange($event)"
				(alias_search_change)="
					onSearchChange($event, true)
				"></app-hidden-stations-header>
		</div>

		<div
			class="lk-hidden-stations-list-item lk-half-margin-right lk-half-margin-left"
			*ngFor="let station of stations">
			<app-hidden-stations-list-item
				[station]="station"
				[station_logo]="station.logo_url"
				[station_name]="station.name"
				[station_id]="station.id"
				[is_current_station]="isCurrentStation(station)"
				(refresh)="onRefresh()"
				(station_click)="
					onStationListChangeClick(station)
				"></app-hidden-stations-list-item>
		</div>

		<div
			*ngIf="stations && !stations.length"
			class="lk-full-width lk-centered-column">
			<hr class="lk-list-separator lk-full-width" />
			<span class="lk-centered-text lk-primary-medium-color">
				{{ 'STATION.NO_STATIONS_FOUND_TITLE' | translate }}
			</span>
			<button
				class="lk-main-button lk-half-margin-top"
				mat-button
				[disabled]="is_load_more_running"
				[ngClass]="getDisabledClass()"
				(click)="onStationChangeClick(current_station)">
				<span> Refresh </span>
			</button>
			<hr class="lk-list-separator lk-full-width" />
		</div>

		<div
			*ngIf="stations && stations.length && total_items > stations.length"
			class="lk-full-width lk-centered-column">
			<hr class="lk-list-separator lk-full-width" />
			<button
				class="lk-main-button"
				mat-button
				[disabled]="is_load_more_running"
				[ngClass]="getDisabledClass()"
				(click)="onLoadMoreClick()">
				<span *ngIf="!is_load_more_running">
					{{ 'GENERAL.LOAD_MORE_CTA' | translate }}
				</span>
				<div
					class="lk-spinner-layout"
					*ngIf="is_load_more_running">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>
			<hr class="lk-list-separator lk-full-width" />
		</div>
	</div>
</section>
