import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { HiddenStationsResetMfaDialogComponent } from '../hidden-stations-reset-mfa-dialog/hidden-stations-reset-mfa-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HiddenStationsAdminAddDialogComponent } from '../hidden-stations-admin-add-dialog/hidden-stations-admin-add-dialog.component';

@Component({
	selector: 'app-hidden-stations-header',
	templateUrl: './hidden-stations-header.component.html',
	styleUrls: ['./hidden-stations-header.component.scss'],
})
export class HiddenStationsHeaderComponent implements OnInit {
	@Output() search_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() alias_search_change: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();
	current_search_value?: string;
	current_alias_search_value?: string;
	form_group: FormGroup;

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private snackbar: MatSnackBar,
	) {
		this.form_group = this.form_service.hidden_stations_search_form_group;
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {}

	onResetMfaClick(): void {
		this.dialog_service
			.openDialog(HiddenStationsResetMfaDialogComponent)
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.snackbar.open('MFA reset successfully!', '', { duration: 2000 });
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onAddAdminClick(): void {
		this.dialog_service
			.openDialog(HiddenStationsAdminAddDialogComponent)
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					console.log('success');
					this.snackbar.open('Admin added successfully!', '', {
						duration: 2000,
					});
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}
}
