<div class="lk-full-width lk-border-radius lk-shadow lk-padding-md lk-primary-background">
  <h2 class="lk-no-margin lk-accent-color">
    {{ 'STATION.SETTINGS_LINK_TITLE' | translate }}
  </h2>
  <p class="lk-primary-medium-color lk-no-margin lk-margin-bottom">
    {{ 'STATION.SETTINGS_LINK_SUBTITLE' | translate }}
  </p>
  
  <app-station-settings-trigger-unlock
    (unlockableOnVehicleAssignmentChange)="onUnlockableOnVehicleAssignmentChange($event)"
    (unlockableUntilBookingEndChange)="onUnlockableUntilBookingEndChange($event)"
    [stationUpdateTrigger]="stationUpdateTrigger"></app-station-settings-trigger-unlock>
  <app-station-settings-trigger-lock
    (lockableAfterVehicleUnlockChange)="onLockableAfterVehicleUnlockChange($event)"
    [stationUpdateTrigger]="stationUpdateTrigger"></app-station-settings-trigger-lock>

  <div class="lk-full-width lk-ended-row lk-margin-top">
    <button class="lk-main-button" mat-button (click)="onTriggerUpdateClick()">
      <span> {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} </span>
    </button>
  </div>
</div>
