import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';
import { AdminService } from 'src/app/core/admin.service';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';
import { FormService } from 'src/app/core/form.service';
import { ERROR_DESCRIPTION_500, UNKNOWN_FLAG } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-hidden-stations-reset-mfa-dialog',
	templateUrl: './hidden-stations-reset-mfa-dialog.component.html',
	styleUrls: ['./hidden-stations-reset-mfa-dialog.component.scss'],
})
export class HiddenStationsResetMfaDialogComponent implements OnInit {
	form_group: FormGroup = this.form_service.mfa_reset_form_group;
	country_prefixes: CountryPrefix[];
	filtered_options: Observable<CountryPrefix[]>;
	current_country_prefix: CountryPrefix;
	unknown_flag_src: string = UNKNOWN_FLAG;
	is_mfa_setup_running: boolean = false;

	constructor(
		private form_service: FormService,
		private countries: CountriesService,
		private admin_service: AdminService,
		private dialog_ref: MatDialogRef<HiddenStationsResetMfaDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {}

	ngOnInit(): void {
		this.form_group.reset();
		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.isFormValid()) {
			const email = this.form_group.get('email')?.value;
			const phone_prefix = this.form_group.get('phone_prefix')?.value;
			const phone_value = this.form_group.get('phone')?.value;
			this.resetMFA(email, {
				prefix: phone_prefix,
				value: phone_value,
			});
		}
	}

	onCountryPrefixSelect(): void {
		const code: string = this.form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	resetMFA(email: string, phone: { prefix: string; value: string }): void {
		this.is_mfa_setup_running = true;
		this.admin_service.resetMfa(email, phone).subscribe({
			next: () => {
				this.dialog_ref.close({ success: true });
				this.is_mfa_setup_running = false;
			},
			error: error => {
				if (
					error.response.data.error &&
					error.response.data.error.description != ERROR_DESCRIPTION_500 &&
					error.response.data.error.description
				) {
					this.dialog_ref.close({
						error_message: error.response.data.error.description,
					});
				} else if (
					!error.response.data.error ||
					!error.response.data.error.description ||
					error.response.data.error.description == ERROR_DESCRIPTION_500
				) {
					this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
				}
				this.is_mfa_setup_running = false;
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_mfa_setup_running || !this.isFormValid(),
			'lk-main-button': !this.is_mfa_setup_running && this.isFormValid(),
		};
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}
}
