import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
	SUBSCRIPTION_INFO_PATH,
	SUBSCRIPTION_PAYMENT_PATH,
	SUBSCRIPTION_SIGNIN_PATH,
	SUBSCRIPTION_SIGNUP_PATH,
	SUBSCRIPTION_START_PATH,
} from '../core/navigation.service';
import { SubscriptionStartComponent } from './subscription-start/subscription-start.component';
import { SubscriptionPaymentComponent } from './subscription-payment/subscription-payment.component';
import { SubscriptionInfoComponent } from './subscription-info/subscription-info.component';
import { SubscriptionSigninComponent } from './subscription-signin/subscription-signin.component';
import { SubscriptionSignupComponent } from './subscription-signup/subscription-signup.component';

const routes: Routes = [
	{
		path: SUBSCRIPTION_START_PATH,
		component: SubscriptionStartComponent,
	},
	{
		path: SUBSCRIPTION_PAYMENT_PATH,
		component: SubscriptionPaymentComponent,
	},
	{
		path: SUBSCRIPTION_INFO_PATH,
		component: SubscriptionInfoComponent,
	},
	{
		path: SUBSCRIPTION_SIGNIN_PATH,
		component: SubscriptionSigninComponent,
	},
	{
		path: SUBSCRIPTION_SIGNUP_PATH,
		component: SubscriptionSignupComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SubscriptionRoutingModule {}
