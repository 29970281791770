import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { NotificationType } from 'src/app/core/notification.service';
import { PicturePolicy, Station } from 'src/app/core/station.service';
import { PROD_STAGE, SUPPORTED_LANGUAGES } from 'src/app/shared/constants';
import { SharedSingleActionDialogComponent } from 'src/app/shared/shared-single-action-dialog/shared-single-action-dialog.component';
import {
	NO_NOTIF_TYPE_MESSAGE,
	SUPPORTED_LANGUAGES_LABELS,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-booking-settings-general-dialog',
	templateUrl: './booking-settings-general-dialog.component.html',
	styleUrls: ['./booking-settings-general-dialog.component.scss'],
})
export class BookingSettingsGeneralDialogComponent implements OnInit {
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	is_sms_checked: boolean = true;
	is_email_checked: boolean = true;
	languages: string[] = SUPPORTED_LANGUAGES;
	mandatory_policy: PicturePolicy = PicturePolicy.MANDATORY;
	mandatory_pre_policy: PicturePolicy = PicturePolicy.MANDATORY_PRE;
	mandatory_post_policy: PicturePolicy = PicturePolicy.MANDATORY_POST;
	optional_policy: PicturePolicy = PicturePolicy.OPTIONAL;
	disabled_policy: PicturePolicy = PicturePolicy.DISABLED;
	is_policy_mandatory: boolean = false;
	is_policy_mandatory_pre: boolean = false;
	is_policy_mandatory_post: boolean = false;
	is_policy_optional: boolean = false;
	is_policy_disabled: boolean = false;
	policy: PicturePolicy;
	form_group: FormGroup;
	station: Station;
	is_production: boolean;

	constructor(
		private dialog_ref: MatDialogRef<BookingSettingsGeneralDialogComponent>,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private form_service: FormService,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.dialog_service.dialog = this.dialog;
		this.station = data.station;
		this.form_group = this.form_service.bookingSettingLangFormGroup;
	}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;
		if (this.station) {
			this.is_sms_checked =
				this.station.settings.default_notification_type ==
					NotificationType.SMS ||
				this.station.settings.default_notification_type ==
					NotificationType.BOTH;
			this.is_email_checked =
				this.station.settings.default_notification_type ==
					NotificationType.EMAIL ||
				this.station.settings.default_notification_type ==
					NotificationType.BOTH;
			this.policy = this.station.settings.pictures_policy;
			this.setPolicyFlags();
			this.form_group.get('lang').setValue(this.station.settings.default_lang);
		}
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		if (this.form_service.validateForm(this.form_group)) {
			if (this.is_sms_checked || this.is_email_checked) {
				let type: NotificationType;
				if (this.is_sms_checked && this.is_email_checked) {
					type = NotificationType.BOTH;
				} else {
					type = this.is_sms_checked
						? NotificationType.SMS
						: NotificationType.EMAIL;
				}
				const lang: string = this.form_group.get('lang').value;
				this.dialog_ref.close({
					settings: {
						notifType: type,
						lang: lang,
						policy: this.is_production ? this.disabled_policy : this.policy,
					},
				});
			} else {
				this.dialog_service.openDialog(SharedSingleActionDialogComponent, {
					message: NO_NOTIF_TYPE_MESSAGE,
				});
			}
		}
	}

	onToggleChange(event, policy: PicturePolicy) {
		if (event.checked) {
			this.policy = policy;
			this.setPolicyFlags();
		} else {
			this.policy = null;
		}
	}

	getLanguageLabel(lang: string) {
		switch (lang) {
			case SUPPORTED_LANGUAGES[0]:
				return SUPPORTED_LANGUAGES_LABELS[0];
			case SUPPORTED_LANGUAGES[1]:
				return SUPPORTED_LANGUAGES_LABELS[1];
			case SUPPORTED_LANGUAGES[2]:
				return SUPPORTED_LANGUAGES_LABELS[2];
			case SUPPORTED_LANGUAGES[3]:
				return SUPPORTED_LANGUAGES_LABELS[3];
		}
	}

	setPolicyFlags() {
		switch (this.policy) {
			case PicturePolicy.MANDATORY:
				this.is_policy_mandatory = true;
				this.is_policy_mandatory_pre = false;
				this.is_policy_mandatory_post = false;
				this.is_policy_optional = false;
				this.is_policy_disabled = false;
				break;
			case PicturePolicy.MANDATORY_PRE:
				this.is_policy_mandatory = false;
				this.is_policy_mandatory_pre = true;
				this.is_policy_mandatory_post = false;
				this.is_policy_optional = false;
				this.is_policy_disabled = false;
				break;
			case PicturePolicy.MANDATORY_POST:
				this.is_policy_mandatory = false;
				this.is_policy_mandatory_pre = false;
				this.is_policy_mandatory_post = true;
				this.is_policy_optional = false;
				this.is_policy_disabled = false;
				break;
			case PicturePolicy.OPTIONAL:
				this.is_policy_mandatory = false;
				this.is_policy_mandatory_pre = false;
				this.is_policy_mandatory_post = false;
				this.is_policy_optional = true;
				this.is_policy_disabled = false;
				break;
			case PicturePolicy.DISABLED:
				this.is_policy_mandatory = false;
				this.is_policy_mandatory_pre = false;
				this.is_policy_mandatory_post = false;
				this.is_policy_optional = false;
				this.is_policy_disabled = true;
				break;
		}
	}
}
