<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-horiz-padding lk-margin-bottom">
		<form [formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				class="lk-full-width"
				color="accent">
				<mat-label> Client ID </mat-label>
				<input
					type="text"
					required
					matInput
					formControlName="client_id" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-full-width lk-margin-top"
				color="accent">
				<mat-label> Client secret </mat-label>
				<input
					type="text"
					required
					matInput
					formControlName="client_secret" />
			</mat-form-field>

			<div class="lk-full-width lk-ended-row lk-margin-top">
				<button
					class="lk-main-button"
					mat-button
					[disabled]="is_save_running"
					[ngClass]="getDisabledClass()"
					(click)="onCredentialsUpdateClick()">
					<span *ngIf="!is_save_running">
						{{ 'GENERAL.SAVE_BUTTON_CTA' | translate }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_save_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
