import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { API } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { Phone } from './core.module';

export const NOTIFICATIONS_API_NAME: string = 'notifications';
export const NOTIFICATIONS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/notification-templates/';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	addNotification(stationId: string, notification: Notification) {
		let apiName: string = NOTIFICATIONS_API_NAME;
		let path: string = 'stations/' + stationId;
		let init = {
			response: true,
			body: notification,
		};
		return from(API.post(apiName, path, init));
	}

	updateNotification(stationId: string, notification: Notification) {
		let apiName: string = NOTIFICATIONS_API_NAME;
		let path: string = notification.id + '/stations/' + stationId + '/content';
		let init = {
			response: true,
			body: notification.content,
		};
		return from(API.put(apiName, path, init));
	}

	removeNotification(stationId: string, notification: Notification) {
		let apiName: string = NOTIFICATIONS_API_NAME;
		let path: string = '/' + notification.id + '/stations/' + stationId;
		let init = { response: true };
		return from(API.del(apiName, path, init));
	}

	listNotifications(stationId: string) {
		let apiName: string = NOTIFICATIONS_API_NAME;
		let path: string = 'stations/' + stationId;
		let init = {
			response: true,
		};
		return from(API.get(apiName, path, init));
	}

	enableNotification(notification_id: string, station_id: string) {
		let apiName: string = NOTIFICATIONS_API_NAME;
		let path: string = notification_id + '/stations/' + station_id + '/enable';
		let init = { response: true };
		return from(API.put(apiName, path, init));
	}

	disableNotification(notification_id: string, station_id: string) {
		let apiName: string = NOTIFICATIONS_API_NAME;
		let path: string = notification_id + '/stations/' + station_id + '/disable';
		let init = { response: true };
		return from(API.put(apiName, path, init));
	}

	testTemplate(
		station_id: string,
		template_name: NotificationName,
		template_type: NotificationType,
		template_id?: string,
		phone?: Phone,
		email?: string,
	): Observable<any> {
		const api_name: string = NOTIFICATIONS_API_NAME;
		const path: string = `stations/${station_id}/simulate`;

		let phone_body: {
			prefix: string;
			value: string;
		};
		if (phone) {
			phone_body = {
				prefix: phone.prefix,
				value: phone.number,
			};
		}

		const init = {
			response: true,
			body: {
				phone: phone_body,
				email: email,
				template_id: template_id,
				template_name: template_name,
				template_type: template_type,
			},
		};
		return from(API.post(api_name, path, init));
	}
}

export class Notification {
	id?: string;
	name?: string;
	type?: NotificationType;
	lang?: string;
	content?: string;
	content_url?: string;
	is_disabled?: boolean;
}

export enum NotificationType {
	EMAIL = 'email',
	SMS = 'sms',
	BOTH = 'all',
}

export enum NotificationName {
	BOOKING_START = 'booking_start',
	BOOKING_END = 'booking_end',
	BOOKING_VEHICLE_CHANGED = 'booking_vehicle_changed',
	BOOKING_DATES_CHANGED = 'booking_dates_changed',
	BOOKING_DELETED = 'booking_deleted',
	BOOKING_COMPLETE = 'booking_completed',
	QUICK_LINK = 'quick_link',
}

export const NOTIFICATIONS: Notification[] = [
	{
		id: null,
		name: NotificationName.BOOKING_START,
		type: NotificationType.SMS,
		lang: null,
	},
	{
		id: null,
		name: NotificationName.BOOKING_END,
		type: NotificationType.SMS,
		lang: null,
	},
	{
		id: null,
		name: NotificationName.BOOKING_START,
		type: NotificationType.EMAIL,
		lang: null,
	},
	{
		id: null,
		name: NotificationName.BOOKING_END,
		type: NotificationType.EMAIL,
		lang: null,
	},
];
