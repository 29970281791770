import { BrowserModule } from '@angular/platform-browser';
import {
	LOCALE_ID,
	NgModule,
	APP_INITIALIZER,
	ErrorHandler,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookingModule } from './booking/booking.module';
import { BookingDetailsModule } from './booking-details/booking-details.module';
import { StationModule } from './station/station.module';
import { StationBuilderModule } from './station-builder/station-builder.module';
import { StationSettingsModule } from './station-settings/station-settings.module';
import { VehicleModule } from './vehicle/vehicle.module';
import { VehicleDetailsModule } from './vehicle-details/vehicle-details.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { InvitationModule } from './invitation/invitation.module';
import { SharedModule } from './shared/shared.module';
import { MetabaseModule } from './metabase/metabase.module';
import { SubscriptionModule } from './subscription/subscription.module';
import { HiddenSettingsModule } from './hidden-settings/hidden-settings.module';
import { HiddenStationsModule } from './hidden-stations/hidden-stations.module';
import { ProfileModule } from './profile/profile.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(
		http,
		'./assets/i18n/',
		'.json?cb=' + new Date().getTime(),
	);
}

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { QuillModule } from 'ngx-quill';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { DecimalPipe, registerLocaleData } from '@angular/common';
import {
	MAT_DATE_LOCALE,
	DateAdapter,
	MAT_DATE_FORMATS,
} from '@angular/material/core';
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter,
} from '@angular/material-moment-adapter';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MatDialogModule,
		BrowserAnimationsModule,
		BookingModule,
		BookingDetailsModule,
		StationModule,
		StationBuilderModule,
		StationSettingsModule,
		VehicleModule,
		MetabaseModule,
		VehicleDetailsModule,
		AuthenticationModule,
		HiddenSettingsModule,
		HiddenStationsModule,
		SubscriptionModule,
		InvitationModule,
		ProfileModule,
		GooglePlaceModule,
		MatButtonModule,
		MatIconModule,
		QuillModule.forRoot({
			modules: {
				blotFormatter: {
					// see config options below
				},
				toolbar: [
					[
						{
							header: [1, 2, 3, false],
						},
					],
					['bold', 'italic', 'underline'],
					[
						{
							align: [],
						},
					],
					[
						{
							list: 'ordered',
						},
						{
							list: 'bullet',
						},
					],
					['link'],
				],
			},
			placeholder: 'foo...',
			theme: 'snow',
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		SharedModule,
		HttpClientModule,
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		DecimalPipe,
		{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
		{ provide: LOCALE_ID, useValue: 'it-IT' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
