import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';

export const METABASE_API_NAME: string = 'metabase';
export const METABASE_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/meta-dashboards/';

@Injectable({
	providedIn: 'root',
})
export class MetabaseService {
	constructor() {}

	getMetabaseAddress(station_id: string): Observable<any> {
		const apiName: string = METABASE_API_NAME;
		const path: string = `stations/${station_id}`;
		const init = { response: true };
		return from(API.get(apiName, path, init));
	}
}
