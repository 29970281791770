import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleDetailsRoutingModule } from './vehicle-details-routing.module';
import { SharedModule } from '../shared/shared.module';
import { VehicleModule } from '../vehicle/vehicle.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { VehicleDetailsComponent } from './vehicle-details.component';
import { VehicleDetailsGeneralComponent } from './vehicle-details-general/vehicle-details-general.component';
import { VehicleDetailsGalleryComponent } from './vehicle-details-gallery/vehicle-details-gallery.component';
import { VehicleDetailsMapComponent } from './vehicle-details-map/vehicle-details-map.component';
import { TranslateModule } from '@ngx-translate/core';
import { VehicleDetailsImageDialogComponent } from './vehicle-details-image-dialog/vehicle-details-image-dialog.component';
import { VehicleDetailsNavbarComponent } from './vehicle-details-navbar/vehicle-details-navbar.component';
import { VehicleDetailsCommandsComponent } from './vehicle-details-commands/vehicle-details-commands.component';
import { VehicleDetailsMeasuresComponent } from './vehicle-details-measures/vehicle-details-measures.component';
import { VehicleDetailsLinksComponent } from './vehicle-details-links/vehicle-details-links.component';

@NgModule({
	declarations: [
		VehicleDetailsComponent,
		VehicleDetailsGeneralComponent,
		VehicleDetailsGalleryComponent,
		VehicleDetailsMapComponent,
		VehicleDetailsImageDialogComponent,
		VehicleDetailsNavbarComponent,
		VehicleDetailsCommandsComponent,
		VehicleDetailsMeasuresComponent,
  VehicleDetailsLinksComponent,
	],
	imports: [
		CommonModule,
		VehicleDetailsRoutingModule,
		SharedModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		VehicleModule,
		MatTooltipModule,
		MatIconModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		MatMenuModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
	],
	entryComponents: [VehicleDetailsImageDialogComponent],
})
export class VehicleDetailsModule {}
