import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HIDDEN_STATIONS_PATH } from '../core/navigation.service';
import { HiddenStationsComponent } from './hidden-stations.component';

const routes: Routes = [
	{
		path: HIDDEN_STATIONS_PATH,
		component: HiddenStationsComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class HiddenStationsRoutingModule {}
