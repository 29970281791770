import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { INVITATION_PATH } from '../core/navigation.service';
import { AuthGuard } from '../shared/auth.guard';
import {InvitationComponent} from './invitation.component';

const routes: Routes = [
  {
    path: INVITATION_PATH,
    component: InvitationComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvitationRoutingModule { }
