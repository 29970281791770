<div
	style="padding: 20px; box-sizing: border-box"
	class="lk-full-width lk-full-height">
	<div class="lk-full-width lk-justified-row">
		<img
			style="height: 50px"
			src="../../../assets/imgs/lk-logo.svg" />
	</div>

	<p
		*ngIf="isNewFlow()"
		class="lk-full-width lk-centered-text lk-double-margin-top"
		[innerHTML]="'SUBSCRIPTION.INFO_TITLE' | translate"></p>

	<p
		*ngIf="!isNewFlow()"
		class="lk-full-width lk-centered-text lk-double-margin-top"
		[innerHTML]="'SUBSCRIPTION.INFO_REGISTERED_SUBTITLE' | translate"></p>

	<p
		class="lk-full-width lk-centered-text"
		[innerHTML]="'SUBSCRIPTION.INFO_SUBTITLE' | translate"></p>

	<div class="lk-full-width lk-margin-top lk-justified-row">
		<div
			id="lk-subscription-info-box-container"
			class="lk-full-width">
			<div
				style="max-width: 488px; padding: 28px; box-sizing: border-box"
				class="lk-full-width lk-full-height lk-shadow lk-border-radius lk-primary-background">
				<span class="lk-accent-color">
					{{ 'SUBSCRIPTION.SHIPMENT_STEP_LABEL' | translate }}
				</span>
				<h2 class="lk-no-margin lk-half-margin-top">
					{{ 'SUBSCRIPTION.SHIPMENT_TITLE' | translate }}
				</h2>

				<p class="lk-no-margin lk-margin-top">
					{{ 'SUBSCRIPTION.SHIPMENT_MESSAGE_1' | translate }}
				</p>

				<p class="lk-no-margin lk-margin-top">
					{{ 'SUBSCRIPTION.SHIPMENT_MESSAGE_2' | translate }}
				</p>
			</div>

			<div
				style="max-width: 488px; padding: 28px; box-sizing: border-box"
				class="lk-full-width lk-full-height lk-shadow lk-border-radius lk-primary-background">
				<span class="lk-accent-color">
					{{ 'SUBSCRIPTION.INSTALLATION_STEP_LABEL' | translate }}
				</span>
				<h2 class="lk-no-margin lk-half-margin-top">
					{{ 'SUBSCRIPTION.INSTALLATION_TITLE' | translate }}
				</h2>

				<p class="lk-no-margin lk-margin-top">
					{{ 'SUBSCRIPTION.INSTALLATION_MESSAGE_1' | translate }}
				</p>

				<p
					class="lk-no-margin lk-margin-top lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'SUBSCRIPTION.INSTALLATION_MESSAGE_2' | translate }}
				</p>
			</div>

			<div
				id="lk-subscription-info-actions"
				class="lk-full-width lk-ended-row">
				<button
					(click)="onNextClick()"
					mat-button
					class="lk-main-button lk-full-width-md">
					{{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
