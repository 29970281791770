import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Station } from 'src/app/core/station.service';
import {
	Vehicle,
	VehicleRequest,
	VehicleService,
} from 'src/app/core/vehicle.service';
import {
	GENERAL_ERROR_MESSAGE,
	VEHICLES_MANUAL_REQUEST_DESCRIPTION_BODY,
	VEHICLES_MANUAL_REQUEST_DESCRIPTION_INTRO,
	VEHICLES_MANUAL_REQUEST_SUBJECT,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-vehicle-manual-info-dialog',
	templateUrl: './vehicle-manual-info-dialog.component.html',
	styleUrls: ['./vehicle-manual-info-dialog.component.scss'],
})
export class VehicleManualInfoDialogComponent implements OnInit {
	station?: Station;
	vehicles?: Vehicle[];
	station_name?: string;
	station_email?: string;
	is_send_request_running: boolean = false;
	vehicle_request_manual_info_form_group: FormGroup =
		this.form_service.vehicle_request_manual_info_form_group;

	constructor(
		private dialog_ref: MatDialogRef<VehicleManualInfoDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private form_service: FormService,
		private vehicle_service: VehicleService,
	) {
		this.station = data?.station;
		this.station_name = data?.station_name;
		this.station_email = data?.station_email;

		this.vehicles = [];
	}

	ngOnInit(): void {}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onAddVehicleClick(): void {
		if (this.isValidForm()) {
			const brand: string =
				this.vehicle_request_manual_info_form_group.get('brand').value;
			const model: string =
				this.vehicle_request_manual_info_form_group.get('model').value;
			const plate: string =
				this.vehicle_request_manual_info_form_group.get('plate').value;
			const year: number =
				this.vehicle_request_manual_info_form_group.get('year').value;

			const vehicle: Vehicle = new Vehicle();
			vehicle.brand = brand;
			vehicle.model = model;
			vehicle.plate = plate;
			vehicle.year = year;
			if (this.vehicles) this.vehicles.push(vehicle);
			if (!this.vehicles) this.vehicles = [vehicle];

			this.vehicle_request_manual_info_form_group.reset();
		}
	}

	onSendClick(): void {
		if (this.vehicles && this.vehicles.length) {
			const request: VehicleRequest = this.buildRequest();
			this.sendRequest(request);
		}
	}

	onRemoveVehicleClick(vehicle: Vehicle): void {
		const index: number = this.vehicles
			.map(v => v.plate)
			.indexOf(vehicle.plate);
		if (index != -1) {
			this.vehicles.splice(index, 1);
		}
	}

	buildRequest(): VehicleRequest {
		const request: VehicleRequest = new VehicleRequest();
		request.station_name = this.station_name;
		request.email = this.station_email;

		request.subject = `${VEHICLES_MANUAL_REQUEST_SUBJECT} ${this.station_name}`;
		request.description = this.buildDescription();
		return request;
	}

	buildDescription(): string {
		const intro: string = `${VEHICLES_MANUAL_REQUEST_DESCRIPTION_INTRO} ${this.station_name},`;
		const body: string = ` \n\n${VEHICLES_MANUAL_REQUEST_DESCRIPTION_BODY}:\n`;

		let list: string = '';
		this.vehicles.forEach(vehicle => {
			const vehicle_string: string = `\n${this.vehicleToString(vehicle)}`;
			list = `${vehicle_string}; ${list}`;
		});

		return `${intro}${body}${list}`;
	}

	sendRequest(request: VehicleRequest): void {
		if (this.station?.id) {
			this.is_send_request_running = true;
			this.vehicle_service.sendRequest(this.station.id, request).subscribe({
				next: response => {
					this.dialog_ref.close({ success: true });
					this.is_send_request_running = false;
				},
				error: error => {
					this.is_send_request_running = false;
					this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
				},
			});
		}
	}

	vehicleToString(vehicle: Vehicle, is_plate_hidden?: boolean): string {
		return `${vehicle.brand} ${vehicle.model}, ${vehicle.year}${
			!is_plate_hidden && vehicle.plate ? ' (' + vehicle.plate + ')' : ''
		}`;
	}

	isValidForm(): boolean {
		return this.form_service.validateForm(
			this.vehicle_request_manual_info_form_group,
		);
	}

	getVehicleDisabledClass() {
		return {
			'lk-secondary-disabled-button':
				this.is_send_request_running || !this.isValidForm(),
			'lk-secondary-button':
				!this.is_send_request_running && this.isValidForm(),
		};
	}

	getSendDisabledClass() {
		return {
			'lk-disabled-button': this.is_send_request_running,
			'lk-main-button': !this.is_send_request_running,
		};
	}
}
