import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormService} from '../../core/form.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {User, UserService} from '../../core/user.service';
import {LOCAL_STATION_KEY} from '../../shared/constants';
import {LocalManagerService} from '../../core/local-manager.service';
import {Station} from '../../core/station.service';
import { DialogService } from 'src/app/core/dialog.service';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
  selector: 'app-booking-builder-dialog',
  templateUrl: './booking-user-builder-dialog.component.html',
  styleUrls: ['./booking-user-builder-dialog.component.scss']
})
export class BookingUserBuilderDialogComponent implements OnInit {
  form_group: FormGroup;
  phone: string;
  phone_prefix: string;
  is_request_loading: boolean = false;

  constructor(
    private form_service: FormService, 
    private user_service: UserService,
    private dialog_service: DialogService,
    private dialog: MatDialog,
    private dialog_ref: MatDialogRef<BookingUserBuilderDialogComponent>,
    private local_manager: LocalManagerService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.dialog_service.dialog = this.dialog;
    this.form_group = this.form_service.bookingUserBuilderFormGroup;
    this.phone = data.phone;
    this.phone_prefix = data.phone_prefix;;
  }

  ngOnInit() {
    this.form_group.reset();
  }

  onCloseClick() {
    this.dialog_ref.close();
  }

  onConfirmClick() {
    if (this.form_service.validateForm(this.form_group)) {
      let user: User = this.buildUser();
      const station: Station = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
      this.is_request_loading = true;
      this.user_service.createUser(station.id, user).subscribe(
        response => {
          this.dialog_ref.close({user: response.data as User});
          this.is_request_loading = false;
        },
        error => {
          if (error.response.data.error && error.response.data.error.description) {
            this.dialog_service.openInfoDialog(error.response.data.error.description);
          } else if (!error.response.data.error || !error.response.data.error.description) {
            this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
            this.dialog_ref.close();
          } 
          this.is_request_loading = false;
        }
      );
    }
  }

  buildUser() {
    let user: User = new User();
    user.email = this.form_group.get('email').value;
    user.first_name = this.form_group.get('first_name').value;
    user.last_name = this.form_group.get('last_name').value;
    user.tax_code = this.form_group.get('tax_code').value;
    user.phone = {
      prefix  : this.phone_prefix,
      value   : this.phone
    };
    return user;
  }

  getDisabledClass() {
    return {
      'lk-disabled-button'  : this.is_request_loading,
      'lk-main-button'      : !this.is_request_loading
    }
  }
}
