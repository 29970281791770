<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-booking-details-email-content-layout"
		class="lk-horiz-padding lk-margin-top">
		<h3>{{ 'BOOKING_DETAILS.USER_EMAIL_UPDATE_TITLE' | translate }}</h3>
		<form [formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width">
				<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }} </mat-label>
				<input
					matInput
					type="email"
					formControlName="email" />
			</mat-form-field>

			<div class="lk-centered-row lk-full-width lk-margin-top lk-margin-bottom">
				<button
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()"
					[disabled]="is_request_loading"
					[ngClass]="getDisabledClass()">
					<span *ngIf="!is_request_loading"> {{ 'GENERAL.CONFIRM_CTA' | translate }} </span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_request_loading">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
