<form
	[formGroup]="form_group"
	class="lk-shadow lk-border-radius lk-double-padding lk-spaced-row lk-wrapped-flex lk-primary-background lk-z-index">
	<div class="lk-spaced-row lk-full-width">
		<div
			class="lk-primary-dark-background"
			style="width: 20%; height: 2px"></div>
		<img
			style="width: 50%"
			[src]="logo_src" />
		<div
			class="lk-primary-dark-background"
			style="width: 20%; height: 2px"></div>
	</div>

	<p class="lk-double-margin-top">
		{{ 'AUTHENTICATION.MFA_SETUP_CTA' | translate }}
	</p>

	<mat-form-field
		appearance="fill"
		floatLabel="always"
		color="accent"
		class="lk-third-width lk-half-margin-bottom">
		<mat-label>
			{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
		</mat-label>
		<img
			*ngIf="current_country_prefix"
			matPrefix
			style="min-width: 16px; height: 12px"
			class="lk-half-margin-right"
			[src]="current_country_prefix.flag" />

		<img
			*ngIf="!current_country_prefix"
			matPrefix
			style="min-width: 16px; height: 12px"
			class="lk-half-margin-right"
			[src]="unknown_flag_src" />

		<input
			id="lk-booking-phone-builder-prefix-input"
			type="text"
			matInput
			formControlName="phone_prefix"
			required
			[matAutocomplete]="autocountry" />
		<mat-autocomplete
			autoActiveFirstOption
			#autocountry="matAutocomplete"
			(optionSelected)="onCountryPrefixSelect()">
			<mat-option
				*ngFor="let country_prefix of filtered_options | async"
				[value]="country_prefix.code">
				<div class="lk-row">
					<img
						style="min-width: 32px; height: 24px"
						class="lk-half-margin-right"
						[src]="country_prefix.flag" />
					<div class="lk-column">
						<p
							style="
								max-width: calc(100% - 6px);
								font-size: 10px;
								line-height: 18px;
							"
							class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
							{{ country_prefix.name }}
						</p>
						<p
							style="line-height: 18px"
							class="lk-no-margin lk-ellipsized-text">
							{{ country_prefix.prefix }}
						</p>
					</div>
				</div>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>

	<mat-form-field
		appearance="fill"
		color="accent"
		class="lk-three-third-width lk-half-margin-bottom">
		<mat-label>
			{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
		</mat-label>
		<input
			id="lk-booking-phone-builder-phone-input"
			matInput
			type="tel"
			formControlName="phone"
			required />
	</mat-form-field>

	<button
		id="auth-mfa-setup-submit-button"
		mat-button
		class="lk-main-button lk-full-width lk-double-margin-top"
		(click)="onMfaSetupClick()"
		[disabled]="is_mfa_setup_running || !isFormValid()"
		[ngClass]="getDisabledClass()">
		<h3
			class="lk-no-margin lk-primary-color"
			*ngIf="!is_mfa_setup_running">
			{{ 'GENERAL.CONFIRM_CTA' | translate }}
		</h3>
		<div
			class="lk-spinner-layout"
			*ngIf="is_mfa_setup_running">
			<mat-spinner [diameter]="20"></mat-spinner>
		</div>
	</button>
</form>
