<div>
    <div class="lk-ended-row">
      <button 
        mat-icon-button 
        (click)="onCloseClick()" 
        class="lk-primary-medium-background">
        <mat-icon class="lk-primary-medium-color"> clear </mat-icon>
      </button>
    </div>

    <div  
      id="lk-booking-settings-unlock-content-layout" 
      style="padding-top: 0;"
      class="lk-padding lk-hide-scrollbar">
      <app-station-settings-trigger-unlock
        (unlockableOnVehicleAssignmentChange)="onUnlockableOnVehicleAssignmentChange($event)"
        (unlockableUntilBookingEndChange)="onUnlockableUntilBookingEndChange($event)"
        [stationUpdateTrigger]="station_update_trigger"></app-station-settings-trigger-unlock>
    </div>

    <div  
      class="lk-centered-row lk-full-width lk-padding"
      style="padding-bottom: 12px;">
      <button 
        id="lk-booking-settings-unlock-confirm-button"
        mat-button 
        class="lk-main-button" 
        (click)="onConfirmClick()">
        <span> {{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }} </span>
      </button>
    </div>
</div>