<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-vehicle-direct-link-confirm-content-layout"
		class="lk-horiz-padding">
		<div
			*ngIf="is_link_sent"
			class="lk-row"
			style="align-items: flex-start">
			<mat-icon
				class="lk-accent-color lk-half-margin-right lk-vehicle-direct-link-confirm-success-icon">
				check_circle
			</mat-icon>
			<h2 class="lk-accent-color lk-no-margin">
				{{ 'VEHICLE.DIRECT_LINK_SENT_MESSAGE' | translate }}
			</h2>
		</div>

		<p class="lk-centered-text">
			{{ 'VEHICLE.DIRECT_LINK_COPY_MESSAGE' | translate }}
		</p>

		<hr class="lk-list-separator lk-no-margin" />
		<div class="lk-centered-row">
			<button
				(click)="onCopyClick(link)"
				mat-icon-button>
				<mat-icon> content_copy </mat-icon>
			</button>
			<a [href]="link"> {{ link }} </a>
		</div>
		<hr class="lk-list-separator lk-no-margin" />
		<div class="lk-margin-bottom"></div>
	</div>
</div>
