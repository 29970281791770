import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormService} from '../../core/form.service';
import {BehaviorSubject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {DATE_FORMATS, DATE_FORMAT_LABELS, KM_LABEL, LITERS_LABEL, MILES_LABEL, PERCENTAGE_LABEL, LOCAL_STATION_KEY, SUPPORTED_LANGUAGES} from '../../shared/constants';
import {SUPPORTED_LANGUAGES_LABELS} from '../../shared/translations';
import {LocalManagerService} from '../../core/local-manager.service';

@Component({
  selector: 'app-station-settings-lang',
  templateUrl: './station-settings-lang.component.html',
  styleUrls: ['./station-settings-lang.component.scss']
})
export class StationSettingsLangComponent implements OnInit {
  @Input() stationUpdateTrigger: BehaviorSubject<boolean>;
  @Output() langUpdateClick: EventEmitter<string> = new EventEmitter<string>();
  form_group: FormGroup;
  languages: string[]  = SUPPORTED_LANGUAGES;
  date_formats: { format: string; label: string }[] = [
    {
      format: DATE_FORMATS[0],
      label: DATE_FORMAT_LABELS[0]
    },
    {
      format: DATE_FORMATS[1],
      label: DATE_FORMAT_LABELS[1]
    }
  ];
  distanceMUnits: { symbol: string; label: string }[] = [
    {
      symbol: KM_LABEL,
      label: ''
    },
    {
      symbol: MILES_LABEL,
      label: ''
    }
  ]; 
  autonomyMUnits: { symbol: string; label: string }[] = [
    {
      symbol: LITERS_LABEL,
      label: ''
    },
    {
      symbol: PERCENTAGE_LABEL,
      label: ''
    }
  ]; 

  constructor(
    private form_service: FormService, 
    private local_manager: LocalManagerService
  ) {
    this.form_group = this.form_service.stationSettingLangFormGroup;
  }

  ngOnInit() {
    this.stationUpdateTrigger.subscribe(
      update => {
        if (update) {
          let currentStation = this.local_manager.getLocalObject(LOCAL_STATION_KEY);
          if (currentStation) {
            this.form_group.get('lang').setValue(currentStation.settings.default_lang);
          }
        }
      }
    );
  }

  onLangSelectChange(lang: string) {
    if (this.form_service.validateForm(this.form_group)) {
      this.langUpdateClick.emit(lang);
    }
  }

  getLanguageLabel(lang: string) {
    switch (lang) {
      case SUPPORTED_LANGUAGES[0]:
        return SUPPORTED_LANGUAGES_LABELS[0];
      case SUPPORTED_LANGUAGES[1]:
        return SUPPORTED_LANGUAGES_LABELS[1];
    }
  }
}
