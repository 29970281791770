import { Component, Inject, OnInit } from '@angular/core';
import { Station } from '../../core/station.service';
import {
	LOCAL_STATION_KEY,
	VEHICLE_NOT_FOUND_ERROR_CODE,
} from '../../shared/constants';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalManagerService } from '../../core/local-manager.service';
import {
	Vehicle,
	VehicleService,
	VehicleStatus,
} from '../../core/vehicle.service';
import { DialogService } from 'src/app/core/dialog.service';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';
import { Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { VEHICLES_PATH } from 'src/app/core/navigation.service';

@Component({
	selector: 'app-booking-add-vehicle-dialog',
	templateUrl: './booking-add-vehicle-dialog.component.html',
	styleUrls: ['./booking-add-vehicle-dialog.component.scss'],
})
export class BookingAddVehicleDialogComponent implements OnInit {
	form_group: FormGroup;
	is_request_loading: boolean = false;
	vehicle: Vehicle;
	vehicles: Vehicle[];
	filtered_vehicles: Observable<Vehicle[]>;
	is_request_running: boolean = false;

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private vehicle_service: VehicleService,
		private dialog_ref: MatDialogRef<BookingAddVehicleDialogComponent>,
		private local_manager: LocalManagerService,
		private router: Router,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.form_group = this.form_service.bookingAddVehicleFormGroup;
	}

	ngOnInit() {
		this.form_group.reset();
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.listVehicles(station.id);

		this.filtered_vehicles = this.form_group.get('plate').valueChanges.pipe(
			startWith(''),
			map(value => this.filterVehicles(value || '')),
		);
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		const vehicle: Vehicle = this.vehicles.filter(v => {
			return (
				v.plate.toLowerCase() ==
				this.form_group.get('plate').value?.toLowerCase()
			);
		})[0];
		this.dialog_ref.close({ vehicle: vehicle });
	}

	listVehicles(stationId: string) {
		this.is_request_running = true;
		this.vehicle_service
			.listVehicles(stationId, null, null, null, null, null, 100000)
			.subscribe(
				response => {
					this.vehicles = response.data.items;
					this.is_request_running = false;
				},
				error => {
					if (
						error.response.data.error &&
						error.response.data.error.description &&
						error.response.data.error.code != VEHICLE_NOT_FOUND_ERROR_CODE
					) {
						this.dialog_service.openInfoDialog(
							error.response.data.error.description,
						);
					} else if (
						!error.response.data.error ||
						!error.response.data.error.description
					) {
						this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					} else if (
						error.response.data.error.code == VEHICLE_NOT_FOUND_ERROR_CODE
					) {
						this.vehicles = [];
						this.is_request_running = false;
						this.router.navigate([VEHICLES_PATH]);
					}
				},
			);
	}

	filterVehicles(value: string) {
		const filterValue = value.toLowerCase();
		return this.vehicles.filter(option =>
			option.plate.toLowerCase().includes(filterValue),
		);
	}

	getSearchDisabledClass() {
		return {
			'lk-disabled-button': this.is_request_loading,
			'lk-main-button': !this.is_request_loading,
		};
	}

	getNextDisabledClass() {
		return {
			'lk-disabled-button': !this.getNextDisabledCondition(),
			'lk-main-button': this.getNextDisabledCondition(),
		};
	}

	getNextDisabledCondition(): boolean {
		return this.vehicles
			.map(v => v.plate.toLowerCase())
			.includes(this.form_group.get('plate').value?.toLowerCase());
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}

	getOnlineClass(vehicle: Vehicle) {
		return {
			'lk-booking-add-vehicle-online-circle-enabled':
				vehicle?.stats?.online && vehicle?.stats?.online?.value,
			'lk-booking-add-vehicle-online-circle-disabled':
				!vehicle?.stats?.online || !vehicle?.stats?.online?.value,
		};
	}
}
