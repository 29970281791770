import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Vehicle, VehicleCategory } from '../../core/vehicle.service';
import {
	MAP_DEFAULT_BASE_POINT,
	MAP_DEFAULT_ZOOM,
	MAP_MAX_ZOOM,
	MAPBOX_API_KEY,
	MAP_DEFAULT_URL,
	MAP_CUSTOM_MARKER_ICON,
	SATELLITE_LAYER_TYPE,
	STREET_LAYER_TYPE,
	MAP_SATELLITE_URL,
} from '../../shared/constants';

declare var L: any;

@Component({
	selector: 'app-vehicle-details-map',
	templateUrl: './vehicle-details-map.component.html',
	styleUrls: ['./vehicle-details-map.component.scss'],
})
export class VehicleDetailsMapComponent implements OnInit {
	@Input() vehicleSubject: BehaviorSubject<Vehicle>;
	vehicle: Vehicle;
	layer_type: string = STREET_LAYER_TYPE;
	map;
	marker;

	constructor() {}

	ngOnInit() {
		this.loadMap();
		this.vehicleSubject.subscribe(vehicle => {
			if (vehicle) {
				this.vehicle = vehicle;
				this.loadMarker();
			}
		});
	}

	onLocationClick() {
		if (this.vehicle.stats.position) {
			const latitude = this.vehicle.stats.position.value.latitude;
			const longitude = this.vehicle.stats.position.value.longitude;
			if (latitude && longitude) {
				this.map.setView([latitude, longitude], MAP_MAX_ZOOM);
			}
		}
	}

	onDirectionsClick() {
		const latitude = this.vehicle.stats.position.value.latitude;
		const longitude = this.vehicle.stats.position.value.longitude;
		if (latitude && longitude) {
			window.open(
				`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
			);
		}
	}

	onLayersClick(): void {
		this.layer_type =
			this.layer_type == STREET_LAYER_TYPE
				? SATELLITE_LAYER_TYPE
				: STREET_LAYER_TYPE;

		if (this.layer_type == SATELLITE_LAYER_TYPE) {
			let mapBoxUrl = MAP_SATELLITE_URL + MAPBOX_API_KEY;
			L.tileLayer(mapBoxUrl, {
				attribution: '',
				maxZoom: MAP_MAX_ZOOM,
				id: 'mapbox/light-v10',
				accessToken: 'your.mapbox.access.token',
			}).addTo(this.map);
		} else {
			let mapBoxUrl = MAP_DEFAULT_URL + MAPBOX_API_KEY;
			L.tileLayer(mapBoxUrl, {
				attribution: '',
				maxZoom: MAP_MAX_ZOOM,
				id: 'mapbox/light-v10',
				accessToken: 'your.mapbox.access.token',
			}).addTo(this.map);
		}
	}

	loadMap() {
		this.map = L.map('lk-vehicle-details-map-layout', {
			attributionControl: false,
			minZoom: 3,
			zoomControl: false,
		});
		L.control
			.zoom({
				position: 'topright',
			})
			.addTo(this.map);

		let mapBoxUrl = MAP_DEFAULT_URL + MAPBOX_API_KEY;
		this.map.setView(MAP_DEFAULT_BASE_POINT, MAP_DEFAULT_ZOOM);
		return L.tileLayer(mapBoxUrl, {
			attribution: '',
			maxZoom: MAP_MAX_ZOOM,
			id: 'mapbox/light-v10',
			accessToken: 'your.mapbox.access.token',
		}).addTo(this.map);
	}

	loadMarker() {
		if (this.marker) {
			this.map.removeLayer(this.marker);
		}
		MAP_CUSTOM_MARKER_ICON.iconUrl = this.getCustomMarkerIcon(this.vehicle);
		const lkMarkerIcon = L.icon(MAP_CUSTOM_MARKER_ICON);
		if (this.vehicle && this.vehicle.stats && this.vehicle.stats.position) {
			const latitude = this.vehicle.stats.position.value.latitude;
			const longitude = this.vehicle.stats.position.value.longitude;
			if (latitude && longitude) {
				this.marker = L.marker([latitude, longitude], {
					icon: lkMarkerIcon,
				}).addTo(this.map);
				this.map.setView([latitude, longitude], 16);
			}
		}
	}

	getCustomMarkerIcon(vehicle: Vehicle) {
		const categories: string[] = [
			VehicleCategory.COMMERCIAL.toString(),
			VehicleCategory.LARGE.toString(),
			VehicleCategory.VAN.toString(),
			VehicleCategory.WAGON.toString(),
			VehicleCategory.MEDIUM.toString(),
			VehicleCategory.PREMIUM.toString(),
			VehicleCategory.SMALL.toString(),
			VehicleCategory.SUV.toString(),
		];

		if (!categories.includes(vehicle.category)) {
			return `../../../assets/imgs/lk-vehicle-icons/lk-vehicle-medium.png`;
		}
		return `../../../assets/imgs/lk-vehicle-icons/lk-vehicle-${vehicle.category.toLowerCase()}.png`;
	}

	getLayersIconClass(): { [key: string]: boolean } {
		return {
			'lk-accent-color':
				this.layer_type && this.layer_type == SATELLITE_LAYER_TYPE,
			'lk-primary-dark-color':
				!this.layer_type || this.layer_type != SATELLITE_LAYER_TYPE,
		};
	}
}
