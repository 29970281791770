import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';

export const VEHICLES_API_NAME: string = 'vehicles';
export const VEHICLES_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/vehicles/';

@Injectable({
	providedIn: 'root',
})
export class VehicleService {
	constructor(private http: HttpClient) {}

	createVehicle(stationId: string, vehicle: Vehicle) {
		let apiName: string = VEHICLES_API_NAME;
		let path: string = 'stations/' + stationId;
		let init = {
			response: true,
			body: vehicle,
		};
		return from(API.post(apiName, path, init));
	}

	readVehicle(stationId: string, vehicleId: string) {
		let apiName: string = VEHICLES_API_NAME;
		let path: string = vehicleId + '/stations/' + stationId;
		let init = { response: true };
		return from(API.get(apiName, path, init));
	}

	updateVehicle(stationId: string, vehicle: Vehicle) {
		let apiName: string = VEHICLES_API_NAME;
		let path: string = vehicle.id + '/stations/' + stationId;
		let init = {
			response: true,
			body: vehicle,
		};
		return from(API.put(apiName, path, init));
	}

	deleteVehicle(stationId: string, vehicleId: string) {
		let apiName: string = VEHICLES_API_NAME;
		let path: string = vehicleId + '/stations/' + stationId;
		let init = { response: true };
		return from(API.del(apiName, path, init));
	}

	listVehicles(
		stationId: string,
		page?: number,
		plate?: string,
		category?: string,
		brand?: string,
		status?: string,
		perPage?: number,
		is_map_search?: boolean,
	) {
		const VEHICLES_PER_PAGE: number = perPage ? perPage : 30;
		let apiName: string = VEHICLES_API_NAME;
		let path: string = 'stations/' + stationId;
		let queryStringParams = {
			page: page ? page : 1,
			per_page: page ? VEHICLES_PER_PAGE : 100000000,
		};
		if (plate) {
			queryStringParams['plate'] = plate;
		}
		if (category) {
			queryStringParams['category'] = category;
		}
		if (brand) {
			queryStringParams['brand'] = brand;
		}
		if (status) {
			queryStringParams['status'] = status;
		}
		if (is_map_search) {
			queryStringParams['map_search'] = is_map_search;
		}

		let init = {
			response: true,
			queryStringParameters: queryStringParams,
		};
		return from(API.get(apiName, path, init));
	}

	startVehicle(stationId: string, vehicle: Vehicle) {
		let apiName: string = VEHICLES_API_NAME;
		let path: string = vehicle.id + '/stations/' + stationId + '/start';
		let init = { response: true };
		return from(API.post(apiName, path, init));
	}

	stopVehicle(stationId: string, vehicle: Vehicle) {
		let apiName: string = VEHICLES_API_NAME;
		let path: string = vehicle.id + '/stations/' + stationId + '/stop';
		let init = { response: true };
		return from(API.post(apiName, path, init));
	}

	sendRequest(station_id: string, request: VehicleRequest) {
		let apiName: string = VEHICLES_API_NAME;
		const path: string = `/stations/${station_id}/send-request`;
		let init = { response: true, body: request };
		return from(API.post(apiName, path, init));
	}

	export(station__id: string) {
		let api_name: string = VEHICLES_API_NAME;
		let path: string = `stations/${station__id}/export`;
		let init = {
			response: true,
			headers: {
				Accept: 'text/csv',
			},
			responseType: 'blob',
		};
		return from(API.get(api_name, path, init));
	}

	sendDirectLink(
		vehicle_id: string,
		station_id: string,
		nbf: number,
		exp: number,
		full_phone?: string,
		type?: string,
	) {
		const api_name: string = VEHICLES_API_NAME;
		const path: string = `${vehicle_id}/stations/${station_id}/send-direct-link`;
		let init = {
			response: true,
			body: {
				nbf,
				exp,
				full_phone,
				type,
			},
		};
		return from(API.post(api_name, path, init));
	}
}

export class VehicleRequest {
	station_name?: string;
	subject?: string;
	description?: string;
	email?: string;
	phone?: string;
}

export class Vehicle {
	id: string;
	plate: string;
	reference_code: string;
	status: VehicleStatus;
	category: string;
	brand: string;
	model: string;
	color: string;
	year: number;
	img_urls: string[];
	damages: VehicleDamage[];
	intervations: VehicleIntervention[];
	capabilities: string[];
	stats: VehicleStats;
	adapter: AdapterVehicle;
	ext_id: string;
	station_id?: string;
}

export enum VehicleStatus {
	UNLOCKED = 'unlocked',
	LOCKED = 'locked',
	MOVING = 'moving',
}

export class VehicleDamage {
	date: number;
	description: string;
	image: string;
	booking_id: string;
	reporter: any;
	is_user_report: boolean;
}

export class VehicleIntervention {
	date: number;
	description: string;
	km: number;
	operator: any;
}

export class VehicleAutonomy {
	distance: number;
	percentage: number;
}

export type VehicleStats = {
	online: VehicleStatsOnline;
	distance_covered: VehicleStatsDistance;
	position: VehicleStatsPosition;
	autonomy_percentage: VehicleStatsAutonomyPercentage;
	autonomy_meters: VehicleStatsAutonomyMeters;
	external_voltage: VehicleStatsExternalVoltage;
	updated_at: number;
};

export type VehicleStatsDistance = {
	value: number;
	updated_at: number;
};

export type VehicleStatsOnline = {
	value: boolean;
	updated_at: number;
};

export type VehicleStatsPosition = {
	value: { latitude: number; longitude: number };
	updated_at: number;
};

export type VehicleStatsAutonomyMeters = {
	value: number;
	updated_at: number;
};

export type VehicleStatsAutonomyPercentage = {
	value: number;
	updated_at: number;
};

export type VehicleStatsExternalVoltage = {
	value: number;
	updated_at: number;
};

export class AdapterVehicle {
	id?: string;
	subscription_id?: string;
	qrcode?: string;
	profile?: string;
	topic?: string;
	imei?: string;
	oem?: string;
}

export enum VehicleCategory {
	COMMERCIAL = 'commercial',
	LARGE = 'large',
	VAN = 'van',
	WAGON = 'wagon',
	MEDIUM = 'medium',
	PREMIUM = 'premium',
	SMALL = 'small',
	SUV = 'suv',
}

export enum VehicleOdometerUms {
	mi = 'mi',
	km = 'km',
}
