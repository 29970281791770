import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { API, Auth, Storage } from 'aws-amplify';
import {
	STATIONS_API_ENDPOINT,
	STATIONS_API_NAME,
} from './app/core/station.service';
import { ADMINS_API_ENDPOINT, ADMINS_API_NAME } from './app/core/admin.service';
import {
	INVITATIONS_API_ENDPOINT,
	INVITATIONS_API_NAME,
} from './app/core/invitation.service';
import {
	NOTIFICATIONS_API_ENDPOINT,
	NOTIFICATIONS_API_NAME,
} from './app/core/notification.service';
import {
	VEHICLES_API_ENDPOINT,
	VEHICLES_API_NAME,
} from './app/core/vehicle.service';
import {
	BOOKINGS_API_ENDPOINT,
	BOOKINGS_API_NAME,
} from './app/core/booking.service';
import { USERS_API_ENDPOINT, USERS_API_NAME } from './app/core/user.service';
import {
	BOOKINGS_SUMMARY_API_ENDPOINT,
	BOOKINGS_SUMMARY_API_NAME,
} from './app/core/bookings-summary.service';
import {
	VEHICLES_SUMMARY_API_ENDPOINT,
	VEHICLES_SUMMARY_API_NAME,
} from './app/core/vehicles-summary.service';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import {
	BOOKINGS_MEDIA_API_ENDPOINT,
	BOOKINGS_MEDIA_API_NAME,
} from './app/core/booking-media.service';
import {
	DIRECT_LINK_API_ENDPOINT,
	DIRECT_LINK_API_NAME,
	LOCK_KEY_API_ENDPOINT,
	LOCK_KEY_API_NAME,
	LOCK_KEY_V2_API_ENDPOINT,
	LOCK_KEY_V2_API_NAME,
	UNLOCK_KEY_API_ENDPOINT,
	UNLOCK_KEY_API_NAME,
	UNLOCK_KEY_V2_API_ENDPOINT,
	UNLOCK_KEY_V2_API_NAME,
} from './app/core/keys.service';
import {
	UNLOCK_COMMANDS_API_ENDPOINT,
	UNLOCK_COMMANDS_API_NAME,
} from './app/core/unlock-commands.service';
import {
	LOCK_COMMANDS_API_ENDPOINT,
	LOCK_COMMANDS_API_NAME,
} from './app/core/lock-commands.service';
import { PROD_STAGE } from './app/shared/constants';
import {
	BOOKINGS_EVENTS_API_ENDPOINT,
	BOOKINGS_EVENTS_API_NAME,
	VEHICLES_COMMANDS_API_ENDPOINT,
	VEHICLES_COMMANDS_API_NAME,
} from './app/core/events.service';
import {
	METABASE_API_NAME,
	METABASE_API_ENDPOINT,
} from './app/core/metabase.service';

Sentry.init({
	dsn: 'https://ee3086c6905041189742374c255ee292@o1211116.ingest.sentry.io/6347121',
	integrations: [
		new BrowserTracing({
			tracingOrigins: [
				'https://manage.linkey.2hire.io',
				'https://back-api.linkey.2hire.io',
			],
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

Auth.configure({
	Auth: {
		region: environment.USER_POOL_REGION,
		userPoolId: environment.USER_POOL_ID,
		userPoolWebClientId: environment.USER_POOL_WEB_CLIENT_ID,
		identityPoolId: environment.IDENTITY_POOL_ID,
		identityPoolRegion: environment.IDENTITY_POOL_REGION,
	},
});

API.configure({
	endpoints: [
		{
			name: STATIONS_API_NAME,
			endpoint: STATIONS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: ADMINS_API_NAME,
			endpoint: ADMINS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: INVITATIONS_API_NAME,
			endpoint: INVITATIONS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: NOTIFICATIONS_API_NAME,
			endpoint: NOTIFICATIONS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: VEHICLES_API_NAME,
			endpoint: VEHICLES_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: BOOKINGS_API_NAME,
			endpoint: BOOKINGS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: USERS_API_NAME,
			endpoint: USERS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: BOOKINGS_SUMMARY_API_NAME,
			endpoint: BOOKINGS_SUMMARY_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: VEHICLES_SUMMARY_API_NAME,
			endpoint: VEHICLES_SUMMARY_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: BOOKINGS_MEDIA_API_NAME,
			endpoint: BOOKINGS_MEDIA_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: UNLOCK_KEY_API_NAME,
			endpoint: UNLOCK_KEY_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: LOCK_KEY_API_NAME,
			endpoint: LOCK_KEY_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: UNLOCK_KEY_V2_API_NAME,
			endpoint: UNLOCK_KEY_V2_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: LOCK_KEY_V2_API_NAME,
			endpoint: LOCK_KEY_V2_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: UNLOCK_COMMANDS_API_NAME,
			endpoint: UNLOCK_COMMANDS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: LOCK_COMMANDS_API_NAME,
			endpoint: LOCK_COMMANDS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: BOOKINGS_EVENTS_API_NAME,
			endpoint: BOOKINGS_EVENTS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: VEHICLES_COMMANDS_API_NAME,
			endpoint: VEHICLES_COMMANDS_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: METABASE_API_NAME,
			endpoint: METABASE_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
		{
			name: DIRECT_LINK_API_NAME,
			endpoint: DIRECT_LINK_API_ENDPOINT,
			region: environment.USER_POOL_REGION,
		},
	],
});

Storage.configure({
	AWSS3: {
		bucket: environment.BUCKET_NAME,
		region: environment.BUCKET_REGION,
	},
});

if (environment.STAGE == PROD_STAGE) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
