<app-shared-toolbar 
	[currentNavItem]="current_item" 
	[stationUpdateTrigger]="station_update_trigger"></app-shared-toolbar>

<section class="lk-section-with-toolbar lk-padding-md lk-spaced-column">
  <div class="lk-centered-column lk-full-width">
    <div 
			id="lk-station-builder-team-form-layout" 
			class="lk-centered-column-wrapper">
      <div class="lk-centered-row lk-margin-bottom">
        <h1 class="lk-accent-color lk-no-margin lk-station-builder-step-text"> 3 </h1>
        <h1 class="lk-no-margin lk-half-margin-left"> {{ 'STATION_BUILDER.STEP_TEAM_TITLE' | translate }} </h1>
      </div>
  
      <div class="lk-shadow lk-border-radius lk-padding-md lk-primary-background">
        <div
          class="lk-centered-row lk-wrapped-flex"
          *ngIf="!is_request_running">
          <form 
						[formGroup]="form_group" 
						class="lk-full-width">
            <mat-form-field 
							appearance="fill" 
							class="lk-full-width" 
							color="accent">
              <mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
              <input 
								matInput 
								type="email" 
								formControlName="email" 
								(change)="onEmailChange()">
            </mat-form-field>
          </form>
    
          <div  
						class="lk-full-width lk-wrapped-flex" 
						style="display: flex"
            *ngIf="!is_team_loading">
            <div 
							*ngFor="let invite of invites"
              class="lk-chips-layout lk-primary-medium-background lk-centered-row
                lk-half-margin-top lk-half-margin-right">
              <span 
								style="font-size: 12px" 
								class="lk-primary-medium-color"> {{ invite }} </span>
            </div>
          </div>
    
          <div  
						class="lk-full-width lk-wrapped-flex" 
						style="display: flex"
            *ngIf="!is_team_loading">
            <div 
							*ngFor="let admin of admins"
              class="lk-chips-layout lk-primary-medium-background lk-centered-row
                lk-half-margin-top lk-half-margin-right">
              <span 
								style="font-size: 12px" 
								class="lk-accent-color"> {{ admin.email }} </span>
              <mat-icon 
								class="lk-half-margin-left lk-cursor-pointer" 
								style="color: gray"
              	(click)="onTeammateRemoveClick(admin)"> cancel </mat-icon>
            </div>
          </div>
    
          <div 
						class="lk-full-width lk-padding lk-centered-row" 
						*ngIf="is_team_loading">
            <mat-spinner 
							diameter="20" 
							color="accent"></mat-spinner>
          </div>
    
          <button 
						mat-button 
						class="lk-main-button lk-full-width lk-double-margin-top"
          	(click)="onNextClick()" 
						*ngIf="!update_mode">
            <h3 class="lk-no-margin lk-primary-color"> {{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }} </h3>
          </button>
    
          <button 
						mat-button 
						class="lk-main-button lk-full-width lk-double-margin-top"
						(click)="onUpdateClick()" 
						*ngIf="update_mode">
            <h3 class="lk-no-margin lk-primary-color"> {{ 'GENERAL.SAVE_BUTTON_CTA' | translate }} </h3>
          </button>
        </div>

				<div 
					class="lk-centered-row"
					*ngIf="is_request_running">
					<mat-spinner
						diameter="30"
						color="accent"></mat-spinner>
				</div>
      </div>
    </div>
  </div>

  <app-station-builder-steps [currentStep]="currentStep"></app-station-builder-steps>
</section>


