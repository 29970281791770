import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
	MatDialogRef,
	MAT_DIALOG_DATA,
	MatDialog,
} from '@angular/material/dialog';
import { Admin, AdminService } from 'src/app/core/admin.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { ERROR_DESCRIPTION_500 } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';
import { HiddenStationsAdminRemoveConfirmDialogComponent } from '../hidden-stations-admin-remove-confirm-dialog/hidden-stations-admin-remove-confirm-dialog.component';

@Component({
	selector: 'app-hidden-stations-admin-add-dialog',
	templateUrl: './hidden-stations-admin-add-dialog.component.html',
	styleUrls: ['./hidden-stations-admin-add-dialog.component.scss'],
})
export class HiddenStationsAdminAddDialogComponent implements OnInit {
	super_admins?: Admin[];
	form_group: FormGroup = this.form_service.set_superhost_form_group;
	is_list_running: boolean = false;
	is_save_running: boolean = false;

	constructor(
		private form_service: FormService,
		private admin_service: AdminService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private dialog_ref: MatDialogRef<HiddenStationsAdminAddDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.listAdmins();
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.form_service.validateForm(this.form_group)) {
			const email: string = this.form_group.get('email')?.value;
			this.addAdmin(email);
		}
	}

	onRemoveClick(admin: Admin): void {
		this.dialog_service
			.openDialog(HiddenStationsAdminRemoveConfirmDialogComponent, {
				email: admin.email,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result?.confirm) {
					this.removeAdmin(admin.id);
				}
			});
	}

	listAdmins(): void {
		this.is_list_running = true;
		this.admin_service.listSuperAdmin().subscribe({
			next: response => {
				this.super_admins = response.data;
				this.is_list_running = false;
			},
			error: () => {
				this.super_admins = [];
				this.is_list_running = false;
			},
		});
	}

	removeAdmin(admins_id: string): void {
		this.is_list_running = true;
		this.admin_service.removeAsSuperAdmin(admins_id).subscribe({
			next: () => {
				this.listAdmins();
			},
			error: error => {
				let error_message: string = '';
				if (
					error.response.data.error &&
					error.response.data.error.description &&
					error.response.data.error.description != ERROR_DESCRIPTION_500
				) {
					error_message = error.response.data.error.description;
				} else {
					error_message = GENERAL_ERROR_MESSAGE;
				}
				this.dialog_ref.close({ error_message });
				this.is_list_running = false;
			},
		});
	}

	addAdmin(email: string): void {
		this.is_save_running = true;
		this.admin_service.setAsSuperAdmin(email).subscribe({
			next: () => {
				this.dialog_ref.close({
					success: true,
				});
				this.is_save_running = false;
			},
			error: error => {
				let error_message: string = '';
				if (
					error.response.data.error &&
					error.response.data.error.description &&
					error.response.data.error.description != ERROR_DESCRIPTION_500
				) {
					error_message = error.response.data.error.description;
				} else {
					error_message = GENERAL_ERROR_MESSAGE;
				}
				this.dialog_ref.close({ error_message });
				this.is_save_running = false;
			},
		});
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_save_running,
			'lk-main-button': !this.is_save_running,
		};
	}
}
