import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingDetailsRoutingModule } from './booking-details-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BookingModule } from '../booking/booking.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { BookingDetailsComponent } from './booking-details.component';
import { BookingDetailsGeneralComponent } from './booking-details-general/booking-details-general.component';
import { BookingDetailsDocsComponent } from './booking-details-docs/booking-details-docs.component';
import { BookingDetailsVehicleComponent } from './booking-details-vehicle/booking-details-vehicle.component';
import { BookingDetailsGalleryDialogComponent } from './booking-details-gallery-dialog/booking-details-gallery-dialog.component';
import { BookingDetailsLinksComponent } from './booking-details-links/booking-details-links.component';
import { BookingDetailsEmailDialogComponent } from './booking-details-email-dialog/booking-details-email-dialog.component';
import { BookingDetailsNavbarComponent } from './booking-details-navbar/booking-details-navbar.component';
import { BookingDetailsUnlockLinkComponent } from './booking-details-unlock-link/booking-details-unlock-link.component';
import { BookingDetailsLockLinkComponent } from './booking-details-lock-link/booking-details-lock-link.component';
import { BookingDetailsEventsComponent } from './booking-details-events/booking-details-events.component';
import { BookingDetailsDataComponent } from './booking-details-data/booking-details-data.component';
import { BookingDetailsPathComponent } from './booking-details-path/booking-details-path.component';
import { BookingDetailsDataItemComponent } from './booking-details-data-item/booking-details-data-item.component';

@NgModule({
	declarations: [
		BookingDetailsComponent,
		BookingDetailsGeneralComponent,
		BookingDetailsDocsComponent,
		BookingDetailsVehicleComponent,
		BookingDetailsGalleryDialogComponent,
		BookingDetailsLinksComponent,
		BookingDetailsEmailDialogComponent,
		BookingDetailsNavbarComponent,
		BookingDetailsUnlockLinkComponent,
		BookingDetailsLockLinkComponent,
		BookingDetailsEventsComponent,
		BookingDetailsDataComponent,
		BookingDetailsPathComponent,
		BookingDetailsDataItemComponent,
	],
	imports: [
		CommonModule,
		BookingDetailsRoutingModule,
		SharedModule,
		BookingModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		MatMenuModule,
		TranslateModule,
		MatSlideToggleModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSelectModule,
		ReactiveFormsModule,
		FormsModule,
	],
})
export class BookingDetailsModule {}
