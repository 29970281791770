<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			(click)="onCloseClick()"
			class="lk-primary-medium-background">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-vehicle-direct-link-content-layout"
		class="lk-horiz-padding">
		<h2 class="lk-accent-color">
			{{ 'VEHICLE.DIRECT_LINK_DIALOG_TITLE' | translate }}
		</h2>

		<h3>{{ 'VEHICLE.DIRECT_LINK_INTERVAL_CTA' | translate }}</h3>

		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="direct_link_time_form_group">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-half-width lk-half-margin-bottom">
				<mat-label> {{ 'BOOKING.START_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-booking-time-builder-start-date-input"
					matInput
					type="text"
					formControlName="start_date"
					required
					[max]="direct_link_time_form_group.get('end_date')?.value"
					[matDatepicker]="startPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-half-margin-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.START_HOUR_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-start-hour-select"
					formControlName="start_hour"
					required>
					<mat-option
						*ngFor="let hour of hours"
						[value]="hour">
						{{ hour }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-half-margin-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.START_MINUTE_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-start-minute-select"
					formControlName="start_minute"
					required>
					<mat-option
						*ngFor="let minute of minutes"
						[value]="minute">
						{{ minute }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				class="lk-half-width lk-half-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'BOOKING.END_COLUMN_LABEL' | translate }} </mat-label>
				<input
					id="lk-booking-time-builder-end-date-input"
					type="text"
					matInput
					formControlName="end_date"
					required
					[min]="direct_link_time_form_group.get('start_date')?.value"
					[matDatepicker]="endPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-half-margin-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.END_HOUR_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-end-hour-select"
					formControlName="end_hour"
					required>
					<mat-option
						*ngFor="let hour of hours"
						[value]="hour">
						{{ hour }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-half-margin-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.END_MINUTE_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-end-minute-select"
					formControlName="end_minute"
					required>
					<mat-option
						*ngFor="let minute of minutes"
						[value]="minute">
						{{ minute }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-half-margin-bottom">
				<mat-label> Timezone </mat-label>
				<mat-select
					id="lk-booking-time-builder-timezone-select"
					formControlName="timezone"
					required>
					<mat-option
						*ngFor="let timezone of timezones"
						[value]="timezone">
						({{ getTimezoneOffset(timezone) }})
						{{ timezone }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				floatLabel="always"
				color="accent"
				class="lk-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
				</mat-label>
				<img
					*ngIf="current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-half-margin-right"
					[src]="unknown_flag_src" />

				<input
					id="lk-booking-phone-builder-prefix-input"
					type="text"
					matInput
					formControlName="phone_prefix"
					[matAutocomplete]="autocountry" />
				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								style="min-width: 32px; height: 24px"
								class="lk-half-margin-right"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="
										max-width: calc(100% - 6px);
										font-size: 10px;
										line-height: 18px;
									"
									class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-no-margin lk-ellipsized-text">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-three-third-width lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					id="lk-booking-phone-builder-phone-input"
					matInput
					type="tel"
					formControlName="phone" />
			</mat-form-field>

			<div>
				<h3>{{ 'VEHICLE.DIRECT_LINK_TYPE_CTA' | translate }}</h3>
				<mat-radio-group
					aria-label="Select an option"
					class="lk-column"
					formControlName="type">
					<mat-radio-button
						class="lk-half-margin-bottom"
						value="1"
						>{{
							'VEHICLE.DIRECT_LINK_TYPE_OPTION_1_CTA' | translate
						}}</mat-radio-button
					>
					<mat-radio-button
						class="lk-half-margin-bottom"
						value="2"
						>{{
							'VEHICLE.DIRECT_LINK_TYPE_OPTION_2_CTA' | translate
						}}</mat-radio-button
					>
					<!--<mat-radio-button
						class="lk-half-margin-bottom"
						value="3"
						>{{
							'VEHICLE.DIRECT_LINK_TYPE_OPTION_3_CTA' | translate
						}}</mat-radio-button
					>-->
				</mat-radio-group>
			</div>

			<div class="lk-centered-row lk-full-width lk-margin-top">
				<button
					mat-button
					[disabled]="!isFormValid() || is_send_running"
					[ngClass]="getDisabledClass()"
					class="lk-main-button"
					(click)="onConfirmClick()">
					<h3
						class="lk-no-margin lk-primary-color"
						*ngIf="!is_send_running">
						{{ 'GENERAL.CONFIRM_CTA' | translate }}
					</h3>
					<div
						class="lk-spinner-layout"
						*ngIf="is_send_running">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
