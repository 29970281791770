<div
	id="lk-booking-details-vehicle-root-layout"
	class="lk-border-radius lk-shadow lk-padding lk-primary-background lk-hide-scrollbar lk-full-height"
	[ngClass]="getProductionLayoutClass()">
	<div class="lk-full-width lk-spaced-row">
		<h2 class="lk-no-margin lk-subtitle-size">
			{{ 'BOOKING_DETAILS.VEHICLE_SECTION_LABEL' | translate }}
		</h2>

		<button
			*ngIf="isAddVehicleVisible(booking)"
			id="lk-booking-details-vehicle-update-button"
			mat-icon-button
			(click)="onVehicleUpdateClick()"
			class="lk-background-icon"
			matTooltip="{{ 'BOOKING_DETAILS.UPDATE_VEHICLE_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> create </mat-icon>
		</button>
	</div>

	<div class="lk-half-margin-top">
		<div class="lk-double-margin-top">
			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					id="lk-booking-details-vehicle-plate-text"
					class="lk-no-margin lk-cursor-pointer lk-cursor-link-hover lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle"
					(click)="onVehicleClick()">
					{{ booking.vehicle.plate }}
				</p>
				<p
					id="lk-booking-details-vehicle-plate-empty-text"
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle">
					{{ booking.vehicle.brand }} {{ booking.vehicle.model }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.COLOR_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle">
					{{ booking.vehicle.color }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle">
					{{ booking.vehicle.category }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.YEAR_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle">
					{{ booking.vehicle.year }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.STATUS_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<div
					class="lk-border-radius"
					*ngIf="booking.vehicle"
					[ngClass]="getVehicleStatusClass(booking.vehicle)"
					style="padding: 2px 12px; width: fit-content">
					<span *ngIf="booking.vehicle.status">
						{{ booking.vehicle.status | lowercase }}
					</span>
					<span *ngIf="!booking.vehicle.status"> unknown </span>
				</div>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row lk-wrapped-flex lk-margin-top">
				<button
					mat-button
					(click)="onStartVehicleClick(booking.vehicle)"
					[disabled]="is_start_vehicle_running"
					[ngClass]="getStartDisabledClass()"
					class="lk-main-button lk-half-width lk-half-margin-bottom">
					<div
						class="lk-spinner-layout lk-primary-color"
						*ngIf="is_start_vehicle_running">
						<mat-spinner
							class="lk-white-spinner-layout"
							[diameter]="20"></mat-spinner>
					</div>

					<div
						*ngIf="!is_start_vehicle_running"
						class="lk-centered-row">
						<mat-icon> lock_open </mat-icon>
						<p
							class="lk-primary-color lk-ellipsized-text"
							style="margin: 0 0 0 4px">
							{{ 'VEHICLE_DETAILS.UNLOCK_VEHICLE' | translate }}
						</p>
					</div>
				</button>

				<button
					mat-button
					(click)="onStopVehicleClick(booking.vehicle)"
					[disabled]="is_stop_vehicle_running"
					[ngClass]="getStopDisabledClass()"
					class="lk-main-button lk-half-width lk-half-margin-bottom">
					<div
						class="lk-spinner-layout"
						*ngIf="is_stop_vehicle_running">
						<mat-spinner
							class="lk-white-spinner-layout"
							[diameter]="20"></mat-spinner>
					</div>

					<div
						*ngIf="!is_stop_vehicle_running"
						class="lk-centered-row">
						<mat-icon> lock </mat-icon>
						<p
							class="lk-primary-color lk-ellipsized-text"
							style="margin: 0 0 0 4px">
							{{ 'VEHICLE_DETAILS.LOCK_VEHICLE' | translate }}
						</p>
					</div>
				</button>
			</div>
		</div>
	</div>
</div>
