import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export const UNLOCK_KEY_API_NAME: string = 'unlock_key';
export const UNLOCK_KEY_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/vehicle-unlock-keys/';
export const LOCK_KEY_API_NAME: string = 'lock_key';
export const LOCK_KEY_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/vehicle-lock-keys/';

export const UNLOCK_KEY_V2_API_NAME: string = 'unlock_key_v2';
export const UNLOCK_KEY_V2_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/unlock-keys/';
export const LOCK_KEY_V2_API_NAME: string = 'lock_key_v2';
export const LOCK_KEY_V2_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/lock-keys/';

export const DIRECT_LINK_API_NAME: string = 'direct_key';
export const DIRECT_LINK_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/direct-key/';

@Injectable({
	providedIn: 'root',
})
export class KeysService {
	readUnlockKey(station_id: string, booking_id: string) {
		let apiName: string = UNLOCK_KEY_API_NAME;
		let path: string = 'stations/' + station_id + '/bookings/' + booking_id;
		let init = {
			response: true,
		};
		return from(API.get(apiName, path, init));
	}

	readLockKey(station_id: string, booking_id: string) {
		let apiName: string = LOCK_KEY_API_NAME;
		let path: string = 'stations/' + station_id + '/bookings/' + booking_id;
		let init = {
			response: true,
		};
		return from(API.get(apiName, path, init));
	}

	readUnlockKeyV2(
		station_id: string,
		booking_id: string,
		unlock_key_id: string,
	) {
		let apiName: string = UNLOCK_KEY_V2_API_NAME;
		let path: string =
			unlock_key_id + '/stations/' + station_id + '/bookings/' + booking_id;
		let init = {
			response: true,
		};
		return from(API.get(apiName, path, init));
	}

	readLockKeyV2(station_id: string, booking_id: string, lock_key_id: string) {
		let apiName: string = LOCK_KEY_V2_API_NAME;
		let path: string =
			lock_key_id + '/stations/' + station_id + '/bookings/' + booking_id;
		let init = {
			response: true,
		};
		return from(API.get(apiName, path, init));
	}

	sendUnlockKeyV2(
		station_id: string,
		booking_id: string,
		unlock_key_id: string,
	) {
		let apiName: string = UNLOCK_KEY_V2_API_NAME;
		let path: string =
			unlock_key_id +
			'/stations/' +
			station_id +
			'/bookings/' +
			booking_id +
			'/send';
		let init = { response: true };
		return from(API.post(apiName, path, init));
	}

	sendLockKeyV2(station_id: string, booking_id: string, lock_key_id: string) {
		let apiName: string = LOCK_KEY_V2_API_NAME;
		let path: string =
			lock_key_id +
			'/stations/' +
			station_id +
			'/bookings/' +
			booking_id +
			'/send';
		let init = { response: true };
		return from(API.post(apiName, path, init));
	}

	createDirectKey(
		station_id: string,
		vehicle_id: string,
		nbf: number,
		exp: number,
		full_phone?: string,
		type?: string,
	): Observable<any> {
		const api_name = DIRECT_LINK_API_NAME;
		const path = `stations/${station_id}/vehicle/${vehicle_id}`;
		const init = {
			response: true,
			body: {
				nbf,
				exp,
				full_phone,
				type,
			},
		};
		return from(API.post(api_name, path, init));
	}

	listDirectKeys(station_id: string, vehicle_id: string) {
		const api_name = DIRECT_LINK_API_NAME;
		const path = `stations/${station_id}/vehicle/${vehicle_id}`;
		const init = {
			response: true,
		};
		return from(API.get(api_name, path, init));
	}

	enableDirectKey(
		direct_link_id: string,
		station_id: string,
		vehicle_id: string,
	): Observable<any> {
		const api_name = DIRECT_LINK_API_NAME;
		const path = `${direct_link_id}/stations/${station_id}/vehicle/${vehicle_id}/enable`;
		const init = {
			response: true,
		};
		return from(API.post(api_name, path, init));
	}

	disableDirectKey(
		direct_link_id: string,
		station_id: string,
		vehicle_id: string,
	): Observable<any> {
		const api_name = DIRECT_LINK_API_NAME;
		const path = `${direct_link_id}/stations/${station_id}/vehicle/${vehicle_id}/disable`;
		const init = {
			response: true,
		};
		return from(API.post(api_name, path, init));
	}
}

export interface DirectKey {
	id: string;
	object: 'direct_key';
	status: 'active' | 'disable';
	short_url: string;
	url: string;
	vehicle_id: string;
	station_id: string;
	consumed_at: number;
	created_at: number;
	updated_at: number;
	nbf?: number;
	exp?: number;
}
