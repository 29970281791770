<div>
	<div class="lk-ended-row">
		<button
			mat-icon-button
			class="lk-primary-medium-background"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>
	<div
		id="lk-auth-forgot-password-content-layout"
		class="lk-horiz-padding lk-margin-top">
		<p class="lk-no-margin lk-margin-bottom">
			{{ 'AUTHENTICATION.FORGOT_PSS_MESSAGE' | translate }}
		</p>
		<form [formGroup]="formGroup">
			<mat-form-field
				class="lk-full-width lk-margin-bottom"
				appearance="fill"
				color="accent">
				<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
				<input
					type="email"
					matInput
					formControlName="username"
					placeholder="address@email.com" />
			</mat-form-field>

			<div class="lk-full-width lk-centered-row lk-margin-bottom">
				<button
					mat-button
					class="lk-main-button"
					(click)="onConfirmClick()"
					[disabled]="isForgotPssRunning"
					[ngClass]="getDisabledClass()">
					<h3
						class="lk-no-margin lk-primary-color"
						*ngIf="!isForgotPssRunning">
						{{ 'GENERAL.NEXT_STEP_BUTTON_CTA' | translate }}
					</h3>
					<div
						class="lk-spinner-layout"
						*ngIf="isForgotPssRunning">
						<mat-spinner [diameter]="20"></mat-spinner>
					</div>
				</button>
			</div>
		</form>
	</div>
</div>
