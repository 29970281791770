import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { API } from 'aws-amplify';

export const BOOKINGS_MEDIA_API_NAME: string        = 'booking-media';
export const BOOKINGS_MEDIA_API_ENDPOINT: string    = environment.API_BASE_PATH + '/booking-media/';

@Injectable({
  providedIn: 'root'
})
export class BookingMediaService {
  listBookingMedia(stationId: string, bookingId: string) {
    let apiName : string  = BOOKINGS_MEDIA_API_NAME;
    let path    : string  = 'stations/' + stationId + '/bookings/' + bookingId;
    let init              = { response  : true };
    return from(API.get(apiName, path, init));
  }
}

export class BookingMedia {
  id          : string;
  name        : VehicleReportName;
  type        : BookingMediaType;
  path        : string;
  content     : string;
  content_url : string;
}

export enum VehicleReportName {
  FRONT       = 'front',
  FRONT_RIGHT = 'front_right',
  BACK_RIGHT  = 'back_right',
  BACK        = 'back',
  BACK_LEFT   = 'back_left',
  FRONT_LEFT  = 'front_left'
}

export enum BookingMediaType {
  PRE_REPORT  = 'pre_report',
  POST_REPORT = 'post_report'
}
