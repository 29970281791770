import {Component, OnInit} from '@angular/core';
import {Notification, NotificationName, NOTIFICATIONS, NotificationService, NotificationType} from '../../core/notification.service';
import {SUPPORTED_LANGUAGES} from '../../shared/constants';
import {BOOKING_CANCELED_EDIT_SMS_LABEL, BOOKING_CANCELED_EMAIL_LABEL, BOOKING_DATES_EDITED_EMAIL_LABEL, BOOKING_DATES_EDITED_SMS_LABEL, BOOKING_VEHICLE_EDITED_EMAIL_LABEL, BOOKING_VEHICLE_EDITED_SMS_LABEL, DEFAULT_LANGUAGE_LABEL, END_RENTAL_EMAIL_LABEL, END_RENTAL_SMS_LABEL, GENERAL_ERROR_MESSAGE, START_RENTAL_EMAIL_LABEL, START_RENTAL_SMS_LABEL, STATION_BUILDER_NOTIF_STEP, SUPPORTED_LANGUAGES_LABELS, TOOLBAR_STATION_NAV_ITEM_NAME} from '../../shared/translations';
import {ActivatedRoute, Router} from '@angular/router';
import {Station, StationService} from '../../core/station.service';
import {DialogService} from 'src/app/core/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { STATION_PATH } from 'src/app/core/navigation.service';

@Component({
  selector: 'app-station-builder-notif-list',
  templateUrl: './station-builder-notif-list.component.html',
  styleUrls: ['./station-builder-notif-list.component.scss']
})
export class StationBuilderNotifListComponent implements OnInit {
  station_id: string;
  //current_language: string;
  station: Station;
  notifications: Notification[];
  current_notif_loading: Notification;
  station_update_trigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  current_item: string = TOOLBAR_STATION_NAV_ITEM_NAME;
  sms: Notification[] = [];
  emails: Notification[] = [];
  sms_type: NotificationType = NotificationType.SMS;
  email_type: NotificationType = NotificationType.EMAIL;
  languages: string[] = SUPPORTED_LANGUAGES;
  update_mode: boolean = false;
  is_request_running: boolean = false;
  default_check: boolean = false;
  current_step: string = STATION_BUILDER_NOTIF_STEP;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private dialog_service: DialogService,
    private dialog: MatDialog,
    private station_service: StationService,
    private notif_service: NotificationService
  ){
    this.dialog_service.dialog = this.dialog;
    this.station_id = this.route.snapshot.paramMap.get('station_id');
    //this.current_language = this.route.snapshot.paramMap.get('lang');
    this.route.queryParams.subscribe(params => {
        if (params && params.update) {
          this.update_mode = params.update;
        }
      }
    );
    if (!this.station_id /*|| !this.current_language*/) {
      this.router.navigate([STATION_PATH]);
    }
  }

  ngOnInit() {
    this.station_update_trigger.next(false);
    if (this.station_id) {
      this.readStation();
    }
  }

  onNotificationInput(event, notification: Notification) {
    let file: File = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      notification.content = reader.result.toString();
      //notification.lang = this.current_language;
      notification.lang = '';
      if (notification.id) {
        this.updateNotification(notification);
      } else {
        this.addNotification(notification);
      }
    }
  }

  onNextClick() {
    this.router.navigate([STATION_PATH]);
    /*this.dialog_service.openDialog(StationBuilderNotifDialogComponent).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.add) {
          if (this.update_mode) {
            this.router.navigate(
              [STATION_PATH + '/' + this.station_id + STATION_BUILDER_LANG_PATH], 
              { queryParams: { update: true } }
            );
          } else {
            this.router.navigate([STATION_PATH + '/' + this.station_id + STATION_BUILDER_LANG_PATH]);
          }
        } else if (dialogResult && dialogResult.store) {
          this.router.navigate([STATION_PATH]);
        }
      }
    );*/
  }

  onNotifDeleteClick(notification: Notification) {
    this.deleteNotification(notification);
  }

  onSetDefaultLangClick(event: MatSlideToggleChange) {
    /*if (event.checked) {
      this.default_check = true;
      this.station.settings.default_lang = this.current_language;
      this.updateStation();
    }*/
  }

  onUpdateClick() {
    this.router.navigate([STATION_PATH]);
  }

  onLangClick(lang: string) {
    /*this.current_language = lang;
    this.default_check = false;
    if (this.station_id) {
      this.readStation();
    }*/
  }

  readStation() {
    this.is_request_running = true;
    this.station_service.readStation(this.station_id).subscribe(
      response => {
        this.station = response.data;
        /*if (this.station.settings.default_lang == this.current_language) {
          this.default_check = true;
        }*/
        this.listNotifications();
      }, error => {
        this.router.navigate([STATION_PATH]);
        this.is_request_running = false;
      }
    );
  }

  listNotifications() {
    this.is_request_running = true;
    this.notif_service.listNotifications(this.station_id).subscribe(
      response => {
        this.notifications = response.data;
        this.is_request_running = false;
        this.setSmsAndEmails();
      }, error => {
        this.notifications = [];
        this.is_request_running = false;
        this.setSmsAndEmails();
      }
    );
  }

  addNotification(notification: Notification) {
    this.current_notif_loading = notification;
    this.notif_service.addNotification(this.station_id, notification).subscribe(
      response => {
        this.current_notif_loading = null;
        this.listNotifications();
      }, error => {
        this.current_notif_loading = null;
        if (error.response.data.error && error.response.data.error.description) {
          this.dialog_service.openInfoDialog(error.response.data.error.description);
        } else {
          this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
        }
      }
    );
  }

  updateNotification(notification: Notification) {
    this.current_notif_loading = notification;
    this.notif_service.updateNotification(this.station_id, notification).subscribe(
      response => {
        this.current_notif_loading = null;
        this.listNotifications();
      }, error => {
        this.current_notif_loading = null;
        if (error.response.data.error && error.response.data.error.description) {
          this.dialog_service.openInfoDialog(error.response.data.error.description);
        } else {
          this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
        }
      }
    );
  }

  deleteNotification(notification: Notification) {
    this.current_notif_loading = notification;
    this.notif_service.removeNotification(this.station_id, notification).subscribe({
      next: (response) => {
        this.current_notif_loading = null;
        this.listNotifications();
      },
      error: (error) => {
        this.current_notif_loading = null;
        if (error.response.data.error && error.response.data.error.description) {
          this.dialog_service.openInfoDialog(error.response.data.error.description);
        } else {
          this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
        }
      }
    });
  }

  updateStation() {
    this.is_request_running = true;
    this.station_service.updateStation(this.station).subscribe(
      response => {
        this.is_request_running = false;
        this.readStation();
      },
      error   => {
        this.is_request_running = false;
        if (error.response.data.error && error.response.data.error.description) {
          this.dialog_service.openInfoDialog(error.response.data.error.description);
        } else {
          this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
        }
      }
    );
  }

  setSmsAndEmails() {
    //const langNotifications = this.notifications.filter(n => { return n.lang == this.current_language; });
    const langNotifications = this.notifications;
    const sms = langNotifications.filter(n => { return n.type == NotificationType.SMS; });
    const emails = langNotifications.filter(n => { return n.type == NotificationType.EMAIL; });
    const smsNames = sms.map(n => n.name);
    const emailNames = emails.map(n => n.name);

    const missingSms = NOTIFICATIONS.filter(n => {
      return n.type == NotificationType.SMS && !smsNames.includes(n.name);
    });
    const missingEmails = NOTIFICATIONS.filter(n => {
      return n.type == NotificationType.EMAIL && !emailNames.includes(n.name);
    });

    this.sms = sms.concat(missingSms);
    this.emails = emails.concat(missingEmails);
  }

  getNotifTextClass(notification: Notification) {
    return {
      'lk-accent-color':  notification.content_url,
      '':                 !notification.content_url
    }
  }

  getNotifLabel(type: NotificationType, name: NotificationName) {
    if (type == NotificationType.SMS) {
      switch(name) {
        case NotificationName.BOOKING_START:
          return START_RENTAL_SMS_LABEL;
        case NotificationName.BOOKING_END:
          return END_RENTAL_SMS_LABEL;
        case NotificationName.BOOKING_VEHICLE_CHANGED:
          return BOOKING_VEHICLE_EDITED_SMS_LABEL;
        case NotificationName.BOOKING_DATES_CHANGED:
          return BOOKING_DATES_EDITED_SMS_LABEL;
        case NotificationName.BOOKING_DELETED:
          return BOOKING_CANCELED_EDIT_SMS_LABEL;
      }
    } else if (type == NotificationType.EMAIL) {
      switch(name) {
        case NotificationName.BOOKING_START:
          return START_RENTAL_EMAIL_LABEL;
        case NotificationName.BOOKING_END:
          return END_RENTAL_EMAIL_LABEL;
        case NotificationName.BOOKING_VEHICLE_CHANGED:
          return BOOKING_VEHICLE_EDITED_EMAIL_LABEL;
        case NotificationName.BOOKING_DATES_CHANGED:
          return BOOKING_DATES_EDITED_EMAIL_LABEL;
        case NotificationName.BOOKING_DELETED:
          return BOOKING_CANCELED_EMAIL_LABEL;
      }
    }
    return name;
  }

  getLanguageLabel() {
    /*switch (this.current_language) {
      case this.languages[0]:
        return SUPPORTED_LANGUAGES_LABELS[0];
      case this.languages[1]:
        return SUPPORTED_LANGUAGES_LABELS[1];
      default:
        return DEFAULT_LANGUAGE_LABEL;
    }*/
  }

  isCurrentNotifLoading(notification: Notification) {
    return (this.current_notif_loading 
      && this.current_notif_loading.name == notification.name
      && this.current_notif_loading.type == notification.type
    );
  }

  isSmsEnabled() {
    return (
      this.station &&
      this.station.settings.default_notification_type &&
      (this.station.settings.default_notification_type == NotificationType.SMS ||
        this.station.settings.default_notification_type == NotificationType.BOTH)
    );
  }

  isEmailEnabled() {
    return (
      this.station &&
      this.station.settings.default_notification_type &&
      (this.station.settings.default_notification_type == NotificationType.EMAIL ||
        this.station.settings.default_notification_type == NotificationType.BOTH)
    );
  }
}
