import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetabaseRoutingModule } from './metabase-routing.module';
import { SharedModule } from '../shared/shared.module';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MetabaseComponent } from './metabase/metabase.component';
import { MetabaseLoadingComponent } from './metabase-loading/metabase-loading.component';
import { MetabaseLoadedComponent } from './metabase-loaded/metabase-loaded.component';

@NgModule({
	declarations: [
		MetabaseComponent,
		MetabaseLoadingComponent,
		MetabaseLoadedComponent,
	],
	imports: [
		CommonModule,
		MetabaseRoutingModule,
		SharedModule,
		MatProgressSpinnerModule,
	],
})
export class MetabaseModule {}
