<div
	id="lk-station-add-team-dialog-root-layout"
	class="lk-hide-scrollbar">
	<div class="lk-ended-row">
		<button
			mat-icon-button
			class="lk-background-icon"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-margin-top lk-padding">
		<form
			[formGroup]="form_group"
			class="lk-full-width lk-spaced-row lk-wrapped-flex">
			<div class="lk-full-width lk-spaced-row">
				<mat-form-field
					style="width: calc(100% - 70px)"
					appearance="fill"
					color="accent">
					<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
					<input
						matInput
						type="email"
						formControlName="email" />
				</mat-form-field>

				<button
					(click)="onAddTeammateClick()"
					mat-fab>
					<mat-icon class="lk-primary-color"> add </mat-icon>
				</button>
			</div>

			<div
				style="background: rgba(0, 0, 0, 0.04)"
				class="lk-row lk-wrapped-flex lk-full-width lk-padding lk-margin-top lk-margin-bottom lk-border-radius">
				<p
					*ngIf="!invites || !invites.length"
					class="lk-primary-medium-color lk-centered-text lk-full-width">
					{{ 'STATION.TEAM_EMPTY_TEXT' | translate }}
				</p>

				<div
					*ngFor="let invite of invites"
					class="lk-chips-layout lk-primary-medium-background lk-centered-row lk-half-margin-top lk-half-margin-right">
					<span
						style="font-size: 12px"
						class="lk-primary-medium-color">
						{{ invite }}
					</span>
				</div>
			</div>

			<button
				mat-button
				[disabled]="!invites || !invites.length || is_team_loading"
				[ngClass]="getDisabledClass()"
				(click)="onConfirmClick()"
				class="lk-main-button lk-full-width">
				<h3
					class="lk-no-margin lk-primary-color"
					*ngIf="!is_team_loading">
					{{ 'GENERAL.CONFIRM_CTA' | translate }}
				</h3>
				<div
					class="lk-spinner-layout"
					*ngIf="is_team_loading">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			</button>
		</form>
	</div>
</div>
