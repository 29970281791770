import { Injectable } from '@angular/core';
import { Address, Phone } from './core.module';
import { NotificationType } from './notification.service';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { environment } from '../../environments/environment';

export const STATIONS_API_NAME: string = 'stations';
export const STATIONS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/stations/';

@Injectable({
	providedIn: 'root',
})
export class StationService {
	public createStation(station: Station) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = '';
		let init = {
			response: true,
			body: station,
		};
		return from(API.post(apiName, path, init));
	}

	public readStation(stationId: string) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = stationId;
		let init = { response: true };
		return from(API.get(apiName, path, init));
	}

	public updateStation(station: Station) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = station.id;
		let init = {
			response: true,
			body: station,
		};
		return from(API.put(apiName, path, init));
	}

	public deleteStation(stationId: string) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = stationId;
		let init = { response: true };
		return from(API.del(apiName, path, init));
	}

	public listStations(
		page?: number,
		per_page?: number,
		query_params?: StationQueryParams,
	) {
		const STATIONS_PER_PAGE: number = per_page ? per_page : 36;
		let apiName: string = STATIONS_API_NAME;
		let path: string = '';
		let queryStringParams = {
			page: page ? page : 1,
			per_page: page ? STATIONS_PER_PAGE : 100000000,
			...query_params,
		};
		let init = {
			response: true,
			queryStringParameters: queryStringParams,
		};
		return from(API.get(apiName, path, init));
	}

	public setLogo(stationId: string, logo: string) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = stationId + '/logo';
		let init = {
			response: true,
			body: { logo: logo },
		};
		return from(API.patch(apiName, path, init));
	}

	public setCustomLogo(stationId: string, logo: string) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = stationId + '/custom-guest-logo';
		let init = {
			response: true,
			body: { logo: logo },
		};
		return from(API.patch(apiName, path, init));
	}

	public addServices(stationId: string, services: Service[]) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = stationId + '/services/add';
		let init = {
			response: true,
			body: services,
		};
		return from(API.post(apiName, path, init));
	}

	public removeServices(stationId: string, services: Service[]) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = stationId + '/services/remove';
		let init = {
			response: true,
			body: services,
		};
		return from(API.post(apiName, path, init));
	}

	public setAlias(stationId: string, alias: string) {
		let apiName: string = STATIONS_API_NAME;
		let path: string = stationId + '/alias';
		let init = {
			response: true,
			body: { alias },
		};
		return from(API.patch(apiName, path, init));
	}
}

export interface StationQueryParams {
	name?: string;
	alias?: string;
}

export class Station {
	id: string;
	name: string;
	alias: string;
	email: string;
	ext_email: string;
	ext_timezone: string;
	ext_time_format: string;
	address: Address;
	logo: string;
	logo_url: string;
	notification_logo_url: string;
	logo_path: string;
	phone: Phone;
	services: Service[];
	settings: Settings;
	meta_dashboard: number;
	vehicle_collection_length?: number;
}

export class Service {
	type: string;
	value: string;
}

export class Settings {
	default_lang: string;
	default_notification_type: NotificationType;
	pictures_policy: PicturePolicy;
	lock_settings: LockSettings;
	unlock_settings: UnlockSettings;
	flow: Flow;
	odometer_um: string;
	date_format: string;
	is_area_control_enabled?: boolean;
	is_interact_after_close_enabled?: boolean;
	is_parking_input_enabled?: boolean;
	is_unlock_confirmation_enabled?: boolean;
	is_custom_logo_enabled?: boolean;
	is_auto_close_enabled?: boolean;
	is_booking_complete_notif_enabled?: boolean;
	is_booking_complete_custom_template_enabled?: boolean;
	is_booking_vehicle_changed_custom_template_enabled?: boolean;
	is_team_management_enabled?: boolean;
	checklist: StationChecklist;
	custom_sms_sender_alias: string;
	custom_email_sender_address: string;
	custom_guest_logo: string;
	low_voltage_threshold?: number;
}

export class StationChecklist {
	messages: StationChecklistMessage[];
}

export class StationChecklistMessage {
	en?: string;
	es?: string;
	it?: string;
}

export class LockSettings {
	is_link_invalid: boolean;
	is_lockable_after_vehicle_unlock: boolean;
	time_to_lock_link_before_booking_end: number;
	//time_to_reminder_before_booking_end         : number;
	time_to_lock_availability_before_booking_end: number;
	time_to_lock_availability_after_booking_end: number;
}

export class UnlockSettings {
	is_link_invalid: boolean;
	is_unlockable_on_vehicle_assignment: boolean;
	is_unlockable_until_booking_end_date: boolean;
	time_to_unlock_link_before_booking_start: number;
	time_to_unlock_availability_before_booking_start: number;
	time_to_unlock_availability_after_booking_start: number;
}

export class Flow {
	booking_start_constraints: Constraint[];
	booking_stop_constraints: Constraint[];
}

export class Constraint {
	type: ConstraintType;
	index: number;
}

export enum PicturePolicy {
	MANDATORY = 'mandatory',
	MANDATORY_PRE = 'mandatory_pre',
	MANDATORY_POST = 'mandatory_post',
	OPTIONAL = 'optional',
	DISABLED = 'disabled',
}

export enum ConstraintType {
	DOCUMENTS = 'documents',
	PAYMENT = 'payment',
	START_CONTRACT_SIGN = 'start_contract_sign',
	END_CONTRACT_SIGN = 'end_contract_sign',
}
