import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-spinner-overlay',
  templateUrl: './shared-spinner-overlay.component.html',
  styleUrls: ['./shared-spinner-overlay.component.scss']
})
export class SharedSpinnerOverlayComponent implements OnInit {
  @Input() show_spinner: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
